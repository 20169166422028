import Select from "antd/lib/select";
import { FC } from "react";
import paymentMethodData from "../../../data/paymentMethod.json";

const { Option } = Select;

interface IProps {
  initialValue: any;
  onChange?: ((value: any[], option: any) => void) | undefined;
}

const PaymentMethodSelect: FC<IProps> = (props: any) => {
  const { initialValue, onChange } = props;
  return (
    <Select
      defaultValue={initialValue}
      mode="multiple"
      placeholder="Seleccione uno o mas medios de pago"
      optionLabelProp="label"
      onChange={onChange}
    >
      {paymentMethodData.map((option: any, idx: number) => (
        <Option key={idx} value={option.value} label={option.name}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
};
export default PaymentMethodSelect;
