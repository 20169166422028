import "./Tickets.css";
import Table from "../table/customizedTable/CustomizedTable";
import HelmetTitle from "../other/HelmetTitle";
import { Col, Row, Form, Button } from "antd";
import PageTitle from "../other/PageTitle";
import { Columns } from "./Columns";
import Filters from "../shared/filters/Filters";
//import { fields } from "./fields";
import { useFilters } from "../../hooks/useFilters";
import { fetchStoresTickets } from "../../actions/tickets.actions";
import { FilterSection } from "../../types/enum/filters";
import { useTickets } from "../../hooks/useTickets/useTickets";
import { Ticket } from "./Ticket";
import { FilterOutlined } from "@ant-design/icons";
import HorizontalFilter from "../shared/filters/HorizontalFilter";
import { DownloadCSV } from "../shared/buttons/DownloadCSV";
import { headers } from "./FileHeaders";
import { useState } from "react";
import useTicketFields from "../../hooks/useTickets/useTicketFields";

const Tickets = () => {
  const [defaultParams, setDefaultParams] = useState("")

  const {fields, form} = useTicketFields();

  const {
    filters,
    handleChange,
    handleChangeSelect,
    resetFilter,
    toggleFilters,
    collapsed,
    handleDonwload,
    onFinishFiltering,
  } = useFilters(
    fetchStoresTickets,
    fields,
    FilterSection.TICKETS,
    defaultParams,
    form
  );
  
  const {
    ticketsState,
    pagination,
    items,
    date,
    handleOpenTicket,
    handleCloseTicket,
    isModal
  } = useTickets(form);
  

  const columns = Columns(handleOpenTicket);

  return (
    <>
      <HelmetTitle
        title="Tickets - Backoffice D&#38;N"
        description="Pantalla para listar, crear, y editar tickets"
      />
      {
        <>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 5 }}>
              <PageTitle title="Tickets" />
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 19 }}>
              <Row className="d-flex flex-end">
                <DownloadCSV
                  headers={headers}
                  date={date}
                  section={FilterSection.TICKETS}
                  handleDownload={handleDonwload}
                />

                <Button type="text" onClick={toggleFilters}>
                  <FilterOutlined />
                  Filtros
                </Button>
              </Row>
            </Col>
          </Row>

          <Form form={form} onFinish={onFinishFiltering}>
            <Filters
              fields={fields}
              filters={filters}
              collapsed={collapsed}
              onFinishFiltering={onFinishFiltering}
              productStoreFilters={true}
              toggleFilters={toggleFilters}
              resetFilter={resetFilter}
              handleChange={handleChange}
              handleChangeSelect={handleChangeSelect}
              form={form}
            />

            <HorizontalFilter
              fields={fields}
              onFinishFiltering={onFinishFiltering}
              filters={filters}
              handleChange={handleChange}
              resetFilter={resetFilter}
              handleChangeSelect={handleChangeSelect}
              form={form}
            />
          </Form>

          <Row>
            <Col span={24}>
              <Table
                dataSource={ticketsState.tickets.data.data}
                columns={columns}
                name="Tickets"
                path="stores"
                loading={ticketsState.tickets.loading}
                pagination={pagination}
              />
            </Col>
          </Row>
        </>
      }
      <Ticket
        showModal={isModal}
        closeModal={handleCloseTicket}
        items={items}
      />
    </>
  );
};

export default Tickets;