import { Button, Tooltip } from "antd";
import CopyTextColumn from "../other/CopyTextColumn";
import { ShoppingOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { returnDateFormat } from "../../utils/localDate";

export const Columns = (handleOpenTicket: any) => {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: function RevealTextOnHover(text: any, record: any, index: any) {
        return <CopyTextColumn text={text} record={record} />;
      },
    },
    {
      title: "Comercio",
      dataIndex: ["store", "storeName"],
      key: "store",
      sorter: (a: any, b: any) =>
        a.store.storeName.localeCompare(b.store.storeName),
    },

    {
      title: "Fecha de creación",
      dataIndex: "date",
      key: "date",
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.date),
      render: (text: any, record: any) => returnDateFormat(text),
    },
    {
      title: "Fecha de sincronización",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
      /*
      render:(text: any, record: any) => returnDateFormat(text),
      */
      render: (text: any, record: any) => text,

    },
    {
      title: "Total",
      dataIndex: "totalPrice",
      key: "totalPrice",
      sorter: (a: any, b: any) =>
        parseInt(a.totalPrice) - parseInt(b.totalPrice),
    },
    {
      title: "Moneda",
      dataIndex: "currency",
      key: "currency",
      sorter: (a: any, b: any) => {
        if (a.currency && b.currency) {
          return a.currency.localeCompare(b.currency);
        }

        return;
      },
    },
    {
      title: "Acciones",
      key: "key",
      dataIndex: "key",
      render: (text: any, record: any) => {
        const urlMap = `https://www.google.com/maps/place/${record?.metadata?.latitude},${record?.metadata?.longitude}/@${record?.metadata?.latitude},${record?.metadata?.longitude},17z/data=!3m1!4b1!4m4!3m3!8m2!3d${record?.metadata?.latitude}!4d${record?.metadata?.longitude}?entry=ttu`;
        return (

          <>
            {
              <div className="table__button-container">
                <Tooltip title="Productos">
                  <Button
                    type="text"
                    shape="circle"
                    icon={<ShoppingOutlined />}
                    className="btn-table"
                    style={{ marginRight: 5 }}
                    onClick={() => handleOpenTicket(record)}
                  />
                </Tooltip>
                {record?.metadata?.latitude &&
                  <Tooltip title="Ver ubicacion">
                    <a href={urlMap} target="_blank">
                      <Button
                        type="text"
                        shape="circle"
                        icon={<EnvironmentOutlined />}
                        className="btn-table"
                        style={{ marginRight: 5 }}

                      />
                    </a>
                  </Tooltip>
                }
              </div>
            }
          </>
        )
      }
    },
  ];
};

export const ticketColumn = () => {
  return [
    {
      title: "Código de barra",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a: any, b: any) => a.barcode.localeCompare(b.barcode),
    },
    {
      title: "Descripcion",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      sorter: (a: any, b: any) => {
        if (a.price && b.price) {
          return parseInt(a.price) - parseInt(b.price);
        }
        return;
      },
    },
    {
      title: "Impuesto",
      dataIndex: "taxPercent",
      key: "taxPercent",
      sorter: (a: any, b: any) => {
        if (a.taxPercent && b.taxPercent) {
          return parseInt(a.taxPercent) - parseInt(b.taxPercent);
        }
        return;
      },
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      sorter: (a: any, b: any) => {
        return parseInt(a.subtotal) - parseInt(b.subtotal);
      },
    },

    {
      title: "Tipo",
      dataIndex: "addType",
      key: "addType",

      render: (text: any, record: any) => {
        if (record.addType) {
          return record.addType == 1 ? "Escaner" : "Manual"
        }
        return ""
      }
    },



    {
      title: "Categoría",
      dataIndex: ["category", "type"],
      key: "category",
      sorter: (a: any, b: any) =>
        a.category.type.localeCompare(b.category.type),
    },
  ];
};