import { Typography } from "antd";
import './PageTitle.css';

const { Title } = Typography;

const PageTitle = (props:any) => {
    return (
        <Title level={1} className="title-h1">{props.title}</Title>
    );
};

export default PageTitle;