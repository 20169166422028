import { Form } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store/store";
import { countriesState } from "../types/countries.types";

import { dataCountry } from "../data/countries";

const useCountriesForm = () => {
  const countriesState: countriesState = useSelector(
    (state: RootState) => state.countries
  );

  const [formInstance] = Form.useForm();
  const [paymentMvalue, setPaymentMValue] = useState(
    countriesState.country.data.paymentMethods
  );
  const [currencyValue, setCurrencyValue] = useState(
    countriesState.country.data.currency
  );
  const [unitValue, setUnitValue] = useState(
    countriesState.country.data.unitMeasureCodes
  );

  const handleOnChangePayment = (options: any) => {
    setPaymentMValue(Array.from(new Set(paymentMvalue.concat(options))));
  };
  const handleOnChangeCurrency = (option: any) => {
    setCurrencyValue(option);
  };
  const handleOnChangeUnit = (option: any) => {
    setUnitValue(Array.from(new Set(unitValue.concat(option))));
  };



  const handleOnChangeCountry = (e: string, options: any) => {
    let selectedCountry: any = {};
    selectedCountry = dataCountry.find(
      (country: any) => country.name === options.label
    );

    formInstance.setFieldsValue({
      countryCode: selectedCountry.code,
      currency: selectedCountry.currencyCode,
      timezone: selectedCountry.timezone
    });
  };

  return {
    formInstance,
    handleOnChangeCountry,
    paymentMvalue,
    handleOnChangePayment,
    currencyValue,
    handleOnChangeCurrency,
    unitValue,
    handleOnChangeUnit,
  };
};
export default useCountriesForm;
