import { IUser, usersAction } from '../types/users.types';
import { usersActionTypes } from "../constants/index";
import { IError } from "../types/interfaces/error.interface";
import { ThunkAction } from 'redux-thunk';
import { AppDispatch, RootState } from '../store/store';
import { ActionCreator } from "redux";
import instance from '../utils/axios.instance';
import { Result } from '../types/enum/result';

/*********************FETCH USERS ACTIONS************************************/
export const fetchUsersRequest = () => {
    return {
        type: usersActionTypes.FETCH_USERS_REQUEST
    };
};

export const fetchUsersSuccess = (data:any) => {
    return {
        type: usersActionTypes.FETCH_USERS_SUCCESS,
        payload: data
    };
};

export const fetchUsersFailure = (error:IError) => {
    return {
        type: usersActionTypes.FETCH_USERS_FAILURE,
        payload: error
    };
};

/*********************FETCH USER ACTIONS************************************/
export const fetchUserRequest = () => {
    return {
        type: usersActionTypes.FETCH_USER_REQUEST
    };
};

export const fetchUserSuccess = (data:any) => {
    return {
        type: usersActionTypes.FETCH_USER_SUCCESS,
        payload: data
    };
};

export const fetchUserFailure = (error:IError) => {
    return {
        type: usersActionTypes.FETCH_USER_FAILURE,
        payload: error
    };
};

/*********************DELETE USER ACTION************************************/
export const deleteUserRequest = () => {
    return {
        type: usersActionTypes.DELETE_USER_REQUEST
    };
};

export const deleteUserSuccess = (data:any) => {
    return {
        type: usersActionTypes.DELETE_USER_SUCCESS,
        payload: data
    };
};

export const deleteUserFailure = (error:IError) => {
    return {
        type: usersActionTypes.DELETE_USER_FAILURE,
        payload: error
    };
};

/*********************CREATE USER ACTION************************************/
export const createUserRequest = () => {
    return {
        type: usersActionTypes.CREATE_USER_REQUEST
    };
};

export const createUserSuccess = (data:IUser) => {
    return {
        type: usersActionTypes.CREATE_USER_SUCCESS,
        payload: data
    };
};

export const createUserFailure = (error:IError) => {
    return {
        type: usersActionTypes.CREATE_USER_FAILURE,
        payload: error
    };
};

/*********************UPDATE USER ACTION************************************/
export const updateUserRequest = () => {
    return {
        type: usersActionTypes.UPDATE_USER_REQUEST
    };
};

export const updateUserSuccess = (data:IUser) => {
    return {
        type: usersActionTypes.UPDATE_USER_SUCCESS,
        payload: data
    };
};

export const updateUserFailure = (error:IError) => {
    return {
        type: usersActionTypes.UPDATE_USER_FAILURE,
        payload: error
    };
};

/*********************ENABLE USER ACTION************************************/
export const enableUserRequest = () => {
    return {
        type: usersActionTypes.ENABLE_USER_REQUEST
    };
};

export const enableUserSuccess = (data:IUser) => {
    return {
        type: usersActionTypes.ENABLE_USER_SUCCESS,
        payload: data
    };
};

export const enableUserFailure = (error:IError) => {
    return {
        type: usersActionTypes.ENABLE_USER_FAILURE,
        payload: error
    };
};

/*********************DISABLE USER ACTION************************************/
export const disableUserRequest = () => {
    return {
        type: usersActionTypes.DISABLE_USER_REQUEST
    };
};

export const disableUserSuccess = (data:IUser) => {
    return {
        type: usersActionTypes.DISABLE_USER_SUCCESS,
        payload: data
    };
};

export const disableUserFailure = (error:IError) => {
    return {
        type: usersActionTypes.DISABLE_USER_FAILURE,
        payload: error
    };
};

/*********************CLEAN UP COUNTRY ACTION************************************/
export const cleanUpUserData = () =>{
    return{
        type: usersActionTypes.USER_CLEAN_UP
    }
}
/****************************************************************************/
export const fetchUsers:ActionCreator<ThunkAction<void, RootState, null, usersAction>> = (currentPage:any = 1, pageSize:any = 20, params:string = '') => 
    async (dispatch:AppDispatch) => {
        try {
            dispatch(fetchUsersRequest());
            const response = await instance.get(`/api/users?page=${currentPage}&pageSize=${pageSize}${params}`);
            dispatch(fetchUsersSuccess(response.data));
        } catch (error:any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchUsersFailure(data));
        }
    };

export const deleteUser:ActionCreator<ThunkAction<void, RootState, null, usersAction>> = (id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(deleteUserRequest());
            const response = await instance.delete(`/api/users/${id}`);
            dispatch(deleteUserSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(deleteUserFailure(data));

            return Result.ERROR;
        }
    };

export const enableUser:ActionCreator<ThunkAction<void, RootState, null, usersAction>> = (id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(enableUserRequest());
            const response = await instance.patch(`/api/users/${id}/activate`);
            dispatch(enableUserSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(enableUserFailure(data));

            return Result.ERROR;
        }
    };

export const disableUser:ActionCreator<ThunkAction<void, RootState, null, usersAction>> = (id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(disableUserRequest());
            const response = await instance.delete(`/api/users/${id}`);
            dispatch(disableUserSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(disableUserFailure(data));

            return Result.ERROR;
        }
    };


export const createUser:ActionCreator<ThunkAction<void, RootState, null, usersAction>> = (body:any) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(createUserRequest());
            const response = await instance.post(`/api/users`, body);
            dispatch(createUserSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(createUserFailure(data));

            return Result.ERROR;
        }
    };

export const updateUser:ActionCreator<ThunkAction<void, RootState, null, usersAction>> = (body:any, id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchUserRequest());
            const response = await instance.patch(`/api/users/${id}`, body);
            dispatch(fetchUserSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchUserFailure(data));

            return Result.ERROR;
        }
    };

export const fetchUser:ActionCreator<ThunkAction<void, RootState, null, usersAction>> = (id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchUserRequest());
            const response = await instance.get(`/api/users/${id}`);
            dispatch(fetchUserSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchUserFailure(data));

            return Result.ERROR;
        }
    };

export const cleanupUser = () => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpUserData());
    }
};