import Layout from "./layout/Layout";
import AppRouter from "../scenes/AppRouter";
import "antd/dist/antd.min.css";
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './other/PrivateRoute';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import {authState} from "../types/auth.types";
import KeycloakProvider from '../keycloak/keycloak-provider/KeycloakProvider';

function App() {
  const authState: authState = useSelector((state: RootState) => state.auth);
  return (
    <>
       <KeycloakProvider>
        <Routes>
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Layout>
                  <AppRouter />
                </Layout>
              </PrivateRoute>
            }
          />
        </Routes>
      </KeycloakProvider>
    </>
  );
}

export default App;
