import { Form, message} from "antd";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchCountries } from "../actions/countries.actions";
import { countriesState } from "../types/countries.types";
import { RootState, useAppDispatch } from "../store/store";
import { createCatalogs, fetchCatalogs } from "../actions/catalogs.actions";
import { IResponse } from "../types/interfaces/response.interface";
import { Result } from "../types/enum/result";

export const useCatalogsForm = (props: any) => {
    const dispatch = useAppDispatch();
    const { setShowForm } = props;
    const [formInstance] = Form.useForm();
    const countriesState: countriesState = useSelector((state: RootState) => state.countries);
    const [taxPercentCatalog, setTaxPercentCatalog]: any = useState([])
    const [dataForm, setDataForm]: any = useState({active:true})

    useEffect(() => {
        if (countriesState?.countries?.data?.data.length == 0) {
            dispatch(fetchCountries());
        }
    }, [])

    const handleOnChangeCountry = (e: string, options: any) => {
        const dataCountry: any = countriesState?.countries?.data?.data.find((item: any) => item.id === options.value)
        onChangesField([dataCountry?.countryCode], "countryCode",)
    }
    const onChangesField = (value: any, name: string) => {
        setDataForm({ ...dataForm, [name]: value })
    }

    const saveCatalogs = () =>{
        if(taxPercentCatalog.length === 0){
            message.error('Debes agregar un impuesto');
            return
        }
        dataForm.attributes ={
            internal_ref: dataForm.attributes
        }
        dispatch(createCatalogs({...dataForm, "taxPercentCatalog": taxPercentCatalog})).then((result: IResponse) => {
            if (result.status === Result.OK) {
                message.success(`El catálogo ha sido creado con éxito`);
                setShowForm(false);
                dispatch(fetchCatalogs());
            }else {
                message.error(result.data?.message);
            }
        });
    }
    
    return{setShowForm, formInstance,onChangesField, dataForm, countriesState, handleOnChangeCountry, setTaxPercentCatalog, taxPercentCatalog , saveCatalogs}
}