import { typeError } from '../types/enum/modal';
import { Role } from '../types/enum/roles'
import regularExpression from './regularExpression';

export const requiredError = (headerName: string, rowNumber: number, columnNumber: number) => {
	return JSON.stringify({  field: headerName, rowNumber: rowNumber, columnNumber: columnNumber, type: typeError.Required, message: 'El campo es requerido.' });
}

export const validateError = (headerName: string, rowNumber: number, columnNumber: number) => {
	return JSON.stringify({ field: headerName, rowNumber: rowNumber, columnNumber: columnNumber, type: typeError.Validate, message: 'El campo o el formato no es valido.' });
}

export const validateErrorMail = (headerName: string, rowNumber: number, columnNumber: number) => {
	return JSON.stringify({ field: headerName, rowNumber: rowNumber, columnNumber: columnNumber, type: typeError.Validate, message: 'El mail no tiene un formato esperado.' });
}


export const uniqueError = (headerName: string, rowNumber: number) => {
	return JSON.stringify({ field: headerName, rowNumber: rowNumber, columnNumber: '', type: typeError.Unique, message: 'El campo no es unico.' });
}

export const isEmailValid =  (email: string) => {
	const reqExp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/
	return reqExp.test(email)
}

export const isPasswordValid = function (password: string) {
	return password.length >= 4
}

export const isFormatText = (text: string) => {
	return text.match(regularExpression.lettersNonWhitespaces) ? true : false;
}

export const isIntegerValid = (value: any) => {
	return !isNaN(value)
}

export const isRole = (value: any) => {
	return Object.values(Role).includes(value);	
}