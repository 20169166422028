export const storeActionTypes = {
    FETCH_STORES_REQUEST:"FETCH_STORES_REQUEST",
    FETCH_STORES_SUCCESS:"FETCH_STORES_SUCCESS",
    FETCH_STORES_FAILURE:"FETCH_STORES_FAILURE",

    FETCH_STORE_REQUEST:"FETCH_STORE_REQUEST",
    FETCH_STORE_SUCCESS:"FETCH_STORE_SUCCESS",
    FETCH_STORE_FAILURE:"FETCH_STORE_FAILURE",

    CREATE_STORE_REQUEST:"CREATE_STORE_REQUEST",
    CREATE_STORE_SUCCESS:"CREATE_STORE_SUCCESS",
    CREATE_STORE_FAILURE:"CREATE_STORE_FAILURE",

    UPDATE_STORE_REQUEST:"UPDATE_STORE_REQUEST",
    UPDATE_STORE_SUCCESS:"UPDATE_STORE_SUCCESS",
    UPDATE_STORE_FAILURE:"UPDATE_STORE_FAILURE",

    ENABLE_STORE_REQUEST:"ENABLE_STORE_REQUEST",
    ENABLE_STORE_SUCCESS:"ENABLE_STORE_SUCCESS",
    ENABLE_STORE_FAILURE:"ENABLE_STORE_FAILURE",

    DISABLE_STORE_REQUEST:"DISABLE_STORE_REQUEST",
    DISABLE_STORE_SUCCESS:"DISABLE_STORE_SUCCESS",
    DISABLE_STORE_FAILURE:"DISABLE_STORE_FAILURE",

    STORE_CLEAN_UP:"STORE_CLEAN_UP"
};