import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store/store";
import { cleanUpSearchProductsData, fetchStoreProductsBaseShortcutBySearch, } from "../actions/storeProductsSearch.actions";
import { storeProductsState } from "../types/storeProducts.types";

import { removeDuplicates } from "../utils/arrayFunctions";
import { fetchShortcut, fetchShortcutCreateOrUpdate } from "../actions/shortcut.actions";
import { defaultParams } from "./useShortcut";
import { Select } from 'antd';
import { cleanupStoreProducts } from "../actions/storeProducts/cleanup.actions";

const { Option } = Select;
const useShortcutForms = (props: any, filters: any) => {

    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState("");
    const [promotionProducts,]: any = useState([]);
    const [newPromotionProducts, setNewPromotionProducts]: any = useState([]);
    const [showAddProductsModal, setShowAddProductsModal] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);

    const [dataField, setDataField]: any = useState({});


    const [showCreateProdModal, setShowCreateProdModal] = useState(false);


    const storeProductsState: storeProductsState = useSelector(
        (state: RootState) => state.storeProducts
    );

    const onSearchAddProducts = async (
        value: string
    ) => {
        const trimmedValue = value.trim();
        setSearchValue(trimmedValue);
        // Especificamos el id del catalogo de los productos actuales para evitar adherir productos de otros catalogos
        await dispatch(
            fetchStoreProductsBaseShortcutBySearch(
                trimmedValue,
                dataField?.catalogId ?? props.dataShortchut?.catalogId?.id ?? "",
                promotionProducts,
                1,
                20
            )
        );
    };

    const openModalProduct = async () => {
        if (!dataField?.catalogId && !props?.dataShortchut?.catalogId?.id) {
            message.error(`Debes seleccionar un catálogo`);
            return
        }
        await dispatch(cleanUpSearchProductsData());
        setSearchValue("")
        setShowAddProductsModal(true);
    }

    // Add products modal
    const handleCancelAddProductsModal = () => {
        setShowAddProductsModal(false);
    };

    const setRestartSearch = () => {

    }

    useEffect(() => {
        const data = props.dataShortchut?.items;
        if (data) {
            data.map((item: any, key: number) => item.key = key)
            setNewPromotionProducts(data)
        }
    }, [])
    const handleRowSelectionAddProductsTable = {
        onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
            //setNewPromotionProducts([]);
            let newPromotionProductsTemp: string[] = [];

            if (selectedRows.length) {
                selectedRows.forEach((row: any) => {
                    if (!row) {
                        return;
                    }
                    if (!newPromotionProducts.find((item: any) => item.id === row.id)) {
                        newPromotionProductsTemp.push(row);
                    }
                });
                console.log(newPromotionProducts, newPromotionProductsTemp)
                if (newPromotionProductsTemp.length > 0) {
                    setNewPromotionProducts([...newPromotionProducts, ...newPromotionProductsTemp]);
                }
            }
        },
        onSelect: (
            record: any,
            selected: any,
        ) => {
            if (selected) {
                if (!promotionProducts.some((item: any) => item.id === record.id)) {
                    const findElement = newPromotionProducts.find((item: any) => item.id === record.id);
                    if (!findElement) {
                        setNewPromotionProducts([...newPromotionProducts, record]);
                    }
                }
            } else {
                setNewPromotionProducts([
                    ...newPromotionProducts.filter((obj: any) => obj.id !== record.id),
                ]);
            }
        },
        type: "checkbox",
    };

    const addNewPromotionProducts = () => {
        setShowAddProductsModal(false)
    }

    const changesField = (name: any, value: any) => {
        if (name === "catalogId") {
            setNewPromotionProducts([])
            if (value === props?.dataShortchut?.catalogId?.id) {
                const data = props.dataShortchut?.items;
                data.map((item: any, key: number) => item.key = key)
                setNewPromotionProducts(data)
            }
        }
        setDataField({ ...dataField, [name]: value })

    }

    const onFinish = async () => {
        setOpenLoading(true)
        const data = props.dataShortchut;
        const dataSend = {
            "name": dataField?.name ?? data.name,
            "picture": "",
            "catalogId": dataField?.catalogId ?? data.catalogId?.id,
            "visible": dataField["visible"] !== undefined ? !!dataField?.visible : true,
            "order": parseInt(dataField?.order ?? data.order),
            "items": newPromotionProducts.map((item: any) => item.id)
        }
        await dispatch(fetchShortcutCreateOrUpdate(dataSend, data.id))

        let menssageText = "Hemos creado con éxito el grupo"
        if (data.id) {
            menssageText = "Hemos actualizado con exito el grupo"
        }

        await dispatch(fetchShortcut(1, 20, "&catalogId=" + filters.catalogId))
        message.success(menssageText);
        setOpenLoading(false)
        props.setShowForm(false)
    }

    const deleteItem = (itemRemove: number) => {
        const deleteItemProduct = newPromotionProducts.filter((item: any) => item.id !== itemRemove)
        setNewPromotionProducts(deleteItemProduct)
    }

    const changesPosition = (newObject: any) => {
        setNewPromotionProducts(newObject)
    }

    const returnItems = (dataItem: number) => {

        const index = newPromotionProducts.findIndex((itemL: any, key: number) => itemL.id === dataItem);
        return index + 1
        /*
        return <Select value={index + 1} style={{ width: 60 }} onChange={(e) => changesPosition(index, e)}>
            {newPromotionProducts.map((item: any, key: number) => {
                return <Option value={key + 1} key={key}>{key + 1}</Option>
            })
            }
        </Select>
        */
    }

    const createPodrucAddResult = (dataCreate?: any) => {
        setShowCreateProdModal(false)
        setNewPromotionProducts((state: any) => ([...state, dataCreate]));
        message.success(` El producto ${dataCreate.description} con el codigo de barras ${dataCreate.barcode} ha sido creado y agregado al grupo ${dataField?.name ?? props.dataShortchut.name}`);
    }
    const handleCreateProduct = () => {

        setShowAddProductsModal(!showAddProductsModal)
        setShowCreateProdModal(!showCreateProdModal)

    }

    return {
        onSearchAddProducts,
        searchValue,
        setSearchValue,
        storeProductsState,
        handleRowSelectionAddProductsTable,
        newPromotionProducts,
        addNewPromotionProducts,
        showAddProductsModal,
        changesField,
        handleCancelAddProductsModal,
        openModalProduct,
        deleteItem,
        onFinish,
        openLoading,
        returnItems,
        showCreateProdModal,
        setShowCreateProdModal,
        promotionProducts,
        setRestartSearch,
        dataField,
        setNewPromotionProducts,
        handleCreateProduct,
        createPodrucAddResult,
        paginationSearch: {
            current: storeProductsState?.searchProducts.data.page,
            pageSize: storeProductsState?.searchProducts.data.pageSize,
            total: storeProductsState?.searchProducts.data.totalCount,
            onChange: (page: number, pageSize: number) => {
                //setNewPromotionProducts([]);
                dispatch(fetchStoreProductsBaseShortcutBySearch(
                    searchValue,
                    dataField?.catalogId ?? props.dataShortchut?.catalogId?.id ?? "",
                    promotionProducts,
                    page,
                    pageSize
                ))
            },
        },
    };
};

export default useShortcutForms;
