import { Field, Position } from '../../../types/enum/field'
export const fields = [
    {
        name: 'id',
        type: Field.SEARCH,
        placeholder: 'ID',
        position: Position.HORIZONTAL,
        value: ''
    },
    {
        name: 'barcode',
        type: Field.SEARCH,
        placeholder: 'Código de barra',
        position: Position.HORIZONTAL,
        value: ''
    },
    {
        name: 'description',
        type: Field.INPUT,
        placeholder: 'Descripción',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'contents',
        type: Field.INPUT,
        placeholder: 'Contenido',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'unit',
        type: Field.UNIT,
        placeholder: 'Unidades de medida',
        mode: 'multiple',
        position: Position.VERTICAL,
    },
    {
        name: 'price',
        type: Field.INPUT,
        placeholder: 'Precio',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'taxPercent',
        type: Field.INPUT,
        placeholder: '% de impuesto',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'catalogId',
        type: Field.CATALOG,
        placeholder: '',
        position: Position.VERTICAL,
        value: '60d4f60a378aec6831a3f459'
    },
    {
        name: 'createdAt',
        type: Field.DATEPICKER,
        placeholder: 'Fecha de creación',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'updatedAt',
        type: Field.DATEPICKER,
        placeholder: 'Fecha de modificación',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'categoryId',
        type: Field.CATEGORY,
        placeholder: '',
        mode: 'multiple',
        position: Position.VERTICAL,
        value: ''
    }
]