import {
  Breadcrumb,
  Button,
  Form,
  Input,
  Space,
  InputNumber,
  Alert,
  Table,
  Checkbox
} from "antd";
import { Link } from "react-router-dom";
import PageTitle from "../other/PageTitle";
import CatalogFilesSelect from "../selects/catalogFiles/CatalogFilesSelect";
import AddProductsModal from "../search/products-store-new/promotion/AddProductsModal";
import { ColumnsAddProductsTable } from "../search/products-store-new/promotion/ColumnsAddProductsTable";
import { ColumnsAddProductsBaseTable } from "../search/products-store-new/promotion/ColumnsAddProductsBaseTable";
import useShortcutForms from "../../hooks/useShortcutForm";
import { useMemo } from "react";
import Loading from "../loading/loading";
import { ProductStoreEnum } from "../../types/enum/productStoreFields";
import CreateProductBaseModal from "./CreateProductBaseModal";
import {
  CloudUploadOutlined
} from '@ant-design/icons';
import "./Css/index.css"
import regularExpression from "../../utils/regularExpression";
import SortableTableHook from "../table/TableDrag"


const ShortcutForm = (props: any) => {
  const { dataShortchut, setShowForm, filters } = props;

  const handleBreadcrumbOnClick = () => {
    setShowForm(false);
  };

  const {
    onSearchAddProducts,
    storeProductsState,
    setSearchValue,
    handleRowSelectionAddProductsTable,
    newPromotionProducts,
    addNewPromotionProducts,
    showAddProductsModal,
    changesField,
    handleCancelAddProductsModal,
    openModalProduct,
    deleteItem,
    onFinish,
    openLoading,
    returnItems,
    showCreateProdModal,
    dataField,
    handleCreateProduct,
    paginationSearch,
    setNewPromotionProducts,
    searchValue,
    createPodrucAddResult
  } = useShortcutForms(props, filters);

  const columnsAddProductsTable = ColumnsAddProductsTable();
  const columnsAddProductsBaseTable = ColumnsAddProductsBaseTable();

  const columns = useMemo(() => [

    {
      title: 'Código de barra',
      dataIndex: 'barcode',
      key: 'barcode',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Contenido',
      dataIndex: 'contents',
      key: 'contents',
    },
    /*
    {
      title: 'Orden por defecto',
      dataIndex: 'position',
      key: 'position',
      render: (pos: any, record: any, key: number) => <>{key + 1}</>,
    },
    */
    {
      title: 'Acciones',
      dataIndex: '',
      key: 'x',
      render: (_: any, record: any) => <a onClick={() => deleteItem(record.id)}>Eliminar</a>,
    },
  ], [newPromotionProducts])
  return (
    <>
      <Loading open={openLoading} />
      {showCreateProdModal &&
        <>
          <CreateProductBaseModal
            fieldExceptions={[ProductStoreEnum.categoryList]}
            dataForm={{ category: { catalogId: dataField.catalogId ?? dataShortchut?.catalogId?.id } }}
            openModal={showCreateProdModal}
            createPodrucAddResult={createPodrucAddResult}
            handleCloseModal={handleCreateProduct}
          />
        </>
      }

      <AddProductsModal
        showAddProductsModal={showAddProductsModal}
        onSearchAddProducts={onSearchAddProducts}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        storeProductsState={storeProductsState}
        columnsAddProductsTable={columnsAddProductsTable}
        columnsAddProductsBaseTable={columnsAddProductsBaseTable}
        handleRowSelectionAddProductsTable={handleRowSelectionAddProductsTable}
        addNewPromotionProducts={addNewPromotionProducts}
        newPromotionProducts={newPromotionProducts}
        handleCancelAddProductsModal={handleCancelAddProductsModal}
        addButtonCreateProduct={handleCreateProduct}
        pagination={paginationSearch}
      />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/shortcut" onClick={handleBreadcrumbOnClick}>
            Cartilla
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {dataShortchut.id ? "Editar" : "Agregar"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <PageTitle
        title={dataShortchut.id ? "Editar" : "Agregar" + " grupo"}
      />
      <>
        <Form
          name="form"
          labelCol={{ span: 8 }}
          wrapperCol={{
            xs: { span: 24 },
            lg: { span: 12 },
          }}
          layout="vertical"
          onFinish={onFinish}
          className="formCreateUser"
        >
          <Form.Item
            label="Nombre"
            name="name"
            rules={[
              { required: true, message: "Por favor inserte un Nombre" }
            ]}
            initialValue={dataShortchut.name}

          >
            <Input onBlur={(e) => changesField("name", e.target.value)} />
          </Form.Item>


          <Form.Item
            label="Catálogo"
            name="catalogId"
            rules={[
              { required: true, message: "Por favor seleccione un catálogo" }
            ]}
            initialValue={dataShortchut?.catalogId?.id}
          >
            <CatalogFilesSelect initialValue={dataShortchut?.catalogId?.id} onChange={changesField} />
          </Form.Item>
            {/* 
          <Form.Item
            label="Orden"
            name="order"
            rules={[
              { required: true, message: "Por favor inserte un orden" },
              {
                pattern: new RegExp(regularExpression.integer),
                message: "El campo debe ser un número entero",
              }
            ]}
            initialValue={dataShortchut.order}
          >
            <InputNumber style={{ width: '100%' }} onBlur={(e) => changesField("order", e.target.value)} />
          </Form.Item>
          */}
          <Form.Item
            label="Grupo visible"
            name="visible"
            initialValue={dataShortchut["visible"] === undefined ? true : !!dataShortchut.visible}
            className="styleCheckboxLeft"
            valuePropName="checked"
          >
            <Checkbox
              className="checkbox"
              onChange={(e) => changesField("visible", e.target?.checked)}
            />
          </Form.Item>

          <Form.Item
            label="Agregar productos base"
            name="productBase"
            style={{ textAlign: "left" }}
          >
            {/*
            <div className="styleAddProductBase" onClick={openModalProduct} >
              <CloudUploadOutlined />
              <h4>Click para agregar productos base a la cartilla</h4>
            </div>
          */}
            <div style={{ textAlign: "left", width:"100%" }}>
              <Button type="primary" onClick={openModalProduct}>Agregar producto base </Button>
            </div>
          </Form.Item>
          <Form.Item >
            {(newPromotionProducts.length > 0 && !showAddProductsModal) &&
              <SortableTableHook columns={columns} data={newPromotionProducts} returOrderData={setNewPromotionProducts} />
            }
          </Form.Item>


          <Form.Item
            wrapperCol={{
              xs: { span: 24 },
              lg: { span: 12 },
            }}
          >
            <Space className="d-flex flex-end">
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
              <Button onClick={handleBreadcrumbOnClick}>Cancelar</Button>
            </Space>
          </Form.Item>

        </Form>
      </>

    </>
  );
};

export default ShortcutForm;
