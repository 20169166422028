import { authActionsTypes } from "../constants/index";
import { AppDispatch, RootState } from "../store/store";
import {  IUserInfo } from "../types/auth.types";


  export const addUserInfo = (user: IUserInfo) => {
    return {
      type: authActionsTypes.ADD_USER_LOGGED_IN,
      payload: user,
    };
  };
  
  export const addUserInfoLoggeIn = (user: any) => {
    const currentUser: IUserInfo = {
      firstName: user?.given_name,
      lastName: user?.family_name,
      fullName: user?.name,
      username: user?.preferred_username,
      email: user?.email,
    };
    return (dispatch: AppDispatch) => {
      dispatch(addUserInfo(currentUser));
    };
  };
  