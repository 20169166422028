

import { ICountries, ICountry, ICountriesSelet } from '../types/countries.types';
import {countriesActionTypes} from "../constants/index";
import { Action } from "../types/actions.types";
import { countriesState } from "../types/countries.types";
import { IError } from "../types/interfaces/error.interface";
import { v4 as uuid } from "uuid";

let error:IError={status:'', data:{timestamp:'', path:'',message:''}};
let countries:ICountries={data:[],page:0, pageSize:0, totalCount:0}
let country:ICountry={
    id:'',    
    key:'',
    name:'',
    countryCode:'',
    paymentMethods:[],
    currency:'',
    unitMeasureCodes:[],
    format:{
        currency:{},
        utc:""
    }
}
let countriesSelect:ICountriesSelet ={data:[],page:0, pageSize:0, totalCount:0};

const initialState:countriesState ={
    countries:{
        loading:false,
        data:countries,
        error:error
    },
    country:{
        loading: false,
        data:country,
        error: error,
    },
    countriesSelect:{
        loading:false,
        data:countriesSelect,
        error:error
    }
}

const countriesReducer = (state=initialState,action:Action):countriesState =>{
    switch(action.type){
        case countriesActionTypes.FETCH_COUNTRIES_REQUEST:
            return{
                ...state,
                countries:{
                    ...state.countries,
                    loading:true
                }
            }
            case countriesActionTypes.FETCH_COUNTRIES_SUCCESS:
                const data:ICountry[] = [...action.payload.data];
                data.forEach((item:ICountry, idx:number) => {
                    const unique_id = uuid();
                    item.key = unique_id;
                });
                return{
                    ...state,
                    countries:{
                        loading:false,
                        data: action.payload,
                        error:error
                    }
                }
            case countriesActionTypes.FETCH_COUNTRIES_FAILURE:
                return{
                    ...state,
                    countries:{
                        loading:false,
                        data:countries,
                        error:action.payload
                    }
                }
            case countriesActionTypes.FETCH_COUNTRY_REQUEST:
            case countriesActionTypes.CREATE_COUNTRY_REQUEST:
            case countriesActionTypes.UPDATE_COUNTRY_REQUEST:
            case countriesActionTypes.DELETE_COUNTRY_REQUEST:
                return{
                    ...state,
                    country:{ 
                        ...state.country,
                        loading:true
                    }
                }
            case countriesActionTypes.DELETE_COUNTRY_SUCCESS:
                return{
                    ...state,
                    country:{ 
                        loading:false,
                        data:{...state.country.data, id:action.payload},
                        error:error
                    }
                }
            case countriesActionTypes.FETCH_COUNTRY_FAILURE:
            case countriesActionTypes.CREATE_COUNTRY_FAILURE:
            case countriesActionTypes.UPDATE_COUNTRY_FAILURE:                    
            case countriesActionTypes.DELETE_COUNTRY_FAILURE:
                return{
                    ...state,
                    country:{ 
                        loading:false,
                        data:country,
                        error:action.payload
                    }
                }
            case countriesActionTypes.FETCH_COUNTRY_SUCCESS:
            case countriesActionTypes.CREATE_COUNTRY_SUCCESS:
            case countriesActionTypes.UPDATE_COUNTRY_SUCCESS:
                return{
                    ...state,
                    country:{ 
                        loading:false,
                        data:action.payload,
                        error:error
                    }
                }
            case countriesActionTypes.COUNTRY_CLEAN_UP:
                return{
                    ...state,
                    country:initialState.country
                        
                }
            case countriesActionTypes.FETCH_COUNTRIES_SELECT_REQUEST:
                return{
                    ...state,
                    countriesSelect:{
                        ...state.countriesSelect,
                        loading:true
                    }
                }
            case countriesActionTypes.FETCH_COUNTRIES_SELECT_SUCCESS:
                const dataCountry:ICountry[] = [...action.payload.data];
                dataCountry.forEach((item:ICountry, idx:number) => {
                    const unique_id = uuid();
                    item.key = unique_id;
                });
                return{
                    ...state,
                    countriesSelect:{
                        loading:false,
                        data: action.payload,
                        error:error
                    }
                }
            case countriesActionTypes.FETCH_COUNTRIES_SELECT_FAILURE:
                return{
                    ...state,
                    countriesSelect:{
                        loading:false,
                        data:countriesSelect,
                        error:action.payload
                    }
                }
            default:
                return state;
    }
}

export default countriesReducer;