import { Field, Position } from '../../types/enum/field';
import valuesActive from './../../data/active.json';
import valuesShowTax from './../../data/showTax.json';

export const fieldsFilter = [
    {
        name: 'name',
        type: Field.SEARCH,
        placeholder: 'Nombre',
        position: Position.HORIZONTAL
    },
    {
        name: 'country',
        type: Field.GENERAL,
        placeholder: 'País',
        position: Position.VERTICAL,
        values: []
    },
    {
        name: 'address',
        type: Field.INPUT,
        placeholder: 'Dirección',
        position: Position.VERTICAL
    },
    {
        name: 'username',
        type: Field.SEARCH,
        placeholder: 'Username',
        position: Position.HORIZONTAL
    },
    {
        name: 'active',
        type: Field.GENERAL,
        placeholder: 'Estado',
        position: Position.VERTICAL,
        values: valuesActive
    },
    {
        name: 'taxDetail',
        type: Field.GENERAL,
        placeholder: 'Mostrar impuesto',
        position: Position.VERTICAL,
        values: valuesShowTax
    },
]