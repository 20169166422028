import "./ModalUpload.css";
import {
  Button,
  Col,
  Modal,
  Row,
  Upload as UploadAntd,
  Tooltip,
  Form,
} from "antd";
import { UploadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { columns, columnsErrors } from "./Columns";
import { TableErrors } from "./TableErrors";
import SelectFilter from "../filters/SelectFilter";
import { ISelectFilter } from "../../../types/filters.types";

export const ModalUpload = (props: any) => {
  const {
    title,
    type,
    showUploadModal,
    closeUploadModal,
    uploadFileState,
    uploadImage,
    file,
    ejemplo,
    uploadProps,
    validateDataCsv,
    csvFormatInformation,
    fields,
    form,
    filter,
    onChange,
  } = props;

  const checkedFile = () => {
    return file === "" ? true : false;
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div>
      <Modal
        wrapClassName="modal-csv"
        title={title}
        visible={showUploadModal}
        onCancel={() => {
          closeUploadModal();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              closeUploadModal();
            }}
          >
            Cancelar
          </Button>,
          <Button
            type="primary"
            key="upload"
            loading={uploadFileState.upload.loading}
            disabled={checkedFile()}
            onClick={() => uploadImage(file, filter)}
          >
            {uploadFileState.upload.loading ? "Cargando" : "Cargar"}
          </Button>,
        ]}
        mask={false}
        maskStyle={{ backgroundColor: "#00000073" }}
      >
        <Row gutter={[0, 16]}>
          <Col span={24} className="form-filter-modal">
            <Form form={form}>
              {fields.length > 0 ? (
                <>
                  <p>Ingresa los siguientes datos:</p>
                  {fields.map((field: ISelectFilter, key: number) => {
                    return (
                      <SelectFilter
                        key={key}
                        name={field.name}
                        type={field.type}
                        values={field.values}
                        form={form}
                        placeholder={field.placeholder}
                        position={field.position}
                        handleChangeSelect={onChange}
                        check={true}
                        disabled={field.disabled}
                      />
                    );
                  })}
                </>
              ) : null}
              <p>
                Para realizar la carga masiva de {type} deberá adjuntar un
                archivo de tipo ".csv" con la estructura indicada en el
                siguiente{" "}
                <a
                  download
                  target="_blank"
                  rel="noreferrer"
                  href={ejemplo}
                  title="Haga click para ver el ejemplo"
                >
                  ejemplo
                </a>{" "}
                <Tooltip
                  title={csvFormatInformation}
                  overlayStyle={{ whiteSpace: "pre-wrap" }}
                  overlayInnerStyle={{
                    backgroundColor: "white",
                    color: "black",
                    width: "600px",
                  }}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </p>
              <Form.Item
                name="uploadFile"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <UploadAntd {...uploadProps}>
                  <Button icon={<UploadOutlined />}>
                    Click para subir archivo (.csv)
                  </Button>
                </UploadAntd>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        {validateDataCsv.length > 0 ? (
          <TableErrors columns={columns} validateDataCsv={validateDataCsv} />
        ) : null}
        {uploadFileState.upload.upload.success ? (
          <TableErrors
            columns={columnsErrors}
            validateDataCsv={uploadFileState.errorData.data}
            success={
              uploadFileState.upload.upload.success
                ? uploadFileState.upload.upload.success.length
                : 0
            }
          />
        ) : null}
      </Modal>
    </div>
  );
};
