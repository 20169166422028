import {
    UserOutlined,
    BookOutlined,
    AppstoreOutlined,
    UnorderedListOutlined,
    GlobalOutlined,
    ShoppingOutlined,
    ProfileOutlined,
    BarChartOutlined,
    FormOutlined,
    ShoppingCartOutlined,
    MobileOutlined,PieChartOutlined 
} from '@ant-design/icons';
import { nameModules } from '../../../types/enum/modules.types';
export const itemsMenu = (typeModule: string) =>{
    let data :any[] = [] 
    if(typeModule === nameModules.Demos){
        data = [
            {
                link: "demo-irt",
                name:"Demo IRT",
                Icon: MobileOutlined 
            }
        ]
    }else{
        data = [
            {
                link: "products-base",
                name:"Productos base",
                Icon: ShoppingOutlined
            },
            {
                link: "products-store-new",
                name:"Productos de tienda",
                Icon: ShoppingOutlined
            },
            {
                link: "tickets",
                name:"Tickets",
                Icon: ShoppingCartOutlined
            },
            {
                link: "catalogs",
                name:"Catálogos",
                Icon: BookOutlined
            },
            {
                link: "countries",
                name:"Países",
                Icon: GlobalOutlined
            },
            {
                link: "stores",
                name:"Comercios",
                Icon: UnorderedListOutlined
            },
            {
                link: "categories",
                name:"Categorías",
                Icon: AppstoreOutlined
            },
            

            {
                link: "conf-powerbi",
                name:"PowerBI",
                Icon: BarChartOutlined
            },
            {
                link: "catalog-files",
                name:"Archivos de catálogo",
                Icon: ProfileOutlined
            },
            /*
            {
                link: "users",
                name:"Usuarios",
                Icon: UserOutlined
            },
            */
            {
                link: "shortcut",
                name:"Cartilla",
                Icon: FormOutlined
            },
          
            {
                link: "statistics",
                name:"Estadísticas",
                Icon: PieChartOutlined
            },
          

        ]
    }
    return data
     
}