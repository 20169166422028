import { Button, Form, Input, Spin, Breadcrumb, Space } from "antd";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../store/store";
import { countriesState } from "../../types/countries.types";
import { cleanupCountry } from "../../actions/countries.actions";
import "./CountriesForm.css";
import CountrySelect from "../selects/country/CountrySelect";
import PaymentMethodSelect from "../selects/paymentMethods/paymentMethodSelect";
import UnitSelect from "../selects/unit/UnitSelect";
import useCountriesForm from "../../hooks/useCountriesForm";
import PageTitle from "../other/PageTitle";
import regularExpression from "../../utils/regularExpression";

const CountriesForm = (props: any) => {
  const { onFinish, setShowForm } = props;
  const dispatch = useAppDispatch();
  const countriesState: countriesState = useSelector(
    (state: RootState) => state.countries
  );
  const {
    formInstance,
    handleOnChangeCountry,
    paymentMvalue,
    handleOnChangePayment,
    unitValue,
    handleOnChangeUnit,
  } = useCountriesForm();

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link
            to="/countries"
            onClick={() => {
              setShowForm(false);
              dispatch(cleanupCountry());
            }}
          >
            Países
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {!countriesState.country.loading
            ? countriesState.country.data.id
              ? "Editar"
              : "Agregar"
            : ""}
        </Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle title={countriesState.country.data.name} />

      {!countriesState.country.loading ? (
        <>
          <Form
            form={formInstance}
            name="form"
            labelCol={{ span: 8 }}
            wrapperCol={{
              xs: { span: 24 },
              lg: { span: 12 },
            }}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              label="Nombre"
              name="name"
              rules={[
                { required: true, message: "Por favor seleccione un país." },
              ]}
              initialValue={countriesState.country.data.name}
            >
              <CountrySelect
                initialValue={countriesState.country.data.name}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                loadLocalData={true}
                onChange={handleOnChangeCountry}
                disabled={countriesState.country.data.id ? true : false}
              ></CountrySelect>
            </Form.Item>

            <Form.Item
              label="Código de país"
              name="countryCode"
              rules={[
                {
                  required: true,
                  message: "Por favor inserte el código de país.",
                },
              ]}
              initialValue={countriesState.country.data.countryCode}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Moneda"
              name="currency"
              rules={[
                { required: true, message: "Por favor seleccione una moneda." },
              ]}
              initialValue={countriesState.country.data.currency}
            >
              <Input disabled />
            </Form.Item>


            <Form.Item
              label="Zona horaria"
              name="timezone"
              rules={[
                { required: true, message: "Por favor seleccione una zona horaria." },
              ]}
              initialValue={countriesState.country.data.format?.utc}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Medios de pago"
              name="paymentMethods"
              initialValue={countriesState.country.data.paymentMethods}
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione al menos un medio de pago",
                },
              ]}
            >
              <PaymentMethodSelect
                initialValue={paymentMvalue}
                onChange={handleOnChangePayment}
              />
            </Form.Item>



            <Form.Item
              label="Unidades de medida"
              name="unitMeasureCodes"
              rules={[
                {
                  required: true,
                  message:
                    "Por favor seleccione al menos una unidad de medida.",
                },
              ]}
              initialValue={unitValue}
            >
              <UnitSelect
                mode="multiple"
                initialValue={unitValue}
                onChange={handleOnChangeUnit}
              />
            </Form.Item>


            <Form.Item
              label="Formato de moneda: Digitos"
              name="digits"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el número de digitos",
                },
                {
                  pattern: new RegExp(regularExpression.positiveNumbers),
                  message: "El campo debe ser un número positivo",
                }
              ]}
              initialValue={countriesState.country.data.format?.currency?.digits}
            >
              <Input type="number" min={1}/>
            </Form.Item>

            <Form.Item
              label="Formato de moneda: Decimales"
              name="decimals"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el número de decimales",
                },
                {
                  pattern: new RegExp(regularExpression.integer),
                  message: "El campo debe ser un número mayor o igual a cero",
                }
              ]}
              initialValue={countriesState.country.data.format?.currency?.decimals ?? 0}
            >
              <Input type="number" min={0}/>
            </Form.Item>

            <Form.Item
              label="Símbolo de la moneda"
              name="symbol"
              rules={[
                {
                  required: true,
                  message: "Por favor inserte un símbolo para la moneda",
                }
              ]}
              initialValue={countriesState.country.data?.format?.currency?.symbol}
            >
              <Input  maxLength={1}/>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                xs: { span: 24 },
                lg: { span: 12 },
              }}
            >
              <Space className="d-flex flex-end">
                <Button type="primary" htmlType="submit">
                  Guardar
                </Button>
                <Button
                  onClick={() => {
                    setShowForm(false);
                    dispatch(cleanupCountry());
                  }}
                >
                  Cancelar
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </>
      ) : (
        <Spin />
      )}
    </>
  );
};

export default CountriesForm;
