import { FilterSection } from "../types/enum/filters";
import { NotificationTypes } from "../types/notifications.types";

export const convertObjectToArray = (obj: []) => {
  let newArray: any = [];
  Object.keys(obj).forEach((e: any, idx) => {
    newArray.push({ name: e, value: obj[e] });
  });
  return newArray;
};

export const convertArrayToObject = (array:any[]) =>{
  const object: any = [];
  array.forEach((field: any) => {
    if (field.value !== undefined) {
      object[field.name] = field.value;
    }
  });
  return Object.assign({}, object);
}

export const mergeArraysByPropertyValue = (objOne: any, objTwo: any) => {
  const objArrayOne = convertObjectToArray(objOne);
  let mergeArrays = objTwo.map((item: any, i: number) => {
    const idxElemente = objArrayOne.findIndex((i: any) => i.name === item.name);
    return Object.assign({}, item, objArrayOne[idxElemente]);
  });
  return mergeArrays;
};

export const combineKeyValueArray = (
  values: any,
  dateArray: any = [],
  booleanArray: any = []
) => {
  let params = "";

  Object.keys(values).forEach((key: any) => {
    const value: any =
      typeof values[key] === "string" ? values[key].trim() : values[key];

    if (value !== undefined) {
      if (value !== "") {
        if (dateArray.includes(key)) {
          params += `&${key}=${convertDate(value)}`;
        } else {
          if (booleanArray.includes(key)) {
            if (key === "includeBarcode" && value === true) {
              params += `&${key}=true`;
            }

            if (key === "promotable" && value === true) {
              params += `&${key}=false`;
            }
          } else {
            params += `&${key}=${value}`;
          }
        }
      }
    }
  });

  return params;
};

const convertDate = (str: string) => {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

export const capitalize = (word: string) => {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}


export const getParameterByName = (name: string, url = window.location.href) => {
  name = name.replace(/[\\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}


export const arraySomeArray = (array: any, compareArray: any) => {
  let newSearchArray: any = [];
  let count = 0;
  array.forEach((c: any) => {
    const exist = compareArray.some((item: any) => {
      return item.id === c.id;
    });
    if (!exist) {
      newSearchArray.push(c);
    } else {
      count = count + 1;
    }
  });
  return [newSearchArray, count];
}


export const removeDuplicates = (array: any) => {
  const tempArray: any = [];
  array.forEach((item: any) => {
    if (!tempArray.includes(item)) {
      tempArray.push(item);
    }
  })
  return tempArray;
}

export const translateMessage = (item: any) => {

  switch (item.message) {
    case NotificationTypes.MAIL_EXIST:
      return 'El mail existe.';
    case NotificationTypes.NO_CATALOG_VALID:
      return 'El Catalogo no es valido.';
    case NotificationTypes.NO_COUNTRY_VALID:
      return 'El Pais no es valido.';
    case NotificationTypes.USERNAME_EXIST:
      return 'El Usuario existe.';
    case NotificationTypes.NO_ROLE_VALID:
      return 'El Rol no es valido.';
    default:
      return '';
  }

}

export const  getUrlBySection = ( section: FilterSection) => {
  switch ( section ) {
    case FilterSection.BASEPRODUCTS:
      return '/api/products/base';
    case FilterSection.USERS:
      return '/api/users';
    case FilterSection.STOREPRODUCTS:
      return '/api/products/store/all';
    case FilterSection.STORES:
      return '/api/stores';
    case FilterSection.TICKETS:
        return '/api/tickets';
    default:
      return '';
  }
}