import { Button, Tooltip,Modal } from "antd";
import { EditOutlined, DeleteOutlined, StopOutlined } from "@ant-design/icons";
import { capitalize } from "../../../utils/arrayFunctions";

const ButtonsTable = (props:any) =>{
    const { 
      titleModal, 
      onClickEdit,
      onClickDelete,
      isVisible,
      onClickOKModal, 
      onClickCancelModal, 
      deleteButtonAction = 'eliminar',
      item
    } = props;

    const deleteButtonActionCapitalized = capitalize(deleteButtonAction);

    return(
      <>
        <div className="table__button-container flex-end">
          <Tooltip title="Editar">
            <Button
              type="text"
              shape="circle"
              icon={<EditOutlined />}
              className="btn-table"
              style={{ marginRight: 5 }}
              onClick={onClickEdit}
            />
          </Tooltip>
          <Tooltip title={deleteButtonActionCapitalized}>
            <Button
              type="text"
              shape="circle"
              icon={deleteButtonAction === 'eliminar' ? <DeleteOutlined /> : <StopOutlined /> }
              className="btn-table"
              style={{ marginRight: 5 }}
              onClick={onClickOKModal}
            />
          </Tooltip>
        </div>
        <Modal
          title={`${deleteButtonActionCapitalized} ${titleModal}`}
          visible={isVisible}
          onCancel={onClickCancelModal}
          footer={[
            <Button key="cancel" onClick={onClickCancelModal} type="primary">
              Cancelar
            </Button>,
            <Button key="delete" onClick={onClickDelete}>
              {deleteButtonActionCapitalized}
            </Button>,
          ]}
          mask={false}
          maskStyle={{ backgroundColor: "#00000073" }}
        >
          <p>¿Esta seguro que desea {deleteButtonAction} el registro "{item.name}"?</p>
        </Modal>
      </>
    )

}
export default ButtonsTable;