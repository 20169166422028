import moment from "moment";
import { Field, Position } from "../../types/enum/field";
import { useAppDispatch } from "../../store/store";
import { fetchStores } from "../../actions/stores.actions";
import { Form } from "antd";

const useLogsFields = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const fields = [
    /*
    {
      name: "idUser",
      type: Field.STOREKEY,
      placeholder: "Mail comercio",
      position: Position.HORIZONTAL,
    },
    */

    {
      name: 'username',
      type: Field.SEARCH,
      placeholder: 'Correo electronico',
      position: Position.HORIZONTAL
    },
    {
      name: "countryId",
      type: Field.COUNTRY,
      placeholder: "Pais",
      value: "60db267049c5f13a8d0f0e86", 
      position: Position.HORIZONTAL,
      onChange: (field: string, value: any) => {

      },
      onReset: () => {
        form.resetFields(["storeKey", "storeId"])
      }
    },

  ];

  return { fields, form }

}
export default useLogsFields;