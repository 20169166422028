import { Breadcrumb, Button, Col, Form, Input, Row, Space } from "antd";
import { Link } from "react-router-dom";
import PageTitle from "../other/PageTitle";
import CatalogSelect from "../selects/catalog/CatalogSelect";
import { Select } from 'antd';
import { useUpdateProduct } from "../../hooks/useUpdateProduct";
import StoresSelect from "../selects/stores/StoresSelect";
const { Option } = Select;

const UpdateProducts = ({updateProductSave, reloadFilter}: any) => {
    const {formInstance, handleChange, dataField, saveData} = useUpdateProduct(updateProductSave, reloadFilter);
    return (
        <>
                <Form
                    form={formInstance}
                    name="form"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    onFinish={saveData}
                >

                    <Row gutter={[8, 8]}>

                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                        >
                            <Form.Item
                                label="Catálogo"
                                name="catalogId"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor seleccione un catálogo.",
                                    },
                                ]}
                            >
                                <CatalogSelect onChange={(value) => handleChange(value, "catalogId")} />
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                        >
                            <Form.Item
                                label="Tipo de actualización"
                                name="type"
                                rules={[
                                    { required: true, message: "Por favor inserte el nombre." }
                                ]}

                            >
                                <Select onChange={(value) => handleChange(value, "type")}>
                                    <Option value="all">Aplicar a todas las tiendas</Option>
                                    <Option value="howMany">Seleccionar comercios</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                        >
                            <Form.Item
                                label="Barcode"
                                name="barcode"
                                rules={[
                                    { required: true, message: "Por favor inserte el barcode." }
                                ]}
                            >
                                <Input onChange={(e: any) => handleChange(e.target.value, "barcode")} />
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                        >
                            <Form.Item
                                label="Descripción"
                                name="description"
                                rules={[
                                    { required: true, message: "Por favor inserte la descripción." }
                                ]}
                            >
                                <Input onChange={(e: any) => handleChange(e.target.value, "description")} />
                            </Form.Item>
                        </Col>
                        {dataField?.type === "howMany" &&
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                            >
                                <Form.Item
                                    label="Comercios"
                                    name="storeId"
                                    rules={[
                                        { required: true, message: "Por favor seleccione los comercios que se van actualizar" }
                                    ]}
                                >
                                    <StoresSelect
                                        mode={"multiple"}
                                        initialValue={dataField.storeId}
                                        onChange={(value: any, option: any) => handleChange(option, "storeId")}
                                    />
                                </Form.Item>
                            </Col>
                        }
                        <Col span={24} className="d-flex flex-end">
                            <Form.Item>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Actualizar
                                    </Button>
                                 
                                </Space>
                            </Form.Item>
                        </Col>

                    </Row>

                </Form>

        </>
    );
};

export default UpdateProducts;
