
interface IColumn {
    title: string;
    dataIndex: string;
    key: string;
    render?: any;
    sorter?: any;
    filters?: any;
    onFilter?: any;
}

export const useColumns = (columns: any, getColumnSearchProps: any) => {
    
    const data:IColumn[] = [];
    
    const renderColumn = (column: any) => {
        if(column.render){
            return column.render
        }
    }

    const sorterColumn = (a: any, b: any, column: any) => {
        switch(column.sorter){
            case 2:
                return a[column.key] - b[column.key];
            default:
                return a[column.key].localeCompare(b[column.key])
        }
    }

    columns.forEach((column: any) => {
        let columnData: IColumn = {
            title: column.title,
            dataIndex: column.key,
            key:  column.key,
        }
        columnData = {
            ...columnData,
            sorter: (column.sorter !== 0) ? (a:any, b:any) => sorterColumn(a, b, column) : null,
            render: renderColumn(column),
            filters: (column.filters) ? column.filters : null,
            onFilter: (column.onFilter) ? column.onFilter : null,
        }
        data.push(columnData)
    });

    return data;

}