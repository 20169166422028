const monthChanges = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]

const formatNumber = (numberD: any) =>{
  let numberFormat: any = numberD;
  if(numberFormat < 10){
    numberFormat = "0" + numberD?.toString()
  }
  return numberFormat
}

export const returnDateFormat = (syncDate: any) => {
    //let endDate = new Date(syncDate);
    let startdate = new Date(syncDate);
    /*
    const gmtUser = startdate.getTimezoneOffset();
    
    console.log(gmtUser, "gmtUsergmtUser")
    if (gmtUser > 0) {
      startdate.setMinutes(endDate.getMinutes() - gmtUser);
    } else {
      startdate.setMinutes(endDate.getMinutes() + Math.abs(gmtUser));
    }
    */

    let dayFormat: any = formatNumber(startdate.getDate()) ;
    let hoursFormat: any = formatNumber(startdate.getHours());
    let minutesFormat : any = formatNumber(startdate.getMinutes());
    let secoundsFormat : any = formatNumber(startdate.getSeconds());

    return startdate.getFullYear() + "-" + monthChanges[startdate.getMonth()] + "-" +dayFormat + " " +  hoursFormat + ":" + ""  +minutesFormat + ":"+ secoundsFormat
  }