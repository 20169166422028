import { Button, Space } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { storeProductsState } from "../../../../../types/storeProducts.types";

const PromotionButtons = (props:any) => {
    const storeProductsState: storeProductsState = useSelector(
        (state: RootState) => state.storeProducts
    );

    return (
        <Space>
            <Button
                disabled={
                    storeProductsState.baseProductsRelated.data.data.length > 0
                    ? true
                    : false
                }
                type="primary"
                onClick={props.onClickCreateProduct}
            >
                Crear producto
            </Button>

            <Button
                type="primary"
                onClick={props.onClickPromoteProduct}
                id="promote-button"
            >
                Promover
            </Button>

            <Button
                onClick={() => {
                    props.cancelPromote();
                    props.togglePromotionWindow();
                }}
            >
                Cancelar
            </Button>
        </Space>
    )
};

export default PromotionButtons;