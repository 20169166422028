import { Breadcrumb, Col, Row } from "antd";
import { Link } from "react-router-dom";

interface IProps {
  showBreadcrumbs?: boolean;
  handleOnClick?: React.MouseEventHandler<HTMLElement> | undefined;
  breadcrumb?:{title:string, item:string, link:string}
}

const BreadcrumbComponent = (props: IProps) => {
  const { showBreadcrumbs, handleOnClick, breadcrumb } = props;
  return (
    <Row>
      <Col span={24} className="d-flex space-between">
        <Col span={12}>
          {showBreadcrumbs ? (
            <></>
          ) : (
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={breadcrumb?.link ===undefined ? "":breadcrumb?.link} onClick={handleOnClick}>
                 {breadcrumb?.title}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{breadcrumb?.item}</Breadcrumb.Item>
            </Breadcrumb>
          )}
        </Col>
      </Col>
    </Row>
  );
};
export default BreadcrumbComponent;
