import ButtonsTable from "../table/customizedTable/ButtonsTable";

export const Columns = (
  getColumnSearchProps: any,
  item: any,
  handleEdit: any,
  handleDelete: any,
  isModalVisible: any,
  showEnableModal: any,
  handleCancel: any
) => {
  return [
    {
      title: "País",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      // ...getColumnSearchProps("name"),
    },
    {
      title: "Código país",
      dataIndex: "countryCode",
      key: "countryCode",
      sorter: (a: any, b: any) => a.countryCode.localeCompare(b.countryCode),
      // ...getColumnSearchProps("countryCode"),
    },
    {
      title: "Moneda",
      dataIndex: "currency",
      key: "currency",
      sorter: (a: any, b: any) => a.currency.localeCompare(b.currency),
      // ...getColumnSearchProps("currency"),
    },
    {
      title: "Medios de pago",
      dataIndex: "paymentMethods",
      key: "paymentMethods",
      render: (text: any, record: any, index: any) => {
        const paymentMethodsString = record.paymentMethods.join(", ");

        return paymentMethodsString;
      },
    },
    {
      title: "Unidades de medida",
      dataIndex: "unitMeasureCodes",
      key: "unitMeasureCodes",
      render: (text: any, record: any, index: any) => {
        const unitMeasureCodesString = record.unitMeasureCodes.join(", ");

        return unitMeasureCodesString;
      },
    },
    {
      title: "",
      key: "key",
      dataIndex: "key",
      render: (text: any, record: any) => (
        <ButtonsTable
          titleModal="País"
          onClickEdit={() => handleEdit(record)}
          onClickDelete={() => handleDelete(item)}
          isVisible={isModalVisible}
          onClickOKModal={() => showEnableModal(record)}
          onClickCancelModal={() => handleCancel()}
          item={item}
        />
      ),
    },
  ];
};
