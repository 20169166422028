import { LogsStatistics } from "./logs";
import { SalesByCountry } from "./salesByCountry";

interface StatisticItem {
    name: string;
    key: number;
    component: () => JSX.Element;
}

export const dataItemsStatistics: StatisticItem[] =[
    {
        name:"Tickets por país",
        key:0,
        component: () => <SalesByCountry />
    },
    {
        name:"Logs",
        key:1,
        component: () => <LogsStatistics />
    }
    /*
    {
        name:"Ventas por comercio",
        key:2
    },
    {
        name:"Ventas por categoría",
        key:3
    },
    {
        name:"Ventas por producto",
        key:4
    }
        */
]