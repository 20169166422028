import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ticketsActionTypes } from '../constants/index';
import instance from '../utils/axios.instance';
import { AppDispatch, RootState } from '../store/store';
import { IError } from '../types/interfaces/error.interface';
import {  ticketsAction } from '../types/tickets.types';
import { IData } from '../types/countries.types';
import { getValueFromCharacterString } from '../utils/stringFunctions';
import { getTimeZone } from '../utils/timeZoneFunctions';

/*********************FETCH STORES ACTIONS************************************/
export const fetchStoreRequest = () => {
    return {
        type: ticketsActionTypes.FETCH_TICKET_REQUEST
    }
}

export const fetchStoresSuccess = (data: any) => {
    return {
        type: ticketsActionTypes.FETCH_TICKETS_SUCCESS,
        payload: data 
    }
}

export const fetchStoresFailure = (error: IError) => {
    return {
        type: ticketsActionTypes.FETCH_TICKETS_FAILURE,
        payload: error
    }
}
/*********************FETCH STORE ACTIONS************************************/
export const fetchStoresRequest = () => {
    return {
        type: ticketsActionTypes.FETCH_TICKETS_REQUEST
    }
}

/****************************************************************************/
//Get Stores
export const fetchStoresTickets: ActionCreator<ThunkAction<void, RootState, null, ticketsAction>> = (currentPage: any = 1, pageSize: any = 20, params: string = '') => {
    return async (dispatch: AppDispatch, getState) => {
        let tz=""
        //get Time Zone by country
        const countryId=getValueFromCharacterString(params,"countryId");
        tz = getTimeZone(getState().countries?.countries?.data?.data,countryId);
        dispatch(fetchStoresRequest());
        await instance.get(`/api/tickets?page=${currentPage}&pageSize=${pageSize}${params}&tz=${tz}`)
            .then((response: any) => {
                let data = [];
                if(response.data){
                    data = response.data;
                }
                dispatch(fetchStoresSuccess(data));
            })
            .catch((error: any) => {
                const data: IError = { status: error.response.status, data: { timestamp: error.response?.data?.timestamp, path: error.response?.data?.path, message: error.response?.data?.message } }
                dispatch(fetchStoresFailure(data));
            });
    }
}