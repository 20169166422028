import { Button, Input, message, Space } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  fetchCountries,
  deleteCountry,
  fetchCountryFromStore,
  createCountry,
  cleanupCountry,
  updateCountry,
  fetchCountriesSelect,
} from "../actions/countries.actions";
import { RootState, useAppDispatch } from "../store/store";
import { Result } from "../types/enum/result";
import { countriesState } from "../types/countries.types";
import { fetchStores } from "../actions/stores.actions";

import { SearchOutlined } from "@ant-design/icons";
import { IResponse } from "../types/interfaces/response.interface";

export const useCountries = () => {
  const [showForm, setShowForm] = useState(false);
  const dispatch = useAppDispatch();
  const countriesState: countriesState = useSelector(
    (state: RootState) => state.countries
  );

  const pageSize = 10;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteCountriesModalVisible, setIsDeleteCountriesModalVisible] =
    useState(false);
  const [item, setItem] = useState("");
  const [selectedCountries, setSelectedCountries]: any = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    dispatch(fetchCountries(1, pageSize));
    dispatch(fetchStores(1, 10));
    dispatch(fetchCountriesSelect());
  }, [dispatch]);

  const onFinish = (values: any) => {
    const format = {
      currency:{
        digits:values.digits,
        decimals:values.decimals,
        symbol:values.symbol,
      },
      utc:values.timezone
    }
    delete values.digits
    delete values.decimals
    delete values.timezone
    delete values.symbol
    const dataApi ={...values, format: format}

    if (!countriesState.country.data.id) {
      dispatch(createCountry(dataApi)).then((result: IResponse) => {
        if (result.status === Result.OK) {
          message.success(`El país "${dataApi.name}" ha sido creado con éxito`);
          dispatch(
            fetchCountries(
              countriesState.countries.data.page,
              countriesState.countries.data.pageSize
            )
          );
          setShowForm(false);
          dispatch(cleanupCountry());
          dispatch(fetchCountriesSelect());
        } else {
          message.error(result.data?.message);
        }
      });
    } else {
      dispatch(updateCountry(dataApi, countriesState.country.data.id)).then(
        (result: any) => {
          if (result === Result.OK) {
            message.success(
              `El país "${values.name}" ha sido modificado con éxito`
            );
            dispatch(
              fetchCountries(
                countriesState.countries.data.page,
                countriesState.countries.data.pageSize
              )
            );
            setShowForm(false);
            dispatch(cleanupCountry());
            dispatch(fetchCountriesSelect());
          } else {
            message.error(countriesState.country.error.data.message);
          }
        }
      );
    }

  };

  const handleDelete = (item: any) => {
    dispatch(deleteCountry(item.id)).then((result: IResponse) => {
      if (result.status === Result.OK) {
        dispatch(
          fetchCountries(
            countriesState.countries.data.page,
            countriesState.countries.data.pageSize
          )
        );
        setItem("");
        setShowForm(false);
        setIsModalVisible(false);
        message.success(`El país "${item.name}" ha sido eliminado con éxito`);
        dispatch(fetchCountriesSelect());
      } else {
        setIsModalVisible(false);
        message.error(result.data?.message);
      }
    });
  };

  const onChangeFetchItems = (page: any, pageSize: any) => {
    dispatch(fetchCountries(page, pageSize));
  };

  const handleEdit = (body: any) => {
    setShowForm(true);
    const country = countriesState.countries.data.data.find(
      (country) => country.id === body.id
    );
    dispatch(fetchCountryFromStore(country));
  };

  const showEnableModal = (record: any = null) => {
    setIsModalVisible(true);
    setItem(record);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClick = () => {
    dispatch(cleanupCountry());
    setShowForm(true);
  };

  const showDeleteCountriesModal = () => {
    setIsDeleteCountriesModalVisible(!isDeleteCountriesModalVisible);
  };

  const deleteCountriesSelected = () => {
    selectedCountries.forEach((el: any) => {
      handleDelete(el);
    });

    setSelectedCountries([]);
    showDeleteCountriesModal();
  };

  // Local filters
  const handleRowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedCountries(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys,
    type: "check",
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSelectedCountries([]);
    setSelectedRowKeys([]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSelectedCountries([]);
    setSelectedRowKeys([]);
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: any;
      selectedKeys: any;
      confirm: any;
      clearFilters: any;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  // Local filters

  return {
    countriesState,
    pagination: {
      current: countriesState.countries.data.page,
      pageSize: countriesState.countries.data.pageSize,
      total: countriesState.countries.data.totalCount,
      onChange: (page: number, pageSize: number) => {
        setSelectedCountries([]);
        dispatch(fetchCountries(page, pageSize));
      },
    },
    onChangeFetchItems,
    handleDelete,
    handleEdit,
    onClick,
    onFinish,
    showForm,
    setShowForm,
    isModalVisible,
    item,
    showEnableModal,
    handleCancel,
    selectedCountries,
    handleRowSelection,
    showDeleteCountriesModal,
    isDeleteCountriesModalVisible,
    deleteCountriesSelected,
    getColumnSearchProps,
  };
};
