import { Button, Input } from "antd"
import { useState } from "react";
import "./TaxPercentCatalog.css"
const TaxPercentCatalog = ({ setTaxPercentCatalog }: any) => {
    const [valueTax, setValueTax]: any = useState("")
    const addTax = () => {
        if (parseInt(valueTax) >= 0) {
            setTaxPercentCatalog((state: any) => ([...state, valueTax]))
            setValueTax("")
        }
    }
    const changesTax = (e: any) => {
        const value = e.target.value < 0 ? 0 : e.target.value;
        setValueTax(parseInt(value))
    }
    return (
        <>
            <Input type="number" onChange={changesTax} value={valueTax} />
            <Button type="primary" size={"middle"} onClick={addTax}>Guardar</Button>

        </>

    )
}

export default TaxPercentCatalog