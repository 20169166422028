import { IConfPowerbi } from '../types/confPowerBI.types';
import { ActionCreator } from "redux"
import { ThunkAction } from "redux-thunk";
import { message } from "antd";

import { confPowerBITypes } from "../constants/index";
import { AppDispatch, RootState } from "../store/store";
import instance from "../utils/axios.instance";
import { IException } from "../types/interfaces/IException.interface";
import { confPowerBIAction, IError } from '../types/confPowerBI.types';
import { mergeArraysByPropertyValue } from '../utils/arrayFunctions';
import { configPowerBIModel, IConfigPowerModel } from '../models/configPowerBI.model';
import { Result } from '../types/enum/result';

/*********************FETCH CONFPOWERBI*****************************/
export const fetchConfPowerBIRequest = () => {
    return {
        type: confPowerBITypes.FETCH_CONFPOWERBI_REQUEST
    }
}

export const fetchConfPowerBISuccess = (data: IConfigPowerModel) => {
    return {
        type: confPowerBITypes.FETCH_CONFPOWERBI_SUCCESS,
        payload: data
    }
}

export const fetchConfPowerBIFailure = (error: IError) => {
    return {
        type: confPowerBITypes.UPDATE_CONFPOWERBI_FAILURE,
        payload: error
    }
}
/*********************Edit CONFPOWERBI*****************************/
export const editConfPowerBI = (data: IConfigPowerModel) => {
    return {
        type: confPowerBITypes.EDIT_CONFPOWERBI,
        payload: data
    }
}
/*********************Update CONFPOWERBI*****************************/
export const updateConfPowerBIRequest = () => {
    return {
        type: confPowerBITypes.UPDATE_CONFPOWERBI_REQUEST
    }
}
export const updateConfPowerBISuccess = (data: IConfigPowerModel) => {
    return {
        type: confPowerBITypes.UPDATE_CONFPOWERBI_SUCCESS,
        payload: data
    }
}
export const updateConfPowerBIFailure = (error: IError) => {
    return {
        type: confPowerBITypes.UPDATE_CONFPOWERBI_FAILURE,
        payload: error
    }
}
/**************************************************/
export const fetchConfPowerBI: ActionCreator<ThunkAction<void, RootState, null, confPowerBIAction>> = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchConfPowerBIRequest());
        await instance.get("/api/dashboards/stores")
            .then((response: any) => {
                /** Map data ***/
                const data: IConfigPowerModel = mapDataToModel(response.data);
                dispatch(fetchConfPowerBISuccess(response.data));
            })
            .catch((error: any) => {
                const data: IException = { status: error.response.status, message: error?.response?.data, data: error.response?.data?.data }
                dispatch(fetchConfPowerBIFailure(data));
            });
    }
}

export const updateConfPowerBIAction: ActionCreator<ThunkAction<void, RootState, null, confPowerBIAction>> = (data: IConfigPowerModel[]) =>
    async (dispatch: AppDispatch) => {
        const confPowerBI = mapModelToData(data);
        try {
            dispatch(updateConfPowerBIRequest());
            const response = await instance.put("/api/dashboards/stores", confPowerBI);
            const data: IConfigPowerModel = mapDataToModel(response.data);
            dispatch(updateConfPowerBISuccess(data));
            return Result.OK;
        } catch (error: any) {
            const data: IException = { status: error.response.status, message: error?.response?.data, data: error.response?.data?.data }
            dispatch(updateConfPowerBIFailure(data));
            return Result.ERROR;
        }
    };


export const createOrUpdatePowerBi: ActionCreator<ThunkAction<void, RootState, null, confPowerBIAction>> = (data: any, setShowForm: any,_id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            let response;
            let menssageSucces
            if(_id){
                response = await instance.put(`/api/dashboards/stores`, data);
                menssageSucces = "Hemos actualizado correctamente la configuración de Power Bi"
            }else{
                response = await instance.post(`/api/dashboards/stores`, data);
                menssageSucces = "Hemos creado correctamente la configuración de Power Bi"

            }
            dispatch(fetchConfPowerBI());
            setShowForm(false)
            message.success(menssageSucces);
            return Result.OK;        
        } catch (error: any) {
            const data: IException = { status: error?.response?.status, message: error?.response?.data, data: error.response?.data?.data }
            //dispatch(updateConfPowerBIFailure(data));
            message.error(error.response?.data?.message);
            return Result.ERROR;
        }
    };


const mapDataToModel = (data: IConfPowerbi) => {
    const model: IConfigPowerModel = mergeArraysByPropertyValue(data, configPowerBIModel);
    return model;
}

const mapModelToData = (model: IConfigPowerModel[]) => {
    const data: { [key: string]: any } = {};
    model.forEach(field =>
        data[field.name] = field.value
    );
    return data;
}