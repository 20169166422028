import { ThunkAction } from 'redux-thunk';
import { storeProductsActionTypes } from "../constants/index";
import instance from '../utils/axios.instance';
import { ActionCreator } from "redux";
import { AppDispatch, RootState } from '../store/store';
import { storeProductsAction } from '../types/storeProducts.types';
import { IError } from "../types/interfaces/error.interface";
import { arraySomeArray } from '../utils/arrayFunctions';

/*********************FETCH STORE_PRODUCTS ACTIONS************************************/

export const cleanUpSearchProductsData = () => {
    return {
        type: storeProductsActionTypes.FETCH_STORE_PRODUCTS_SEARCH_CLEAN_UP,
    }
}

export const cleanUpSearchProducts = () => {
    return (dispatch: AppDispatch) => {
        dispatch(cleanUpSearchProductsData());
    }
};

export const fetchStoreProductsSearchRequest = () => {
    return {
        type: storeProductsActionTypes.FETCH_STORE_PRODUCTS_SEARCH_REQUEST
    };
};

export const fetchStoreProductsSearchSuccess = (data: any) => {
    return {
        type: storeProductsActionTypes.FETCH_STORE_PRODUCTS_SEARCH_SUCCESS,
        payload: data
    };
};

export const fetchStoreProductsSearchFailure = (error: IError) => {
    return {
        type: storeProductsActionTypes.FETCH_STORE_PRODUCTS_SEARCH_FAILURE,
        payload: error
    };
};





export const fetchStoreProductsBySearch: ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (q: string = '', catalogId: string = '', promotionProducts: any, similarProducts: any, currentPage: any = 1, pageSize: any = 20) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchStoreProductsSearchRequest());
            const response = await instance.get(`/api/products/store/all/search?q=${q}&catalogId=${catalogId}&page=${currentPage}&pageSize=${pageSize}`);

            const [newSearchArray, count] = arraySomeArray(response.data.data, promotionProducts);
            const [finalSearchArray, count2] = arraySomeArray(newSearchArray, similarProducts.data.data);

            response.data.data = finalSearchArray;
            response.data.totalCount = (response.data.totalCount - count) - count2;

            dispatch(fetchStoreProductsSearchSuccess(response.data));
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchStoreProductsSearchFailure(data));
        }
    };



export const fetchStoreProductsBaseBySearch: ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (q: string = '', catalogId: string = '', promotionBaseProducts: any, currentPage: any = 1, pageSize: any = 20) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchStoreProductsSearchRequest());
            const response = await instance.get(`/api/products/base/all/search?q=${q}&catalogId=${catalogId}&page=${currentPage}&pageSize=${pageSize}`);
            const [newSearchArray, count] = arraySomeArray(response.data.data, promotionBaseProducts.data.data);

            response.data.data = newSearchArray;
            response.data.totalCount = response.data.totalCount - count;

            dispatch(fetchStoreProductsSearchSuccess(response.data));
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchStoreProductsSearchFailure(data));
        }
    }; 


    export const fetchStoreProductsBaseShortcutBySearch: ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (q: string = '', catalogId: string = '', promotionBaseProducts: any, currentPage: any = 1, pageSize: any = 20) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchStoreProductsSearchRequest());
            const response = await instance.get(`/api/products/base/all/search?q=${q}&catalogId=${catalogId}&page=${currentPage}&pageSize=${pageSize}`);
            const [newSearchArray, count] = arraySomeArray(response.data.data, []);
            const [finalSearchArray, count2] = arraySomeArray(newSearchArray, []);

            response.data.data = finalSearchArray;
            response.data.totalCount = (response.data.totalCount - count) - count2;
            
            dispatch(fetchStoreProductsSearchSuccess(response.data));
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchStoreProductsSearchFailure(data));
        }
    }; 