import { useEffect, useState } from 'react';

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store/store"
import { createOrUpdatePowerBi, fetchConfPowerBI } from '../actions/confPowerBI.actions';
export const usePowerBi = () => {
    const confPowerBIState = useSelector((state: RootState) => state.confPowerBI);
    const [showForm, setShowForm] = useState(false)
    const [powerBiEdit, setPowerBiEdit]: any = useState({})

    const dispatch = useAppDispatch();
    useEffect(() => {
        //get the powerBI configuration
        dispatch(fetchConfPowerBI());
    }, [dispatch]);

    const savePowerBi = (dataFields: any) => {
        const dataSend = {
            apiUrl: dataFields.apiUrl || powerBiEdit.apiUrl,
            authenticationMode: dataFields.authenticationMode|| powerBiEdit.authenticationMode,
            authorityUri: dataFields.authorityUri|| powerBiEdit.authorityUri,
            clientId: dataFields.clientId|| powerBiEdit.clientId,
            clientSecret: dataFields.clientSecret|| powerBiEdit.clientSecret,
            countryId: dataFields.countryId|| powerBiEdit?.countryId?._id,
            dataset: dataFields.dataset|| powerBiEdit.dataset,
            password: dataFields.password|| powerBiEdit.password,
            reportId: dataFields.reportId|| powerBiEdit.reportId,
            scope: dataFields.scope|| powerBiEdit.scope,
            tenantId: dataFields.tenantId|| powerBiEdit.tenantId,
            username: dataFields.username|| powerBiEdit.username,
            workspaceId: dataFields.workspaceId || powerBiEdit.workspaceId
        }
        dispatch(createOrUpdatePowerBi(dataSend, setShowForm, powerBiEdit._id))

    }

    const handleEdit = (data: string) => {
        setPowerBiEdit(data)
        setShowForm(true)
    }
    return {
        confPowerBIState,
        showForm,
        setShowForm,
        savePowerBi,
        handleEdit,
        powerBiEdit,
        setPowerBiEdit
    }


}