export const ColumnsBarcodePromotion = (
) => {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Código de barra',
            dataIndex: 'barcode',
            key: 'barcode',
            sorter: (a:any, b:any) => a.barcode - b.barcode
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            sorter: (a:any, b:any) => a.description.localeCompare(b.description)
        },
        {
            title: 'Contenido',
            dataIndex: 'contents',
            key: 'contents',
            sorter: (a:any, b:any) => a.contents - b.contents
        },
        {
            title: 'Unidad',
            dataIndex: 'unit',
            key: 'unit',
            sorter: (a:any, b:any) => a.unit.localeCompare(b.unit)
        },
        {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price',
            sorter: (a:any, b:any) => a.price - b.price
        },
        {
            title: '% de impuesto',
            dataIndex: 'taxPercent',
            key: 'taxPercent',
            sorter: (a:any, b:any) => a.taxPercent - b.taxPercent
        },
        {
            title: 'Comercio',
            dataIndex: 'store',
            key: 'store',
            render: (text:any, record:any, index:any) => {
                return record.store.storeName;
            },
            sorter: (a:any, b:any) => a.store.storeName.localeCompare(b.store.storeName)
        },
    ];
};
