import { useSelector } from "react-redux";
import { storesState } from "../../types/store.types";
import { RootState, useAppDispatch } from "../../store/store";
import { fetchStores } from "../../actions/stores.actions";
import { useEffect, useState } from "react";
import { FormInstance } from "antd";
import { GetLogUser } from "../../services/Statistics/Logs";
import { listLogs } from "../../components/statistics/logs/ColumnsLogs";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const useLogs = (queryParams: string, form: FormInstance<any>) => {
    const dispatch = useAppDispatch();
    const pageSize = 20;
    const storesState: storesState = useSelector(
        (state: RootState) => state.stores
    );

    const [viewLogsUser, setViewLogsUser] = useState(false)
    const [userLog, setUserLog]: any = useState({})
    const [dataUserLog, setDataUserLog]: any = useState({
        loading: true,
        data: []
    })

    const [viewButton, setViewButton] = useState(true)

    const [dataUserLogAll, setDataUserLogAll]: any = useState([])
    const viewLogs = (data: any) => {
        setViewLogsUser(true)
        setUserLog(data)
    }
    const [numberLogs, setNumberLogs]: any = useState(10)

    const [dataChart, setDataChart]: any = useState([])
    const [startDate, setStartDate] = useState < Date | any > (null);


    useEffect(() => {
        if (dataUserLogAll?.length > 0) {
            setDataUserLog({
                loading: false,
                data: dataUserLogAll?.slice(0, numberLogs)
            })
            totalCharts(dataUserLogAll?.slice(0, numberLogs) ?? [])

        }
    }, [numberLogs, setNumberLogs])

    useEffect(() =>{
        dispatch(fetchStores(1, 20, `&countryId=60db267049c5f13a8d0f0e86`));
    }, [])

    const removeDuplicates = async(arr: any) => {
        const uniqueItems = new Map();
    
        arr.forEach((item: any) => {
            const date = Object.keys(item)[0]; // Obtiene la clave (fecha)
            if (!uniqueItems.has(date)) {
                uniqueItems.set(date, item);
            }
        });
    
        return Array.from(uniqueItems.values());
    }
    

    const getDataLogs = async () => {
        try {
            const dataResult = await GetLogUser(userLog?.country?.id, userLog?.id)
            const dataReserve = dataResult.data?.reverse()
            const dataUni = await removeDuplicates(dataReserve)
            setDataUserLogAll(dataUni)
            setDataUserLog({
                loading: false,
                data: dataUni?.slice(0, numberLogs)
            })

            totalCharts(dataUni?.slice(0, numberLogs) ?? [])
        } catch (error) {
            toast.error("No hemos encontrado registros para esta tienda");

            setDataUserLog({
                loading: false,
                data: []
            })
            setDataChart([])
        }

    }


    const colorPalette = [
        "#4E79A7", "#59A14F", "#F28E2B", "#E15759", "#76B7B2", "#FF9DA7", "#9B59B6",
        "#F1C40F", "#BDC3C7", "#1F77B4", "#B2DF8A", "#FF7F0E", "#D62728", "#9467BD",
        "#8C564B", "#E377C2", "#7F7F7F", "#17BECF", "#9ACD32", "#FF6347", "#F39C12",
        "#C0392B", "#2980B9", "#2ECC71", "#34495E"
    ];


    const totalCharts = (dataSum: any) => {
        const totalCounts: any = {};
        dataSum.forEach((dayObj: any) => {
            const dayData: any = Object.values(dayObj)[0]; // Obtiene el objeto de eventos dentro del día
            Object.entries(dayData).forEach(([event, count]) => {
                totalCounts[event] = (totalCounts[event] || 0) + count;
            });
        });
        const dataList: any = []
        let dataChartList = listLogs.map((item: any, index: number) => {

            dataList.push({
                label: item.name,
                value: totalCounts?.[item.type] ?? 0,
                color: colorPalette[index]
            })

        })
        setDataChart(dataList)
    }

    useEffect(() => {
        if (userLog?.id) {
            getDataLogs()
        }
    }, [userLog, setUserLog])


    const handleStartDateChange = (date: Date | any) => {
        setStartDate(date)
    }


    const clearFilter = () => {
        if (dataUserLogAll.length > 0) {
            setNumberLogs(10)
            setDataUserLog({
                loading: false,
                data: dataUserLogAll?.slice(0, 10)
            })
            totalCharts(dataUserLogAll?.slice(0, 10) ?? [])
            setStartDate(null)
            setViewButton(true)
        }
    }
    const searchDate = () => {
        if (startDate) {
            const dateNew = new Date(startDate)
            const year = dateNew.getFullYear();
            const month = (dateNew.getMonth() + 1).toString().padStart(2, '0');
            const day = dateNew.getDate().toString().padStart(2, '0');
            let result = dataUserLogAll.find((item: any) => item[`${year}-${month}-${day}`]);
            if (result) {
                setDataUserLog({
                    ...dataUserLog,
                    data: [result]
                })
                totalCharts([result])
                setViewButton(false)
            } else {
                toast.error("No hemos encontrado resultados para la fecha seleccionada");

            }

        }else{
            toast.error("Debes seleccionar una fecha");

        }

    }

    const clearView = () => {
        clearFilter()
        setViewLogsUser(!viewLogsUser)
        setDataUserLogAll([])
        setDataUserLog({
            loading: true,
            data: []
        })
        setNumberLogs(10)
        setStartDate(null)
        setUserLog({})
        setDataChart([])
    }

    return {
        storesState, pagination: {
            current: storesState.stores.data.page,
            pageSize: storesState.stores.data.pageSize,
            total: storesState.stores.data.totalCount,
            onChange: (page: number, pageSize: number) => {
                dispatch(fetchStores(page, pageSize, `&countryId=${form.getFieldValue("countryId")}`));

            },
        },
        viewLogsUser,
        setViewLogsUser,
        viewLogs,
        dataUserLog,
        userLog,
        dataChart,
        numberLogs,
        setNumberLogs,
        dataUserLogAll,
        handleStartDateChange,
        startDate,
        searchDate,
        clearFilter,
        clearView,
        viewButton
    }
}