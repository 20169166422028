export const headers = [
    {
        label: "Nombre",
        key: "name",
    },
    {
        label: "País",
        key: "country.name",
    },
    {
        label: "Dirección",
        key: "address",
    },
    {
        label: "Mostrar impuestos",
        key: "taxDetail",
    },
    {
        label: "Username (E-mail)",
        key: "storeKey",
    },
    {
        label: "Estado",
        key: "active",
    },
];