import { Col } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { storeProductsState } from "../../../../types/storeProducts.types";
import PageTitle from "../../../other/PageTitle";
import Table from "../../../table/customizedTable/CustomizedTable";
import { Columns } from "./Columns";

export const SimilarProducts = () => {
  const storeProductsState: storeProductsState = useSelector(
    (state: RootState) => state.storeProducts
  );
  const columns = Columns();

  return (
    <>
      <Col span={24}>
        <PageTitle title="Productos de tienda similares" />
      </Col>

      <Col span={24} className="table-container-de">
        <Table
          dataSource={storeProductsState.similarProducts.data.data}
          columns={columns}
          loading={storeProductsState.similarProducts.loading}
        />
      </Col>
    </>
  );
};
