import { Button, Modal, Select } from "antd";
import { FormOutlined, StarOutlined, CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

export const ActionNotPromotable = (record: any, actions: any) => {
  const {
    isNoPromoteModalVisible,
    handleCancel,
    productStoreID,
    onClickNoPromoteProduct,
    noPromoteReason,
    onChangeNoPromoteReason,
    queryParams,
  } = actions;
  if (record.baseProduct) {
    return (
      <CloseOutlined
        style={{ opacity: "0.3" }}
        title="No aplica"
        className="table__button"
        alt="Icono de habilitar"
      />
    );
  } else if (!record.baseProduct && record.promotable === false) {
    return (
      <CloseOutlined
        style={{ opacity: "0.3" }}
        title="No aplica"
        className="table__button"
        alt="Icono de habilitar"
      />
    );
  } else {
    return (
      <div className="table__button-container">
        <CloseOutlined
          title="No promover"
          className="table__button"
          alt="Icono de habilitar"
          onClick={() => actions.showNoPromoteModal(record.id)}
        />
        <Modal
          title={`No promover producto`}
          visible={isNoPromoteModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" type="primary" onClick={handleCancel}>
              Cancelar
            </Button>,
            <Button
              key="no-promote"
              onClick={() =>{
                onClickNoPromoteProduct(productStoreID, noPromoteReason,queryParams)
              }
            }
            >
              No promover
            </Button>,
          ]}
          mask={false}
          maskStyle={{ backgroundColor: "#00000073" }}
        >
          <p>
            ¿Esta seguro que desea marcar el producto como no promovible?, en
            caso de aceptar no se mostrará nuevamente en el listado de Productos
            de tienda
          </p>
          <Select
            placeholder="Seleccione una razón"
            dropdownClassName="select"
            onChange={(value: string) => onChangeNoPromoteReason(value)}
          >
            <Option value="Sin código de barra" label="Sin código de barra">
              Sin código de barra
            </Option>

            <Option
              value="Código de barra inválido"
              label="Código de barra inválido"
            >
              Código de barra inválido
            </Option>

            <Option
              value="Código de barra asociado a varios productos"
              label="Código de barra asociado a varios productos"
            >
              Código de barra asociado a varios productos
            </Option>

            <Option
              value="Código de barra no encontrado en internet"
              label="Código de barra no encontrado en internet"
            >
              Código de barra no encontrado en internet
            </Option>
          </Select>
        </Modal>
      </div>
    );
  }
};

export const ActionEditProduct = (record: any, actions: any) => {
  const { handleEdit } = actions;
  return (
    <FormOutlined
      title="Ver detalle"
      alt="Icono de ver detalle"
      className="table__button"
      onClick={() => handleEdit(record.id)}
    />
  );
};

export const ActionPromotable = (record: any, actions: any) => {
  const { onChangeCheckbox, onClickPromoteProducts } = actions;
  return (
    <div
      onClick={() => {
        let type = "Por promover";
        if (record.baseProduct) {
          type = "Promovido";
        }
        if (
          !record.hasOwnProperty("baseProduct") &&
          record.hasOwnProperty("reason")
        ) {
          type = "No promovible";
        }
        onChangeCheckbox(true, record, type);
        onClickPromoteProducts();
      }}
    >
      <StarOutlined
        title="Promover"
        alt="Icono de promover"
        className="table__button"
      />
    </div>
  );
};
