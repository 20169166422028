import { Button, Col, Row, Form } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import HelmetTitle from "../../other/HelmetTitle";
import Table from "../../table/customizedTable/CustomizedTable";
import Filters from "../../shared/filters/Filters";
import { useProductsBase } from "../../../hooks/useProductsBase";
//import ProductsBaseForm from "./ProductsBaseForm";
import ProductsBaseForm from "./ProductsBaseForm";
import PageTitle from "../../other/PageTitle";
import { Columns } from "./Columns";
import { headers } from "./FileHeaders";
import { fields } from "./fields";
import { useFilters } from "../../../hooks/useFilters";
import { fetchBaseProducts } from "./../../../actions/baseProducts.actions";
import HorizontalFilter from "../../shared/filters/HorizontalFilter";
import FilterBreadcrumb from "../../shared/filters/FilterBreadcrumb";
import { FilterSection } from "../../../types/enum/filters";
import { DownloadCSV } from "../../shared/buttons/DownloadCSV";

const defaultParams = `&catalogId=60d4f60a378aec6831a3f459`;

const ProductsBase = () => {
  const [form] = Form.useForm();

  const {
    filters,
    handleChange,
    handleChangeSelect,
    resetFilter,
    toggleFilters,
    collapsed,
    onFinishFiltering,
    queryParams,
    filtersState,
    handleDonwload,
  } = useFilters(
    fetchBaseProducts,
    fields,
    FilterSection.BASEPRODUCTS,
    defaultParams,
    form
  );

  const {
    baseProductsState,
    pagination,
    handleEdit,
    onClick,
    showForm,
    setShowForm,
    date,
  } = useProductsBase(queryParams, defaultParams);

  const columns = Columns(handleEdit);

  return (
    <>
      <HelmetTitle
        title="Productos base - Backoffice D&#38;N"
        description="Pantalla para listar, y editar productos base"
      />
      {showForm ? (
        <ProductsBaseForm setShowForm={setShowForm} queryParams={queryParams} />
      ) : (
        <>
          <Row className="d-flex space-between">
            <Col span={12}>
              <PageTitle title="Productos base" />

              <FilterBreadcrumb filters={filtersState.baseProducts} />
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              className="d-flex flex-end"
            >
              <DownloadCSV
                headers={headers}
                date={date}
                section={FilterSection.BASEPRODUCTS}
                handleDownload={handleDonwload}
              />

              <Button type="text" onClick={toggleFilters}>
                <FilterOutlined />
                Filtros
              </Button>
            </Col>
          </Row>

          <Form form={form} onFinish={onFinishFiltering}>
            <HorizontalFilter
              fields={fields}
              onFinishFiltering={onFinishFiltering}
              filters={filters}
              handleChange={handleChange}
              handleChangeSelect={handleChangeSelect}
              resetFilter={resetFilter}
              form={form}
            />
            <Filters
              fields={fields}
              filters={filters}
              collapsed={collapsed}
              onFinishFiltering={onFinishFiltering}
              productStoreFilters={true}
              toggleFilters={toggleFilters}
              resetFilter={resetFilter}
              handleChange={handleChange}
              handleChangeSelect={handleChangeSelect}
              form={form}
            />
          </Form>

          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Table
                dataSource={baseProductsState.baseProducts.data.data}
                columns={columns}
                name="producto base"
                path="products-base"
                onClick={onClick}
                loading={baseProductsState.baseProducts.loading}
                pagination={pagination}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductsBase;
