import { message, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  fetchStoreProduct,
} from "../../actions/storeProducts/main.actions";
import { RootState, useAppDispatch } from "../../store/store";
import { storeProductsState } from "../../types/storeProducts.types";
import { ProductType } from "../../types/enum/modalType";
import { Result } from "../../types/enum/result";
import {
  cleanupBaseProductsRelated,
  cleanupSimilarProducts,
} from "../../actions/storeProducts/cleanup.actions";
import {
  fetchBaseProductsRelated,
  fetchBaseProductsRelatedSuccess,
  fetchSimilarProducts,
  promoteProductsStoreV2,
} from "../../actions/storeProducts/promotion.actions";
import { removeDuplicates } from "../../utils/arrayFunctions";
import usePromotionProductsTable from "./usePromotionProductsTable";
import useAddProductsModal from "./useAddProductsModal";
import usePagination from "./usePagination";

const { Option } = Select;

const useProductsPromotion = (setRestartSearch: any) => {
  const storeProductsState: storeProductsState = useSelector(
    (state: RootState) => state.storeProducts
  );
  const [promotionProducts, setPromotionProducts]: any = useState([]);
  const [newPromotionProducts, setNewPromotionProducts]: any = useState([]);
  const [searchParameter, setSearchParameter] = useState("description");
  const [catalogId, setCatalogId] = useState("");
  const dispatch = useAppDispatch();
  const navigate: any = useNavigate();
  const [baseProductSelected, setBaseProductSelected]: any = useState({
    barcode: "",
    id: "",
  });
  const [showCreateProdModal, setShowCreateProdModal] = useState(false);
  const [showResumeModal, setShowResumeModal] = useState(false);

  /************************ related custom hooks *****************************/
  //PromotionProductsTable
  const { setSelectedRowKeysState, handleRowSelectionPromotionProducts } =
    usePromotionProductsTable({
      setBaseProductSelected,
      catalogId,
      baseProductSelected,
    });

  //AddProductsModal
  const {
    searchValue,
    setSearchValue,
    modalType,
    showAddProductsModal,
    handleShowAddProductsModal,
    handleCancelAddProductsModal,
    onSearchAddProducts,
    handleRowSelectionAddProductsTable,
  } = useAddProductsModal({
    setNewPromotionProducts,
    newPromotionProducts,
    catalogId,
    promotionProducts,
  });
  //Pagination
  const {paginationSearch,pagination} = usePagination({setNewPromotionProducts,
    modalType,
    catalogId,
    searchValue,
    promotionProducts});
  /*********************** end related custom hooks ***************************/

  const handleRowSelectionSimilarProducts = {
    type: "checkbox",
  };

  const handleRowSelectionBaseProductRelated = {
    onSelect: (
      record: any,
      selected: any,
      selectedRows: any,
      nativeEvent: any
    ) => {
      if (selected) {
        setBaseProductSelected({
          id: record.id,
          barcode: record.barcode,
        });
      } else {
        setBaseProductSelected({
          id: "",
          barcode: "",
        });
      }
    },
    type: "radio",
  };

  const addNewProductsBase = () => {
    const tempProductsBase = newPromotionProducts;
    storeProductsState.baseProductsRelated.data.data.forEach((item) => {
      const filter = tempProductsBase.some(
        (value: any) => value.id === item.id
      );
      if (!filter) {
        tempProductsBase.push(item);
      }
    });
    setNewPromotionProducts(tempProductsBase);
    dispatch(fetchBaseProductsRelatedSuccess({ data: newPromotionProducts }));
  };

  const addNewPromotionProducts = (type: ProductType = ProductType.STORE) => {
    if (type === ProductType.BASE) {
      addNewProductsBase();
    } else {
      const promotionProductsUpdated =
        promotionProducts.concat(newPromotionProducts);
      // Eliminamos los duplicados
      const ids = promotionProductsUpdated.map((obj: any) => obj.id);
      const filteredPromotionProductsUpdated = promotionProductsUpdated.filter(
        ({ id }: { id: string }, index: number) => !ids.includes(id, index + 1)
      );

      const products = removeDuplicates(filteredPromotionProductsUpdated);
      setPromotionProducts(products);
    }

    handleCancelAddProductsModal();
    message.success("Los productos han sido adicionados con éxito");
  };

  const onChangeSearchParameter = (value: string) => {
    setSearchParameter(value);
  };

  const searchParametersList = (
    <Select
      defaultValue={searchParameter}
      className="select-after"
      onChange={onChangeSearchParameter}
    >
      <Option value="description">Descripción</Option>
      <Option value="barcode">Código de barra</Option>
    </Select>
  );
  // Add products modal

  useEffect(() => {
    let barcodes: string[] = [];
    dispatch(cleanupSimilarProducts());
    dispatch(cleanupBaseProductsRelated());

    const promotionProductsConcat: any =
      storeProductsState.productsToBePromoted.data.concat(
        storeProductsState.productsPromoted.data,
        storeProductsState.productsNoPromotable.data
      );

    // Si no hay productos a promocionar, se redirecciona a la pantalla de productos de tienda
    if (!promotionProductsConcat.length) {
      navigate("/products-store-new");
      // window.location.reload();
      return;
    }

    // Id del catalogo al que pertenecen los productos
    const catalogIdValue: string =
      promotionProductsConcat[0].category[0].catalogId;

    // Selecciona por defecto todos los productos
    const rowIds = promotionProductsConcat.map((obj: any) => obj.key);
    setSelectedRowKeysState(rowIds);

    // Hacemos fetch de todos los prod similares
    promotionProductsConcat.forEach((obj: any) => {
      if (obj.barcode !== "") {
        dispatch(
          fetchSimilarProducts(
            1,
            1000000000,
            [`&catalogId=${catalogIdValue}&barcode=${obj.barcode}`],
            obj.id,
            promotionProducts
          )
        );
      }
      barcodes.push(obj.barcode);
    });

    const filterBarcodes = barcodes.filter((barcode: string) => barcode !== "");

    dispatch(fetchBaseProductsRelated(catalogIdValue, filterBarcodes.join()));

    setCatalogId(catalogIdValue);
    const products = removeDuplicates(promotionProductsConcat);
    setPromotionProducts(products);
  }, []);

  const onClickCreateProduct = () => {
    setShowCreateProdModal(true);
    dispatch(fetchStoreProduct(promotionProducts[0].id));
  };

  const onClickPromoteProduct = () => {
    let productsArray = promotionProducts;

    if (
      baseProductSelected.id.trim() === "" ||
      baseProductSelected.barcode.trim() === ""
    ) {
      message.warning(
        "Por favor, seleccione un producto base al cual se promocionarán los productos de tienda seleccionados"
      );
      return;
    }

    // storeProductsIds
    if (storeProductsState.similarProducts.data.data.length) {
      productsArray = productsArray.concat(
        storeProductsState.similarProducts.data.data
      );
    }

    // We remove duplicates
    const removedDuplicates = productsArray.reduce((unique: any, o: any) => {
      if (!unique.some((obj: any) => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);

    // Get ids
    const storeProductsIds = removedDuplicates.map((obj: any) => obj.id);

    const body = {
      baseProductId: baseProductSelected.id,
      storeProductsIds,
      catalogId,
    };

    dispatch(promoteProductsStoreV2(body)).then((result: any) => {
      if (result === Result.OK) {
        setShowResumeModal(true);
        setRestartSearch(true);
      } else {
        message.error(
          storeProductsState.promotionProductsResult.error.data.message
        );
      }
    });
  };

  const resumeModalColumns = [
    {
      title: "Producto",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Estado",
      dataIndex: "reason",
      key: "reason",
      render: (text: any, record: any, index: any) => {
        if (record.reason === "UPDATE") {
          return "Exitoso";
        } else {
          return "Fallido";
        }
      },
    },
  ];

  return {
    handleRowSelectionSimilarProducts,
    handleRowSelectionPromotionProducts,
    promotionProducts,
    showAddProductsModal,
    handleCancelAddProductsModal,
    handleShowAddProductsModal,
    onSearchAddProducts,
    searchValue,
    setSearchValue,
    storeProductsState,
    paginationSearch: {paginationSearch},
    pagination: {pagination},
    handleRowSelectionAddProductsTable,
    newPromotionProducts,
    addNewPromotionProducts,
    searchParametersList,
    handleRowSelectionBaseProductRelated,
    onClickCreateProduct,
    modalType,
    showCreateProdModal,
    setShowCreateProdModal,
    onClickPromoteProduct,
    showResumeModal,
    resumeModalColumns,
    setShowResumeModal,
  };
};

export default useProductsPromotion;
