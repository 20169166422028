
import Categories from "../components/categories/Categories";
import CategoriesForm from "../components/categories/CategoriesForm";
import Countries from "../components/countries/Countries";
import CountriesForm from "../components/countries/CountriesForm";
import CatalogFiles from "../components/management/catalog-files/CatalogFiles";
import CatalogFilesAdd from "../components/management/catalog-files/CatalogFilesAdd";
import ConfPowerBi from "../components/management/conf-powerbi/ConfPowerBI";
import Regions from "../components/regions/Regions";
import RegionsForm from "../components/regions/RegionsForm";
import ProductsBase from "../components/search/products-base/ProductsBase";
import ProductsBaseForm from "../components/search/products-base/ProductsBaseForm";
import Promote from "../components/search/products-base/promote/Promote";
import ProductsStore from "../components/search/products-store/ProductsStore";
import Stores from "../components/stores/Stores";
import StoresForm from "../components/stores/StoresForm";
//import Users from "../components/users/Users";
//import UsersForm from "../components/users/UsersForm";
import Catalogs from "../components/catalogs/Catalogs";
import Forbidden from "../components/other/Forbidden";

import { Role } from './../types/enum/roles';
import ProductsStoreNew from "../components/search/products-store-new/ProductsStore";
import ProductsStoreForm from "../components/search/products-store-new/ProductsStoreForm";
import ProductsStoreNoPromotable from "../components/search/products-store-new/no-promotable/ProductsStoreNoPromotable";
import ProductsPromotion from "../components/search/products-store-new/promotion/ProductsPromotion";

import Shortcut from "../components/shortcut/Shortcut";
import Tickets from "../components/tickets/Tickets";

//Modules
//import DemoIrt from "../vendorTools/views/demoIrt"
import RedirectByRol from "../components/redirect/RedirectComponent";
import UpdateProducts from "../components/updateProduct";
import { Statistics } from "../components/statistics";

const routes = [
    { path: "/", name: "redirect", Component: RedirectByRol, permissions: [ Role.ADMINISTRADOR_GENERAL,Role.GESTION ] },
    { path: "/countries", name: "Paises", Component: Countries, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/countries/add", name: "Anadir Pais", Component: CountriesForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/countries/edit/:id", name: "Edir Pais", Component: CountriesForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/regions", name: "Region", Component: Regions, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/regions/add", name: "Anadir Region", Component: RegionsForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/regions/edit/:id", name: "Editar Region", Component: RegionsForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/stores", name: "Comercios", Component: Stores, permissions: [ Role.ADMINISTRADOR_GENERAL, Role.GESTION ] },
    { path: "/stores/add", name: "Anadir Comercio", Component: StoresForm, permissions: [ Role.ADMINISTRADOR_GENERAL, Role.GESTION ] },
    { path: "/stores/edit/:id", name: "Editar Comercio", Component: StoresForm, permissions: [ Role.ADMINISTRADOR_GENERAL, Role.GESTION ] },
    { path: "/categories", name: "Categorías", Component: Categories, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/categories/add", name: "Anadir Categoría", Component: CategoriesForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/categories/edit/:id", name: "Editar Categoría", Component: CategoriesForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    /*
    { path: "/users", name: "Usuarios", Component: Users, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/users/add", name: "Anadir Usuarios", Component: UsersForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/users/edit/:id", name: "Editar Usuarios", Component: UsersForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    */
    { path: "/products-base", name: "Productos Base", Component: ProductsBase, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/products-base/add", name: "Anadir Producto Base", Component: ProductsBaseForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/products-base/edit/:id", name: "Editar Producto Base", Component: ProductsBaseForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/products-base/promote", name: "Promover Producto Base", Component: Promote, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/products-store", name: "Productos de tienda", Component: ProductsStore, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/conf-powerbi", name: "Configuración PowerBI", Component: ConfPowerBi, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/catalog-files", name: "Archivos de catálogo", Component: CatalogFiles, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/catalog-files/add", name: "Anadir Archivos de catálogo", Component: CatalogFilesAdd, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/catalogs", name: "Catálogos Base", Component: Catalogs, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/catalogs/:id/categories", name: "Categorías", Component: Categories, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/forbidden", name: "Acceso Denegado", Component: Forbidden, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/products-store-new", name: "Productos de tienda", Component: ProductsStoreNew, permissions: [ Role.ADMINISTRADOR_GENERAL ] },

    { path: "/update-products", name: "Actualizar productos tienda", Component: UpdateProducts, permissions: [ Role.ADMINISTRADOR_GENERAL ] },

    { path: "/products-store-new/edit/:id", name: "Editar producto de tienda", Component: ProductsStoreForm, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/products-store-new/no-promotable", name: "Productos no promovibles", Component: ProductsStoreNoPromotable, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/products-store-new/promotion", name: "Promoción de productos", Component: ProductsPromotion, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    { path: "/shortcut", name: "Shortcut", Component: Shortcut, permissions: [ Role.ADMINISTRADOR_GENERAL ] }, 
    { path: "/tickets", name: "Tickets", Component: Tickets, permissions: [ Role.ADMINISTRADOR_GENERAL ] },
    
    
    { path: "/statistics", name: "Estadísticas", Component: Statistics, permissions: [ Role.ADMINISTRADOR_GENERAL ] },

];


export default routes;