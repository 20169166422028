import HelmetTitle from "../../other/HelmetTitle";
import PageTitle from '../../other/PageTitle';
import { Col, Row, Table, Button } from "antd";
import { columnsPowerBi } from './Columns';
import { usePowerBi } from "../../../hooks/usePowerBi";
import { PlusOutlined } from "@ant-design/icons";
import { PowerBiForm } from "./ConfPowerBiForm";
import TableComponente from "../../table/customizedTable/CustomizedTable";

const ConfPowerBi = () => {
    const { confPowerBIState, showForm, setShowForm, savePowerBi, handleEdit, powerBiEdit, setPowerBiEdit } = usePowerBi()

    return (
        <>
            <HelmetTitle title="Configuración PowerBI - Backoffice D&#38;N" description="Pantalla para configurar el tablero de PowerBI" />
            {showForm ?
                <PowerBiForm setShowForm={setShowForm} savePowerBi={savePowerBi} powerBiEdit={powerBiEdit}/>
                :
                <>
                    <Row>
                        <Col span={12}>
                            <PageTitle title="Configuración PowerBI" />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="d-flex flex-end">
                            <Button type="text" onClick={() =>{
                                 setShowForm(true)
                                 setPowerBiEdit({})
                            }} >
                                <PlusOutlined />
                                Nuevo Power Bi
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                      
                            <TableComponente
                                dataSource={confPowerBIState.configuration.data}
                                columns={columnsPowerBi(handleEdit)}
                                name="powerBi"
                                path="powerBi"
                                loading={false}
                               
                            />
                        </Col>
                    </Row>
                </>
            }
        </>
    )
};
export default ConfPowerBi;