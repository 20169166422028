import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { storeActionTypes } from '../constants/index';
import instance from '../utils/axios.instance';
import { AppDispatch, RootState } from '../store/store';
import { Result } from '../types/enum/result';
import { IError } from '../types/interfaces/error.interface';
import { IStore, IStoreUpdate, storesAction } from '../types/store.types';

/*********************FETCH STORES ACTIONS************************************/
export const fetchStoresRequest = () => {
    return {
        type: storeActionTypes.FETCH_STORES_REQUEST
    }
}

export const fetchStoresSuccess = (data: any) => {
    return {
        type: storeActionTypes.FETCH_STORES_SUCCESS,
        payload: data
    }
}

export const fetchStoresFailure = (error: IError) => {
    return {
        type: storeActionTypes.FETCH_STORES_FAILURE,
        payload: error
    }
}
/*********************FETCH STORE ACTIONS************************************/
export const fetchStoreRequest = () => {
    return {
        type: storeActionTypes.FETCH_STORE_REQUEST
    }
}

export const fetchStoreSuccess = (data: IStore) => {
    return {
        type: storeActionTypes.FETCH_STORE_SUCCESS,
        payload: data
    }
}

export const fetchStoreFailure = (error: IError) => {
    return {
        type: storeActionTypes.FETCH_STORE_FAILURE,
        payload: error
    }
}
/*********************CREATE STORE ACTIONS************************************/
export const createStoreRequest = () => {
    return {
        type: storeActionTypes.CREATE_STORE_REQUEST
    }
}

export const createStoreSuccess = (data: any) => {
    return {
        type: storeActionTypes.CREATE_STORE_SUCCESS,
        payload: data
    }
}

export const createStoreFailure = (error: IError) => {
    return {
        type: storeActionTypes.CREATE_STORE_FAILURE,
        payload: error
    }
}
/*********************UPDATE STORE ACTIONS************************************/
export const updateStoreRequest = () => {
    return {
        type: storeActionTypes.UPDATE_STORE_REQUEST
    }
}

export const updateStoreSuccess = (data: any) => {
    return {
        type: storeActionTypes.UPDATE_STORE_SUCCESS,
        payload: data
    }
}

export const updateStoreFailure = (error: IError) => {
    return {
        type: storeActionTypes.UPDATE_STORE_FAILURE,
        payload: error
    }
}
/*********************ENABLE STORE ACTIONS************************************/
export const enableStoreRequest = () => {
    return {
        type: storeActionTypes.ENABLE_STORE_REQUEST
    }
}

export const enableStoreSuccess = (data: any) => {
    return {
        type: storeActionTypes.ENABLE_STORE_SUCCESS,
        payload: data
    }
}

export const enableStoreFailure = (error: IError) => {
    return {
        type: storeActionTypes.ENABLE_STORE_FAILURE,
        payload: error
    }
}
/*********************ENABLE STORE ACTIONS************************************/
export const disableStoreRequest = () => {
    return {
        type: storeActionTypes.DISABLE_STORE_REQUEST
    }
}

export const disableStoreSuccess = (data: any) => {
    return {
        type: storeActionTypes.DISABLE_STORE_SUCCESS,
        payload: data
    }
}

export const disableStoreFailure = (error: IError) => {
    return {
        type: storeActionTypes.DISABLE_STORE_FAILURE,
        payload: error
    }
}
/*********************CLEANUP STORE ACTION************************************/
export const cleanUp = () => {
    return {
        type: storeActionTypes.STORE_CLEAN_UP
    }
}
/****************************************************************************/
//Get Stores
export const fetchStores: ActionCreator<ThunkAction<void, RootState, null, storesAction>> = (currentPage: any = 1, pageSize: any = 20, params: string = '') => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchStoresRequest());
        await instance.get(`/api/stores?page=${currentPage}&pageSize=${pageSize}${params}`)
            .then((response: any) => {
                let data = [];
                if(response.data){
                    data = response.data;
                }
                dispatch(fetchStoresSuccess(data));
            })
            .catch((error: any) => {
                if(error.response){
                    const data: IError = { status: error.response.status, data: { timestamp: error.response?.data?.timestamp, path: error.response?.data?.path, message: error.response?.data?.message } }
                    dispatch(fetchStoresFailure(data));
                }
            });
    }
}
//Get a Store
export const fetchStore: ActionCreator<ThunkAction<void, RootState, null, storesAction>> = (id: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchStoreRequest());
        await instance.get(`/api/stores/${id}`)
            .then((response: any) => {
                const data = response.data;
                dispatch(fetchStoreSuccess(data));
            })
            .catch((error: any) => {
                if(error.response){
                    const data: IError = { status: error.response.status, data: { timestamp: error.response?.data?.timestamp, path: error.response?.data?.path, message: error.response?.data?.message } }
                    dispatch(fetchStoreFailure(data));
                }
            });
    }
}
//Create
export const createStore: ActionCreator<ThunkAction<void, RootState, null, storesAction>> = (body: IStore) =>
    async (dispatch: AppDispatch) => {

        try {
            dispatch(createStoreRequest());
            const response = await instance.post(`/api/stores`, body);
            const data = response.data;
            dispatch(createStoreSuccess(data));
            return Result.OK;
        }
        catch (error: any) {
            const data: IError = { status: error.response.status, data: { timestamp: error.response?.data?.timestamp, path: error.response?.data?.path, message: error.response?.data?.message } }
            dispatch(createStoreFailure(data));
            return Result.ERROR;
        }
    }
//Update
export const updateStore: ActionCreator<ThunkAction<void, RootState, null, storesAction>> = (body: IStoreUpdate, id: string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(updateStoreRequest());
            const response = await instance.put(`/api/stores/${id}`, body)
            const data = response.data;
            dispatch(updateStoreSuccess(data));
            return Result.OK;
        }
        catch (error: any) {
            const data: IError = { status: error.response.status, data: { timestamp: error.response?.data?.timestamp, path: error.response?.data?.path, message: error.response?.data?.message } }
            dispatch(updateStoreFailure(data));
            return Result.ERROR;
        }
    }
//Enable Store
export const enableStore: ActionCreator<ThunkAction<void, RootState, null, storesAction>> = (id: string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(enableStoreRequest());
            const response = await instance.patch(`/api/stores/${id}/activate`)
            const data = response.data;
            dispatch(enableStoreSuccess(data));
            return Result.OK;
        }
        catch (error: any) {
            const data: IError = { status: error.response.status, data: { timestamp: error.response?.data?.timestamp, path: error.response?.data?.path, message: error.response?.data?.message } }
            dispatch(enableStoreFailure(data));
            return Result.ERROR;
        }
    }
//Disable Store
export const disableStore: ActionCreator<ThunkAction<void, RootState, null, storesAction>> = (id: string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(disableStoreRequest());
            const response = await instance.delete(`/api/stores/${id}`)
            const data = response.data;
            dispatch(disableStoreSuccess(data));
            return Result.OK;
        } catch (error: any) {
            const data: IError = { status: error.response.status, data: { timestamp: error.response?.data?.timestamp, path: error.response?.data?.path, message: error.response?.data?.message } }
            dispatch(disableStoreFailure(data));
            return Result.ERROR;
        }
    }