import { IStoreProducts, IStoreProduct, IData } from '../types/storeProducts.types';
import { storeProductsActionTypes } from "../constants";
import { v4 as uuid } from "uuid";
import { IError } from "../types/interfaces/error.interface";
import { Action } from "../types/actions.types";
import { storeProductsState } from "../types/storeProducts.types";

let error: IError = {
    status: '',
    data: {
        timestamp: '',
        path: '',
        message: ''
    }
};

let storeProducts: IStoreProducts = {
    data: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

let similarProducts: IStoreProducts = {
    data: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

let searchProducts: IStoreProducts = {
    data: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

let storeProduct: IStoreProduct = {
    id: '',
    description: '',
    brand: '',
    contents: '',
    barcode: '',
    unit: '',
    price: '',
    currency: '',
    taxPercent: '',
    sellMode: '',
    catalogId: '',
    category: '',
    active: true
};

const resetStoreProduct = {
    description: "",
    brand: "",
    contents: "",
    barcode: "",
    unit: "",
    price: "",
    currency: "",
    taxPercent: "",
    sellMode: "",
    active: false
}

const initialState = {
    storeProducts: {
        loading: false,
        data: storeProducts,
        error: error,
    },
    storeProduct: {
        loading: false,
        data: storeProduct,
        error: error
    },
    promoteProductStoreByBarcode: {
        loading: false,
        data: storeProducts,
        error: error
    },
    barcodeProducts: {
        data: [],
        withBarcode: true,
    },
    noBarcodeProducts: {
        data: [],
        withBarcode: false,
    },
    // Objetos relacionados a la nueva pantalla de productos de tienda
    productsToBePromoted: {
        data: [],
    },
    productsPromoted: {
        data: [],
    },
    productsNoPromotable: {
        data: [],
        result: {
            data: [],
            loading: false
        }
    },
    similarProducts: {
        loading: false,
        data: storeProducts,
        error: error,
    },
    baseProductsRelated: {
        loading: false,
        data: storeProducts,
        error: error,
    },
    searchProducts: {
        loading: false,
        data: searchProducts,
        error: error,
    },
    // This has the result of the promoted products
    promotionProductsResult: {
        data: [],
        error: error,
    }
};

const storeProductsReducer = (state = initialState, action: Action): storeProductsState => {
    let removedProductId: string;
    let filtered: any;
    let removedProductBarcode: string;
    let removedProductCatalogId: string;
    let data: IData[];
    let barcode: any;
    let productsBeingRemoved: any;

    switch (action.type) {
        case storeProductsActionTypes.PROMOTE_PRODUCTS_STORE_SUCCESS_V2:
            data = [...action.payload];

            data.forEach((item: IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });

            return {
                ...state,
                promotionProductsResult: {
                    ...state.promotionProductsResult,
                    data: action.payload
                }
            };
        case storeProductsActionTypes.PROMOTE_PRODUCTS_STORE_FAILURE_V2:
            return {
                ...state,
                promotionProductsResult: {
                    ...state.promotionProductsResult,
                    error: action.payload
                }
            };
        case storeProductsActionTypes.FETCH_BASE_PRODUCTS_RELATED_REQUEST:
            return {
                ...state,
                baseProductsRelated: {
                    ...state.baseProductsRelated,
                    loading: true
                }
            };
        case storeProductsActionTypes.FETCH_BASE_PRODUCTS_RELATED_SUCCESS:
            data = [...action.payload.data];

            data.forEach((item: IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });

            return {
                ...state,
                baseProductsRelated: {
                    ...state.baseProductsRelated,
                    data: {
                        ...state.baseProductsRelated.data,
                        data: action.payload.data
                    },
                    loading: false
                }
            };
        case storeProductsActionTypes.FETCH_BASE_PRODUCTS_RELATED_FAILURE:
            return {
                ...state,
                baseProductsRelated: {
                    ...state.baseProductsRelated,
                    loading: false,
                    error: action.payload
                }
            };
        case storeProductsActionTypes.ADD_BASE_PRODUCTS_RELATED:
            const baseProductsRelated: IData[] = [...state.baseProductsRelated.data.data, action.payload]
            return {
                ...state,
                baseProductsRelated: {
                    ...state.baseProductsRelated,
                    data: {
                        ...state.baseProductsRelated.data,
                        data: baseProductsRelated
                    },
                    loading: false
                }
            };
        case storeProductsActionTypes.NO_PROMOTE_STORE_PRODUCTS_REQUEST:
            return {
                ...state,
                productsNoPromotable: {
                    ...state.productsNoPromotable,
                    result: {
                        ...state.productsNoPromotable.result,
                        loading: true
                    }
                }
            };
        case storeProductsActionTypes.NO_PROMOTE_STORE_PRODUCTS_SUCCESS:
            data = [...action.payload.data.data];

            data.forEach((item: IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });

            return {
                ...state,
                productsNoPromotable: {
                    ...state.productsNoPromotable,
                    result: {
                        data: action.payload.data.data,
                        loading: false
                    }
                }
            };
        case storeProductsActionTypes.NO_PROMOTE_STORE_PRODUCTS_FAILURE:
            return {
                ...state,
                productsNoPromotable: {
                    ...state.productsNoPromotable,
                    result: {
                        ...state.productsNoPromotable.result,
                        loading: false,
                        error: action.payload
                    }
                }
            };
        case storeProductsActionTypes.FETCH_SIMILAR_PRODUCTS_REQUEST:
            return {
                ...state,
                similarProducts: {
                    ...state.similarProducts,
                    loading: true
                }
            };
        case storeProductsActionTypes.FETCH_STORE_PRODUCT_REQUEST:
            return {
                ...state,
                storeProduct: {
                    ...state.storeProduct,
                    loading: true
                }
            };
        case storeProductsActionTypes.FETCH_STORE_PRODUCT_FAILURE:
            return {
                ...state,
                storeProduct: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case storeProductsActionTypes.FETCH_STORE_PRODUCT_SUCCESS:
            const storeProduct: IStoreProduct = { ...action.payload };
            storeProduct.catalogId = action.payload.store.catalogId
            return {
                ...state,
                storeProduct: {
                    loading: false,
                    data: storeProduct,
                    error: error
                }
            };
        case storeProductsActionTypes.FETCH_STORE_PRODUCTS_REQUEST:
            return {
                ...state,
                storeProducts: {
                    ...state.storeProducts,
                    loading: true
                }
            };
        case storeProductsActionTypes.FETCH_SIMILAR_PRODUCTS_SUCCESS:
            data = [...action.payload.data];

            // Product id used where belongs the barcode
            const productId = action.productId;

            // We remove from response the product we selected for promotion
            filtered = data.filter((obj: any) => obj.id !== productId);

            // We add a key to the items of the list
            filtered.forEach((item: IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });
            action.payload.data = filtered;
            action.payload.totalCount = state.similarProducts.data.totalCount + filtered.length;

            // We concatenate products from store and new products fetched
            const concatenatedArrays = state.similarProducts.data.data.concat(filtered);

            // We remove duplicates
            const removedDuplicates = concatenatedArrays.reduce((unique: any, o: any) => {
                if (!unique.some((obj: any) => obj.id === o.id)) {
                    unique.push(o);
                }
                return unique;
            }, []);

            return {
                ...state,
                similarProducts: {
                    loading: false,
                    data: {
                        ...state.similarProducts.data,
                        totalCount: action.payload.totalCount,
                        data: removedDuplicates
                    },
                    error: error
                }
            };
        case storeProductsActionTypes.FETCH_STORE_PRODUCTS_SUCCESS:
            data = [...action.payload.data];

            data.forEach((item: IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });

            return {
                ...state,
                storeProducts: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case storeProductsActionTypes.FETCH_STORE_PRODUCTS_FAILURE:
            return {
                ...state,
                storeProducts: {
                    loading: false,
                    data: storeProducts,
                    error: action.payload
                }
            };

        case storeProductsActionTypes.FETCH_STORE_PRODUCTS_SEARCH_CLEAN_UP:
            return {
                ...state,
                searchProducts: initialState.searchProducts
            };
        case storeProductsActionTypes.FETCH_STORE_PRODUCTS_SEARCH_REQUEST:
            return {
                ...state,
                searchProducts: {
                    ...state.searchProducts,
                    loading: true
                }
            };
        case storeProductsActionTypes.FETCH_STORE_PRODUCTS_SEARCH_SUCCESS:
            data = [...action.payload.data];

            data.forEach((item: IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });

            return {
                ...state,
                searchProducts: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case storeProductsActionTypes.FETCH_STORE_PRODUCTS_SEARCH_FAILURE:
            return {
                ...state,
                searchProducts: {
                    loading: false,
                    data: searchProducts,
                    error: action.payload
                }
            };
        case storeProductsActionTypes.FETCH_SIMILAR_PRODUCTS_FAILURE:
            return {
                ...state,
                similarProducts: {
                    loading: false,
                    data: similarProducts,
                    error: action.payload
                }
            };
        case storeProductsActionTypes.PROMOTE_PRODUCT_STORE_BY_BARCODE_REQUEST:
            return {
                ...state,
                promoteProductStoreByBarcode: {
                    ...state.promoteProductStoreByBarcode,
                    loading: true
                }
            };
        case storeProductsActionTypes.PROMOTE_PRODUCT_STORE_BY_BARCODE_SUCCESS:
            return {
                ...state,
                promoteProductStoreByBarcode: {
                    loading: false,
                    error: error
                }
            };
        case storeProductsActionTypes.PROMOTE_PRODUCT_STORE_BY_BARCODE_FAILURE:
            return {
                ...state,
                promoteProductStoreByBarcode: {
                    loading: false,
                    error: action.payload
                }
            };
        case storeProductsActionTypes.ADD_PRODUCTS_PROMOTED:
            return {
                ...state,
                productsPromoted: {
                    ...state.productsPromoted,
                    data: [...state.productsPromoted.data, action.payload]
                }
            };
        case storeProductsActionTypes.ADD_PRODUCTS_NO_PROMOTABLE:
            return {
                ...state,
                productsNoPromotable: {
                    ...state.productsNoPromotable,
                    data: [...state.productsNoPromotable.data, action.payload]
                }
            };
        case storeProductsActionTypes.ADD_PRODUCTS_TO_BE_PROMOTED:
            return {
                ...state,
                productsToBePromoted: {
                    ...state.productsToBePromoted,
                    data: [...state.productsToBePromoted.data, action.payload]
                }
            };
        case storeProductsActionTypes.ADD_BARCODE_PRODUCT:
            return {
                ...state,
                barcodeProducts: {
                    ...state.barcodeProducts,
                    data: [...state.barcodeProducts.data, action.payload]
                }
            };
        case storeProductsActionTypes.ADD_NO_BARCODE_PRODUCT:
            return {
                ...state,
                noBarcodeProducts: {
                    ...state.noBarcodeProducts,
                    data: [...state.noBarcodeProducts.data, action.payload]
                }
            };
        case storeProductsActionTypes.REMOVE_PRODUCTS_PROMOTED:
            let productsPromotedData = action.payload;
            let productsPromotedState = state.productsPromoted;
            filtered = productsPromotedState.data.filter((el: any) => el.id !== productsPromotedData.id)

            return {
                ...state,
                productsPromoted: {
                    ...state.productsPromoted,
                    data: filtered
                }
            };
        case storeProductsActionTypes.REMOVE_PRODUCTS_NO_PROMOTABLE:
            const productsNoPromotableData = action.payload;
            const productsNoPromotableState = state.productsNoPromotable;
            filtered = productsNoPromotableState.data.filter((el: any) => el.id !== productsNoPromotableData.id)

            return {
                ...state,
                productsNoPromotable: {
                    ...state.productsNoPromotable,
                    data: filtered
                }
            };
        case storeProductsActionTypes.REMOVE_PRODUCTS_TO_BE_PROMOTED:
            const productsToBePromotedData = action.payload;
            const productsToBePromotedState = state.productsToBePromoted;
            filtered = productsToBePromotedState.data.filter((el: any) => el.id !== productsToBePromotedData.id)

            return {
                ...state,
                productsToBePromoted: {
                    ...state.productsToBePromoted,
                    data: filtered
                }
            };
        case storeProductsActionTypes.REMOVE_BARCODE_PRODUCT:
            removedProductBarcode = action.payload.barcode;
            removedProductCatalogId = action.payload.category[0].catalogId;

            filtered = state.barcodeProducts.data.filter((el: any) => {
                if (el.barcode === removedProductBarcode) {
                    if (el.category[0].catalogId === removedProductCatalogId) {
                        return false;
                    }
                    return true;
                }
                return true;
            });

            return {
                ...state,
                barcodeProducts: {
                    ...state.barcodeProducts,
                    data: filtered
                }
            };
        case storeProductsActionTypes.REMOVE_NO_BARCODE_PRODUCT:
            removedProductId = action.payload.id;
            filtered = state.noBarcodeProducts.data.filter((el: any) => el.id !== removedProductId);

            return {
                ...state,
                noBarcodeProducts: {
                    ...state.noBarcodeProducts,
                    data: filtered
                }
            };
        case storeProductsActionTypes.PROMOTE_PRODUCTS_STORE_FAILURE:
            return {
                ...state,
                storeProduct: {
                    ...state.storeProduct,
                    error: action.payload
                }
            };
        case storeProductsActionTypes.SIMILAR_PRODUCT_CLEAN_UP:
            barcode = action.barcode;
            productsBeingRemoved = state.similarProducts.data.data.filter((obj: any) => obj.barcode === barcode);
            filtered = state.similarProducts.data.data.filter((obj: any) => obj.barcode !== barcode);

            return {
                ...state,
                similarProducts: {
                    ...state.similarProducts,
                    data: {
                        ...state.similarProducts.data,
                        totalCount: state.similarProducts.data.totalCount - productsBeingRemoved.length,
                        data: filtered
                    }
                }
            };
        case storeProductsActionTypes.SIMILAR_PRODUCTS_CLEAN_UP:
            return {
                ...state,
                similarProducts: initialState.similarProducts
            };
        case storeProductsActionTypes.STORE_PRODUCT_CLEAN_UP:
            return {
                ...state,
                storeProduct: initialState.storeProduct
            };
        case storeProductsActionTypes.STORE_PRODUCTS_CLEAN_UP:
            return {
                ...state,
                storeProducts: initialState.storeProducts
            };
        case storeProductsActionTypes.STORE_PRODUCT_RESET:
            const storeProd: IStoreProduct = { ...state.storeProduct.data, ...resetStoreProduct }
            return {
                ...state,
                storeProduct: {
                    loading: false,
                    data: storeProd,
                    error: error
                }
            }
        case storeProductsActionTypes.BARCODE_PRODUCT_CLEAN_UP:
            return {
                ...state,
                barcodeProducts: initialState.barcodeProducts
            };
        case storeProductsActionTypes.NO_BARCODE_PRODUCT_CLEAN_UP:
            return {
                ...state,
                noBarcodeProducts: initialState.noBarcodeProducts
            };
        case storeProductsActionTypes.PRODUCTS_TO_BE_PROMOTED_CLEAN_UP:
            return {
                ...state,
                productsToBePromoted: initialState.productsToBePromoted
            };
        case storeProductsActionTypes.PRODUCTS_PROMOTED_CLEAN_UP:
            return {
                ...state,
                productsPromoted: initialState.productsPromoted
            };
        case storeProductsActionTypes.PRODUCTS_NO_PROMOTABLE_CLEAN_UP:
            return {
                ...state,
                productsNoPromotable: initialState.productsNoPromotable
            };
        case storeProductsActionTypes.BASE_PRODUCT_RELATED_CLEAN_UP:
            barcode = action.barcode;
            filtered = state.baseProductsRelated.data.data.filter((obj: any) => obj.barcode !== barcode);

            return {
                ...state,
                baseProductsRelated: {
                    ...state.baseProductsRelated,
                    data: {
                        ...state.baseProductsRelated.data,
                        data: filtered
                    }
                }
            };
        case storeProductsActionTypes.BASE_PRODUCTS_RELATED_CLEAN_UP:
            return {
                ...state,
                baseProductsRelated: initialState.baseProductsRelated
            };
        default:
            return state;
    }
};

export default storeProductsReducer;