import { Select } from "antd";
import { FC } from "react";

import unitOfMeasurementData from "../../../data/unitOfMeasurement.json";
const { Option } = Select;

type mode = "multiple" | undefined;

interface IProps {
  mode?: mode;
  initialValue?: any;
  onChange?: ((value: any[], option: any) => void) | undefined;
  handleChangeSelect?: any;
  disabled?: boolean;
}

const UnitSelect: FC<IProps> = (props) => {
  const {
    initialValue,
    onChange,
    mode = undefined,
    handleChangeSelect,
    disabled = false,
  } = props;

  /*
  const handleChange = (value: any) => {
    if (handleChangeSelect) {
      return handleChangeSelect("unit", value);
    }
    if (onChange) {
      return onChange;
    }
  };*/

  return (
    <Select
      mode={mode ?? undefined}
      placeholder="Seleccione una unidad"
      optionLabelProp="label"
      defaultValue={initialValue}
      onChange={onChange || handleChangeSelect}
      disabled={disabled}
    >
      {unitOfMeasurementData.map((option: any, idx: number) => (
        <Option key={idx} value={option.value} label={option.name}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
};
export default UnitSelect;
