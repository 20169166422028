export const headers = [
  {
    label: "Tienda",
    key: "store.storeName",
  },
  {
    label: "Productos",
    key: "items",
  },
  {
    label: "Moneda",
    key: "currency",
  },
  {
    label: "Mostrar impuestos",
    key: "taxDetail",
  },
  {
    label: "Total",
    key: "totalPrice",
  },
  {
    label: "Creacion",
    key: "date",
  },
  {
    label: "Sincronizacion",
    key: "createdAt",
  },
];
