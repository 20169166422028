const regularExpression ={
    positiveNumbers:"^[1-9][0-9]*$",
    numbers:"^[0-9 -]",
    integer:"^[0-9]",
    alphanumeric:"^[\\w\\-\\s]+$",
    lettersNumbersSpaces:"^[a-zA-ZÀ-ÿ0-9 _]*[a-zA-ZÀ-ÿ0-9][a-zA-ZÀ-ÿ0-9 _]*$",
    email:"^\\S+@\\S+\\.\\S+$",
    barcode:"^DN0[0-9]*",
    lettersAndNumbers:"^[a-zA-ZÀ-ÿ0-9]*[a-zA-ZÀ-ÿ0-9][a-zA-ZÀ-ÿ0-9]*$",
    price:"^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
    latitud:"^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,6})?))$",
    longitud:"^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,6})?))$",
    password:"(?=.*[A-Z])",
    uniqueIdentifier:"^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$",
    letters:"^[a-zA-ZÀ-ÿ\u00f1\u00d1 _]*[a-zA-ZÀ-ÿ\u00f1\u00d1 _]*[a-zA-ZÀ-ÿ\u00f1\u00d1 _]+$",
    lettersNonWhitespaces:"^[a-zA-ZÀ-ÿ\u00f1\u00d1]*[a-zA-ZÀ-ÿ\u00f1\u00d1]*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$",
    nonWhitespacesAtBeginnig:"^[^.\\s]",
    url:"^https?:\\/\\/([\\w-]+\\.)+[\\w-]+(\\/[\\w-./?%&=]*)?$"
   }
export default regularExpression;