import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import regularExpression from "../../../utils/regularExpression";
import useStoreProductsForm from "../../../hooks/useStoreProductsForm";

interface IProps {
  setShowForm?: any;
  disabled?: boolean;
  handleOnCancel?: React.MouseEventHandler<HTMLElement> | undefined;
  disableFields?: string[] | any;
  isModal?: boolean;
  handleReset?: React.MouseEventHandler<HTMLElement> | undefined;
  promotionProducts?:string[] | null,
  setRestartSearch?: any;
  setShowResumeModal?:any | null;
}

const ProductsStoreForm = (props: IProps) => {
  const {
    setShowForm,
    handleOnCancel,
    handleReset,
    disableFields,
    isModal = false,
    promotionProducts,
    setRestartSearch,
    setShowResumeModal
  } = props;

  const {
    storeProductsState,
    loading,
    formInstance,
    state,
    onFinishProductBaseForm,
  } = useStoreProductsForm({ setShowForm, handleOnCancel, promotionProducts, setShowResumeModal, setRestartSearch });

  return !loading ? (
    <>
      <Row>
        <Col span={24} className="d-flex space-between">
          <Col span={12}>
            {isModal ? (
              <></>
            ) : (
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/products-store-new" onClick={handleOnCancel}>
                    Productos de tienda
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {state === null ? "Editar" : "Agregar"}
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
          </Col>
        </Col>
      </Row>

      {!storeProductsState.storeProduct.loading ? (
        <>
          <Form
            form={formInstance}
            name="form"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={(values) =>
              onFinishProductBaseForm(
                values,
                state === null && isModal ? false : state,
                setRestartSearch
              )
            }
          >
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label="Descripción"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Por favor inserte la Descripción!",
                    },
                    {
                      pattern: new RegExp(regularExpression.nonWhitespacesAtBeginnig),
                      message: "El campo no puede estar vacío.",
                    }
                  ]}
                  initialValue={
                    storeProductsState.storeProduct.data.description
                  }
                >
                  <Input
                    disabled={
                      disableFields?.includes("description") ? true : false
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Código de barra"
                  name="barcode"
                  rules={[
                    {
                      required: true,
                      message: "Por favor inserte el Código de barra!",
                    },
                    {
                      pattern: new RegExp(regularExpression.lettersAndNumbers),
                      message: "Solo se permiten letras y numeros",
                    },
                  ]}
                  initialValue={storeProductsState.storeProduct.data.barcode}
                >
                  <Input
                    disabled={disableFields?.includes("barcode") ? true : false}
                  />
                </Form.Item>

                <Form.Item
                  label="Marca"
                  name="brand"
                  rules={[
                    { required: true, message: "Por favor inserte la Marca!" },
                    {
                      pattern: new RegExp(regularExpression.alphanumeric),
                      message: "Solo se permiten letras y numeros",
                    },
                  ]}
                  initialValue={storeProductsState.storeProduct.data.brand}
                >
                  <Input
                    disabled={disableFields?.includes("brand") ? true : false}
                  />
                </Form.Item>

                <Form.Item
                  label="Contenido"
                  name="contents"
                  rules={[
                    {
                      required: true,
                      message: "Por favor inserte el Contenido!",
                    },
                    {
                      pattern: new RegExp(regularExpression.alphanumeric),
                      message: "Solo se permiten letras y numeros",
                    },
                  ]}
                  initialValue={storeProductsState.storeProduct.data.contents}
                >
                  <Input
                    disabled={
                      disableFields?.includes("contents") ? true : false
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Unidad"
                  name="unit"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una unidad de medida!",
                    },
                  ]}
                  initialValue={storeProductsState.storeProduct.data.unit}
                >
                  <Input
                    disabled={disableFields?.includes("unit") ? true : false}
                  />
                </Form.Item>

                <Form.Item
                  label="Precio"
                  name="price"
                  rules={[
                    { required: true, message: "Por favor inserte el Precio!" },
                    {
                      pattern: new RegExp(regularExpression.price),
                      message:
                        "Solo se permiten numeros con el siguiente formato #.##",
                    },
                  ]}
                  initialValue={storeProductsState.storeProduct.data.price}
                >
                  <Input
                    disabled={disableFields?.includes("price") ? true : false}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Moneda"
                  name="currency"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una moneda.",
                    },
                  ]}
                  initialValue={storeProductsState.storeProduct.data.currency}
                >
                  <Input
                    disabled={
                      disableFields?.includes("currency") ? true : false
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Porcentaje de impuesto"
                  name="taxPercent"
                  rules={[
                    {
                      required: true,
                      message:
                        "Por favor seleccione un porcentaje de impuesto!",
                    },
                  ]}
                  initialValue={storeProductsState.storeProduct.data.taxPercent}
                >
                  <Input
                    disabled={
                      disableFields?.includes("taxPercent") ? true : false
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Modo de venta"
                  name="sellMode"
                  rules={[
                    {
                      required: true,
                      message: "Por favor inserte el Modo de venta!",
                    },
                  ]}
                  initialValue={storeProductsState.storeProduct.data.sellMode}
                >
                  <Input
                    disabled={
                      disableFields?.includes("sellMode") ? true : false
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Catálogo"
                  name="catalogId"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione un Catálogo!",
                    },
                  ]}
                  initialValue={
                    storeProductsState.storeProduct.data.category[0]?.catalogId
                  }
                >
                  <Input
                    disabled={
                      disableFields?.includes("catalogId") ? true : false
                    }
                  />
                </Form.Item>

                <Form.Item label="ID de catálogo">
                  <Input
                    defaultValue={
                      storeProductsState.storeProduct.data.category[0]
                        ?.catalogId
                    }
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  label="ID de categoría"
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: "Por favor inserte el ID de categoría!",
                    },
                    {
                      pattern: new RegExp(regularExpression.lettersAndNumbers),
                      message: "Solo se permiten letras y numeros",
                    },
                  ]}
                  initialValue={
                    storeProductsState.storeProduct.data.category[0]?.id
                  }
                >
                  <Input
                    disabled={
                      disableFields?.includes("categoryId") ? true : false
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={24} className="d-flex flex-end">
                <Form.Item>
                  <Space>
                    <Button
                      type="primary"
                      htmlType="submit"
                    >
                      {isModal ? "Guardar y Promover": "Guardar"}
                    </Button>
                    <Button
                      onClick={handleOnCancel}
                    >
                      Cancelar
                    </Button>
                    <Button disabled={state === null && isModal ? false:true} onClick={handleReset}>Limpiar</Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <Spin />
      )}
    </>
  ) : (
    <Spin />
  );
};

export default ProductsStoreForm;
