import moment from "moment";

export const concatParameters = (value: any) => {
  let params = "";
  Object.entries(value).forEach(([key, value]:any) => {
    params =
      value || key === "date"
        ? `${`${params}&${key}=${
            key === "date"
              ? moment(value ?? new Date()).format("YYYY-MM-DD")
              : value
          }`}`
        : params;
  });
  return params;
};

export const getValueFromCharacterString = (characterString: string, value: string) => {
  const matchString = characterString.match(/([^=#]+)=([^&#]*)/g);
  const filteredString = matchString?.filter((item) =>
    item.includes(value)
  )?.[0];
  const result = filteredString?.split("=");
  return result ? result[1] : "";
};
