import {  shortcutActionTypes } from "../constants";
import { IError } from "../types/interfaces/error.interface";
import { Action } from "../types/actions.types";
import { IShortcut, IData } from '../types/shortcut.types';
import { v4 as uuid } from "uuid";

let error:IError = {
    status: '',
    data: { 
        timestamp: '', 
        path: '',
        message: ''
    }
};

let shortcutsInit:IShortcut = {
    data: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};



const initialState = {
    shortcuts: {
        loading: false,
        data: shortcutsInit,
        error: error,
    }
};

const shortcutReducer = (state = initialState, action:Action):any => {
   
    switch (action.type) {
        case shortcutActionTypes.FETCH_SHORTCUT_REQUEST:
            console.log("1")
            return {
                ...state,
                shortcuts: {
                    ...state.shortcuts,
                    loading: true
                }
            };    
        case shortcutActionTypes.FETCH_SHORTCUT_SUCCESS:
            const data = action.payload;
            data.data.forEach((item:IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });
            return {
                ...state,
                shortcuts: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
            
            
        case shortcutActionTypes.FETCH_SHORTCUT_FAILURE:
            return state
            
        /*
        case usersActionTypes.FETCH_USER_REQUEST:
        case usersActionTypes.CREATE_USER_REQUEST:
        case usersActionTypes.UPDATE_USER_REQUEST:
        case usersActionTypes.DELETE_USER_REQUEST:
        case usersActionTypes.ENABLE_USER_REQUEST:
        case usersActionTypes.DISABLE_USER_REQUEST:
            return {
                ...state,
                user: {
                    ...state.user,
                    loading: true
                }
            };
        case usersActionTypes.ENABLE_USER_SUCCESS:
        case usersActionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                user: {
                    loading: false,
                    data: {
                        ...state.user.data,
                        id: action.payload
                    },
                    error: error
                }
            };
        case usersActionTypes.FETCH_USER_FAILURE:
        case usersActionTypes.CREATE_USER_FAILURE:
        case usersActionTypes.UPDATE_USER_FAILURE:
        case usersActionTypes.DELETE_USER_FAILURE:
        case usersActionTypes.ENABLE_USER_FAILURE:
            return {
                ...state,
                user: {
                    loading: false,
                    data: user,
                    error: action.payload
                }
            };
        case usersActionTypes.FETCH_USER_SUCCESS:
        case usersActionTypes.CREATE_USER_SUCCESS:
        case usersActionTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case usersActionTypes.USER_CLEAN_UP:
            return {
                ...state,
                user: initialState.user
            };
        case usersActionTypes.DISABLE_USER_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user, 
                    loading:false
                }
            };
        */
        default:
            return state;
    }
};

export default shortcutReducer;