import { ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import {regionsActionTypes} from "../constants/index";
import instance from "../utils/axios.instance";
import { AppDispatch, RootState } from '../store/store';
import { IError } from "../types/interfaces/error.interface";
import { Result } from '../types/enum/result';
import { IRegion, IRegions, regionsAction } from '../types/regions.types';
import { IStores } from "../types/store.types";

/*********************FETCH REGIONS ACTIONS************************************/
export const fetchRegionsRequest = () =>{
    return{
        type:regionsActionTypes.FETCH_REGIONS_REQUEST
    }
}

export const fetchRegionsSuccess = (data:IRegions) =>{
    return{
        type:regionsActionTypes.FETCH_REGIONS_SUCCESS,  
        payload:data
    }
}

export const fetchRegionsFailure = (error:IError) =>{
    return{
        type:regionsActionTypes.FETCH_REGIONS_FAILURE,
        payload:error
    }
}
/*********************FETCH REGION ACTIONS************************************/
export const fetchRegionRequest = () =>{
    return{
        type:regionsActionTypes.FETCH_REGION_REQUEST
    }
}

export const fetchRegionSuccess = (data:any) =>{
    return{
        type:regionsActionTypes.FETCH_REGION_SUCCESS,  
        payload:data
    }
}

export const fetchRegionFailure = (error:IError) =>{
    return{
        type:regionsActionTypes.FETCH_REGION_FAILURE,
        payload:error
    }
}
/*********************DELETE REGION ACTION************************************/
export const deleteRegionRequest = () =>{
    return{
        type:regionsActionTypes.DELETE_REGION_REQUEST
    }
}

export const deleteRegionSuccess = (data:any) =>{
    return{
        type:regionsActionTypes.DELETE_REGION_SUCCESS,  
        payload:data
    }
}

export const deleteRegionFailure = (error:IError) =>{
    return{
        type:regionsActionTypes.DELETE_REGION_FAILURE,
        payload:error
    }
}
/*********************CREATE REGION ACTION************************************/
export const createRegionRequest = () =>{
    return{
        type:regionsActionTypes.CREATE_REGION_REQUEST
    }
}

export const createRegionSuccess = (data:IRegion) =>{
    return{
        type:regionsActionTypes.CREATE_REGION_SUCCESS,  
        payload:data
    }
}

export const createRegionFailure = (error:IError) =>{
    return{
        type:regionsActionTypes.CREATE_REGION_FAILURE,
        payload:error
    }
}
/*********************UPDATE REGION ACTION************************************/
export const updateRegionRequest = () =>{
    return{
        type:regionsActionTypes.UPDATE_REGION_REQUEST
    }
}

export const updateRegionSuccess = (data:IRegion) =>{
    return{
        type:regionsActionTypes.UPDATE_REGION_SUCCESS,  
        payload:data
    }
}

export const updateRegionFailure = (error:IError) =>{
    return{
        type:regionsActionTypes.UPDATE_REGION_FAILURE,
        payload:error
    }
}
/*********************CLEAN UP REGION ACTION************************************/
export const cleanUpRegionData = () =>{
    return{
        type:regionsActionTypes.REGION_CLEAN_UP
    }
}
/****************************************************************************/
//Get all Regions
export const fetchRegions:ActionCreator<ThunkAction<void, RootState, null, regionsAction>> = (currentPage:any = 1, pageSize:any = 20)=>{
    return async (dispatch:AppDispatch)=>{
        dispatch(fetchRegionsRequest());
       await instance.get(`/api/regions?page=${currentPage}&pageSize=${pageSize}`)
      .then((response:any) => {
        const data=response.data;
        dispatch(fetchRegionsSuccess(data));
      })
      .catch((error:any) => {
        const data:IError= {status:error.response.status, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}}
        dispatch(fetchRegionsFailure(data));
      });
    }
}
//Get a Region
export const fetchRegion:ActionCreator<ThunkAction<void, RootState, null, regionsAction>> = (id:string)=>{
    return async (dispatch:AppDispatch)=>{
        dispatch(fetchRegionRequest());
       await instance.get(`/api/regions/${id}`)
      .then((response:any) => {
        const data=response.data;
        dispatch(fetchRegionSuccess(data));
      })
      .catch((error:any) => {
        const data:IError= {status:error.response.status, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}}
        dispatch(fetchRegionFailure(data));
      });
    }
}
//Get a Regions by CountryId
//Nota:there should be an API to obtain this data.
export const fetchRegionsByCountryId:ActionCreator<ThunkAction<void, RootState, null, regionsAction>> = (currentPage:any = 1, pageSize:any = 1000, countryId:string)=>{
    return async (dispatch:AppDispatch)=>{
        dispatch(fetchRegionsRequest());
       await instance.get(`/api/regions?page=${currentPage}&pageSize=${pageSize}`)
      .then((response:any) => {
        const data:IStores=response.data;
        const regions = data.data.filter((regions:any) => regions.country.id === countryId);
        const store:IStores={ data:regions, page: data.page, pageSize: data.pageSize,totalCount: data.totalCount}
        dispatch(fetchRegionsSuccess(store));
      })
      .catch((error:any) => {
        const data:IError= {status:error.response.status, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}}
        dispatch(fetchRegionsFailure(data));
      });
    }
}
//Delete a Region by Id
export const deleteRegion:ActionCreator<ThunkAction<void, RootState, null, regionsAction>> = (id:string)=>
     async (dispatch:AppDispatch)=>{
        try{
            dispatch(deleteRegionRequest());
            const response = await instance.delete(`/api/regions/${id}`)
            dispatch(deleteRegionSuccess(response.data));
            return Result.OK;
        }catch(error:any){
            const data:IError= {status:error.response.status, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}};
            dispatch(deleteRegionFailure(data));
            return Result.ERROR;
      };
    };
//Create a Region
export const createRegion:ActionCreator<ThunkAction<void, RootState, null, regionsAction>> = (body:any)=>
     async (dispatch:AppDispatch)=>{
        try{
            dispatch(createRegionRequest());
            const response = await instance.post(`/api/regions`, body)
            dispatch(createRegionSuccess(response.data));
            return Result.OK;
        }catch(error:any){
            const data:IError= {status:error.response.status, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}}
            dispatch(createRegionFailure(data));
            return Result.ERROR;
      };
    };
//Update a Region
export const updateRegion:ActionCreator<ThunkAction<void, RootState, null, regionsAction>> = (body:any,id:string)=>
     async (dispatch:AppDispatch)=>{
        try{
            dispatch(updateRegionRequest());
            const response = await instance.put(`/api/regions/${id}`, body)
            dispatch(updateRegionSuccess(response.data));
            return Result.OK;
        }catch(error:any){
            const data:IError= {status:error.response.status, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}}
            dispatch(updateRegionFailure(data));
            return Result.ERROR;
      };
    };

export const cleanupRegion=()=>{
    return (dispatch:AppDispatch)=>{
            dispatch(cleanUpRegionData());
        }
}