import { useState, useRef } from "react"
import instance from "../utils/axios.instance";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const useDownloadStoreProduct = () => {
    const [openModalDownload, setOpenModalDownload] = useState(false)
    const [dataDate, setDataDate] = useState({
        initDate: null,
        endDate: null
    })
    const buttonRefDownload: any = useRef();
    const [openLoadingDownload, setOpenLoadingDownload] = useState(false)
    const [dataDownload, setDataDownload] = useState([])
    const downloadData = async (params: any) => {
        if (!dataDate.endDate || !dataDate.initDate) {
            toast.error("Debes seleccionar un rango de fecha");
            return;
        }
        let typeUrl = ""
        if (params.promotable !== null) {
            typeUrl = "&promotable=" + params.promotable
        }
        setOpenLoadingDownload(true)
        try {
            const response: any = await instance.get(`/api/products/store/all/range?page=400000&catalogId=${params.catalogId}&initDate=${dataDate.initDate}&endDate=${dataDate.endDate}${typeUrl}`);
            let data = response.data.data;
            if (data.length === 0) {
                toast.error("No tenemos información en los días seleccionados");
                setOpenLoadingDownload(false)
                setDataDate({
                    initDate: null,
                    endDate: null
                })
                
                return
            }
            setDataDownload(data)
            buttonRefDownload.current.link.click();
            setDataDate({
                initDate: null,
                endDate: null
            })
            setOpenModalDownload(false)
            toast.success("Descarga exitosa");


        } catch (error) {
            toast.error("Hemos tenido problemas, intenta de nuevo");

        }

        setOpenLoadingDownload(false)

    }
    const onChangesDate = (dataInit: any, dataEnd: any) => {
        setDataDate({ initDate: dataInit, endDate: dataEnd })
    }
    return { openModalDownload, setOpenModalDownload, downloadData, onChangesDate, dataDownload, buttonRefDownload, openLoadingDownload }
}