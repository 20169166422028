import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchStoreProducts } from "../actions/storeProducts/main.actions";
import { RootState, useAppDispatch } from "../store/store";
import { storeProductsState } from "../types/storeProducts.types";
import { Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { Result } from "../types/enum/result";
import { noPromoteStoreProducts } from "../actions/storeProducts/promotion.actions";

export const useStoreProductsNoPromotable = () => {
    const [showForm, setShowForm] = useState(false);
    const dispatch = useAppDispatch();
    const storeProductsState:storeProductsState = useSelector((state:RootState) => state.storeProducts);
    const [loading, setLoading] = useState(false);
    const [massReason, setMassReason] = useState('');
    const [reasonFormInstance] = Form.useForm();
    const [noPromotableProducts, setNoPromotableProducts]:any = useState([]);
    const navigate = useNavigate();
    const [showResumeModal, setShowResumeModal] = useState(false);
    const [selectedProducts, setSelectedProducts]:any = useState([]);

    // Estado relacionado al filtro lateral
    const [collapsed, setCollapsed] = useState(true);

    const handleRowSelection = {
        onSelectAll: (selected:any, selectedRows:any, changeRows:any) => {
            if (selected) {
                if (selectedRows.length) {
                    let selectedProductsTemp:any = []; 

                    selectedRows.forEach((row:any) => {
                        if (!row) {
                            return;
                        }

                        selectedProductsTemp.push(row);
                    });

                    setSelectedProducts(selectedProductsTemp);
                }
            } else {
                setSelectedProducts([]);
            }
        },
        onSelect: (record:any, selected:any, selectedRows:any, nativeEvent:any) => {
            if (selected) {
                setSelectedProducts([...selectedProducts, record]);
            } else {
                setSelectedProducts(selectedProducts.filter((obj:any) => obj.id !== record.id));
            }
        },
        type: "checkbox"
    };

    useEffect(() => {
        let productsToBePromotedTemp = storeProductsState.productsToBePromoted.data;

        // Si no hay productos no promovibles redirecciona a la pantalla de productos de tienda
        if (!productsToBePromotedTemp.length) {
            navigate('/products-store-new');
        } else {
            // Asignamos la propiedad "reason" a cada producto en el objeto
            let productsToBePromotedTempModified = productsToBePromotedTemp.map((obj:any) => {
                obj.reason = '';
                return obj;
            });
    
            setNoPromotableProducts(productsToBePromotedTempModified);
        }
    }, [dispatch]);

    const onClickNoPromoteProduct = () => {
        // Nos aseguramos de que cada producto tenga una razon asignada
        const objIndex = noPromotableProducts.findIndex((obj:any) => !obj.reason.trim());

        if (objIndex !== -1) {
            message.warning('Por favor asegúrese de asignarle una razón a cada producto listado en la tabla');
            return;
        }

        const requestBody = noPromotableProducts.map((obj:any) => {
            return {
                storeProductId: obj.id,
                reason: obj.reason,
                promotable: false
            };
        });

        dispatch(noPromoteStoreProducts(requestBody)).then((result:any) => {
            if (result === Result.OK) {
                setShowResumeModal(true);
                // message.success('El registro ha sido marcado como no promocionable con éxito');
                // dispatch(fetchStoreProducts(storeProductsState.storeProducts.data.page, storeProductsState.storeProducts.data.pageSize));
            } else {
                message.error(storeProductsState.productsNoPromotable.result.error);
            }
        });
    };

    // Asignamos la razon de forma masiva al array en el estado local
    const massAssignment = () => {
        let noPromotableProductsTemp = noPromotableProducts;

        noPromotableProductsTemp.forEach((el:any) => {
            const selected = selectedProducts.find((obj:any) => obj.id === el.id);

            if (selected) {
                el.reason = massReason;
                const reason = massReason;
                const inputName = `reason-${el.id}`;
    
                reasonFormInstance.setFieldsValue({
                    [inputName]: reason
                });
            }
        });

        setNoPromotableProducts(noPromotableProductsTemp);
    };

    // Asignamos la razon a un producto en especifico
    const onChangeSingleReason = (reason:any, id:any) => {
        let noPromotableProductsTemp = noPromotableProducts;

        const objIndex = noPromotableProductsTemp.findIndex((obj:any) => obj.id === id);
        noPromotableProductsTemp[objIndex].reason = reason;

        setNoPromotableProducts(noPromotableProductsTemp);
    };

    const onChangeMassReason = (reason:any) => {
        setMassReason(reason);
    };

    const handleCancelResumeModal = () => {
        setShowResumeModal(false);
    };

    const noPromotableProductsResume = noPromotableProducts;

    const removeNoPromotableProduct = (id:string) => {
        const noPromotableProductsUpdated = noPromotableProducts.filter((el:any) => el.id !== id)
        setNoPromotableProducts(noPromotableProductsUpdated);

        if (!noPromotableProductsUpdated.length) {
            navigate('/products-store-new')
        }
    };

    const noPromotableResumeColumns = [
        {
            title: 'Producto',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Estado',
            dataIndex: 'success',
            key: 'success',
            render: (text: any, record: any, index: any) => {
                if (record.success === true) {
                    return 'Exitoso';
                } else {
                    return 'Fallido';
                }
            }
        },
    ];

    return {
        storeProductsState,
        pagination: {
            current: storeProductsState.storeProducts.data.page,
            pageSize: storeProductsState.storeProducts.data.pageSize,
            total: storeProductsState.storeProducts.data.totalCount,
            onChange: (page:number, pageSize:number) => {
                dispatch(fetchStoreProducts(page, pageSize));
            }
        },
        showForm,
        setShowForm,
        loading,
        setLoading,
        collapsed,
        setCollapsed,
        onClickNoPromoteProduct,
        massAssignment,
        onChangeSingleReason,
        reasonFormInstance,
        onChangeMassReason,
        showResumeModal,
        handleCancelResumeModal,
        noPromotableProductsResume,
        noPromotableResumeColumns,
        removeNoPromotableProduct,
        noPromotableProducts,
        handleRowSelection,
    };
};