import { Breadcrumb } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCatalogs } from "../../../actions/catalogs.actions";
import { RootState } from "../../../store/store";
import { catalogsState } from "../../../types/catalogs.types";

const FilterBreadcrumb = (props:any) => {
    const { filters } = props;
    const dispatch = useDispatch();
    const catalogsState:catalogsState = useSelector((state:RootState) => state.catalogs);

    useEffect(() => {
        dispatch(fetchCatalogs())
    }, [dispatch]);

    return (
        <Breadcrumb>
            {
                filters && 
                Object.keys(filters).map((key:any) => { return filters[key] }).map((obj:any, i:number, row:any) => {
                    switch (obj.type) {
                        case 'STORE':
                            break;
                        case 'CATALOG':
                            if (obj.value) {
                                if (obj.value.trim() === '') {
                                    return;
                                }
                                
                                const catalogId = obj.value;
                                const selectedCatalog:any = catalogsState.catalogs.data.data.find((obj) => obj.id === catalogId);
    
                                return <Breadcrumb.Item key={i}>
                                        {selectedCatalog?.name}
                                    </Breadcrumb.Item>
                            }
                            break;
                        case 'CATEGORY':
                            break;
                        case 'CURRENCY':
                            break;
                        case 'UNIT':
                            break;
                        case 'INPUT':
                            break;
                        case 'MULTIVALUE':
                            break;
                        case 'CHECKBOX':
                            break;
                        case 'DATEPICKER':
                            break;
                        case 'GENERAL':
                            break;
                        default:
                            break;
                    }
                    
                    return;
                })
            }
        </Breadcrumb>
    )
};

export default FilterBreadcrumb;