import { Table, Col, Row, Pagination } from "antd";
import "./CustomizedTable.css";

const TableComponente = (props: any) => {
  const {
    loading,
    dataSource,
    columns,
    pagination = false,
    handleRowSelection = false,
    scroll = false
  } = props;
  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Table
            //tableLayout="auto"
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            pagination={false}
            rowSelection={handleRowSelection}
            rowKey={(record: any): any => {
              return record.key || record._id;
            }}
            size="small"
             scroll={{ x: scroll ? "max-content" :"100%"}}
          />
        </Col>

        {pagination ? (
          <Pagination
            total={
              pagination.hasOwnProperty("paginationSearch")
                ? pagination.paginationSearch.total | 0
                : pagination.total | 0
            }
            onChange={
              pagination.hasOwnProperty("paginationSearch")
                ? pagination.paginationSearch.onChange
                : pagination.onChange
            }
            current={
              pagination.hasOwnProperty("paginationSearch")
                ? pagination.paginationSearch.current | 0
                : pagination.current | 0
            }
            pageSize={
              pagination.hasOwnProperty("paginationSearch")
                ? pagination.paginationSearch.pageSize | 0
                : pagination.pageSize | 0
            }
            showTotal={(total) => `Total ${total} registros`}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            showQuickJumper
            showSizeChanger
          />
        ) : null}
      </Row>
    </>
  );
};
export default TableComponente;
