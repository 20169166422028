import { Breadcrumb, Button, Col, Row, Space, Spin } from "antd";
import HelmetTitle from "../../../other/HelmetTitle";
import Table from "../../../table/customizedTable/CustomizedTable";
import '../ProductsStore.css';
import { Columns } from "./Columns";
import NoPromotableReasonSelect from "../../../selects/products/noPromotableReasonSelect";
import PageTitle from "../../../other/PageTitle";
import { Link } from "react-router-dom";
import { useStoreProductsNoPromotable } from "../../../../hooks/useStoreProductsNoPromotable";
import NoPromotableResumeModal from "./NoPromotableResumeModal";

const ProductsStoreNoPromotable = () => {
    const { 
        storeProductsState,
        loading,
        onClickNoPromoteProduct,
        onChangeSingleReason,
        onChangeMassReason,
        massAssignment,
        reasonFormInstance,
        showResumeModal,
        noPromotableResumeColumns,
        removeNoPromotableProduct,
        noPromotableProducts,
        handleRowSelection,
    } = useStoreProductsNoPromotable();

    const columns = Columns(
        onChangeSingleReason,
        reasonFormInstance,
        removeNoPromotableProduct,
    );

    return (
        !loading ?
        <>
            <HelmetTitle title="Productos de tienda - Backoffice D&#38;N" description="Pantalla para listar, y marcar como no promovibles los productos de tienda" />
        
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/products-store-new">Productos de tienda</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    No promovible - Asignar razón
                </Breadcrumb.Item>
            </Breadcrumb>

            <>
                <Row gutter={[0, 16]} className="d-flex">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <PageTitle title="No promovible - Asignar razón" />
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 12 }} className="d-flex flex-end">
                        <Space>
                            <NoPromotableReasonSelect
                                onChange={onChangeMassReason}
                                initialValue={null}
                            />
                            <Button 
                                type="primary" 
                                // htmlType="submit" 
                                onClick={massAssignment}
                            >
                                Asignación masiva
                            </Button>
                        </Space>
                    </Col>

                    <Col span={24}>
                        <Table
                            dataSource={noPromotableProducts} 
                            columns={columns} 
                            name="producto de tienda"
                            title={null}
                            path="products-store"
                            // onClick={onClick}
                            loading={storeProductsState.productsNoPromotable.result.loading}
                            // pagination={pagination}
                            handleRowSelection={handleRowSelection}
                        />
                    </Col>

                    <Col span={24} className="d-flex flex-end">
                        <Space>
                            <Button 
                                type="primary" 
                                onClick={onClickNoPromoteProduct}
                            >
                                Marcar productos
                            </Button>
                            <Button>
                                <Link to="/products-store-new">
                                    Cancelar
                                </Link>
                            </Button>
                        </Space>
                    </Col>
                </Row>

                <NoPromotableResumeModal 
                    showResumeModal={showResumeModal}
                    dataSource={storeProductsState.productsNoPromotable.result.data}
                    noPromotableResumeColumns={noPromotableResumeColumns}
                    noPromotableProducts={noPromotableProducts}
                />
            </>
        </>
        :
        <Spin />
    );
};

export default ProductsStoreNoPromotable;