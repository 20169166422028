import { CSVLink } from "react-csv";
import { Button } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { RefObject, useState } from "react";
import { FilterSection } from "../../../types/enum/filters";
import React from "react";

interface IProps {
  headers: any;
  date: string;
  section: FilterSection;
  handleDownload: any;
}

export const DownloadCSV = (props: IProps) => {
  const { headers, date, section, handleDownload } = props;

  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState([]);

  const buttonRef: any = React.useRef();

  return (
    <>
      <Button
        type="text"
        onClick={() => {
          handleDownload(setLoading, setDownload, buttonRef);
        }}
      >
        <CloudDownloadOutlined />
        {loading ? "Cargando" : "Descargar CSV"}
      </Button>
      <CSVLink
        headers={headers}
        data={download}
        separator={","}
        filename={`${section}${date}`}
        ref={buttonRef}
      ></CSVLink>
    </>
  );
};
