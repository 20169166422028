import CopyTextColumn from "../../../other/CopyTextColumn";

export const ColumnsAddProductsTable = () => {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: function RevealTextOnHover(text: any, record: any, index: any) {
        return <CopyTextColumn text={text} record={record} />;
      },
    },
    {
      title: "Código de barra",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a: any, b: any) => a.barcode - b.barcode,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Comercio",
      dataIndex: ["store", "storeName"],
      key: "store",
      sorter: (a: any, b: any) =>
        a.store.storeName.localeCompare(b.store.storeName),
    },
    {
      title: "Tipo de producto",
      key: "productType",
      render: (text: any, record: any, index: any) => {
        if (
          !record.hasOwnProperty("baseProduct") &&
          !record.hasOwnProperty("reason")
        ) {
          return <span className="to-be-promoted">Por promover</span>;
        }

        if (
          !record.hasOwnProperty("baseProduct") &&
          record.hasOwnProperty("reason")
        ) {
          return <span className="no-promotable">No promovible</span>;
        }

        if (
          record.hasOwnProperty("baseProduct") &&
          !record.hasOwnProperty("reason")
        ) {
          return <span className="promoted">Promovido</span>;
        }
      },
    },
  ];
};
