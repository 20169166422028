import { Button, Input, Space } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchCatalogs } from "../actions/catalogs.actions";
import { RootState, useAppDispatch } from "../store/store";
import { catalogsState } from "../types/catalogs.types";
import { useNavigate } from 'react-router-dom';
import { 
    SearchOutlined 
  } from '@ant-design/icons';

export const useCatalogs = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const catalogsState:catalogsState = useSelector((state:RootState) => state.catalogs);
    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        dispatch(fetchCatalogs());
    }, [dispatch]);

   
    const onChangeFetchItems = (page:number, pageSize:number) => {
        dispatch(fetchCatalogs(page, pageSize))
    };

    const handleActionCategories = (id: string) => {
        navigate(id+'/categories');
    }


    const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
        confirm();
        // setSelectedCountries([]);
        // setSelectedRowKeys([]);
    };

    const handleReset = (clearFilters:any) => {
        clearFilters();
        // setSelectedCountries([]);
        // setSelectedRowKeys([]);
    };

    const getColumnSearchProps = (dataIndex:string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters } : { setSelectedKeys:any, selectedKeys:any, confirm:any, clearFilters:any }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Limpiar
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        confirm({ closeDropdown: false });
                    }}
                >
                    Filtrar
                </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered:any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value:any, record:any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });
    // Local filters

    return {
        catalogsState,
        pagination: {
            current: (catalogsState.catalogs.data.page) ? catalogsState.catalogs.data.page : 1,
            pageSize: (catalogsState.catalogs.data.pageSize) ? catalogsState.catalogs.data.pageSize : 1,
            total: (catalogsState.catalogs.data.totalCount) ? catalogsState.catalogs.data.totalCount : 1,
            onChange: (page:number, pageSize:number) => {
                dispatch(fetchCatalogs(page, pageSize));
            }
        },
        onChangeFetchItems,
        getColumnSearchProps,
        handleActionCategories,
        showForm, 
        setShowForm
    };
};