import { Button, Input, message, Space } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { fetchCatalogs } from "../actions/catalogs.actions";
import { fetchCountries } from "../actions/countries.actions";
import {
  fetchStores,
  fetchStore,
  cleanUp,
  disableStore,
  enableStore,
  updateStore,
  createStore,
} from "../actions/stores.actions";
import { RootState, useAppDispatch } from "../store/store";
import { catalogsState } from "../types/catalogs.types";
import { IUserStore, storesState } from "../types/store.types";
import { regionsState } from "../types/regions.types";
import { countriesState } from "../types/countries.types";
import { Result } from "../types/enum/result";
import { SearchOutlined } from "@ant-design/icons";
import { get } from "lodash-es";
import { Field, Position } from "../types/enum/field";
import { v4 as uuid } from "uuid";
import { fieldsFilter } from "./../components/stores/fields";

const csvFormatInformation = `A continuación se detalla el valor a ser usado en cada campo del archivo:
- StoreName: nombre del comercio
- Email: correo electrónico que se usará para iniciar sesión en el comercio
- Password: contraseña a ser usada para la cuenta del comercio
- Address: dirección del comercio
- Latitude: debe ser un número entre -90 y 90
- Longitude: debe ser un número entre -180 y 180
- TaxDetail: puede tener dos valores SHOW_TAX o NOT_SHOW_TAX
- Username: nombre de usuario el cual podrá acceder a la aplicación Tienda Ticket (se suele crear un usuario para cada tendero)
- Pin: es un número de 4 digitos
- UserEmail: correo electrónico del usuario
- FirstName: primer nombre del usuario
- LastName: apellido del usuario`;

export const useStores = (queryParams: string) => {
  const [showForm, setShowForm] = useState(false);
  const [currentUser, setcurrentUser] = useState({});
  const [users, setUsers] = useState<any[]>([]);
  const [fieldsStore, setFieldsStore] = useState<any[]>([]);
  const [toggleNewUser, setToggleNewUser] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hideUserList, setHideUserList] = useState(false);
  const dispatch = useAppDispatch();
  const pageSize = 20;
  const [item, setItem] = useState("");
  const [isActivateStoresModalVisible, setIsActivateStoresModalVisible] =
    useState(false);
  const [isDeactivateStoresModalVisible, setIsDeactivateStoresModalVisible] =
    useState(false);
  const [selectedStoresActivate, setSelectedStoresActivate]: any = useState([]);
  const [selectedStoresDeactivate, setSelectedStoresDeactivate]: any = useState(
    []
  );

  useEffect(() => {
    setFieldsStore([...fieldsFilter]);
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const storesState: storesState = useSelector(
    (state: RootState) => state.stores
  );
  const catalogState: catalogsState = useSelector(
    (state: RootState) => state.catalogs
  );
  const countriesState: countriesState = useSelector(
    (state: RootState) => state.countries
  );
  const regionsState: regionsState = useSelector(
    (state: RootState) => state.regions
  );

  useEffect(() => {
    dispatch(fetchStores(1, pageSize));
    dispatch(fetchCatalogs(1, 1000));
    dispatch(fetchCountries(1, 1000));
  }, [dispatch]);

  useEffect(() => {
    if (storesState.store.data.users[0].id !== "") {
      setUsers(storesState.store.data.users);
    }
  }, [setUsers, storesState.store.data.users]);

  const initialCountries = [{ name: "", value: "" }];
  const [countries, setCountries] = useState(initialCountries);
  const [disableCountries, setDisableCountries] = useState(true);

  useEffect(() => {
    const countriesTemp = countriesState.countries.data.data.map(
      (item: any) => {
        return { name: item.name, value: item.id };
      }
    );
    setCountries(countriesTemp);
    setFieldsStore(fieldsFilter.map((field: any) => { 
      if(field.name === 'country'){
        field.values = countriesTemp;
      } 
      return field;
    } ));
  }, [countriesState]);

  const changeCountriesByCatalog = (catalogId: string) => {
    if (catalogId === "") {
      setDisableCountries(true);
      setCountries(initialCountries);
      return;
    }
    const tempCatalog = catalogState.catalogs.data.data.find(
      (catalog) => catalog.id === catalogId
    );
    if (tempCatalog) {
      const countryCode =
        tempCatalog.countryCode.length > 0 ? tempCatalog.countryCode[0] : "";
      const filterCountries = countriesState.countries.data.data.filter(
        (country: any) => country.countryCode === countryCode
      );
      const newCountries = filterCountries.map((item: any) => {
        return { name: item.name, value: item.id };
      });
      setDisableCountries(false);
      setCountries(newCountries);
    }
    return;
  };

  const fieldsModalUpload = [
    {
      name: "catalogId",
      type: Field.CATALOG,
      placeholder: "Catalogo",
      position: Position.VERTICAL,
      subFilter: changeCountriesByCatalog,
      disabled: false,
    },
    {
      name: "countryId",
      type: Field.GENERAL,
      placeholder: "País",
      position: Position.VERTICAL,
      values: countries,
      disabled: disableCountries,
    },
  ];

  /****************** STORE  *******************/
  const dateObject = new Date();
  const date = `${dateObject.getFullYear()}_${
    dateObject.getMonth() + 1
  }_${dateObject.getDate()}`;

  const handleEdit = (body: any) => {
    setShowForm(true);
    dispatch(fetchCatalogs());
    dispatch(fetchCountries());
    // dispatch(fetchRegions());
    dispatch(fetchStore(body.id));
  };

  const showModal = (record: any = null) => {
    setIsModalVisible(true);
    setItem(record);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const hadleOnClickDeleteModal = (item: any) => {
    setIsModalVisible(false);
    dispatch(disableStore(item.id)).then((result: Result) => {
      if (result === Result.OK) {
        setItem("");
        message.success(
          `El comercio "${item.name}" ha sido deshabilitado con éxito`
        );
        dispatch(fetchStores(storesState.stores.data.page, pageSize));
      }
    });
  };

  const handleOnClickEnable = (item: any) => {
    dispatch(enableStore(item.id)).then((result: Result) => {
      if (result === Result.OK) {
        message.success(
          `El comercio "${item.name}" ha sido habilitado con éxito`
        );
        dispatch(fetchStores(storesState.stores.data.page, pageSize));
      }
    });
  };

  const showActivateStoresModal = () => {
    setIsActivateStoresModalVisible(!isActivateStoresModalVisible);
  };

  const showDeactivateStoresModal = () => {
    setIsDeactivateStoresModalVisible(!isDeactivateStoresModalVisible);
  };

  const deactivateStoresSelected = () => {
    selectedStoresDeactivate.forEach((el: any) => {
      hadleOnClickDeleteModal(el);
    });

    setSelectedStoresDeactivate([]);
    setSelectedStoresActivate([]);
    showDeactivateStoresModal();
  };

  const activateStoresSelected = () => {
    selectedStoresActivate.forEach((el: any) => {
      handleOnClickEnable(el);
    });

    setSelectedStoresActivate([]);
    setSelectedStoresDeactivate([]);
    showActivateStoresModal();
  };
  /****************** STORE  FORM *******************/
  const handleBreadcrumbOnClick = () => {
    setShowForm(false);
    setToggleNewUser(false);
    setUsers([]);
    dispatch(cleanUp());
  };

  const onClick = () => {
    setShowForm(true);
    dispatch(fetchCatalogs());
    dispatch(fetchCountries());
  };

  const onFinish = (values: any) => {
    const store = { ...values };
    delete store.latitude;
    delete store.longitude;
    store.gps = { latitude: values.latitude, longitude: values.longitude };
    //delete id property from users list when it has empty value.
    const newUsersList = [...users];
    newUsersList.forEach((user) => {
      if (user?.isNew) {
        delete user.id;
        delete user.isNew;
      }
    });
    console.log(store);
    store.users = newUsersList;
    if (users.length) {
      if (storesState.store.data.id) {
        //Update
        //delete store.password; SLI-1756
        dispatch(updateStore(store, storesState.store.data.id)).then(
          (result: Result) => {
            if (result === Result.OK) {
              dispatch(fetchStores(storesState.stores.data.page, pageSize));
              setShowForm(false);
              message.success(
                `El comercio "${values.storeName}" ha sido modificado con éxito`
              );
              setUsers([]);
            } else {
              message.error(storesState.store.error.data.message.toString());
            }
          }
        );
      } else {
        //Create
        dispatch(createStore(store)).then((result: Result) => {
          if (result === Result.OK) {
            message.success(
              `El comercio "${values.storeName}" ha sido creado con éxito`
            );

            dispatch(fetchStores(storesState.stores.data.page, pageSize));
            setShowForm(false);
            setUsers([]);
          } else {
            message.error(storesState.store.error.data.message.toString());
          }
        });
      }
    } else message.warning("El comercio debe tener al menos un usuario creado");
  };

  /****************** STORE FORM *******************/
  const onClickToggleUserForm = () => {
    // Esta linea de codigo funciona para mostrar / ocultar el formulario de usuario
    setToggleNewUser(!toggleNewUser);
    setcurrentUser({});

    // Esta linea de codigo funciona para ocultar la lista de usuarios creados
    setHideUserList(!hideUserList);
  };

  const onClickEditUser = (user: IUserStore) => {
    user.pin = "";
    setcurrentUser(user);
    // Esta linea de codigo funciona para mostrar en el formulario de usuario el boton "Modificar usuario"
    setToggleNewUser(true);
  };

  const onClickDeleteUser = (user: any) => {
    const filteredUsers = users.filter(
      (item: any) => item.email !== user.email || item.id !== user?.id
    );
    setUsers(filteredUsers);
  };

  const onFinishUserForm = (user: IUserStore) => {
    if (!user?.id) {
      const copyUser = Object.assign({}, { ...user, id: uuid(), isNew: true });
      setUsers([...users, copyUser]);
      console.log(copyUser);
    } else {
      const userIndex = users.findIndex(
        (item: IUserStore) => item.id === user.id
      );

      const copyUsers = users;
      copyUsers[userIndex] = { ...user };
      setUsers([...copyUsers]);
      console.log(copyUsers);
    }
    setHideUserList(false);
    setToggleNewUser(false);
  };

  // Local filters
  const handleRowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      if (selectedRows.length) {
        const selectedStoresActivateArray = selectedRows.filter(
          (el: any) => el.active === false
        );
        const selectedStoresDeactivateArray = selectedRows.filter(
          (el: any) => el.active === true
        );
        setSelectedStoresActivate(selectedStoresActivateArray);
        setSelectedStoresDeactivate(selectedStoresDeactivateArray);
      } else {
        setSelectedStoresActivate([]);
        setSelectedStoresDeactivate([]);
      }

      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys,
    type: "check",
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSelectedStoresActivate([]);
    setSelectedStoresDeactivate([]);
    setSelectedRowKeys([]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSelectedStoresActivate([]);
    setSelectedStoresDeactivate([]);
    setSelectedRowKeys([]);
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: any;
      selectedKeys: any;
      confirm: any;
      clearFilters: any;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      get(record, dataIndex)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  });
  // Local filters

  return {
    storesState,
    pagination: {
      current: storesState.stores.data.page,
      pageSize: storesState.stores.data.pageSize,
      total: storesState.stores.data.totalCount,
      onChange: (page: number, pageSize: number) => {
        dispatch(fetchStores(page, pageSize, queryParams));
        setSelectedStoresActivate([]);
        setSelectedStoresDeactivate([]);
      },
    },
    handleEdit,
    handleOnClickEnable,
    onClick,
    showForm,
    setShowForm,
    isModalVisible,
    showModal,
    handleCancel,
    onFinish,
    currentUser,
    setcurrentUser,
    users,
    toggleNewUser,
    setUsers,
    onClickToggleUserForm,
    onClickEditUser,
    onClickDeleteUser,
    handleBreadcrumbOnClick,
    onFinishUserForm,
    catalogState,
    countriesState,
    regionsState,
    hadleOnClickDeleteModal,
    item,
    handleRowSelection,
    activateStoresSelected,
    deactivateStoresSelected,
    selectedStoresActivate,
    selectedStoresDeactivate,
    showActivateStoresModal,
    showDeactivateStoresModal,
    isActivateStoresModalVisible,
    isDeactivateStoresModalVisible,
    getColumnSearchProps,
    csvFormatInformation,
    date,
    fieldsModalUpload,
    fieldsStore,
    setFieldsStore,
  };
};
