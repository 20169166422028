import { Col, Table, Button, Tag, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";

interface ITableErrors {
  validateDataCsv: any[];
  columns: any[];
  success?: number;
}

export const TableErrors = (props: ITableErrors) => {
  const { validateDataCsv, columns, success } = props;
  return (
    <div className="table-errors-content">
      <Row gutter={[0, 16]}>
        <Col span={17}>
          <div className="error-labels">
            <Tag color="green">{success} insertados</Tag>
          </div>
          <div className="error-labels">
            <Tag color="red">{validateDataCsv.length} errores</Tag>
          </div>
        </Col>
        <Col span={7}>
          <Button className="btn-export-now" icon={<DownloadOutlined />}>
            <CSVLink
              filename={"errors_stores.csv"}
              data={validateDataCsv}
              className="btn btn-primary"
            >
              Exportar Log
            </CSVLink>
          </Button>
        </Col>
        <Col span={24}>
          <Table
            tableLayout="auto"
            dataSource={validateDataCsv}
            columns={columns}
            rowKey="field"
          />
        </Col>
      </Row>
    </div>
  );
};
