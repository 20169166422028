import { ICategory, ICategories, categoriesAction, IPicture } from '../types/categories.types';
import { categoriesActionTypes } from "../constants/index";
import { IError } from "../types/interfaces/error.interface";
import { ThunkAction } from 'redux-thunk';
import { AppDispatch, RootState } from '../store/store';
import { ActionCreator } from "redux";
import instance from '../utils/axios.instance';
import { Result } from '../types/enum/result';

/*********************FETCH CATEGORIES ACTIONS************************************/
export const fetchCategoriesRequest = () => {
    return {
        type: categoriesActionTypes.FETCH_CATEGORIES_REQUEST
    };
};

export const fetchCategoriesSuccess = (data:any) => {
    return {
        type: categoriesActionTypes.FETCH_CATEGORIES_SUCCESS,
        payload: data
    };
};

export const fetchCategoriesFailure = (error:IError) => {
    return {
        type: categoriesActionTypes.FETCH_CATEGORIES_FAILURE,
        payload: error
    };
};

/*********************FETCH CATEGORY ACTIONS************************************/
export const fetchCategoryRequest = () => {
    return {
        type: categoriesActionTypes.FETCH_CATEGORY_REQUEST
    };
};

export const fetchCategorySuccess = (data:any) => {
    return {
        type: categoriesActionTypes.FETCH_CATEGORY_SUCCESS,
        payload: data
    };
};

export const fetchCategoryFailure = (error:IError) => {
    return {
        type: categoriesActionTypes.FETCH_CATEGORY_FAILURE,
        payload: error
    };
};

/*********************DELETE CATEGORY ACTION************************************/
export const deleteCategoryRequest = () => {
    return {
        type: categoriesActionTypes.DELETE_CATEGORY_REQUEST
    };
};

export const deleteCategorySuccess = (data:any) => {
    return {
        type: categoriesActionTypes.DELETE_CATEGORY_SUCCESS,
        payload: data
    };
};

export const deleteCategoryFailure = (error:IError) => {
    return {
        type: categoriesActionTypes.DELETE_CATEGORY_FAILURE,
        payload: error
    };
};

/*********************CREATE CATEGORY ACTION************************************/
export const createCategoryRequest = () => {
    return {
        type: categoriesActionTypes.CREATE_CATEGORY_REQUEST
    };
};

export const createCategorySuccess = (data:ICategory) => {
    return {
        type: categoriesActionTypes.CREATE_CATEGORY_SUCCESS,
        payload: data
    };
};

export const createCategoryFailure = (error:IError) => {
    return {
        type: categoriesActionTypes.CREATE_CATEGORY_FAILURE,
        payload: error
    };
};

/*********************UPDATE CATEGORY ACTION************************************/
export const updateCategoryRequest = () => {
    return {
        type: categoriesActionTypes.UPDATE_CATEGORY_REQUEST
    };
};

export const updateCategorySuccess = (data:ICategory) => {
    return {
        type: categoriesActionTypes.UPDATE_CATEGORY_SUCCESS,
        payload: data
    };
};

export const updateCategoryFailure = (error:IError) => {
    return {
        type: categoriesActionTypes.UPDATE_CATEGORY_FAILURE,
        payload: error
    };
};

/*********************ENABLE CATEGORY ACTION************************************/
export const enableCategoryRequest = () => {
    return {
        type: categoriesActionTypes.ENABLE_CATEGORY_REQUEST
    };
};

export const enableCategorySuccess = (data:ICategory) => {
    return {
        type: categoriesActionTypes.ENABLE_CATEGORY_SUCCESS,
        payload: data
    };
};

export const enableCategoryFailure = (error:IError) => {
    return {
        type: categoriesActionTypes.ENABLE_CATEGORY_FAILURE,
        payload: error
    };
};

/*********************FETCH PICTURES ACTION************************************/
export const fetchPicturesRequest = () => {
    return {
        type: categoriesActionTypes.FETCH_PICTURES_REQUEST
    };
};

export const fetchPicturesSuccess = (data:IPicture) => {
    return {
        type: categoriesActionTypes.FETCH_PICTURES_SUCCESS,
        payload: data
    };
};

export const fetchPicturesFailure = (error:IError) => {
    return {
        type: categoriesActionTypes.FETCH_PICTURES_FAILURE,
        payload: error
    };
};

/*********************CLEAN UP COUNTRY ACTION************************************/
export const cleanUpCategoryData = () =>{
    return{
        type: categoriesActionTypes.CATEGORY_CLEAN_UP
    }
}
/****************************************************************************/

const filterCategoriesByCatalogs = (id: string, response: ICategories) => {
    let data = response.data.filter((item) => item.catalogId === id);
    response.data = data
    response.totalCount = response.data.length
    return response;
}

export const fetchCategories:ActionCreator<ThunkAction<void, RootState, null, categoriesAction>> = (currentPage:any = 1, pageSize:any = 20, paramas:string = '') => 
    async (dispatch:AppDispatch) => {
        try {
            dispatch(fetchCategoriesRequest());
            let url = `/api/categories?page=${currentPage}&pageSize=${pageSize}${paramas}`
            const response = await instance.get(url);
            let data = response.data;
           
            dispatch(fetchCategoriesSuccess(data));

        } catch (error:any) {
            if(error.response){
                const data: IError = {
                    status: error.response.status,
                    data: {
                        timestamp: error.response?.data?.timestamp,
                        path: error.response?.data?.path,
                        message: error.response?.data?.message
                    }
                };
                dispatch(fetchCategoriesFailure(data));
            }
        }
    };

export const deleteCategory:ActionCreator<ThunkAction<void, RootState, null, categoriesAction>> = (id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(deleteCategoryRequest());
            const response = await instance.delete(`/api/categories/${id}`);
            dispatch(deleteCategorySuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(deleteCategoryFailure(data));

            return Result.ERROR;
        }
    };

export const createCategory:ActionCreator<ThunkAction<void, RootState, null, categoriesAction>> = (body:any) =>
    async (dispatch: AppDispatch) => {
        try {
            if (body.internal_ref) {
                body.attributes = {
                    internal_ref: body.internal_ref
                };
            }

            dispatch(createCategoryRequest());
            const response = await instance.post(`/api/categories`, body);
            dispatch(createCategorySuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(createCategoryFailure(data));

            return Result.ERROR;
        }
    };

export const updateCategory:ActionCreator<ThunkAction<void, RootState, null, categoriesAction>> = (body:any, id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            if (body.internal_ref) {
                body.attributes = {
                    internal_ref: body.internal_ref
                };
            }
            
            dispatch(fetchCategoryRequest());
            const response = await instance.patch(`/api/categories/${id}`, body);
            dispatch(fetchCategorySuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchCategoryFailure(data));

            return Result.ERROR;
        }
    };

export const fetchCategory:ActionCreator<ThunkAction<void, RootState, null, categoriesAction>> = (id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchCategoryRequest());
            const response = await instance.get(`/api/categories/${id}`);
            dispatch(fetchCategorySuccess(response.data));
            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchCategoryFailure(data));

            return Result.ERROR;
        }
    };

export const fetchPictures:ActionCreator<ThunkAction<void, RootState, null, categoriesAction>> = () =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchPicturesRequest());
            const response = await instance.get(`/api/categories/pictures`);
            dispatch(fetchPicturesSuccess(response.data));
            return Result.OK;
        } catch (error: any) {
            if(error.response){
                const data: IError = {
                    status: error.response.status,
                    data: {
                        timestamp: error.response?.data?.timestamp,
                        path: error.response?.data?.path,
                        message: error.response?.data?.message
                    }
                };
                dispatch(fetchPicturesFailure(data));
            }
            return Result.ERROR;
            
        }
    };


export const cleanupCategory = () => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpCategoryData());
    }
};