import CopyTextColumn from "../../../other/CopyTextColumn";

export const Columns = () => {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: function RevealTextOnHover(text: any, record: any, index: any) {
        return <CopyTextColumn text={text} record={record} />;
      },
    },
    {
      title: "Código de barra",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a: any, b: any) => a.barcode - b.barcode,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
  ];
};
