import { useState } from "react"
import { fetchStores } from "../../../actions/stores.actions";
import useTicketFields from "../../../hooks/useTickets/useTicketFields";
import { FilterSection } from "../../../types/enum/filters";
import HorizontalFilter from "../../shared/filters/HorizontalFilter"
import { useFilters } from "../../../hooks/useFilters";
import { Form, Col, Row, Tooltip, Button } from "antd";
import DatePicker, { registerLocale } from 'react-datepicker';
import { addDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import Table from "../../table/customizedTable/CustomizedTable";
import { useLogs } from "../../../hooks/statistics/useLogs";
import { ColumnsLogs, ColumnsLogsView } from "./ColumnsLogs";
import useLogsFields from "../../../hooks/statistics/useLogsFields";
import { Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";

export const LogsStatistics = () => {
    const [defaultParams, setDefaultParams] = useState("")
    const pageSize = 20;
    const { fields, form } = useLogsFields()

    const {
        filters,
        handleChange,
        handleChangeSelect,
        resetFilter,
        toggleFilters,
        collapsed,
        queryParams,
        handleDonwload,
        onFinishFiltering,
    } = useFilters(
        fetchStores,
        fields,
        FilterSection.STORES,
        defaultParams,
        form,
        pageSize
    );

    const { storesState, pagination,viewButton, viewLogs, viewLogsUser, dataUserLog, userLog, dataChart, numberLogs, setNumberLogs, dataUserLogAll, handleStartDateChange, startDate , searchDate, clearFilter, setViewLogsUser, clearView} = useLogs(queryParams, form)


    const columns = ColumnsLogs(viewLogs);
    const columnsViewLogs = ColumnsLogsView()

    return (
        <>
            {!viewLogsUser ?
                <>
                    <Form form={form} onFinish={onFinishFiltering}>
                        <HorizontalFilter
                            fields={fields}
                            onFinishFiltering={onFinishFiltering}
                            filters={filters}
                            handleChange={handleChange}
                            resetFilter={resetFilter}
                            handleChangeSelect={handleChangeSelect}
                            form={form}
                        />
                    </Form>

                    <Row>
                        <Col span={24}>
                            <Table
                                dataSource={storesState.stores.data.data}
                                columns={columns}
                                name="Comercios"
                                path="stores"
                                loading={storesState.stores.loading}
                                pagination={pagination}

                            />
                        </Col>
                    </Row>
                </>
                :
                <>
                    <Row>
                        <Col span={10} >
                            <h1>Comercio: {userLog?.name}  - {userLog?.country?.name} </h1>
                        </Col>
                        <Col span={10}>

                            <div className="styleDateRangeFilter" style={{ display: "inline-block" }}>
                                <div className="inputDateRange">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        selectsStart
                                        startDate={startDate}
                                        dateFormat="dd-MM-yyyy"
                                        locale="es"
                                        className=""
                                        placeholderText="Selecciona una fecha"

                                    />
                                </div>
                            </div>
                            <Button type="primary" className='buttonFilterStatistics' onClick={searchDate}>
                                Filtrar
                            </Button>

                            <Button type="default" className='buttonFilterStatistics' style={{marginLeft:"10px"}} onClick={clearFilter}>
                                Limpiar filtro
                            </Button>
                        </Col>
                        <Col span={4} style={{textAlign:"right"}}>
                        <Button type="default" className='buttonFilterStatistics' onClick={clearView}>
                                Regresar
                            </Button>
                        </Col>

                    </Row>
                    <div style={{marginTop:"10px"}}>
                        <Table
                            dataSource={dataUserLog?.data}
                            columns={columnsViewLogs}
                            name="Comercios"
                            path="stores"
                            loading={dataUserLog?.loading}
                            scroll={true}
                        />
                    </div>
                    {(dataUserLogAll?.length > numberLogs && viewButton) &&
                        <Col style={{ textAlign: "center", marginTop: "10px" }}>
                            <Button
                                type="primary"
                                onClick={() => setNumberLogs(numberLogs + 10)}
                            >
                                Ver más registros
                            </Button>
                        </Col>
                    }
                    {dataChart.length > 0 &&
                        <>
                            <h1 style={{ marginTop: "40px" }}>Total de visitas del usuario  para {dataUserLog?.data?.length} {dataUserLog?.data?.length > 1 ? "días": "día"} </h1>

                            <ResponsiveContainer width="100%" height={450}>
                                <BarChart data={dataChart} margin={{
                                    top: 20, // Agrega espacio adicional en la parte superior
                                    right: 0,
                                    left: 0,
                                    bottom: 130,
                                }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="label" angle={-45} textAnchor="end" dy={0} />
                                    <YAxis />
                                    <Bar dataKey="value">
                                        {dataChart.map((entry: any, index: any) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                        <LabelList dataKey="value" position="top" />
                                    </Bar>
                                </BarChart>

                            </ResponsiveContainer>
                        </>
                    }

                </>
            }
        </>

    )
}
