import { ITickets, ITicket, IData } from '../types/tickets.types';
import { ticketsActionTypes } from "../constants";
import { v4 as uuid } from "uuid";
import { IError } from "../types/interfaces/error.interface";
import { Action } from "../types/actions.types";
import { ticketsState } from "../types/tickets.types";

let error:IError = {
    status: '',
    data: { 
        timestamp: '', 
        path: '',
        message: ''
    }
};

let tickets:ITickets = {
    data: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

let ticket:ITicket = {
    id: '',
    items: [],
    synchronized: false,
    currency: '',
    operator: '',
    storeId: '',
    createdAt: '',
    updatedAt: '',
    totalPrice: 0,
    taxes: 0,
};

const initialState = {
    tickets: {
        loading: false,
        data: tickets,
        error: error,
    },
    ticket: {
        loading: false,
        data: ticket,
        error: error,
        pictures: []
    },
};

const ticketsReducer = (state = initialState, action:Action):ticketsState => {
    switch (action.type) {
        case ticketsActionTypes.FETCH_TICKETS_REQUEST:
            return {
                ...state,
                tickets: {
                    ...state.tickets,
                    loading: true
                }
            };    
        case ticketsActionTypes.FETCH_TICKETS_SUCCESS:
            const data:IData[] = [...action.payload.data];

            data.forEach((item:IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });

            return {
                ...state,
                tickets: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case ticketsActionTypes.FETCH_TICKETS_FAILURE:
            return {
                ...state,
                tickets: {
                    loading: false,
                    data: tickets,
                    error: action.payload
                }
            };
        case ticketsActionTypes.FETCH_TICKET_REQUEST:
        case ticketsActionTypes.CREATE_TICKET_REQUEST:
        case ticketsActionTypes.UPDATE_TICKET_REQUEST:
        case ticketsActionTypes.DELETE_TICKET_REQUEST:
        case ticketsActionTypes.ENABLE_TICKET_REQUEST:
            return {
                ...state,
                ticket: {
                    ...state.ticket,
                    loading: true
                }
            };
        case ticketsActionTypes.ENABLE_TICKET_SUCCESS:
        case ticketsActionTypes.DELETE_TICKET_SUCCESS:
            return {
                ...state,
                ticket: {
                    loading: false,
                    data: {
                        ...state.ticket.data,
                        id: action.payload
                    },
                    error: error
                }
            };
        case ticketsActionTypes.FETCH_TICKET_FAILURE:
        case ticketsActionTypes.CREATE_TICKET_FAILURE:
        case ticketsActionTypes.UPDATE_TICKET_FAILURE:
        case ticketsActionTypes.DELETE_TICKET_FAILURE:
        case ticketsActionTypes.ENABLE_TICKET_FAILURE:
            return {
                ...state,
                ticket: {
                    loading: false,
                    data: ticket,
                    error: action.payload,
                }
            };
        case ticketsActionTypes.FETCH_TICKET_SUCCESS:
        case ticketsActionTypes.CREATE_TICKET_SUCCESS:
        case ticketsActionTypes.UPDATE_TICKET_SUCCESS:
            return {
                ...state,
                ticket: {
                    loading: false,
                    data: action.payload,
                    error: error,
                }
            };
        case ticketsActionTypes.TICKET_CLEAN_UP:
            return {
                ...state,
                ticket: initialState.ticket
            };
        default:
            return state;
    }
};

export default ticketsReducer;