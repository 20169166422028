import { Field, Position } from '../../types/enum/field';


export const fields = [
    {
        name: 'name',
        type: Field.SEARCH,
        placeholder: 'Nombre',
        position: Position.HORIZONTAL
    },
    {
        name: 'catalogId',
        type: Field.CATALOG,
        placeholder: '',
        position: Position.HORIZONTAL,
        value: '60d4f60a378aec6831a3f459'
    }
]