import { catalogFilesTypes } from "../constants/index";
import instance from "../utils/axios.instance";
import { AppDispatch } from "../store/store";
import { Result } from "../types/enum/result";
import { ICatalog } from "../types/interfaces/catalog.interface";
import { IError } from "../types/interfaces/error.interface";
import { IResponse } from "../types/interfaces/response.interface";

/*****************FETCH CATALOG LIST ACTIONS*********************/
export const fetchCatalogRequest = () => {
  return {
    type: catalogFilesTypes.FETCH_CATALOGLIST_REQUEST,
  };
};

export const fetchCatalogSuccess = (data: any) => {
  return {
    type: catalogFilesTypes.FETCH_CATALOGLIST_SUCCESS,
    payload: data,
  };
};

export const fetchCatalogFailure = (error: IError) => {
  return {
    type: catalogFilesTypes.FETCH_CATALOGLIST_FAILURE,
    payload: error,
  };
};
/*****************FETCH CATALOG FILES  ACTIONS*********************/
export const fetchCatalogFilesRequest = () => {
  return {
    type: catalogFilesTypes.FETCH_CATALOGFILES_REQUEST,
  };
};

export const fetchCatalogFilesSuccess = (data: any) => {
  return {
    type: catalogFilesTypes.FETCH_CATALOGFILES_SUCCESS,
    payload: data,
  };
};

export const fetchCatalogFilesFailure = (error: IError) => {
  return {
    type: catalogFilesTypes.FETCH_CATALOGFILES_FAILURE,
    payload: error,
  };
};
/*****************GENERATE CATALOG ACTIONS*********************/
export const generateCatalogRequest = () => {
  return {
    type: catalogFilesTypes.GENERATE_CATALOG_REQUEST,
  };
};

export const generateCatalogSuccess = (data: any) => {
  return {
    type: catalogFilesTypes.GENERATE_CATALOG_SUCCESS,
    payload: data,
  };
};

export const generateCatalogFailure = (error: IError) => {
  return {
    type: catalogFilesTypes.GENERATE_CATALOG_FAILURE,
    payload: error,
  };
};
/***********************************************************/
export const fetchCatalogs = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(fetchCatalogRequest());
    await instance
      .get(`/api/catalogs`)
      .then((response) => {
        const data: ICatalog = response.data;
        dispatch(fetchCatalogSuccess(data));
      })
      .catch((error) => {
        if(error.response){
          const data: IError = {
            status: error.response.status,
            data: {
              timestamp: error.response?.data?.timestamp,
              path: error.response?.data?.path,
              message: error.response?.data?.message,
            },
          };
          dispatch(fetchCatalogFailure(data));
        }
      });
  };
};
export const generateCatalogFile =
  (catalogId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(generateCatalogRequest());
      const response = await instance.post(
        `/api/catalogs/${catalogId}/file`,
        null
      );
      const data: any = response.data;
      dispatch(generateCatalogSuccess(data));
      const result: IResponse = {status:Result.OK}
      return result;
    } catch (error: any) {
      const result: IError = {
        status:Result.ERROR,
        data: {
          timestamp: error.response?.data?.timestamp,
          path: error.response?.data?.path,
          message: error.response?.data?.message,
        },
      };
      dispatch(generateCatalogFailure(result));
      return result;
    }
  };

export const fetchCatalogFiles = (page:number = 1, pageSize:number = 20) => {
  return async (dispatch: AppDispatch) => {
    dispatch(fetchCatalogFilesRequest());
    await instance
      .get(`/api/catalogs/files?page=${page}&pageSize=${pageSize}`)
      .then((response) => {
        const data = response.data;
        dispatch(fetchCatalogFilesSuccess(data));
      })
      .catch((error) => {
        const data: IError = {
          status: error.response.status,
          data: {
            timestamp: error.response?.data?.timestamp,
            path: error.response?.data?.path,
            message: error.response?.data?.message,
          },
        };
        dispatch(fetchCatalogFilesFailure(data));
      });
  };
};
