export const baseProductsActionTypes= {
    // CRUD OPERATIONS
    CREATE_BASE_PRODUCT_REQUEST: "CREATE_BASE_PRODUCT_REQUEST",
    CREATE_BASE_PRODUCT_SUCCESS: "CREATE_BASE_PRODUCT_SUCCESS",
    CREATE_BASE_PRODUCT_FAILURE: "CREATE_BASE_PRODUCT_FAILURE",

    FETCH_BASE_PRODUCTS_REQUEST: "FETCH_BASE_PRODUCTS_REQUEST",
    FETCH_BASE_PRODUCTS_SUCCESS: "FETCH_BASE_PRODUCTS_SUCCESS",
    FETCH_BASE_PRODUCTS_FAILURE: "FETCH_BASE_PRODUCTS_FAILURE",
    
    FETCH_BASE_PRODUCT_REQUEST: "FETCH_BASE_PRODUCT_REQUEST",
    FETCH_BASE_PRODUCT_SUCCESS: "FETCH_BASE_PRODUCT_SUCCESS",
    FETCH_BASE_PRODUCT_FAILURE: "FETCH_BASE_PRODUCT_FAILURE",
    
    UPDATE_BASE_PRODUCT_REQUEST: "UPDATE_BASE_PRODUCT_REQUEST",
    UPDATE_BASE_PRODUCT_SUCCESS: "UPDATE_BASE_PRODUCT_SUCCESS",
    UPDATE_BASE_PRODUCT_FAILURE: "UPDATE_BASE_PRODUCT_FAILURE",

    ADD_BASE_PRODUCT:"ADD_BASE_PRODUCT",

    // EXTRA
    BASE_PRODUCT_CLEAN_UP: "BASE_PRODUCT_CLEAN_UP",

    SET_BASE_PRODUCT_FORM_DATA: "SET_BASE_PRODUCT_FORM_DATA",
    CLEAN_UP_BASE_PRODUCT_FORM_DATA: "CLEAN_UP_BASE_PRODUCT_FORM_DATA",
}