import { FC } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import {
  keycloak,
  keycloakProviderInitConfig,
  isLoadingCheck,
  storeToken,
  updateToken,
} from "..";
import KeycloakInitialized from "./KeycloakInitialized";
import Loading from "../../components/loading/loading";

const KeycloakProvider: FC<any> = ({ children }) => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
      onTokens={({ token }) => {
        storeToken(token ? token : "");
      }}
      LoadingComponent={<Loading />}
      onEvent={(event, error) => {
        if (event === "onTokenExpired") {
          updateToken();
        }
      }}
      isLoadingCheck={isLoadingCheck}
    >
      <KeycloakInitialized children={children} />
    </ReactKeycloakProvider>
  );
};
export default KeycloakProvider;
