import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  cleanUpProductsNoPromotableData,
  cleanUpProductsPromotedData,
  cleanUpProductsToBePromotedData,
} from "../../actions/storeProducts/cleanup.actions";
import { fetchStoreProducts } from "../../actions/storeProducts/main.actions";
import { combineKeyValueArray } from "../../utils/arrayFunctions";

const useSearchByFilters = () => {
  const dispatch = useDispatch();
  // Estados relacionados al filtrado
  const [collapsed, setCollapsed] = useState(true);

  const onFinishFiltering = (values: any) => {
    const dateArray = ["createdAt", "updatedAt"];
    const booleanArray = ["includeBarcode", "promotable"];
    /*Note:the api currently does not handle multiple values*/
    values.unit = values.unit !== undefined ? values?.unit[0] : values.unit;
    values.currency =
      values.currency !== undefined ? values?.currency[0] : values.currency;
    values.storeId =
      values.storeId !== undefined ? values?.storeId[0] : values.storeId;
    /********************************************************/
    const params = combineKeyValueArray(values, dateArray, booleanArray);
    dispatch(cleanUpProductsToBePromotedData());
    dispatch(cleanUpProductsPromotedData());
    dispatch(cleanUpProductsNoPromotableData());

    dispatch(fetchStoreProducts(1, 10, params));
  };

  const toggleFilters = () => {
    setCollapsed(!collapsed);
  };

  return { onFinishFiltering, toggleFilters,collapsed, setCollapsed };
};
export default useSearchByFilters;
