import { AppDispatch, RootState } from '../store/store';
import { filtersActionTypes } from '../constants/filters.actionTypes';
import { IError } from '../types/interfaces/error.interface';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { filtersAction } from '../types/filters.types';
import instance from '../utils/axios.instance';
import { exportFilterSize as pageSize } from './../constants/index'
import { getUrlBySection } from '../utils/arrayFunctions';
import { FilterSection } from '../types/enum/filters';
import { getValueFromCharacterString } from '../utils/stringFunctions';
import { getTimeZone } from '../utils/timeZoneFunctions';

export const addFilterRequest = (fields:any, section:string) => {
    return {
        type: filtersActionTypes.ADD_FILTER,
        fields,
        section,
    };
};

export const updateFilterRequest = (values:any, section:string) => {
    return {
        type: filtersActionTypes.UPDATE_FILTER,
        values,
        section,
    };
};

export const cleanUpFilterData = () => {
    return {
        type: filtersActionTypes.CLEAN_UP_FILTER,
    };
};

export const cleanUpFiltersData = () => {
    return {
        type: filtersActionTypes.CLEAN_UP_FILTERS
    };
};

export const cleanUpFilter = () => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpFilterData());
    }
};

export const cleanUpFilters = () => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpFiltersData());
    }
};

export const addFilter = (fields:any, section:string) => {
    return (dispatch:AppDispatch) => {
        dispatch(addFilterRequest(fields, section));
    }
};

export const updateFilter = (values:any, section:string) => {
    return (dispatch:AppDispatch) => {
        dispatch(updateFilterRequest(values, section));
    }
};

export const fetchFilterExportRequest = () => {
    return {
        type: filtersActionTypes.FETCH_EXPORT_REQUEST
    };
};

export const fetchFilterExportSuccess = (data:any) => {
    return {
        type: filtersActionTypes.FETCH_EXPORT_SUCCESS,
        payload: data
    };
};

export const fetchFilterExportFailure = (error:IError) => {
    return {
        type: filtersActionTypes.FETCH_EXPORT_ERROR,
        payload: error
    };
};


export const fetchFilterExport:ActionCreator<ThunkAction<void, RootState, null, filtersAction>> = (params:string = '', type: any, setLoading: any, setDownload: any, buttonRef: any, downloadPageSize?:number | undefined) => 
    async (dispatch:AppDispatch, getState) => {
        const currentPage = 1;
        try {
            dispatch(cleanUpFilterExportData());
            dispatch(fetchFilterExportRequest());
            const url = getUrlBySection(type);
            /************************************************/
            let tz=""
            if(type === FilterSection.TICKETS){
                //get Time Zone by country
                const countryId=getValueFromCharacterString(params,"countryId");
                tz = `&tz=${getTimeZone(getState().countries?.countries?.data?.data,countryId)}`
            }
            const response: any = await instance.get(`${url}?page=${currentPage}&pageSize=${downloadPageSize ?? pageSize}${params}&${tz}`);
            let data = response.data.data;
            if(type === FilterSection.TICKETS){
                data = response.data.data.map((item: any) => {
                    if(item.items){
                        item.items = item.items.map((item: any) => {
                            return item.barcode;
                        }).join(' ');
                        
                    }
                    return item;
                });
            }
            setDownload(data);
            setLoading(false);
            buttonRef.current.link.click();
            dispatch(fetchFilterExportSuccess(response.data));
        } catch (error:any) {
            setLoading(false);
            const data: IError = {
                status: error?.response?.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchFilterExportFailure(data));
        }
    };

export const cleanUpFilterExportData = () =>{
    return{
        type: filtersActionTypes.CLEAN_UP_EXPORT
    }
}