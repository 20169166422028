export const categoriesActionTypes= {
    FETCH_CATEGORIES_REQUEST: "FETCH_CATEGORIES_REQUEST",
    FETCH_CATEGORIES_SUCCESS: "FETCH_CATEGORIES_SUCCESS",
    FETCH_CATEGORIES_FAILURE: "FETCH_CATEGORIES_FAILURE",
    
    FETCH_CATEGORY_REQUEST: "FETCH_CATEGORY_REQUEST",
    FETCH_CATEGORY_SUCCESS: "FETCH_CATEGORY_SUCCESS",
    FETCH_CATEGORY_FAILURE: "FETCH_CATEGORY_FAILURE",
    
    DELETE_CATEGORY_REQUEST: "DELETE_CATEGORY_REQUEST",
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
    DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",
    
    CREATE_CATEGORY_REQUEST: "CREATE_CATEGORY_REQUEST",
    CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
    CREATE_CATEGORY_FAILURE: "CREATE_CATEGORY_FAILURE",
    
    UPDATE_CATEGORY_REQUEST: "UPDATE_CATEGORY_REQUEST",
    UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
    UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",

    ENABLE_CATEGORY_REQUEST: "ENABLE_CATEGORY_REQUEST",
    ENABLE_CATEGORY_SUCCESS: "ENABLE_CATEGORY_SUCCESS",
    ENABLE_CATEGORY_FAILURE: "ENABLE_CATEGORY_FAILURE",

    FETCH_PICTURES_REQUEST: "FETCH_PICTURES_REQUEST",
    FETCH_PICTURES_SUCCESS: "FETCH_PICTURES_SUCCESS",
    FETCH_PICTURES_FAILURE: "FETCH_PICTURES_FAILURE",

    CATEGORY_CLEAN_UP: "CATEGORY_CLEAN_UP"
}