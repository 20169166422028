import Table from "../table/customizedTable/CustomizedTable";
import HelmetTitle from "../other/HelmetTitle";

import { useStores } from "../../hooks/useStores";
import { Button, Col, Row, Form } from "antd";
import {
  PoweroffOutlined,
  PlusOutlined,
  UploadOutlined,
  FilterOutlined,
  StopOutlined,
} from "@ant-design/icons";
import StoreFormNuevo from "./StoresForm";
import PageTitle from "../other/PageTitle";
import { useFileUpload } from "../../hooks/useFileUpload";
import RowGroupModal from "../other/RowGroupModal";
import { Columns } from "./Columns";
import { ModalUpload } from "../shared/upload/ModalUpload";
import { uploadFile } from "../../actions/upload.action";
import { fetchStores as fetchEntity } from "../../actions/stores.actions";
import { CSVConfig as storeCsv } from "../../utils/constants/csv/storesCsv";
import { headers } from "./FileHeaders";
import Filters from "../shared/filters/Filters";
import { useFilters } from "../../hooks/useFilters";
import { fetchStores } from "./../../actions/stores.actions";
import HorizontalFilter from "../shared/filters/HorizontalFilter";
import { FilterSection } from "../../types/enum/filters";
import { DownloadCSV } from "../shared/buttons/DownloadCSV";
import { useEffect, useState } from "react";

const Stores = () => {
  const defaultParams = ``;
  const [form] = Form.useForm();
  const pageSize = 4000;

  const [fields, setFields] = useState<any[]>([]);

  const {
    filters,
    handleChange,
    handleChangeSelect,
    resetFilter,
    toggleFilters,
    collapsed,
    onFinishFiltering,
    queryParams,
    filtersState,
    handleDonwload,
  } = useFilters(
    fetchStores,
    fields,
    FilterSection.STORES,
    defaultParams,
    form,
    pageSize
  );

  const {
    storesState,
    pagination,
    handleEdit,
    onClick,
    showForm,
    setShowForm,
    isModalVisible,
    showModal,
    handleCancel,
    onFinish,
    currentUser,
    setcurrentUser,
    users,
    setUsers,
    onClickToggleUserForm,
    onClickEditUser,
    onClickDeleteUser,
    handleBreadcrumbOnClick,
    onFinishUserForm,
    toggleNewUser,
    handleOnClickEnable,
    hadleOnClickDeleteModal,
    item,
    handleRowSelection,
    activateStoresSelected,
    deactivateStoresSelected,
    selectedStoresActivate,
    selectedStoresDeactivate,
    showActivateStoresModal,
    showDeactivateStoresModal,
    isActivateStoresModalVisible,
    isDeactivateStoresModalVisible,
    getColumnSearchProps,
    csvFormatInformation,
    date,
    fieldsModalUpload,
    fieldsStore,
    setFieldsStore,
  } = useStores(queryParams);

  useEffect(() => {
    setFields([...fieldsStore]);
  }, [fieldsStore]);

  const {
    uploadFileState,
    uploadImage,
    file,
    uploadProps,
    showUploadModal,
    openUploadModal,
    closeUploadModal,
    validateDataCsv,
    formUpload,
    filter,
    onChange,
  } = useFileUpload([
    uploadFile,
    fetchEntity,
    storesState.stores,
    "stores",
    storeCsv,
    fieldsModalUpload,
  ]);

  const columns = Columns(
    getColumnSearchProps,
    item,
    handleEdit,
    isModalVisible,
    handleCancel,
    handleOnClickEnable,
    hadleOnClickDeleteModal,
    showModal
  );

  return (
    <>
      <HelmetTitle
        title="Comercio - Backoffice D&#38;N"
        description="Pantalla para listar, crear, y editar comercios"
      />
      {showForm ? (
        <StoreFormNuevo
          onFinish={onFinish}
          toggleNewUser={toggleNewUser}
          setShowForm={setShowForm}
          currentUser={currentUser}
          setcurrentUser={setcurrentUser}
          users={users}
          setUsers={setUsers}
          handleBreadcrumbOnClick={handleBreadcrumbOnClick}
          onClickToggleUserForm={onClickToggleUserForm}
          onClickEditUser={onClickEditUser}
          onClickDeleteUser={onClickDeleteUser}
          onFinishUserForm={onFinishUserForm}
        />
      ) : (
        <>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 5 }}>
              <PageTitle title="Comercios" />
            </Col>

            <Col xs={{ span: 24 }} xl={{ span: 19 }}>
              <Row className="d-flex flex-end">
                <Button
                  type="text"
                  disabled={selectedStoresActivate.length ? false : true}
                  onClick={showActivateStoresModal}
                >
                  <PoweroffOutlined />
                  Habilitar comercios
                </Button>

                <Button
                  type="text"
                  disabled={selectedStoresDeactivate.length ? false : true}
                  onClick={showDeactivateStoresModal}
                >
                  <StopOutlined />
                  Deshabilitar comercios
                </Button>

                <Button type="text" onClick={onClick}>
                  <PlusOutlined />
                  Nuevo comercio
                </Button>

                <DownloadCSV
                  headers={headers}
                  date={date}
                  section={FilterSection.STORES}
                  handleDownload={handleDonwload}
                />

                <Button type="text" onClick={openUploadModal}>
                  <UploadOutlined />
                  Carga masiva
                </Button>

                <Button type="text" onClick={toggleFilters}>
                  <FilterOutlined />
                  Filtros
                </Button>
              </Row>
            </Col>
          </Row>

          <Form form={form} onFinish={onFinishFiltering}>
            <Filters
              fields={fields}
              filters={filters}
              collapsed={collapsed}
              onFinishFiltering={onFinishFiltering}
              productStoreFilters={true}
              toggleFilters={toggleFilters}
              resetFilter={resetFilter}
              handleChange={handleChange}
              handleChangeSelect={handleChangeSelect}
              form={form}
            />

            <HorizontalFilter
              fields={fields}
              onFinishFiltering={onFinishFiltering}
              filters={filters}
              handleChange={handleChange}
              resetFilter={resetFilter}
              handleChangeSelect={handleChangeSelect}
              form={form}
            />
          </Form>

          <Row>
            <Col span={24}>
              <Table
                dataSource={storesState.stores.data.data}
                columns={columns}
                name="Comercios"
                path="stores"
                onClick={onClick}
                loading={storesState.stores.loading}
                pagination={pagination}
                handleRowSelection={handleRowSelection}
              />
            </Col>
          </Row>
        </>
      )}

      <ModalUpload
        title="Carga masiva de comercios"
        showUploadModal={showUploadModal}
        closeUploadModal={closeUploadModal}
        uploadFileState={uploadFileState}
        uploadImage={uploadImage}
        file={file}
        type="tiendas"
        ejemplo="./archivo-comercios-ejemplo.csv"
        uploadProps={uploadProps}
        validateDataCsv={validateDataCsv}
        csvFormatInformation={csvFormatInformation}
        fields={fieldsModalUpload}
        form={formUpload}
        filter={filter}
        onChange={onChange}
      />

      <RowGroupModal
        actionType="Habilitar"
        item="comercios"
        isVisible={isActivateStoresModalVisible}
        showAction={showActivateStoresModal}
        actionTrigger={activateStoresSelected}
      />

      <RowGroupModal
        actionType="Deshabilitar"
        item="comercios"
        isVisible={isDeactivateStoresModalVisible}
        showAction={showDeactivateStoresModal}
        actionTrigger={deactivateStoresSelected}
      />
    </>
  );
};

export default Stores;
