export enum ProductBaseEnum{
    Description="description",
    Brand="brand",
    Contents="contents",
    Barcode="barcode",
    Unit="unit",
    Price="price",
    Currency="currency",
    TaxPercent="taxPercent",
    SellMode="sellMode",
    CatalogId="catalogId",
    CategoryId="categoryId"
}