import { IBaseProducts, IBaseProduct, IData } from '../types/baseProducts.types';
import { baseProductsActionTypes } from "../constants";
import { v4 as uuid } from "uuid";
import { IError } from "../types/interfaces/error.interface";
import { Action } from "../types/actions.types";
import { baseProductsState } from "../types/baseProducts.types";

let error:IError = {
    status: '',
    data: { 
        timestamp: '', 
        path: '',
        message: ''
    }
};

let baseProducts:IBaseProducts = {
    data: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

let baseProduct:IBaseProduct = {
    id: '',
    description: '',
    brand: '',
    contents: '',
    barcode: '',
    unit: '',
    price: '',
    currency: '',
    taxPercent: '',
    sellMode: '',
    catalogId: '',
    category: {
        id: '',
        catalogId: ''
    },
    active: true
};

const initialState = {
    baseProducts: {
        loading: false,
        data: baseProducts,
        error: error,
    },
    baseProduct: {
        loading: false,
        data: baseProduct,
        error: error
    },
};

const baseProductsReducer = (state = initialState, action:Action):baseProductsState => {
    switch (action.type) {
        case baseProductsActionTypes.FETCH_BASE_PRODUCTS_REQUEST:
            return {
                ...state,
                baseProducts: {
                    ...state.baseProducts,
                    loading: true
                }
            };    
        case baseProductsActionTypes.FETCH_BASE_PRODUCTS_SUCCESS:
            const data:IData[] = [...action.payload.data];

            data.forEach((item:IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });

            return {
                ...state,
                baseProducts: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case baseProductsActionTypes.FETCH_BASE_PRODUCTS_FAILURE:
            return {
                ...state,
                baseProducts: {
                    loading: false,
                    data: baseProducts,
                    error: action.payload
                }
            };
        case baseProductsActionTypes.FETCH_BASE_PRODUCT_REQUEST:
        case baseProductsActionTypes.CREATE_BASE_PRODUCT_REQUEST:
        case baseProductsActionTypes.UPDATE_BASE_PRODUCT_REQUEST:
            return {
                ...state,
                baseProduct: {
                    ...state.baseProduct,
                    loading: true
                }
            };
        case baseProductsActionTypes.FETCH_BASE_PRODUCT_FAILURE:
        case baseProductsActionTypes.CREATE_BASE_PRODUCT_FAILURE:
        case baseProductsActionTypes.UPDATE_BASE_PRODUCT_FAILURE:
        case baseProductsActionTypes.FETCH_BASE_PRODUCT_SUCCESS:
        case baseProductsActionTypes.CREATE_BASE_PRODUCT_SUCCESS:
        case baseProductsActionTypes.UPDATE_BASE_PRODUCT_SUCCESS:
            return {
                ...state,
                baseProduct: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case baseProductsActionTypes.SET_BASE_PRODUCT_FORM_DATA:
            return {
                ...state,
                baseProduct: {
                    ...state.baseProduct,
                    data: action.payload
                }
            };
        case baseProductsActionTypes.BASE_PRODUCT_CLEAN_UP:
            return {
                ...state,
                baseProduct: initialState.baseProduct
            };
        default:
            return state;
    }
};

export default baseProductsReducer;