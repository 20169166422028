import HelmetTitle from "../other/HelmetTitle";
import { useUsers } from "../../hooks/useUsers";
import { useShortcut } from "../../hooks/useShortcut";

import Table from "../table/customizedTable/CustomizedTable";
import { Button, Col, Row, Form } from "antd";
import {
  PlusOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import PageTitle from "../other/PageTitle";
import { Columns } from "./Columns";
import { fields } from "./fields";
import { useFilters } from "../../hooks/useFilters";
import HorizontalFilter from "../shared/filters/HorizontalFilter";
import Filters from "../shared/filters/Filters";
import { FilterSection } from "../../types/enum/filters";
import { fetchShortcut } from "../../actions/shortcut.actions";
import ShortcutForm from "./ShortcutForm";
import FilterBreadcrumb from "../shared/filters/FilterBreadcrumb";
import SortableTableHook from "../table/TableDrag"

const Shortcut = () => {
  const defaultParams = ``;
  const [form] = Form.useForm();

  const {
    filters,
    handleChange,
    handleChangeSelect,
    resetFilter,
    toggleFilters,
    collapsed,
    onFinishFiltering,
    queryParams,
    filtersState
  } = useFilters(fetchShortcut, fields, FilterSection.SHORTCUT, defaultParams, form);

  const {
    shortcutState,
    pagination,
    handleEdit,
    onClick,
    showForm,
    setShowForm,
    dataShortchut,
    retunrOrder,
    shortcutStateData
  } = useShortcut(queryParams);

  const columns = Columns(handleEdit);

  return (
    <>
      <HelmetTitle
        title="Cartillas - Backoffice D&#38;N"
        description="Pantalla para listar, crear, y editar usuarios"
      />
      {showForm ? (
        <ShortcutForm dataShortchut={dataShortchut} setShowForm={setShowForm} filters={filters}/>
      ) : (
        <>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 5 }}>
              <PageTitle title="Cartilla" />
              <FilterBreadcrumb filters={filtersState.shortcut} />

            </Col>

            <Col xs={{ span: 24 }} xl={{ span: 19 }}>
              <Row className="d-flex flex-end">

                <Button type="text" className="user-new" onClick={onClick}>
                  <PlusOutlined />
                  Nuevo grupo
                </Button>



              </Row>
            </Col>
          </Row>

          <Form form={form} onFinish={onFinishFiltering}>
            <Filters
              fields={fields}
              filters={filters}
              collapsed={collapsed}
              onFinishFiltering={onFinishFiltering}
              productStoreFilters={true}
              toggleFilters={toggleFilters}
              resetFilter={resetFilter}
              handleChange={handleChange}
              handleChangeSelect={handleChangeSelect}
              form={form}
            />

            <HorizontalFilter
              fields={fields}
              onFinishFiltering={onFinishFiltering}
              filters={filters}
              handleChange={handleChange}
              resetFilter={resetFilter}
              handleChangeSelect={handleChangeSelect}
              form={form}
            />
          </Form>

          <Row>
            <Col span={24}>
              {/*
              <Table
                dataSource={shortcutState?.shortcuts?.data?.data}
                columns={columns}
                name="usuario"
                path="users"
                onClick={onClick}
                loading={shortcutState.shortcuts.loading}
                pagination={pagination}

              />
      */}
              <SortableTableHook columns={columns} data={shortcutStateData} returOrderData={retunrOrder} />

            </Col>
          </Row>



        </>
      )}
    </>
  );
};

export default Shortcut;
