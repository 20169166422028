import { Form, message, Upload } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanUploadData,
  cleanUploadErrorData,
} from "../actions/upload.action";
import { RootState } from "../store/store";
import { IValidateData } from "../types/modal.types";
import { uploadFileState } from "../types/uploadFile.types";
import { validateCsv } from "../utils/validateCsv";

export const useFileUpload = (props: any) => {
  const [formUpload] = Form.useForm();
  const [uploadFile, fetchEntity, entityState, type, entityCsv, fields] = props;

  const dispatch = useDispatch();
  const uploadFileState: uploadFileState = useSelector(
    (state: RootState) => state.uploadFile
  );
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [file, setFile] = useState("");

  const [validateDataCsv, setValidateDataCsv] = useState<IValidateData[]>([]);

  const resetData = (flag: boolean) => {
    formUpload.resetFields();
    const name = "catalogId";
    const find = fields.find((item: any) => {
      return item.name === name;
    });
    if (find && find.subFilter) {
      find.subFilter("");
    }
    dispatch(cleanUploadData());
    dispatch(cleanUploadErrorData());
    setShowUploadModal(flag);
    setFile("");
    setValidateDataCsv([]);
    setFilter(initialFilter);
  };

  const initialFilter: any = {};

  fields.forEach((field: any) => {
    initialFilter[field.name] = "";
  });

  const [filter, setFilter] = useState(initialFilter);

  const onChange = (name: string, value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
    const find = fields.find((item: any) => {
      return item.name === name;
    });
    if (find && find.subFilter) {
      find.subFilter(value);
    }
  };

  const openUploadModal = () => {
    setFilter(initialFilter);
    resetData(true);
  };

  const closeUploadModal = () => {
    setFilter(initialFilter);
    resetData(false);
  };

  const validateChangeFormat = (valida: string[]) => {
    let data: IValidateData[] = [];
    valida.forEach((item: any) => {
      data.push(JSON.parse(item));
    });
    setValidateDataCsv(data);
    return data;
  };

  const uploadProps = {
    accept: ".csv",
    multiple: false,
    maxCount: 1,
    name: "file",
    onChange: () => {
      dispatch(cleanUploadData());
      dispatch(cleanUploadErrorData());
    },
    beforeUpload: async (file: any) => {
      setValidateDataCsv([]);
      dispatch(cleanUploadErrorData());
      if (
        file.type === "application/vnd.ms-excel" ||
        file.type === "text/csv"
      ) {
        const v = await validateCsv(file, entityCsv);
        if (v.length > 0) {
          message.error("El archivo no posee el formato solicitado.");
        }
        const valida = validateChangeFormat(v);
        if (valida.length > 0) {
          setFile("");
        } else {
          setValidateDataCsv([]);
          setFile(file);
          message.success(
            `El archivo "${file.name}" ya se encuentra listo para ser cargado`
          );
        }
      } else {
        message.error(`Por favor, inserte solo archivos de tipo ".csv"`);
        return Upload.LIST_IGNORE;
      }

      return false;
    },
    onRemove: () => {
      setValidateDataCsv([]);
      setFile("");
    },
  };

  const uploadImage = async (file: any, filter: any = {}) => {
    let isEmptyValue = false;
    Object.keys(filter).forEach((key) => {
      if (filter[key] === "") {
        isEmptyValue = true;
      }
    });
    if (isEmptyValue) {
      message.error(`Por favor, seleccione los datos solicitados.`);
    } else if (!file) {
      message.error(`Por favor, inserte un archivo de tipo ".csv"`);
    } else {
      dispatch(
        uploadFile(
          file,
          type,
          filter,
          fetchEntity,
          entityState,
          closeUploadModal,
          setFile
        )
      );
    }
  };

  return {
    uploadFileState,
    uploadProps,
    uploadImage,
    openUploadModal,
    showUploadModal,
    setShowUploadModal,
    closeUploadModal,
    file,
    setFile,
    validateDataCsv,
    formUpload,
    filter,
    onChange,
  };
};
