import { message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  cleanupBaseProduct,
  cleanUpBaseProductFormData,
  setBaseProductFormData,
} from "../../actions/baseProducts.actions";
import { fetchCatalog } from "../../actions/catalogs.actions";
import {
  fetchStoreProducts,
  removeBarcodeProduct,
} from "../../actions/storeProducts/main.actions";
import { promoteProductStoreByBarcode } from "../../actions/storeProducts/promotion.actions";
import { RootState, useAppDispatch } from "../../store/store";
import { Result } from "../../types/enum/result";
import { storeProductsState } from "../../types/storeProducts.types";

const usePromoteBarCode = () => {
  const storeProductsState: storeProductsState = useSelector(
    (state: RootState) => state.storeProducts
  );
  // Este estado setea la visualización de la tabla que se muestra cuando se van a promocionar
  // productos con código de barra
  const [showProductStoreByBarcodeTable, setShowProductStoreByBarcodeTable] =
    useState(false);
  // Este estado setea el producto de comercio con código de barra seleccionado a ser promovido
  const [
    selectedProductStoreByBarcodeToBePromoted,
    setSelectedProductStoreByBarcodeToBePromoted,
  ] = useState({
    products: [],
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleRowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedProductStoreByBarcodeToBePromoted({ products: selectedRows });
    },
    type: "radio",
  };

  const onClickPromoteToNewBaseProduct = (withBarcode: boolean) => {
    dispatch(cleanUpBaseProductFormData());
    dispatch(cleanupBaseProduct());

    let product: any;

    if (!withBarcode) {
      product = storeProductsState.noBarcodeProducts.data[0];
      product.category = {
        ...product.category[0],
      };

      dispatch(setBaseProductFormData(product));
    } else {
      product = selectedProductStoreByBarcodeToBePromoted.products[0];
      product.category = {
        ...product.category[0],
      };

      dispatch(setBaseProductFormData(product));
    }

    dispatch(fetchCatalog(product.category.catalogId));

    navigate("/products-base/add", {
      state: {
        edit: false,
        withBarcode: withBarcode ? true : false,
      },
    });
  };

  const onClickPromoteWithBarcode = () => {
    setLoading(true);

    const products: any = storeProductsState.barcodeProducts.data;
    let filteredProducts: any = [];
    let arrayOfPromises: any = [];
    let noPromotedProducts: number = 0;

    products.forEach((p: any) => {
      const catalogId = p.category[0].catalogId;
      const barcode = p.barcode;
      const productAlreadyExistInArray =
        filteredProducts.filter(
          (p: any) => p.storeProductBarcode === barcode
        )[0] || false;

      if (productAlreadyExistInArray) {
        if (productAlreadyExistInArray.catalogId === catalogId) {
          dispatch(removeBarcodeProduct(p));
        } else {
          filteredProducts.push({
            catalogId,
            storeProductBarcode: barcode,
          });
        }
      } else {
        filteredProducts.push({
          catalogId,
          storeProductBarcode: barcode,
        });
      }
    });

    storeProductsState.barcodeProducts.data.forEach((p: any) => {
      const catalogId = p.category[0].catalogId;
      const barcode = p.barcode;
      const body = {
        catalogId,
        storeProductBarcode: barcode,
      };

      arrayOfPromises.push(
        dispatch(promoteProductStoreByBarcode(body)).then((result: any) => {
          if (result === Result.OK) {
            message.success(
              `El registro ha sido promocionado con éxito (Código de barra: ${p.barcode})`
            );

            // Producto que se vaya promocionando se remueve de la lista
            // remove by barcode
            dispatch(removeBarcodeProduct(p));
          } else {
            noPromotedProducts = noPromotedProducts++;
            message.error(
              `No se pudo promover el producto (Código de barra: ${p.barcode})`
            );
            setShowProductStoreByBarcodeTable(true);
          }
        })
      );
    });

    Promise.all(arrayOfPromises).then(() => {
      setLoading(false);

      if (noPromotedProducts === 0) {
        dispatch(
          fetchStoreProducts(
            storeProductsState.storeProducts.data.page,
            storeProductsState.storeProducts.data.pageSize
          )
        );
      }
    });
  };

  return {
    loading,
    setLoading,
    showProductStoreByBarcodeTable,
    setShowProductStoreByBarcodeTable,
    onClickPromoteToNewBaseProduct,
    onClickPromoteWithBarcode,
    handleRowSelection,
    selectedProductStoreByBarcodeToBePromoted,
    setSelectedProductStoreByBarcodeToBePromoted,
  };
};
export default usePromoteBarCode;
