import { Button, Input, message, Space, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cleanupCategory, createCategory, deleteCategory, fetchCategories, fetchCategory, fetchPictures, updateCategory } from "../actions/categories.actions";
import { RootState, useAppDispatch } from "../store/store";
import { Result } from "../types/enum/result";
import { categoriesState } from "../types/categories.types";
import { 
    SearchOutlined 
} from '@ant-design/icons';

export const useCategories = (defaultParams: string) => {
    const [showForm, setShowForm] = useState(false);
    const dispatch = useAppDispatch();
    const categoriesState:categoriesState = useSelector((state:RootState) => state.categories);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const pageSize=20
    const [item, setItem] = useState("");
    const [isDeleteCategoriesModalVisible, setIsDeleteCategoriesModalVisible] = useState(false);
    const [selectedCategories, setSelectedCategories]:any = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    let { id } = useParams();

    useEffect(() => {
        dispatch(fetchCategories(1,pageSize, defaultParams));
        dispatch(fetchPictures());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);


    const isBackCatalog = () => {
        if(id){
            return (
                <div className="breamcrumb-content">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                        <Link
                            to="/catalogs"
                        >
                            Catálogos Base
                        </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )
        }
    }
    
    const onFinish = (values:any) => {
        console.log(defaultParams);
        if (!categoriesState.category.data.id) {
            dispatch(createCategory(values)).then((result:any) => {
                if (result === Result.OK) {
                    dispatch(fetchCategories(
                        categoriesState.categories.data.page, 
                        categoriesState.categories.data.pageSize,
                        defaultParams
                    ));
                    message.success(`La categoría "${values.name}" ha sido creada con éxito`);
                    setShowForm(false);
                } else {
                    message.error(categoriesState.category.error.data.message);
                }
            });
        } else {
            dispatch(updateCategory(values, categoriesState.category.data.id)).then((result:any) => {
                if (result === Result.OK) {
                    dispatch(fetchCategories(
                        categoriesState.categories.data.page, 
                        categoriesState.categories.data.pageSize,
                        defaultParams
                    ));
                    message.success(`La categoría "${values.name}" ha sido modificada con éxito`);
                    setShowForm(false);
                } else {
                    message.error(categoriesState.category.error.data.message);
                }
            });
        }
    };

    const handleEdit = (record:any) => {
        setShowForm(true);
        //dispatch(fetchCatalogs());
        dispatch(fetchPictures());
        dispatch(fetchCategory(record.id));
    };

    const onClick = () => {
        dispatch(cleanupCategory());
        //dispatch(fetchCatalogs());
        setShowForm(true);
    };

    const onChangeFetchItems = (page:number, pageSize:number) => {
        dispatch(fetchCategories(page, pageSize,defaultParams))
    };

    // Modal //
    const showModal = (record: any = null) => {
        setIsModalVisible(true);
        setItem(record);
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const hadleOnClickDeleteModal=(item:any) =>{
        setIsModalVisible(false);
        dispatch(deleteCategory(item.id)).then((result:Result)=>{
            if (result===Result.OK) {
                message.success(`La categoría "${item.name}" ha sido eliminada con éxito`);
                dispatch(fetchCategories(categoriesState.categories.data.page, pageSize,defaultParams));
            }
        });
    };
    // Modal //
    
    const showDeleteCategoriesModal = () => {
        setIsDeleteCategoriesModalVisible(!isDeleteCategoriesModalVisible);
    };

    const deleteCategoriesSelected = () => {
        selectedCategories.forEach((el:any) => {
            hadleOnClickDeleteModal(el);
        });

        setSelectedCategories([]);
        showDeleteCategoriesModal();
    };

    // Local filters
    const handleRowSelection = {
        onChange: (selectedRowKeys:any, selectedRows:any) => {
          setSelectedCategories(selectedRows);
          setSelectedRowKeys(selectedRowKeys);
        },
        selectedRowKeys,
        type: "check",
    };

    const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
        confirm();
        setSelectedCategories([]);
        setSelectedRowKeys([]);
    };

    const handleReset = (clearFilters:any) => {
        clearFilters();
        setSelectedCategories([]);
        setSelectedRowKeys([]);
    };

    const getColumnSearchProps = (dataIndex:string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters } : { setSelectedKeys:any, selectedKeys:any, confirm:any, clearFilters:any }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Limpiar
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        confirm({ closeDropdown: false });
                    }}
                >
                    Filtrar
                </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered:any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value:any, record:any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });
    // Local filters

    return {
        categoriesState,
        pagination: {
            current: categoriesState.categories.data.page,
            pageSize: categoriesState.categories.data.pageSize,
            total: categoriesState.categories.data.totalCount,
            onChange: (page:number, pageSize:number) => {
                dispatch(fetchCategories(page, pageSize,defaultParams));
                setSelectedCategories([]);
            }
        },
        onChangeFetchItems,
        handleEdit,
        onClick,
        onFinish,
        showForm,
        setShowForm,
        isModalVisible,
        showModal,
        handleCancel,
        hadleOnClickDeleteModal,
        item,
        selectedCategories,
        handleRowSelection,
        showDeleteCategoriesModal,
        isDeleteCategoriesModalVisible,
        deleteCategoriesSelected,
        getColumnSearchProps,
        isBackCatalog,
    };
};