export const authActionsTypes={
    LOGIN_REQUEST:"LOGIN_REQUEST",
    //LOGIN_SUCCESS:"LOGIN_SUCCESS",
    LOGIN_FAILURE:"LOGIN_FAILURE",

    LOGOUT:"LOGOUT",

    FETCH_AUTH_USER_REQUEST:"FETCH_AUTH_USER_REQUEST",
    FETCH_AUTH_USER_SUCCESS:"FETCH_AUTH_USER_SUCCESS",
    FETCH_AUTH_USER_FAILURE:"FETCH_AUTH_USER_FAILURE",
    LOGIN_SUCCESS:"LOGIN_SUCCESS",
    ADD_USER_LOGGED_IN:"ADD_USER_LOGGED_IN"
}