import { Button, Form, Space } from "antd";
import "./Filters.css";

import SelectFilter from "./SelectFilter";
import { ISelectFilter } from "../../../types/filters.types";
import { Position } from "../../../types/enum/field";

const Filters = (props: any) => {
  const {
    collapsed,
    toggleFilters,
    fields,
    handleChange,
    handleChangeSelect,
    resetFilter,
    filters,
    form,
  } = props;

  return (
    <div className="sider" style={{ width: !collapsed ? "100%" : "0%" }}>
      {fields.map((field: ISelectFilter, key: number) => {
        if (field.position === Position.VERTICAL) {
          return (
            <SelectFilter
              key={key}
              name={field.name}
              type={field.type}
              mode={field.mode}
              values={field.values}
              position={field.position}
              form={form}
              placeholder={field.placeholder}
              handleChange={handleChange}
              handleChangeSelect={handleChangeSelect}
              check={true}
              value={filters[field.name]}
            />
          );
        } else {
          return null;
        }
      })}

      <Form.Item className="filter__button-container">
        <Space>
          <Button onClick={toggleFilters}>Cerrar</Button>
          <Button onClick={() => resetFilter()}>
            Limpiar
          </Button>
          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
        </Space>
      </Form.Item>
    </div>
  );
};

export default Filters;
