import "./HorizontalFilter.css";
import { Button, Col, Row, Form, Space } from "antd";
import SelectFilter from "./SelectFilter";
import { ISelectFilter } from "./../../../types/filters.types";
import { Position } from "../../../types/enum/field";

const HorizontalFilter = (props: any) => {
  const {
    fields,
    handleChange,
    handleChangeSelect,
    resetFilter,
    form,
    filters,
  } = props;

  return (
    <Row gutter={[0, 16]} className="form-horizontal-now">
      <Col span={24}>
        {fields.map((field: ISelectFilter, key: number) => {
          if (field.position === Position.HORIZONTAL) {
            return (
              <SelectFilter
                key={key}
                name={field.name}
                type={field.type}
                mode={field.mode}
                values={field.values}
                position={field.position}
                form={form}
                placeholder={field.placeholder}
                handleChange={handleChange}
                handleChangeSelect={handleChangeSelect}
                check={false}
                value={filters[field.name]}
              />
            );
          } else {
            return null;
          }
        })}
        <Form.Item className="filter__button-container">
          <Space>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
            <Button
              onClick={() => resetFilter()}
            >
              Limpiar
            </Button>
          </Space>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default HorizontalFilter;
