import { FC, useEffect } from "react";
import { Select } from "antd";
import { RootState, useAppDispatch } from "../../../store/store";
import { countriesState } from "../../../types/countries.types";
import { useSelector } from "react-redux";
import { fetchCountriesSelect } from "../../../actions/countries.actions";
import { dataCountry } from "../../../data/countries";

const { Option } = Select;

interface IProps {
  initialValue?: string;
  filterOption?: boolean | any | undefined;
  onChange?: (e: string, options: any) => void;
  disabled?: boolean;
  handleChangeSelect?: any;
  loadLocalData?:any;
}
const CountrySelect: FC<IProps> = (props) => {
  const { initialValue, filterOption, onChange, disabled, handleChangeSelect, loadLocalData=false } =
    props;
    const countriesState:countriesState = useSelector((state:RootState) => state.countries);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if(!countriesState.countriesSelect?.data?.data?.length)
      dispatch(fetchCountriesSelect());
  }, [dispatch,countriesState.countriesSelect?.data?.data]);

  const handleChange = (value: any, event:any) => {
    return handleChangeSelect ? handleChangeSelect("countryId", value,event) : (onChange? onChange(value, event):null);
  };
  
  const countryData = loadLocalData ? dataCountry :countriesState.countriesSelect?.data?.data;
  
  return (
    <Select
      defaultValue={initialValue}
      placeholder="Seleccione un país"
      showSearch
      optionFilterProp="children"
      filterOption={filterOption}
      onChange={handleChange}
      disabled={disabled}
    >
      {countryData?.map((country: any, idx: number) => (
         <Option key={idx} value={country?.id || country.name} label={country.name}>
         {country.name}
       </Option>
     ))}
   </Select>
  );
};
export default CountrySelect;
