import { modulesActionTypes } from "../constants";
import { Action } from "../types/actions.types";

export interface modelesState { 
    modules: string
}

const initialState = {
    modules:"storelive"
};

const modulesReducer = (state = initialState, action:Action):modelesState => {
    switch (action.type) {
        case modulesActionTypes.CHANGES_MODULES:
            return {
                modules: action.payload
            };    
        
        default:
            return  {
                ...state
            };
    }
};

export default modulesReducer;