import { Button, Col, Row, Form } from "antd";
import HelmetTitle from "../other/HelmetTitle";
import { useCategories } from "../../hooks/useCategories";
import CategoriesForm from "./CategoriesForm";
import Table from "../table/customizedTable/CustomizedTable";
import PageTitle from "../other/PageTitle";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import RowGroupModal from "../other/RowGroupModal";
import { columnsData } from './Columns';
import { useColumns } from './../../hooks/useColumns'
import { fields } from "./fields";
import { FilterSection } from "../../types/enum/filters";
import { useFilters } from "../../hooks/useFilters";
import HorizontalFilter from "../shared/filters/HorizontalFilter";
import { fetchCategories } from "../../actions/categories.actions";

const defaultParams = `&catalogId=60d4f60a378aec6831a3f459`;

const Categories = () => {
    const [form] = Form.useForm();

    const {
        filters,
        handleChange,
        handleChangeSelect,
        resetFilter,
        onFinishFiltering,
        queryParams,
    } = useFilters(
        fetchCategories,
        fields,
        FilterSection.CATEGORIES,
        defaultParams,
        form
    );

    const {
        categoriesState,
        pagination,
        handleEdit,
        onClick,
        showForm,
        setShowForm,
        onFinish,
        showModal,
        handleCancel,
        hadleOnClickDeleteModal,
        isModalVisible,
        item,
        selectedCategories,
        handleRowSelection,
        showDeleteCategoriesModal,
        isDeleteCategoriesModalVisible,
        deleteCategoriesSelected,
        getColumnSearchProps,
        isBackCatalog,
    } = useCategories(queryParams);


    const columns = useColumns(
        columnsData({ handleEdit, hadleOnClickDeleteModal, isModalVisible, showModal, handleCancel, item }),
        getColumnSearchProps
    );

    return (
        <>
            {
                isBackCatalog()
            }
            <HelmetTitle title="Categorías - Backoffice D&#38;N" description="Pantalla para listar, crear, y editar categorías" />
            {
                showForm ?
                    <CategoriesForm
                        onFinish={onFinish}
                        setShowForm={setShowForm}
                    />
                    :
                    <>
                        <Row>
                            <Col span={12}>
                                <PageTitle title="Categorías" />
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 12 }} className="d-flex flex-end">
                                <Button
                                    type="text"
                                    disabled={!selectedCategories.length ? true : false}
                                    onClick={showDeleteCategoriesModal}
                                >
                                    <DeleteOutlined />
                                    Eliminar categorías
                                </Button>

                                <Button type="text" onClick={onClick}>
                                    <PlusOutlined />
                                    Nueva categoría
                                </Button>
                            </Col>
                        </Row>
                    
                        <Form form={form} onFinish={onFinishFiltering}>
                            <HorizontalFilter
                                fields={fields}
                                onFinishFiltering={onFinishFiltering}
                                filters={filters}
                                handleChange={handleChange}
                                handleChangeSelect={handleChangeSelect}
                                resetFilter={resetFilter}
                                form={form}
                            />

                        </Form>
              

                        <Row>
                            <Col span={24}>
                                <Table
                                    dataSource={categoriesState.categories.data.data}
                                    columns={columns}
                                    name="categoría"
                                    path="categories"
                                    onClick={onClick}
                                    loading={categoriesState.categories.loading}
                                    pagination={pagination}
                                    handleRowSelection={handleRowSelection}
                                />
                            </Col>
                        </Row>

                        <RowGroupModal
                            actionType="Eliminar"
                            item="categorías"
                            isVisible={isDeleteCategoriesModalVisible}
                            showAction={showDeleteCategoriesModal}
                            actionTrigger={deleteCategoriesSelected}
                        />
                    </>
            }
        </>
    );
};

export default Categories;