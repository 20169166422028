import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react';
import {store,persistor} from './store/store';
import esES from 'antd/lib/locale/es_ES';
import { ConfigProvider } from 'antd';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { themes } from './themes/themes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <Provider store={store}> 
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <ConfigProvider locale={esES}>
          <ThemeSwitcherProvider themeMap={themes} defaultTheme="storelive">
            <App />
            <ToastContainer />
          </ThemeSwitcherProvider>
        </ConfigProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
