import { useKeycloak } from "@react-keycloak/web";
import { FC, useEffect } from "react";
import { addUserInfoLoggeIn } from "../../actions/auth.actions";
import { useAppDispatch } from "../../store/store";

const KeycloakInitialized: FC<any> = ({ children }) => {
  const { initialized, keycloak } = useKeycloak();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (initialized) {
      keycloak
        .loadUserInfo()
        .then((userInfo: any) => dispatch(addUserInfoLoggeIn(userInfo)));
    }
  }, [dispatch,initialized, keycloak]);

  return children;
};
export default KeycloakInitialized;
