import { FC, useState, useEffect } from "react";
import { Select } from "antd";
const { Option } = Select;

type mode = "multiple" | undefined;

interface IProps {
  initialValue?: string;
  values: any;
  filterOption?: boolean | any | undefined;
  disabled?: boolean | false;
  mode?: mode;
  placeholder: string;
  handleChange?: any;
  form?: any;
  name: string;
  onChange?: any;
}

const GeneralSelect: FC<IProps> = (props) => {
  const {
    initialValue,
    values,
    filterOption,
    disabled,
    mode,
    placeholder,
    onChange,
    name,
  } = props;

  const [newValues, setNewValues] = useState([]);

  const isPromise = (promise: any) =>
    !!promise && typeof promise.then === "function";

  useEffect(() => {
    if (values && isPromise(values)) {
      values
        .then((item: any) => {
          if (item) {
            setNewValues(item);
          }
        })
        .catch((e: any) => setNewValues([]));
    } else {
      setNewValues(values);
    }
  }, [values]);

  return (
    <Select
      defaultValue={initialValue}
      placeholder={placeholder}
      mode={mode ?? undefined}
      showSearch
      optionFilterProp="children"
      onChange={(value) => onChange(name, value)}
      filterOption={filterOption}
      disabled={disabled}
    >
      {newValues.length > 0
        ? newValues.map((value: any, idx: number) => (
            <Option key={idx} value={value.value} label={value.name}>
              {value.label ? value.label : value.name}
            </Option>
          ))
        : null}
    </Select>
  );
};
export default GeneralSelect;
