import { catalogFilesTypes } from "../constants/index";
import { catalogFilesState, IData } from "../types/catalogFiles.types";
import { Action } from "../types/actions.types";
import { ICatalog } from "../types/interfaces/catalog.interface";
import { IError } from "../types/confPowerBI.types";

let error: IError = { status: "", message: "" };
let catalog: ICatalog[] = [];

interface ICatalogFile {
    _id: string;
    key?:number
    catalogId: string;
    fileName: string;
    syncDate: string;
    createdAt: string;
    updatedAt: string;
  }

const catalogFile: ICatalogFile[] = [{
    _id: "",
    key:0,
    catalogId: "",
    fileName: "",
    syncDate: "",
    createdAt: "",
    updatedAt: "",
  }];
const data:IData ={
    data:catalogFile,
    page:0,
    pageSize:0,
    totalCount:0
}

const initialState = {
  catalogList: {
    loading: false,
    data: catalog,
    error: error,
  },
  catalogFiles: {
    loading: false,
    data: data,
    error: error,
  },
  generateCatalog: {
    loading: false,
    data: [],
    error: error,
  },
};

const catalogfFilesReducer = (
  state = initialState,
  action: Action
): catalogFilesState => {
  switch (action.type) {
    case catalogFilesTypes.FETCH_CATALOGLIST_REQUEST:
      return {
        ...state,
        catalogList: {
          ...initialState.catalogList,
          loading: true,
        },
      };
    case catalogFilesTypes.FETCH_CATALOGLIST_SUCCESS:
      return {
        ...state,
        catalogList: {
          loading: false,
          data: action.payload,
          error: error,
        },
      };
    case catalogFilesTypes.FETCH_CATALOGLIST_FAILURE:
      return {
        ...state,
        catalogList: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case catalogFilesTypes.FETCH_CATALOGFILES_REQUEST:
      return {
        ...state,
        catalogFiles: {
          ...initialState.catalogFiles,
          loading: true,
        },
      };
    case catalogFilesTypes.FETCH_CATALOGFILES_SUCCESS:
      const dataCatalogFile:ICatalogFile[] = [...action.payload.data];
      dataCatalogFile.forEach((item:ICatalogFile, idx:number) => {
                    item.key=idx+1;
                });
      return {
        ...state,
        catalogFiles: {
          loading: false,
          data: action.payload,
          error: error,
        },
      };
    case catalogFilesTypes.FETCH_CATALOGFILES_FAILURE:
      return {
        ...state,
        catalogFiles: {
          loading: false,
          data: data,
          error: action.payload,
        },
      };
    case catalogFilesTypes.GENERATE_CATALOG_REQUEST:
      return {
        ...state,
        generateCatalog: {
          ...initialState.generateCatalog,
          loading: true,
        },
      };
    case catalogFilesTypes.GENERATE_CATALOG_SUCCESS:
      return {
        ...state,
        generateCatalog: {
          loading: false,
          data: action.payload,
          error: error,
        },
      };
    case catalogFilesTypes.GENERATE_CATALOG_FAILURE:
      return {
        ...state,
        generateCatalog: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default catalogfFilesReducer;
