import { Layout as LayoutAntd } from "antd";
import "./Layout.css";
import { FC, useState } from "react";
import HeaderApp from "./Header/Header";
import SidebarMenu from "./Sider/SidebarMenu";

const { Content } = LayoutAntd;

interface LayoutProps {
  children: any;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <LayoutAntd>
      <HeaderApp toggleCollapsed={toggleCollapsed} />
      <LayoutAntd>
        <SidebarMenu
          isCollapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <Content className="container">{children}</Content>
      </LayoutAntd>
    </LayoutAntd>
  );
};

export default Layout;
