import { Modal } from "antd";
import useCreateProductBase from "../../../hooks/useCreateProductBase";

import { ProductStoreEnum } from "../../../types/enum/productStoreFields";
import Loading from "../../loading/loading";
import ProductForm from "../../shared/forms/productForm/ProductForm";


export interface IProp {
    fieldExceptions?: string[] | any;
    dataForm?: any,
    openModal: boolean,
    handleCloseModal: Function,
    createPodrucAddResult:Function,
}

const CreateProductBaseModal = (props: IProp) => {
    const { fieldExceptions, dataForm, openModal, handleCloseModal, createPodrucAddResult } = props;
    const disableFields = [ProductStoreEnum.CatalogId, ProductStoreEnum.CategoryId]
    const {
        formInstance,
        onFinishProductBaseForm,
        openLoading
    } = useCreateProductBase(props);

    return (
        <>
            <Loading open={openLoading} />
            <Modal bodyStyle={{ padding: 5 }}
                title="Crear Producto"
                style={{ top: 10, border: 0 }}
                visible={openModal}
                onOk={() => handleCloseModal()}
                onCancel={() => handleCloseModal()}
                width={'80%'}
                footer={null}
                forceRender
            >
                <ProductForm
                    form={formInstance}
                    data={dataForm}
                    disableFields={disableFields}
                    onFinish={(values) => onFinishProductBaseForm(values)
                    }
                    submitButton={{ title: "Guardar" }}
                    cancelButton={{
                        title: "Cancelar",
                        onClick: () => handleCloseModal(),
                        disabled: false,
                    }}
                    resetButton={{ title: "Limpiar", disabled: true }}
                    fieldExceptions={fieldExceptions}
                />
            </Modal>
        </>
    );
};
export default CreateProductBaseModal;
