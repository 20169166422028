import { Layout as LayoutAntd } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "./SidebarMenu.css";
import MenuComponent from "./Menu";
import ModuleSelector from "../ModuleSelector";

const { Sider } = LayoutAntd;

const SidebarMenu = (props:any) => {
    const [currentPage, setCurrentPage] = useState('');
    const [showSider, setShowSider] = useState(true);
    const { isCollapsed, toggleCollapsed } = props;

    const location = useLocation();
    const locationPath = location.pathname;

    useEffect(() => {
        setCurrentPage(locationPath);
    }, [locationPath]);

    return (
        <>
            {
                showSider ?
                <Sider
                    collapsible={true}
                    trigger={null}
                    collapsed={isCollapsed}
                    breakpoint="sm"
                    onBreakpoint={(broken:any) => {
                        if (broken) {
                            setShowSider(false);
                        }
                    }}
                >
                    <MenuComponent 
                        currentPage={currentPage}
                    />
                </Sider>
                :
                <div 
                    className="mobile-menu" 
                    style={{ 
                        width: isCollapsed ? '0' : '100%',
                        
                    }}
                >
                    <a className="closebtn" onClick={toggleCollapsed}>&times;</a>
                    <ModuleSelector />
                    <MenuComponent 
                        currentPage={currentPage}
                        toggleCollapsed={toggleCollapsed}
                    />
                </div>
            }
        </>
    );
};

export default SidebarMenu;