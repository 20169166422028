import { ICatalog, catalogsAction } from '../types/catalogs.types';
import { catalogsActionTypes } from "../constants/index";
import { IError } from "../types/interfaces/error.interface";
import { ThunkAction } from 'redux-thunk';
import { AppDispatch, RootState } from '../store/store';
import { ActionCreator } from "redux";
import instance from '../utils/axios.instance';
import { Result } from '../types/enum/result';
import { IResponse } from '../types/interfaces/response.interface';

/*********************FETCH CATALOGS ACTIONS************************************/
export const fetchCatalogsRequest = () => {
    return {
        type: catalogsActionTypes.FETCH_CATALOGS_REQUEST
    };
};

export const fetchCatalogsSuccess = (data: any) => {
    return {
        type: catalogsActionTypes.FETCH_CATALOGS_SUCCESS,
        payload: data
    };
};

export const fetchCatalogsFailure = (error: IError) => {
    return {
        type: catalogsActionTypes.FETCH_CATALOGS_FAILURE,
        payload: error
    };
};
/*export const updateCatalogCreate = (data: any) => {
    return {
        type: catalogsActionTypes.ADD_CATALOG_CREATE,
        payload: data
    };
};
*/
/*********************CREATE CATALOG ACTIONS************************************/
export const createCatalogRequest = () => {
    return {
        type: catalogsActionTypes.CREATE_CATALOG_REQUEST,
    };
};

export const createCatalogSuccess = (data: ICatalog) => {
    return {
        type: catalogsActionTypes.CREATE_CATALOG_SUCCESS,
        payload: data
    };
};

export const createCatalogFailure = (error: IError) => {
    return {
        type: catalogsActionTypes.CREATE_CATALOG_FAILURE,
        payload: error
    };
};

/*********************FETCH CATALOG ACTIONS************************************/
export const fetchCatalogRequest = () => {
    return {
        type: catalogsActionTypes.FETCH_CATALOG_REQUEST
    };
};

export const fetchCatalogSuccess = (data: any) => {
    return {
        type: catalogsActionTypes.FETCH_CATALOG_SUCCESS,
        payload: data
    };
};

export const fetchCatalogFailure = (error: IError) => {
    return {
        type: catalogsActionTypes.FETCH_CATALOG_FAILURE,
        payload: error
    };
};

/*********************CLEAN UP COUNTRY ACTION************************************/
export const cleanUpCatalogData = () => {
    return {
        type: catalogsActionTypes.CATALOG_CLEAN_UP
    }
}

/****************************************************************************/
export const fetchCatalogs: ActionCreator<ThunkAction<void, RootState, null, catalogsAction>> = (currentPage: any = 1, pageSize: any = 1000) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchCatalogsRequest());
            const response = await instance.get(`/api/catalogs?page=${currentPage}&pageSize=${pageSize}`);
            dispatch(fetchCatalogsSuccess(response.data));
        } catch (error: any) {
            if (error.response) {
                const data: IError = {
                    status: error.response.status,
                    data: {
                        timestamp: error.response?.data?.timestamp,
                        path: error.response?.data?.path,
                        message: error.response?.data?.message
                    }
                };
                dispatch(fetchCatalogsFailure(data));
            }
        }
    };

export const fetchCatalog: ActionCreator<ThunkAction<void, RootState, null, catalogsAction>> = (id: string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchCatalogRequest());
            const response = await instance.get(`/api/catalogs/${id}`);
            dispatch(fetchCatalogSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error?.response?.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchCatalogFailure(data));

            return Result.ERROR;
        }
    };

export const cleanupCatalog = () => {
    return (dispatch: AppDispatch) => {
        dispatch(cleanUpCatalogData());
    }
};

export const createCatalogs:ActionCreator<ThunkAction<void, RootState, null, catalogsAction>> = (data:any)=>
     async (dispatch:AppDispatch)=>{
        try{
            dispatch(createCatalogRequest());
            const response = await instance.post(`/api/catalogs`, data)
            dispatch(createCatalogSuccess(response.data))
            const result: IResponse = {status:Result.OK}
            return result;
        }catch(error:any){
            const result:IError= {status:Result.ERROR, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}}
            dispatch(createCatalogFailure(result));
            return result;
      };
    };