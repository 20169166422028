import { Button, Col } from "antd";
import PageTitle from "../../../../other/PageTitle";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ProductType } from "../../../../../types/enum/modalType";
import TableComponente from "../../../../table/customizedTable/CustomizedTable";
import { Columns } from "../Columns";

const PromotionProducts = (props:any) => {
    const columns = Columns();

    return (
        <>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <PageTitle title="Productos de tienda" />
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }} className="d-flex flex-end">
                <Button
                    type="primary"
                    onClick={() => props.handleShowAddProductsModal(ProductType.STORE)}
                >
                    <PlusCircleOutlined />
                    Productos Tienda
                </Button>
            </Col>

            <Col span={24} className="table-container-de">
                <TableComponente
                    dataSource={props.promotionProducts}
                    columns={columns}
                    name="producto de tienda"
                    title={null}
                    path="products-store"
                    handleRowSelection={props.handleRowSelectionPromotionProducts}
                />
            </Col>
        </>
    )
};

export default PromotionProducts;