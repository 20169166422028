import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchUsers } from "../actions/users.actions";
import { RootState, useAppDispatch } from "../store/store";
import { fetchShortcut } from "../actions/shortcut.actions";
import { updateShortcutOrder } from "../services/Shortcut";
import { message } from "antd";

export const defaultParams = `&catalogId=60d4f60a378aec6831a3f459`;


export const useShortcut = (queryParams: string) => {
  const shortcutState: any = useSelector((state: RootState) => state.shortcurt);

  const [showForm, setShowForm] = useState(false);
  const [dataShortchut, setDataShortchut] = useState({});
  const dispatch = useAppDispatch();
  const [shortcutStateData, setShortcutStateData] = useState(shortcutState?.shortcuts?.data?.data)

  const dateObject = new Date();
  const date = `${dateObject.getFullYear()}_${dateObject.getMonth() + 1
    }_${dateObject.getDate()}`;

  useEffect(() => {
    setShortcutStateData(shortcutState?.shortcuts?.data?.data)
  }, [shortcutState])

  useEffect(() => {
    dispatch(fetchShortcut(1, 20, defaultParams));
  }, [dispatch]);


  const handleEdit = (item: any) => {
    setShowForm(true);
    setDataShortchut(item)
  };

  const onClick = () => {
    setShowForm(true)
    setDataShortchut({})
  };

  const onChangeFetchItems = (page: number, pageSize: number) => {
    console.log("onChangeFetchItems", "dimos click")
  };

  const retunrOrder = (item: any, oldIndex: number, newIndex: number) => {
    let numberNewItemOne = newIndex === 0 ? newIndex : item[newIndex - 1].order 
    let numberNewItemTwo = item[newIndex + 1]?.order
    if(item.length === newIndex +1){
      numberNewItemOne = item[newIndex - 1].order
      numberNewItemTwo = item[newIndex - 1].order + 1
    }
    const newOrder = (numberNewItemTwo + numberNewItemOne) / 2
    item[newIndex].order = newOrder
    setShortcutStateData(item)
    updateShortcutOrder(item[newIndex].id, {order: newOrder}).catch(error =>{
      message.error("Hemos tenido problemas con actualización del orden, intenta de nuevo")
    })
  }

  return {
    shortcutState,
    retunrOrder,
    pagination: {
      current: shortcutState.shortcuts?.data.page,
      pageSize: shortcutState.shortcuts?.data.pageSize,
      total: shortcutState.shortcuts?.data.totalCount,
      onChange: (page: number, pageSize: number) => {
        dispatch(fetchUsers(page, pageSize, queryParams));

      },
    },
    onChangeFetchItems,
    handleEdit,
    onClick,
    showForm,
    setShowForm,
    date,
    dataShortchut,
    shortcutStateData
  };
};
