import { uploadActionTypes } from "../constants/index"
import { Action } from "../types/actions.types"
import { IError } from "../types/interfaces/error.interface"


let error: IError = { status: "", data: { timestamp: '', path: '', message: '' } }
const initialState = {
    upload: {
        loading: false,
        upload: {
            success: [],
            failed: []
        },
        error: error
    },
    errorData: {
        loading: false,
        data: [],
    }
}

const uploadReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case uploadActionTypes.UPLOAD_ERROR_DATA:
            return {
                ...state,
                errorData: {
                    ...state.errorData,
                    data: action.payload,
                    loading: false
                }
            }
        case uploadActionTypes.CLEAN_UPLOAD_ERROR_DATA:
            return {
                ...state,
                errorData: {
                    ...state.errorData,
                    data: [],
                    loading: false
                }
            }
        case uploadActionTypes.UPLOAD_FILE_REQUEST:
            return {
                ...state,
                upload: {
                    ...state.upload,
                    loading: true
                }
            }
        case uploadActionTypes.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                upload: {
                    loading: false,
                    upload: action.payload,
                    error: {}
                }
            }
        case uploadActionTypes.CLEAN_UPLOAD_DATA:
            return {
                ...state,
                upload: {
                    loading: false,
                    upload: {},
                    error: error
                }
            }
        case uploadActionTypes.UPLOAD_FILE_FAILURE:
            return {
                ...state,
                upload: {
                    loading: false,
                    upload: {},
                    error: action.payload
                }
            }
        default:
            return state;
    }
}

export default uploadReducer;