import { Button, Modal, Table } from 'antd';
import { Link } from 'react-router-dom';

const NoPromotableResumeModal = (props:any) => {
  return (
    <Modal
        title="Resumen" 
        visible={props.showResumeModal} 
        closable={false}
        maskClosable={false}
        footer={[
            <Button>
                <Link to="/products-store-new">
                    Cerrar
                </Link>
            </Button>
        ]}
    >
        <p>El estatus de los productos no promovibles es el siguiente:</p>
        <Table 
            dataSource={
                props.dataSource &&
                props.dataSource.map((el:any) => {
                    const productFiltered = props.noPromotableProducts.filter((obj:any) => obj.id === el.storeProductId);
                    productFiltered[0].success = el.success;

                    return productFiltered[0];
                })
            } 
            columns={props.noPromotableResumeColumns}
        />
    </Modal>
  )
};

export default NoPromotableResumeModal;