import { useState } from "react";
import {
    cleanupBaseProductRelated,
  cleanupBaseProductsRelated,
  cleanupSimilarProduct,
  cleanupSimilarProducts,
} from "../../actions/storeProducts/cleanup.actions";
import {
  fetchBaseProductsRelated,
  fetchSimilarProducts,
} from "../../actions/storeProducts/promotion.actions";
import { useAppDispatch } from "../../store/store";

interface IProps {
  setBaseProductSelected: React.Dispatch<
    React.SetStateAction<{
      barcode: string;
      id: string;
    }>
  >;
  catalogId: string;
  baseProductSelected:{
        barcode: string;
        id: string;
      }
    | any;
}

const usePromotionProductsTable = (props: IProps) => {
  const { setBaseProductSelected, catalogId, baseProductSelected } = props;
  const [selectedRowKeysState, setSelectedRowKeysState]: any = useState([]);
  const dispatch = useAppDispatch();

  
  // Promotion products table
  const handleRowSelectionPromotionProducts = {
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      if (selectedRows.length) {
        selectedRows.forEach((row: any) => {
          if (!row && row.barcode && row.barcode === "") {
            return;
          }

          setBaseProductSelected({
            barcode: "",
            id: "",
          });

          if (row.barcode !== "") {
            dispatch(
              fetchSimilarProducts(
                1,
                1000000000,
                [`&catalogId=${catalogId}&barcode=${row.barcode}`],
                row.id,
                selectedRows
              )
            );
          }
        });

        const barcodes = selectedRows.map((obj: any) => obj.barcode);
        const filterBarcodes = barcodes.filter(
          (barcode: string) => barcode !== ""
        );
        dispatch(fetchBaseProductsRelated(catalogId, filterBarcodes.join()));
      }

      if (!selected) {
        setBaseProductSelected({
          barcode: "",
          id: "",
        });

        dispatch(cleanupBaseProductsRelated());
        dispatch(cleanupSimilarProducts());
      }
    },
    onSelect: (
      record: any,
      selected: any,
      selectedRows: any,
      nativeEvent: any
    ) => {
      if (selected && record.barcode) {
        setBaseProductSelected({
          barcode: "",
          id: "",
        });

        if (record.barcode !== "") {
          dispatch(
            fetchSimilarProducts(
              1,
              1000000000,
              [`&catalogId=${catalogId}&barcode=${record.barcode}`],
              record.id,
              selectedRows
            )
          );
        }

        const barcodes = selectedRows.map((obj: any) => obj.barcode);
        const filterBarcodes = barcodes.filter(
          (barcode: string) => barcode !== ""
        );
        dispatch(fetchBaseProductsRelated(catalogId, filterBarcodes.join()));
      } else {
        if (record.barcode) {
          if (baseProductSelected.barcode === record.barcode) {
            setBaseProductSelected({
              barcode: "",
              id: "",
            });
          }
          dispatch(cleanupBaseProductRelated(record.barcode));
        }

        dispatch(cleanupSimilarProduct(record.barcode));
      }
    },
    type: "checkbox",
    onChange: (selectedRowKeys: any) => {
      setSelectedRowKeysState(selectedRowKeys);
    },
    selectedRowKeys: selectedRowKeysState,
  };
  // Promotion products table

  return { handleRowSelectionPromotionProducts,selectedRowKeysState, setSelectedRowKeysState };
};
export default usePromotionProductsTable;
