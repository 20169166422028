

import { Col, Row, Button } from "antd";
import HelmetTitle from "../other/HelmetTitle";
import { useCatalogs } from "../../hooks/useCatalogs";
import Table from "../table/customizedTable/CustomizedTable";
import PageTitle from "../other/PageTitle";
import { columnsData } from './Columns';
import { useColumns } from './../../hooks/useColumns'
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import CatalogForm from "./CatalogsForm";

const Catalogs = () => {
    const {
        catalogsState,
        pagination,
        getColumnSearchProps,
        handleActionCategories,
        showForm,
        setShowForm
    } = useCatalogs();


    const columns = useColumns(
        columnsData({ handleActionCategories }),
        getColumnSearchProps
    );

    return (
        <>
            <HelmetTitle title="Catálogos Base - Backoffice D&#38;N" description="Pantalla para listar, crear, y editar catálogos base" />

            {showForm ?
                <CatalogForm setShowForm={setShowForm} />
                :
                <>
                    <Row>
                        <Col span={12} className="d-flex space-between">
                            <PageTitle title="Catálogos Base" />
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="d-flex flex-end">
                            <Button type="text" onClick={() => setShowForm(true)}>
                                <PlusOutlined />
                                Nuevo Catálogo
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Table
                                dataSource={catalogsState.catalogs.data.data}
                                columns={columns}
                                name="catalogos"
                                path="catalogs"
                                loading={catalogsState.catalogs.loading}
                                pagination={pagination}
                            />
                        </Col>
                    </Row>
                </>
            }


        </>
    );
};

export default Catalogs;