export enum NotificationTypes {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    NO_PROMOTE = 'NO_PROMOTE',
    NO_CATALOG_VALID = 'NO_CATALOG_VALID',
    NO_COUNTRY_VALID = 'NO_COUNTRY_VALID',
    MAIL_EXIST = 'MAIL_EXIST',
    USERNAME_EXIST = 'USERNAME_EXIST',
    MAIL_OR_USERNAME_EXIST = 'MAIL_OR_USERNAME_EXIST',
    NO_ROLE_VALID = 'NO_ROLE_VALID',
}  