import { Button, Modal } from "antd"

const RowGroupModal = (props:any) => {
    const {
        actionType,
        item,
        isVisible,
        showAction,
        actionTrigger,
    } = props;

    const actionTypeLowerCase = actionType.toLowerCase();

    return (
        <Modal
            title={`${actionType} grupo de ${item}`}
            visible={isVisible}
            onCancel={showAction}
            footer={[
            <Button 
                key="cancel" 
                onClick={showAction} 
                type="primary"
            >
                Cancelar
            </Button>,
            <Button 
                key={actionTypeLowerCase}
                onClick={actionTrigger} 
            >
                {actionType}
            </Button>,
            ]}
            mask={false}
            maskStyle={{ backgroundColor: "#00000073" }}
        >
            <p>¿Esta seguro que desea {actionTypeLowerCase} el grupo de {item} seleccionado?</p>
        </Modal>
    );
}

export default RowGroupModal;