import { FC, useEffect } from "react";
import { Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { useSelector } from "react-redux";

import { fetchCatalogs } from "../../../actions/catalogFiles.action";
import { RootState, useAppDispatch } from "../../../store/store";
import { catalogFilesState } from "../../../types/catalogFiles.types";
const { Option } = Select;

type mode = "multiple" | undefined;

interface IProps {
  initialValue?: string;
  onChange?: (value: SelectValue, option: any) => void;
  filterOption?: boolean | any | undefined;
  mode?: mode;
  disabled?: boolean | false;
  handleChangeSelect?: any;
}

const CatalogFilesSelect: FC<IProps> = (props) => {
  const { onChange, filterOption, initialValue, mode, disabled, handleChangeSelect } = props;
  const catalogFilesState: catalogFilesState = useSelector(
    (state: RootState) => state.catalogFiles
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCatalogs());
  }, [dispatch]);

  const handleChange = (value: any, event:any) => {
    return handleChangeSelect ? handleChangeSelect("catalogId", value,event) : (onChange? onChange("catalogId", value): null);
  };

  return (
    <Select
      disabled={disabled}
      defaultValue={initialValue}
      placeholder="Seleccione un catálogo"
      mode={mode ?? undefined}
      showSearch
      optionFilterProp="children"
      filterOption={filterOption}
      onChange={handleChange}
    >
      {catalogFilesState.catalogList.data.map((catalog: any, idx: number) => (
        <Option key={idx} value={catalog.id} label={catalog.name}>
          {catalog.name}
        </Option>
      ))}
    </Select>
  );
};
export default CatalogFilesSelect;
