
export const dataCountry = [
    {
        "name": "Argentina",
        "code": "ARG",
        "currency": "Peso argentino",
        "currencyCode": "ARS",
        "timezone": "America/Buenos_Aires"
    },

    {
        "name": "Brasil",
        "code": "BRA",
        "currency": "Real brasileño",
        "currencyCode": "BRL",
        "timezone": "Brazil/East"
    },
    {
        "name": "Bolivia",
        "code": "BOL",
        "currency": "Boliviano",
        "currencyCode": "BOB",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Colombia",
        "code": "COL",
        "currency": "Peso colombiano",
        "currencyCode": "COP",
        "timezone": "America/Bogota"
    },
    {
        "name": "Costa Rica",
        "code": "CRI",
        "currency": "Colón costarricense",
        "currencyCode": "CRC",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Cuba",
        "code": "CUB",
        "currency": "Peso cubano",
        "currencyCode": "CUP",
        "timezone": "Cuba"
    },
    {
        "name": "Chile",
        "code": "CHL",
        "currency": "Peso chileno",
        "currencyCode": "CLP",
        "timezone": "America/Santiago"
    },
    {
        "name": "Ecuador",
        "code": "ECU",
        "currency": "Dólar americano",
        "currencyCode": "USD",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "El Salvador",
        "code": "SLV",
        "currency": "Colón",
        "currencyCode": "SVC",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "Guatemala",
        "code": "GTM",
        "currency": "Quetzal",
        "currencyCode": "GTQ",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Honduras",
        "code": "HND",
        "currency": "Lempira hondureño",
        "currencyCode": "HNL",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "México",
        "code": "MEX",
        "currency": "Peso mexicano",
        "currencyCode": "MXN",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Nicaragua",
        "code": "NIC",
        "currency": "Córdoba",
        "currencyCode": "NIO",
        "timezone": "America/Managua"
    },
    {
        "name": "Paraguay",
        "code": "PRY",
        "currency": "guaraní",
        "currencyCode": "PYG",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Panamá",
        "code": "PAN",
        "currency": "Dólar americano",
        "currencyCode": "USD",
        "timezone": "America/Panama"
    },

    {
        "name": "Perú",
        "code": "PER",
        "currency": "Sol peruano",
        "currencyCode": "PEN",
        "timezone": "America/Lima"
    },
    {
        "name": "Puerto Rico",
        "code": "PRI",
        "currency": "Dólar americano",
        "currencyCode": "USD",
        "timezone": "America/Puerto_Rico"
    },
    {
        "name": "República Dominicana",
        "code": "DOM",
        "currency": "Dólar americano",
        "currencyCode": "USD",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Uruguay",
        "code": "URY",
        "currency": "Peso uruguayo",
        "currencyCode": "UYU",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Venezuela",
        "code": "VEN",
        "currency": "Bolívar",
        "currencyCode": "VED",
        "timezone": "America/Caracas"
    },
]


export const dataCurrency = () => {
    const dataAll =[]
    const data = dataCountry.map(item => {
        if(!dataAll.find(itemF => itemF.name === item.currency)){
            dataAll.push(
                {
                    name: item.currency,
                    value: item.currencyCode
                }
            )
        }
    
    })
    return dataAll;
}

