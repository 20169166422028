import { useState } from "react"
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SalesByCountry } from "../../services/Statistics/SalesByCountry";



const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#0d8531", "#76A521", "#8884d8"];

export const useSalesByCountry = () =>{
    const [dataDate,setDataDate] = useState({
        initDate:null,
        endDate:null
    })
    const [dataCharts, setDataCharts] = useState([])
    const [openLoading, setOpenLoading] = useState(false)
    const findDataCountry = async () => {
        if(!dataDate.endDate || !dataDate.initDate){
            toast.error("Debes seleccionar un rango de fecha");
            return;
        }
        await setOpenLoading(true)
        const dataApi = await SalesByCountry(dataDate.initDate, dataDate.endDate )
        setDataCharts(dataApi.data)
        await setOpenLoading(false)
    }

    const onChangesDate = (dataInit: any, dataEnd: any) =>{
        setDataDate({initDate :dataInit , endDate: dataEnd})
    }
    const totalTicket = () =>{
        return dataCharts.reduce((accumulator, item: any) => {
            return accumulator + item.total;
        }, 0);
    }
    return {findDataCountry, onChangesDate, dataCharts, COLORS, openLoading, totalTicket}
}

