import { IBaseProduct, baseProductsAction } from '../types/baseProducts.types';
import { baseProductsActionTypes } from "../constants/index";
import { IError } from "../types/interfaces/error.interface";
import { ThunkAction } from 'redux-thunk';
import { AppDispatch, RootState } from '../store/store';
import { ActionCreator } from "redux";
import instance from '../utils/axios.instance';
import { Result } from '../types/enum/result';  

/*********************FETCH BASE_PRODUCTS ACTIONS************************************/
export const fetchBaseProductsRequest = () => {
    return {
        type: baseProductsActionTypes.FETCH_BASE_PRODUCTS_REQUEST
    };
};

export const fetchBaseProductsSuccess = (data:any) => {
    return {
        type: baseProductsActionTypes.FETCH_BASE_PRODUCTS_SUCCESS,
        payload: data
    };
};

export const fetchBaseProductsFailure = (error:IError) => {
    return {
        type: baseProductsActionTypes.FETCH_BASE_PRODUCTS_FAILURE,
        payload: error
    };
};

/*********************FETCH BASE_PRODUCT ACTIONS************************************/
export const fetchBaseProductRequest = () => {
    return {
        type: baseProductsActionTypes.FETCH_BASE_PRODUCT_REQUEST
    };
};

export const fetchBaseProductSuccess = (data:any) => {
    return {
        type: baseProductsActionTypes.FETCH_BASE_PRODUCT_SUCCESS,
        payload: data
    };
};

export const fetchBaseProductFailure = (error:IError) => {
    return {
        type: baseProductsActionTypes.FETCH_BASE_PRODUCT_FAILURE,
        payload: error
    };
};

/*********************CREATE BASE_PRODUCT ACTION************************************/
export const createBaseProductRequest = () => {
    return {
        type: baseProductsActionTypes.CREATE_BASE_PRODUCT_REQUEST
    };
};

export const createBaseProductSuccess = (data:IBaseProduct) => {
    return {
        type: baseProductsActionTypes.CREATE_BASE_PRODUCT_SUCCESS,
        payload: data
    };
};

export const createBaseProductFailure = (error:IError) => {
    return {
        type: baseProductsActionTypes.CREATE_BASE_PRODUCT_FAILURE,
        payload: error
    };
};

/*********************UPDATE BASE_PRODUCT ACTION************************************/
export const updateBaseProductRequest = () => {
    return {
        type: baseProductsActionTypes.UPDATE_BASE_PRODUCT_REQUEST
    };
};

export const updateBaseProductSuccess = (data:IBaseProduct) => {
    return {
        type: baseProductsActionTypes.UPDATE_BASE_PRODUCT_SUCCESS,
        payload: data
    };
};

export const updateBaseProductFailure = (error:IError) => {
    return {
        type: baseProductsActionTypes.UPDATE_BASE_PRODUCT_FAILURE,
        payload: error
    };
};

export const setBaseProductFormData = (data:IBaseProduct) => {
    return {
        type: baseProductsActionTypes.SET_BASE_PRODUCT_FORM_DATA,
        payload: data
    };
};

export const cleanUpBaseProductFormData = () => {
    return {
        type: baseProductsActionTypes.CLEAN_UP_BASE_PRODUCT_FORM_DATA,
    };
};

/*********************CLEAN UP COUNTRY ACTION************************************/
export const cleanUpBaseProductData = () =>{
    return{
        type: baseProductsActionTypes.BASE_PRODUCT_CLEAN_UP
    }
}
/****************************************************************************/
export const fetchBaseProducts:ActionCreator<ThunkAction<void, RootState, null, baseProductsAction>> = (currentPage:any = 1, pageSize:any = 20,params:string = '') => 
    async (dispatch:AppDispatch) => {
        try {
            dispatch(fetchBaseProductsRequest());
            const response = await instance.get(`/api/products/base?page=${currentPage}&pageSize=${pageSize}${params}`);
            dispatch(fetchBaseProductsSuccess(response.data));
        } catch (error:any) {
            if(error?.response?.status === 403){
                
            }
            const data: IError = {
                status: error?.response?.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchBaseProductsFailure(data));
        }
    };

export const createBaseProduct:ActionCreator<ThunkAction<void, RootState, null, baseProductsAction>> = (body:any) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(createBaseProductRequest());
            const response = await instance.post(`/api/products/base`, body);
            dispatch(createBaseProductSuccess(response.data));

            return response;
        } catch (error: any) {
            const data: IError = {
                status: error?.response?.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(createBaseProductFailure(data));

            return {error:Result.ERROR, dataError: error?.response};
        }
    };

export const updateBaseProduct:ActionCreator<ThunkAction<void, RootState, null, baseProductsAction>> = (body:any, id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(updateBaseProductRequest());
            const response = await instance.put(`/api/products/base/${id}`, body);
            dispatch(updateBaseProductSuccess(response.data));
            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error?.response?.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(updateBaseProductFailure(data));
            return Result.ERROR;
        }
    };

export const fetchBaseProduct:ActionCreator<ThunkAction<void, RootState, null, baseProductsAction>> = (id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchBaseProductRequest());
            const response = await instance.get(`/api/products/base/${id}`);
            dispatch(fetchBaseProductSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error?.response?.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchBaseProductFailure(data));

            return Result.ERROR;
        }
    };

export const cleanupBaseProduct = () => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpBaseProductData());
    }
};