

import { useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";

export const useThemes = (theme: string) => {
    const { switcher, themes } = useThemeSwitcher();
    useEffect(() => {
        switcher({ theme: themes[theme] });
    });
}