import { Button, Col, Row, Form } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import HelmetTitle from "../../other/HelmetTitle";
import Table from "../../table/customizedTable/CustomizedTable";
import "./ProductsStore.css";
import Filters from "../../shared/filters/Filters";
import { useStoreProducts } from "../../../hooks/useStoreProducts";
import PageTitle from "../../other/PageTitle";
import { Columns } from "./Columns";
import { ColumnsBarcodePromotion } from "./ColumnsBarcodePromotion";
import { headers } from "./FileHeaders";
import { useFilters } from "../../../hooks/useFilters";
import { fetchStoreProducts } from "../../../actions/storeProducts/main.actions";
import HorizontalFilter from "../../shared/filters/HorizontalFilter";
import { fields } from "./fields";
import FilterBreadcrumb from "../../shared/filters/FilterBreadcrumb";
import { FilterSection } from "../../../types/enum/filters";
import { DownloadCSV } from "../../shared/buttons/DownloadCSV";

const defaultParams = `&catalogId=60d4f60a378aec6831a3f459`;

const ProductsStore = () => {
  const [form] = Form.useForm();

  const {
    filters,
    handleChange,
    handleChangeSelect,
    resetFilter,
    toggleFilters,
    collapsed,
    onFinishFiltering,
    queryParams,
    filtersState,
    handleDonwload,
  } = useFilters(
    fetchStoreProducts,
    fields,
    FilterSection.STOREPRODUCTS,
    defaultParams,
    form
  );

  const {
    storeProductsState,
    pagination,
    onClick,
    loading,
    productStoreID,
    isNoPromoteModalVisible,
    noPromoteReason,
    showProductStoreByBarcodeTable,
    selectedProductStoreByBarcodeToBePromoted,
    onClickPromoteToExisingBaseProduct,
    showNoPromoteModal,
    handleCancel,
    onChangeNoPromoteReason,
    onChangeCheckbox,
    handleRowSelection,
    date,
    onClickPromoteToNewBaseProduct,
    onClickNoPromoteProduct,
    onClickPromoteWithBarcode,
  } = useStoreProducts(queryParams, defaultParams);

  const columns = Columns(
    onChangeCheckbox,
    isNoPromoteModalVisible,
    onClickNoPromoteProduct,
    handleCancel,
    onChangeNoPromoteReason,
    showNoPromoteModal,
    productStoreID,
    noPromoteReason
  );

  const columnsProductStoreByBarcodeToBePromoted = ColumnsBarcodePromotion();

  return (
    <>
      <HelmetTitle
        title="Productos de tienda - Backoffice D&#38;N"
        description="Pantalla para listar, y promover productos de tienda"
      />
      {!showProductStoreByBarcodeTable ? (
        <>
          <Row gutter={[0, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 5 }}>
              <PageTitle title="Productos de tienda" />

              <FilterBreadcrumb filters={filtersState.storeProducts} />
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 19 }}>
              <Row className="d-flex flex-end">
                <Button
                  type="text"
                  disabled={
                    storeProductsState.noBarcodeProducts.data.length
                      ? false
                      : true
                  }
                  onClick={onClickPromoteToExisingBaseProduct}
                >
                  Promover a producto base existente
                </Button>
                <Button
                  type="text"
                  disabled={
                    storeProductsState.noBarcodeProducts.data.length
                      ? false
                      : true
                  }
                  onClick={() => onClickPromoteToNewBaseProduct(false)}
                >
                  Promover a nuevo producto base
                </Button>
                <Button
                  type="text"
                  disabled={
                    storeProductsState.barcodeProducts.data.length
                      ? false
                      : true
                  }
                  onClick={onClickPromoteWithBarcode}
                >
                  Promover
                </Button>

                <DownloadCSV
                  headers={headers}
                  date={date}
                  section={FilterSection.STOREPRODUCTS}
                  handleDownload={handleDonwload}
                />

                <Button type="text" onClick={toggleFilters}>
                  <FilterOutlined />
                  Filtros
                </Button>
              </Row>
            </Col>
          </Row>

          <Form form={form} onFinish={onFinishFiltering}>
            <Filters
              fields={fields}
              filters={filters}
              collapsed={collapsed}
              onFinishFiltering={onFinishFiltering}
              productStoreFilters={true}
              toggleFilters={toggleFilters}
              resetFilter={resetFilter}
              handleChange={handleChange}
              handleChangeSelect={handleChangeSelect}
              form={form}
            />

            <HorizontalFilter
              fields={fields}
              onFinishFiltering={onFinishFiltering}
              filters={filters}
              handleChange={handleChange}
              handleChangeSelect={handleChangeSelect}
              resetFilter={resetFilter}
              form={form}
            />
          </Form>

          <Row>
            <Col span={24}>
              <Table
                dataSource={storeProductsState.storeProducts.data.data}
                columns={columns}
                name="producto de tienda"
                title={null}
                path="products-store"
                onClick={onClick}
                loading={storeProductsState.storeProducts.loading}
                pagination={pagination}
                rowSelectionButton={true}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <PageTitle title="Promoción a nuevo producto base" />
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              className="d-flex flex-end"
            >
              <Button
                disabled={
                  !selectedProductStoreByBarcodeToBePromoted.products.length
                    ? true
                    : false
                }
                type="text"
                onClick={() => onClickPromoteToNewBaseProduct(true)}
              >
                Promover a nuevo producto base
              </Button>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                dataSource={storeProductsState.barcodeProducts.data}
                columns={columnsProductStoreByBarcodeToBePromoted}
                name="producto de tienda"
                path="products-store"
                onClick={onClick}
                loading={loading}
                pagination={false}
                handleRowSelection={handleRowSelection}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductsStore;
