import { Button, Form, Input, Spin, Breadcrumb, InputNumber, Space, Col, Row } from "antd";
import {
  PlusCircleOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../store/store";
import { countriesState } from "../../types/countries.types";
import { IUserStore, storesState } from "../../types/store.types";
import "./StoresForm.css";
import UserStoreForm from "./UserStoreForm";
import PageTitle from "../other/PageTitle";
import CountrySelect from "../selects/country/CountrySelect";
import regularExpression from "../../utils/regularExpression";
import TaxDetailSelect from "../selects/tax/TaxDetailSelect";
import { useEffect } from "react";
import { cleanUp } from "../../actions/stores.actions";
import CatalogSelect from "../selects/catalog/CatalogSelect";

const StoreForm = (props: any) => {
  const storesState: storesState = useSelector(
    (state: RootState) => state.stores
  );
  const countriesState: countriesState = useSelector(
    (state: RootState) => state.countries
  );
  const dispatch = useAppDispatch();

  const {
    onFinish,
    currentUser,
    users,
    handleBreadcrumbOnClick,
    onClickToggleUserForm,
    toggleNewUser,
    onClickEditUser,
    onClickDeleteUser,
    onFinishUserForm,
  } = props;

  useEffect(()=>{
    //clean up
    return () => dispatch(cleanUp());
  },[dispatch]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/stores" onClick={handleBreadcrumbOnClick}>
            Comercio
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {!storesState.store.loading
            ? storesState.store.data.storeKey
              ? "Editar"
              : "Agregar"
            : ""}
        </Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle title={storesState.store.data.name} />

      {!storesState.store.loading ? (
        <>
          <Form
            name="form"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={onFinish}
          >
            <Row gutter={[8, 8]}>

              <Col 
                xs={{ span: 24 }} 
                md={{ span: 12 }}
              >
                <Form.Item
                  label="Nombre del comercio"
                  name="storeName"
                  rules={[
                    { 
                      required: true, 
                      message: "Por favor inserte el nombre del comercio.",
                    },
                    {
                      pattern: new RegExp(regularExpression.nonWhitespacesAtBeginnig),
                      message: "El campo no puede estar vacío.",
                    }
                  ]}
                  initialValue={storesState.store.data.name}
                >
                  <Input maxLength={50} />
                </Form.Item>

                <Form.Item
                  label="Usuario (Correo electrónico)"
                  name="storeKey"
                  rules={[
                    {
                      required: true,
                      message: "Por favor inserte un correo electrónico.",
                    },
                    {
                      required: true,
                      type: "email",
                      message: "Por favor ingrese un correo electrónico válido.",
                    },
                  ]}
                  initialValue={storesState.store.data.storeKey}
                >
                  <Input type="email" maxLength={64} />
                </Form.Item>

                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[
                    storesState.store.data.id
                      ? {}
                      : {
                          required: true,
                          message: "Por favor inserte la contraseña.",
                        },
                    {
                      required: true,
                      min: 4,
                      message: "La contraseña debe tener al menos 4 caracteres.",
                    },
                    {
                      required: true,
                      pattern: new RegExp(regularExpression.lettersAndNumbers),
                      message: "Solo se permiten letras y numeros",
                    },
                    {
                      pattern: new RegExp(
                        regularExpression.nonWhitespacesAtBeginnig
                      ),
                      message: "El campo no puede estar vacío.",
                    },
                  ]}
                >
                  <Input.Password autoComplete="new-password" placeholder="Como mínimo 4 caracteres" />
                </Form.Item>

                <Form.Item
                  label="Catálogo"
                  name="catalogId"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione un catálogo.",
                    },
                  ]}
                  initialValue={storesState.store.data.catalog.id}
                >
                  <CatalogSelect
                    initialValue={storesState.store.data.catalog.id}
                  />
                </Form.Item>

                <Form.Item
                  label="País"
                  name="countryId"
                  rules={[
                    { required: true, message: "Por favor seleccione un país." },
                  ]}
                  initialValue={storesState.store.data.country.id}
                >
                  <CountrySelect
                    initialValue={storesState.store.data.country.id}
                    filterOption={(input: any, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  ></CountrySelect>
                </Form.Item>

                <Form.Item
                  label="Dirección"
                  name="address"
                  rules={[
                    { 
                      required: true, 
                      message: "Por favor inserte la dirección." 
                    },
                  ]}
                  initialValue={storesState.store.data.address}
                >
                  <Input maxLength={60} />
                </Form.Item>

              </Col>

              <Col 
                xs={{ span: 24 }} 
                md={{ span: 12 }}
              >

                <Form.Item
                  label="Latitud"
                  name="latitude"
                  rules={[
                    { 
                      required: true, 
                      message: "Por favor inserte la latitud." 
                    },
                    {
                      required: true,
                      pattern: new RegExp(regularExpression.latitud),
                      message: "La latitud debe de estar entre -90 y 90." 
                    },
                  ]}
                  initialValue={storesState.store.data.gps.latitude}
                >
                  <InputNumber controls={false} style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  label="Longitud"
                  name="longitude"
                  rules={[
                    { 
                      required: true, 
                      message: "Por favor inserte la longitud." 
                    },
                    {
                      required: true,
                      pattern: new RegExp(regularExpression.longitud),
                      message: "La longitud debe de estar entre -180 y 180." 
                    },
                  ]}
                  initialValue={storesState.store.data.gps.longitude}
                >
                  <InputNumber controls={false} style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  label="Impuesto"
                  name="taxDetail"
                  rules={[
                    { required: true, message: "Por favor seleccione una opción." },
                  ]}
                  initialValue={storesState.store.data.taxDetail}
                >
                  <TaxDetailSelect initialValue={storesState.store.data.taxDetail}/>
                </Form.Item>

                <Form.Item>
                  Usuarios
                  <PlusCircleOutlined
                    className="plus-circle-outlined"
                    onClick={onClickToggleUserForm}
                  />
                </Form.Item>

                {users && !toggleNewUser ? (
                  users.map((el: IUserStore, idx: any) => {
                    return (
                      <Form.Item key={idx}>
                        <UserOutlined className="user-panel-icon" />
                        <span className="user-panel-name">
                          {el.name} {el.lastname}
                        </span>
                        <EditOutlined
                          title="Editar"
                          className="user-panel-icon-action"
                          alt="Icono de edición"
                          onClick={() => onClickEditUser(el)}
                        />
                        <DeleteOutlined
                          title="Eliminar"
                          className="user-panel-icon-action"
                          alt="Icono de borrado"
                          onClick={() => onClickDeleteUser(el)}
                        />
                      </Form.Item>
                    );
                  })
                ) : (
                  <></>
                )}

              </Col>

              <Col span={24} className="d-flex flex-end">

                {!toggleNewUser ? (
                  <Form.Item>
                    <Space>
                      <Button 
                          type="primary" 
                          htmlType="submit" 
                      >
                          Guardar
                      </Button>

                      <Button 
                        onClick={handleBreadcrumbOnClick}
                      >
                          Cancelar
                      </Button>
                  </Space>

                    
                  </Form.Item>
                ) : (
                  <></>
                )}

              </Col>

            </Row>
          </Form>

          {toggleNewUser ? (
            <UserStoreForm onFinish={onFinishUserForm} user={currentUser} onClickToggleUserForm={onClickToggleUserForm}/>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Spin />
      )}
    </>
  );
};

export default StoreForm;
