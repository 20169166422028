import { Dropdown } from "antd";
import ModuleList from "./ModuleList";
import { 
    DownOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useThemes } from "../../hooks/useThemes";
import { RootState, useAppDispatch } from "../../store/store";
import { changesModules } from "../../actions/modules.actions";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';


const ModuleSelector = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const typeModules = useSelector((state:RootState) => state.typemodules);
    const [theme, setTheme] = useState('')
    const [menu, setMenu] = useState('')
    const [urlRedirect, setUrlRedirect]: any = useState('')

    const handleChangeMenu = (module: string, url?:string) => {
        dispatch(changesModules(module))
        setTheme(module.toLowerCase());
        setMenu(module);
        setUrlRedirect(url)
    }


    useEffect(() =>{
        if(urlRedirect){
            navigate(urlRedirect)
            setUrlRedirect("")
        } 
    }, [typeModules])

    useEffect(() =>{
        handleChangeMenu(typeModules.modules)
    }, [])
    
    useThemes(theme);

    return (
        <div className="header__module-selector">
            <span className="header__module-selector__text">{menu}</span>
            <Dropdown overlay={<ModuleList menu={menu} handleChangeMenu={handleChangeMenu} />} trigger={['click']}>
                <DownOutlined 
                    style={{ fontSize: 14 }} 
                    onClick={e => e.preventDefault()} />
            </Dropdown>
        </div>
    );
};

export default ModuleSelector;