export const countriesActionTypes= {
FETCH_COUNTRIES_REQUEST:"FETCH_COUNTRIES_REQUEST",
FETCH_COUNTRIES_SUCCESS:"FETCH_COUNTRIES_SUCCESS",
FETCH_COUNTRIES_FAILURE:"FETCH_COUNTRIES_FAILURE",

FETCH_COUNTRIES_SELECT_REQUEST:"FETCH_COUNTRIES_SELECT_REQUEST",
FETCH_COUNTRIES_SELECT_SUCCESS:"FETCH_COUNTRIES_SELECT_SUCCESS",
FETCH_COUNTRIES_SELECT_FAILURE:"FETCH_COUNTRIES_SELECT_FAILURE",

FETCH_COUNTRY_REQUEST:"FETCH_COUNTRY_REQUEST",
FETCH_COUNTRY_SUCCESS:"FETCH_COUNTRY_SUCCESS",
FETCH_COUNTRY_FAILURE:"FETCH_COUNTRY_FAILURE",

DELETE_COUNTRY_REQUEST:"DELETE_COUNTRY_REQUEST",
DELETE_COUNTRY_SUCCESS:"DELETE_COUNTRY_SUCCESS",
DELETE_COUNTRY_FAILURE:"DELETE_COUNTRY_FAILURE",

CREATE_COUNTRY_REQUEST:"CREATE_COUNTRY_REQUEST",
CREATE_COUNTRY_SUCCESS:"CREATE_COUNTRY_SUCCESS",
CREATE_COUNTRY_FAILURE:"CREATE_COUNTRY_FAILURE",

UPDATE_COUNTRY_REQUEST:"UPDATE_COUNTRY_REQUEST",
UPDATE_COUNTRY_SUCCESS:"UPDATE_COUNTRY_SUCCESS",
UPDATE_COUNTRY_FAILURE:"UPDATE_COUNTRY_FAILURE",

COUNTRY_CLEAN_UP:"COUNTRY_CLEAN_UP"
}