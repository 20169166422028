import { Tag } from "antd";

export const customizedTag = (
  state: any,
  setState: any,
  form: any,
  name: any
) => {
  const handleClose = (removedTag: any) => {
    const tags = state.tags.filter((tag: any) => tag !== removedTag);
    setState({ ...state, tags });
    form.setFieldsValue({
      [name]: tags.length > 0 ? tags[0] : "",
    });
  };

  return state.tags.map((tag: any) => {
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        <Tag
          className="multi-search-tag"
          closable
          onClose={(e) => {
            e.preventDefault();
            handleClose(tag);
          }}
        >
          {tag}
        </Tag>
      </span>
    );
  });
};
