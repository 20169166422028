import Select, { SelectValue } from "antd/lib/select";
import { FC } from "react";

const { Option } = Select;

interface IProps {
  placeholder: string;
  initialValue?: string;
  data: any[];
  filterOption?: any;
  onChange?: (value: SelectValue, option: any) => void;
}
const CustomSelect: FC<IProps> = (props: any) => {
  const { initialValue,onChange, placeholder, data } = props;
  return (
    <Select defaultValue={initialValue} placeholder={placeholder} onChange={onChange}>
      {data.map((option: any, idx:number) => (
        <Option key={idx} value={option.value} label={option.name}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
};
export default CustomSelect;
