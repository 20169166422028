import { FC } from "react";
import { Navigate } from "react-router-dom";
import { login } from "../../keycloak/auth/authentication.service";


interface IPrivateRoute {
  children: any;
  isLoggedIn?: any;
}

const PrivateRoute: FC<IPrivateRoute> = ({ children }) => {
  return login() ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
