export const headers = [
    {
        label: 'ID',
        key: 'id',
    },
    {
        label: 'Código de barra',
        key: 'barcode',
    },
    {
        label: 'Descripción',
        key: 'description',
    },
    {
        label: 'Contenido',
        key: 'contents',
    },
    {
        label: 'Unidad',
        key: 'unit',
    },
    {
        label: 'Precio',
        key: 'price',
    },
    {
        label: '% de impuesto',
        key: 'taxPercent',
    },
    {
        label: 'ID de catálogo',
        key: 'store.catalogId',
    },
    {
        label: 'Comercio',
        key: 'store.storeName',
    },
    {
        label: 'ID de comercio',
        key: 'storeId',
    },
];