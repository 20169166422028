import Table from "../table/customizedTable/CustomizedTable";
import HelmetTitle from "../other/HelmetTitle";
import { useCountries } from "../../hooks/useCountries";
import CountriesForm from "./CountriesForm";
import PageTitle from "../other/PageTitle";
import { Button, Col, Row } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import RowGroupModal from "../other/RowGroupModal";
import { Columns } from './Columns';

const Countries = () => {
  const {
    countriesState,
    pagination,
    handleDelete,
    handleEdit,
    onClick,
    showForm,
    setShowForm,
    onFinish,
    isModalVisible,
    item,
    showEnableModal,
    handleCancel,
    selectedCountries,
    handleRowSelection,
    showDeleteCountriesModal,
    isDeleteCountriesModalVisible,
    deleteCountriesSelected,
    getColumnSearchProps,
  } = useCountries();
  const columns = Columns(getColumnSearchProps,
    item,
    handleEdit,
    handleDelete,
    isModalVisible,
    showEnableModal,
    handleCancel,)

  return (
    <>
      <HelmetTitle
        title="Países - Backoffice D&#38;N"
        description="Pantalla para listar, crear, y editar países"
      />
      {showForm ? (
        <CountriesForm onFinish={onFinish} setShowForm={setShowForm} />
      ) : (
        <>
          <Row>
            <Col span={12}>
              <PageTitle title="Países" />
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }} className="d-flex flex-end">
              <Button
                type="text"
                disabled={!selectedCountries.length ? true : false}
                onClick={showDeleteCountriesModal}
              >
                <DeleteOutlined />
                Eliminar países
              </Button>

              <Button type="text" onClick={onClick}>
                <PlusOutlined />
                Nuevo país
              </Button>
            </Col>
          </Row>

          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Table
                dataSource={countriesState.countries.data.data}
                columns={columns}
                name="país"
                path="countries"
                onClick={onClick}
                loading={countriesState.countries.loading}
                pagination={pagination}
                handleRowSelection={handleRowSelection}
              />
            </Col>
          </Row>

          <RowGroupModal
            actionType="Eliminar"
            item="países"
            isVisible={isDeleteCountriesModalVisible}
            showAction={showDeleteCountriesModal}
            actionTrigger={deleteCountriesSelected}
          />
        </>
      )}
    </>
  );
};

export default Countries;
