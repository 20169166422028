import CopyTextColumn from "../../other/CopyTextColumn";
import {
  ActionEditProduct,
  ActionNotPromotable,
  ActionPromotable,
} from "./promotion/ActionsColumns";

export const Columns = (
  onChangeCheckbox: any,
  isNoPromoteModalVisible: any,
  onClickNoPromoteProduct: any,
  handleCancel: any,
  onChangeNoPromoteReason: any,
  showNoPromoteModal: any,
  productStoreID: any,
  noPromoteReason: any,
  handleEdit: any,
  onClickPromoteProducts: any,
  queryParams:any
) => {
  const actions = {
    onChangeCheckbox,
    showNoPromoteModal,
    productStoreID,
    noPromoteReason,
    handleEdit,
    onClickPromoteProducts,
    isNoPromoteModalVisible,
    onClickNoPromoteProduct,
    handleCancel,
    onChangeNoPromoteReason,
    queryParams
  };

  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: function RevealTextOnHover(text: any, record: any, index: any) {
        return <CopyTextColumn text={text} record={record} />;
      },
    },
    {
      title: "Código de barra",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a: any, b: any) => a.barcode - b.barcode,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Contenido",
      dataIndex: "contents",
      key: "contents",
      sorter: (a: any, b: any) => a.contents - b.contents,
    },
    {
      title: "Unidad",
      dataIndex: "unit",
      key: "unit",
      sorter: (a: any, b: any) => a.unit.localeCompare(b.unit),
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      sorter: (a: any, b: any) => a.price - b.price,
    },
    {
      title: "% de impuesto",
      dataIndex: "taxPercent",
      key: "taxPercent",
      sorter: (a: any, b: any) => a.taxPercent - b.taxPercent,
    },
    {
      title: "Comercio",
      dataIndex: ["store", "storeName"],
      key: "store",
      sorter: (a: any, b: any) =>
        a.store.storeName.localeCompare(b.store.storeName),
    },
    {
      title: "Tipo de producto",
      key: "productType",
      render: (text: any, record: any, index: any) => {
        if (record.hasOwnProperty("baseProduct")) {
          return <span className="promoted">Promovido</span>;
        }

        if (
          !record.hasOwnProperty("baseProduct") &&
          !record.hasOwnProperty("reason")
        ) {
          return <span className="to-be-promoted">Por promover</span>;
        }

        if (
          !record.hasOwnProperty("baseProduct") &&
          record.hasOwnProperty("promotable") &&
          record.promotable === false
        ) {
          return <span className="no-promotable">No promovible</span>;
        }
      },
    },
    {
      title: "Acciones",
      dataIndex: "rowButtons",
      key: "rowButtons",
      render: (text: any, record: any, index: any) => {
        return (
          <div
            className="table__button-container"
            style={{ justifyContent: "space-around" }}
          >
            {ActionEditProduct(record, actions)}
            {ActionPromotable(record, actions)}
            {ActionNotPromotable(record, actions)}
          </div>
        );
      },
    },
  ];
};