import { Button, Col, Row } from "antd";
import PageTitle from "../../../../other/PageTitle";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Columns as BaseProductsColumns } from "../baseProductsRelatedColumns";
import { ProductType } from "../../../../../types/enum/modalType";
import TableComponente from "../../../../table/customizedTable/CustomizedTable";
import { storeProductsState } from "../../../../../types/storeProducts.types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

const BaseProductsRelated = (props:any) => {
    const baseProductsRelatedColumns = BaseProductsColumns();
    const storeProductsState: storeProductsState = useSelector(
        (state: RootState) => state.storeProducts
    );

    return (
        <Row gutter={[8, 8]}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <PageTitle title="Productos base" />
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }} className="d-flex flex-end">
                <Button
                    type="primary"
                    onClick={() => props.handleShowAddProductsModal(ProductType.BASE)}
                >
                    <PlusCircleOutlined />
                    Productos Base
                </Button>
            </Col>

            <Col span={24} className="table-container-de-2">
                <TableComponente
                    dataSource={storeProductsState.baseProductsRelated.data.data}
                    columns={baseProductsRelatedColumns}
                    name="producto base"
                    title={null}
                    path="products-base"
                    handleRowSelection={props.handleRowSelectionBaseProductRelated}
                    loading={storeProductsState.baseProductsRelated.loading}
                />
            </Col>
        </Row>
    )
};

export default BaseProductsRelated;