import Keycloak from "keycloak-js";

export const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL ?? "",
  realm: process.env.REACT_APP_KEYCLOAK_REALM ?? "",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID ?? "",
});

export const keycloakProviderInitConfig = {
  onLoad: "login-required",
};

export const isLoadingCheck = (keycloak: any) => {
  return !keycloak.authenticated;
};

export const storeToken = (token: string) => {
  //if(token!="")
  localStorage.setItem("keycloakToken", token);
};

export const updateToken = () => {
  keycloak
    .updateToken(180)
    .then((response: any) => {
      //update existing Token HERE
      console.log("update token");
      console.log(response);
    })
    .catch((error: any) => {
      console.log("update token error");
      console.log("error: ", error);
    });
};

export const hasRealmRole = (role: string) => {
  return keycloak.hasRealmRole(role);
};

export const hasRole = (roles: string | string[]) => {
  if (!Array.isArray(roles)) {
    return keycloak.hasResourceRole(
      roles,
      process.env.REACT_APP_KEYCLOAK_CLIENTAPIID
    );
  } else {
    return roles.some((item: any) =>
      keycloak.hasResourceRole(item, process.env.REACT_APP_KEYCLOAK_CLIENTAPIID)
    );
  }
};
