import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Rectangle,
    ResponsiveContainer,
    LabelList,
    PieChart, Pie, Sector, Cell
} from "recharts";
import { Button, Col, Row, Form } from "antd";
import { InputDate } from "../inputDate";
import { useSalesByCountry } from "../../../hooks/statistics/useSalesByCountry";
import Loading from "../../loading/loading";


const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
}: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export const SalesByCountry = () => {
    const { findDataCountry, onChangesDate, dataCharts, COLORS, openLoading, totalTicket } = useSalesByCountry();

    return (
        <>
            <InputDate onChangesDate={onChangesDate} />
            <Button type="primary" className='buttonFilterStatistics' onClick={findDataCountry}>
                Filtrar
            </Button>
            <Loading open={openLoading} />
            {dataCharts.length > 0 &&
                <>
                    <h1 style={{ textAlign: "center" }}>Total de tickets: {totalTicket()}</h1>
                    <Row>
                      


                        <Col span={16} offset={4}>
                            <ResponsiveContainer width="100%" height={350}>
                                <BarChart data={dataCharts} margin={{
                                    top: 20, // Agrega espacio adicional en la parte superior
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="country" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="total">
                                        {/* Asignamos colores dinámicos a cada barra */}
                                        {dataCharts.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                        {/* Agregamos LabelList para mostrar los valores encima de las barras */}
                                        <LabelList dataKey="total" position="top" />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Col>

                        <Col span={24} style={{ textAlign: "center", marginTop:"30px" }}>
                            {dataCharts.map((item: any, index: number) => {
                                return (
                                    <div className="styleChartDiv">
                                        <div style={{ backgroundColor: COLORS[index] }}></div>
                                        <h6>{item.country}</h6>
                                    </div>
                                )
                            })}
                        </Col>
                        <Col span={16} offset={4}>

                            <ResponsiveContainer width="100%" height={350}>
                                <PieChart>
                                    <Pie
                                        data={dataCharts}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius="80%"
                                        fill="#8884d8"
                                        dataKey="total"
                                    >
                                        {dataCharts.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
                </>

            }
        </>
    )
}