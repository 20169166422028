import { Button, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useState } from "react";

const { Text } = Typography;

const CopyTextColumn = (props: any) => {
  const { record } = props;
  const [ellipsis, setEllipsis] = useState(true);
  const handleOnClick = () => {
    setEllipsis(!ellipsis);
  };

  return (
    <div style={{ display: "flex" }}>
      <Text style={{ width: ellipsis ? 80 : "auto" }}>
        {ellipsis ? record.id.slice(record.id.length - 6) : record.id}
      </Text>
      <Tooltip title="Mostrar ID" style={{ width: 10 }}>
        <Button
          style={{ border: 0 }}
          shape="circle"
          icon={<EyeOutlined size={10} />}
          size="small"
          onClick={handleOnClick}
        />
      </Tooltip>
      <Text copyable={{ text: record.id }} style={{ width: 10 }} />
    </div>
  );
};
export default CopyTextColumn;
