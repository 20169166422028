import Select from "antd/lib/select";
import { FC } from "react";
import noPromotableReasonData from "../../../data/NoPromotableReason.json";

const { Option } = Select;

interface IProps {
  initialValue: any;
  onChange?: ((value: any[], option: any) => void) | undefined;
}

const NoPromotableReasonSelect: FC<IProps> = (props: any) => {
  const { initialValue, onChange } = props;
  return (
    <Select
      defaultValue={initialValue}
      placeholder="Seleccione una razón"
      optionLabelProp="label"
      onChange={onChange}
    >
      {noPromotableReasonData.map((option: any, idx: number) => (
        <Option key={idx} value={option.value} label={option.name}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
};
export default NoPromotableReasonSelect;
