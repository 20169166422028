import { DatePicker, Input, Checkbox, Form } from "antd";
import { Field, Position } from "../../../types/enum/field";
import { ISelectFilter } from "../../../types/filters.types";
import CatalogFilesSelect from "../../selects/catalogFiles/CatalogFilesSelect";
import CategoriesSelect from "../../selects/categories/CategoriesSelect";
import CurrencySelect from "../../selects/currency/CurrencySelect";
import GeneralSelect from "../../selects/general/GeneralSelect";
import StoresSelect from "../../selects/stores/StoresSelect";
import UnitSelect from "../../selects/unit/UnitSelect";
import MultiValuesInput from "../../../components/other/multiValuesInput/MultiValuesInput";
import StoresKeySelect from "../../selects/stores/StoresKeySelect";
import CountrySelect from "../../selects/country/CountrySelect";

export const SelectFilter = (props: ISelectFilter) => {
  const placeholder = props.placeholder ? props.placeholder : "";
  const check = props.check ? props.check : false;

  const {
    type,
    mode,
    name,
    position,
    values,
    form,
    handleChange,
    handleChangeSelect,
    value,
    disabled
  } = props;

  const fields = form?.getFieldsValue();
  const {catalogId} =fields;

  const filterOption = (input: any, option: any) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const showElement = () => {
    if (position === Position.HORIZONTAL && check) {
      return { display: "none" };
    }
    return {};
  };

  const customFormItem = (
    type: Field,
    placeholder: string,
    item: any,
    defaultValue: any
  ) => {
    switch (type) {
      case Field.DATEPICKER:
        return (
          <Form.Item
            style={showElement()}
            name={name}
            valuePropName="value"
            initialValue={value}
          >
            {item}
          </Form.Item>
        );
      case Field.CHECKBOX:
        return (
          <Form.Item
            style={showElement()}
            name={name}
            label={placeholder}
            valuePropName="checked"
          >
            {item}
          </Form.Item>
        );
      default:
        return (
          <Form.Item style={showElement()} name={name}>
            {item}
          </Form.Item>
        );
    }
  };

  const showSelect = (
    type: Field,
    placeholder: string,
    mode: any,
    values: any,
    name: any,
    form: any,
    defaultValue: any
  ) => {
    switch (type) {
      case Field.CATALOG:
        return (
          <CatalogFilesSelect
            disabled={disabled}
            filterOption={filterOption}
            initialValue={value}
            //onChange={handleChangeSelect}
            handleChangeSelect={handleChangeSelect}
          />
        );
        case Field.COUNTRY:
          return (
            <CountrySelect
              disabled={disabled}
              filterOption={filterOption}
              initialValue={value}
              handleChangeSelect={handleChangeSelect}
            />
          );
      case Field.CATEGORY:
        return (
          <CategoriesSelect
            mode={mode}
            filterOption={filterOption}
            initialValue={value}
            handleChangeSelect={handleChangeSelect}
            parentId={catalogId}
          />
        );
      case Field.CURRENCY:
        return (
          <CurrencySelect
            mode={mode}
            filterOption={filterOption}
            initialValue={value}
            handleChangeSelect={handleChangeSelect}
          />
        );
      case Field.STORE:
        return (
          <StoresSelect
            mode={mode}
            filterOption={filterOption}
            initialValue={value}
            handleChangeSelect={handleChangeSelect}
          />
        );
      case Field.STOREKEY:
        return (
          <StoresKeySelect
            mode={mode}
            filterOption={filterOption}
            initialValue={value}
            handleChangeSelect={handleChangeSelect}
          />
        );
      case Field.GENERAL:
        return (
          <GeneralSelect
            name={name}
            values={values}
            disabled={disabled}
            mode={mode}
            form={form}
            initialValue={value}
            filterOption={filterOption}
            placeholder={placeholder}
            onChange={handleChangeSelect}
          />
        );
      case Field.DATEPICKER:
        return (
          <DatePicker
            style={{ width: "100%" }}
            placeholder={placeholder}
            format="YYYY-MM-DD"
            value={value}
            onChange={(value) => {
              handleChangeSelect(name, value);
            }}
            showToday={false}
          />
        );
      case Field.UNIT:
        return (
          <UnitSelect
            mode={mode}
            initialValue={value}
            handleChangeSelect={handleChangeSelect}
          />
        );
      case Field.CHECKBOX:
        return <Checkbox className="checkbox" />;
      case Field.MULTIVALUE:
        return (
          <MultiValuesInput
            placeholder={placeholder}
            form={form}
            name={name}
            handleChange={handleChange}
          />
        );
      case Field.SEARCH:
        return (
          <Input
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => handleChange(name, e, 2)}
          />
        );
      default:
        return (
          <Input
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => handleChange(name, e)}
          />
        );
    }
  };

  const item = showSelect(type, placeholder, mode, values, name, form, value);

  return <>{customFormItem(type, placeholder, item, value)}</>;
};

export default SelectFilter;
