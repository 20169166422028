import { Button, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Field, Position } from '../../types/enum/field'

export const Columns = (handleEdit: any) => {
  return [
    {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        render: (text:any, record:any, index:any) => {
            // return `${record.name} ${record.lastname}`;
            return record.name;
        },
        sorter: (a:any, b:any) => a.name.localeCompare(b.name),
        // ...getColumnSearchProps('name')
    },
    /*
    {
        title: 'Orden',
        dataIndex: 'order',
        key: 'order',
        render: (text:any, record:any, index:any) => {
            // return `${record.name} ${record.lastname}`;
            return record.order;
        },
        // ...getColumnSearchProps('name')
    },
    */
    {
        title: 'Visibilidad',
        dataIndex: 'visible',
        key: 'visible',
        render: (text:any, record:any, index:any) => {
            // return `${record.name} ${record.lastname}`;
            return record.visible ? "Si" : "No";
        },
        // ...getColumnSearchProps('name')
    },
    {
        title: 'Fecha de creación',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text:any, record:any, index:any) => {
            // return `${record.name} ${record.lastname}`;
            return record.createdAt;
        },
        sorter: (a:any, b:any) => a.createdAt.localeCompare(b.createdAt),
        // ...getColumnSearchProps('name')
    },
    
    {
        title: '',
        key: '',
        dataIndex: '',
        render: (text:any, record:any) => {
            if (!record.active) {
                return (
                    <div className="table__button-container">
                         <Tooltip title="Editar">
                            <Button
                                type="text"
                                shape="circle"
                                icon={<EditOutlined />}
                                className="btn-table"
                                style={{ marginRight: 5 }}
                                onClick={() => handleEdit(record)}
                            />
                        </Tooltip>
                    </div>
                );
            }
        }
    }
    
  ];
};
