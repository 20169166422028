import { useSelector } from "react-redux";
import { fetchStoreProducts } from "../../actions/storeProducts/main.actions";
import {
  fetchStoreProductsBaseBySearch,
  fetchStoreProductsBySearch,
} from "../../actions/storeProductsSearch.actions";
import { RootState, useAppDispatch } from "../../store/store";
import { ProductType } from "../../types/enum/modalType";

import { storeProductsState } from "../../types/storeProducts.types";

interface IProps {
  setNewPromotionProducts: React.Dispatch<React.SetStateAction<any[]>>;
  modalType: ProductType;
  catalogId: string;
  searchValue: string;
  promotionProducts: any[];
}

const usePagination = (props: IProps) => {
  const {
    setNewPromotionProducts,
    modalType,
    catalogId,
    searchValue,
    promotionProducts,
  } = props;
  const storeProductsState: storeProductsState = useSelector(
    (state: RootState) => state.storeProducts
  );
  const dispatch = useAppDispatch();

  const paginationSearch = {
      current: storeProductsState?.searchProducts.data.page,
      pageSize: storeProductsState?.searchProducts.data.pageSize,
      total: storeProductsState?.searchProducts.data.totalCount,
      onChange: (page: number, pageSize: number) => {
        setNewPromotionProducts([]);
        if (modalType === ProductType.STORE) {
          dispatch(
            fetchStoreProductsBySearch(
              searchValue,
              catalogId,
              promotionProducts,
              storeProductsState?.similarProducts,
              page,
              pageSize
            )
          );
        } else {
          dispatch(
            fetchStoreProductsBaseBySearch(
              searchValue,
              catalogId,
              storeProductsState.baseProductsRelated,
              page,
              pageSize
            )
          );
        }
        // Especificamos el id del catalogo de los productos actuales para evitar adherir productos de otros catalogos
      },
  };

  const pagination = {
      current: storeProductsState.storeProducts.data.page,
      pageSize: storeProductsState.storeProducts.data.pageSize,
      total: storeProductsState.storeProducts.data.totalCount,
      onChange: (page: number, pageSize: number) => {
        setNewPromotionProducts([]);

        // Especificamos el id del catalogo de los productos actuales para evitar adherir productos de otros catalogos
        dispatch(
          fetchStoreProducts(page, pageSize, [
            `&catalogId=${catalogId}` + !searchValue.trim()
              ? ""
              : `&description=${searchValue}`,
          ])
        );
      },
  };
  
  return { paginationSearch,pagination };
};
export default usePagination;
