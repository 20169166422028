import { Button, Col, Form, Space } from "antd";

type customizedButton = {
  title: string;
  disabled: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
};
interface IProps {
  submitButton: { title: string };
  cancelButton: customizedButton;
  resetButton: customizedButton;
}

const FormButtons = (props: IProps) => {
  const { submitButton, cancelButton, resetButton } = props;
  return (
    <Col span={24} className="d-flex flex-end">
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            {submitButton.title}
          </Button>
          <Button onClick={cancelButton.onClick}>{cancelButton.title}</Button>
          <Button disabled={resetButton.disabled} onClick={resetButton?.onClick}>
            {resetButton.title}
          </Button>
        </Space>
      </Form.Item>
    </Col>
  );
};
export default FormButtons;
