import { Button, Form, Input, Select, Space } from "antd";
import regularExpression from "../../utils/regularExpression";

const { Option } = Select;

const UserStoreForm = (props: any) => {
  const { onFinish, user, onClickToggleUserForm } = props;

  return (
    <>
      <Form
        name="userForm"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item label="Id" name="id" initialValue={user.id || ""}>
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              pattern: new RegExp(
                regularExpression.nonWhitespacesAtBeginnig
              ),
              message: "El campo no puede estar vacío.",
            },
            {
              pattern: new RegExp(regularExpression.lettersNumbersSpaces),
              message: "Solo se permiten letras y numeros",
            }
          ]}
          initialValue={user.name}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Apellido"
          name="lastname"
          rules={[
            {
              required: true,
              pattern: new RegExp(
                regularExpression.nonWhitespacesAtBeginnig
              ),
              message: "El campo no puede estar vacío.",
            },
            {
              pattern: new RegExp(regularExpression.lettersNumbersSpaces),
              message: "Solo se permiten letras y numeros",
            }
          ]}
          initialValue={user.lastname}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            { required: true, message: "Por favor inserte el E-mail." },
            {
              required: true,
              type: "email",
              message: "Por favor ingrese un correo electrónico válido.",
            },
          ]}
          initialValue={user.email}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Usuario"
          name="username"
          rules={[
            {
              required: true,
              pattern: new RegExp(
                regularExpression.nonWhitespacesAtBeginnig
              ),
              message: "El campo no puede estar vacío.",
            },
            {
              pattern: new RegExp(regularExpression.lettersNumbersSpaces),
              message: "Solo se permiten letras y numeros",
            }
          ]}
          initialValue={user.username}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Pin"
          name="pin"
          rules={[{ required: true, message: "Por favor inserte el Pin" }]}
          initialValue={user.pin}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Rol"
          name="role"
          rules={[{ required: true, message: "Por favor seleccione un Rol" }]}
          initialValue={user.role}
        >
          <Select placeholder="Seleccione una opción">
            <Option value="ADMINISTRADOR" label="Administrador General">
              Administrador General
            </Option>

            <Option value="OPERADOR" label="Operador">
              Operador
            </Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {!user?.id ? "Agregar usuario":"Modificar usuario"}
            </Button>
            <Button onClick={onClickToggleUserForm}>Cancelar</Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserStoreForm;
