import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { fetchStores } from "../../actions/stores.actions";
import { fetchStoresTickets } from "../../actions/tickets.actions";
import { RootState, useAppDispatch } from "../../store/store";
import { ticketsState } from "../../types/tickets.types";
import { FormInstance } from "antd";
import { concatParameters } from "../../utils/stringFunctions";

export const useTickets = (form:FormInstance<any>) => {
  const dispatch = useAppDispatch();
  const pageSize = 20;
  const ticketsState: ticketsState = useSelector(
    (state: RootState) => state.tickets
  );

  const getCurrentParams = (form:FormInstance<any>)=>{
    if (!form.getFieldValue("countryId")) {
      //default country value
      form.setFieldsValue({"countryId": "60db267049c5f13a8d0f0e86"});
    }
    const formValues = concatParameters(form.getFieldsValue());
    return formValues;
  }

  useEffect(() => {
    dispatch(fetchStoresTickets(1, pageSize,getCurrentParams(form)));
    dispatch(fetchStores(1, 1000000, `&country=${form.getFieldValue("countryId")}`));
  }, [dispatch,form]);

  const [items, setItems] = useState([]);
  const [isModal, setIsModal] = useState(false);

  const handleOpenTicket = (record: any) => {
    setIsModal(true);
    const itemsMap = record.items.map((item: any) => {
      const unique_id = uuid();
      item.key = unique_id;
      return item;
    });
    setItems(itemsMap);
  };

  const dateObject = new Date();
  const date = `${dateObject.getFullYear()}_${
    dateObject.getMonth() + 1
  }_${dateObject.getDate()}`;

  const handleCloseTicket = () => {
    setIsModal(false);
    setItems([]);
  };

  return {
    ticketsState,
    pagination: {
      current: ticketsState.tickets.data.page,
      pageSize: ticketsState.tickets.data.pageSize,
      total: ticketsState.tickets.data.totalCount,
      onChange: (page: number, pageSize: number) => {
        dispatch(fetchStoresTickets(page, pageSize, getCurrentParams(form)));
      },
    },
    items,
    setItems,
    date,
    handleOpenTicket,
    handleCloseTicket,
    isModal
  };
};
