import { Spin, Result } from 'antd';
import './Loading.css';

const Loading = () =>{
    return(
      <Result style={{display:'flex', flexDirection:'column', height:'80vh', justifyContent:'center', alignItems:'center', background:'transparent'}} icon={<div></div>}>
        <Spin size="large"/>
      </Result>  
    )
}
export default Loading;