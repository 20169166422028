import {
  Spin,
} from "antd";
import useProductsBaseForm from "../../../hooks/useProductsBaseForm";
import BreadcrumbComponent from "../../shared/Breadcrumb";
import ProductForm from "../../shared/forms/productForm/ProductForm";
import { ProductType } from "../../../types/enum/modalType";
import { useEffect, useState } from "react";

const ProductsBaseForm = (props: any) => {
  const { setShowForm, queryParams } = props;
  const [componentMounted, setComponentMounted] = useState(false);

  const {
    baseProductsState,
    catalogsState,
    loading,
    formInstance,
    state,
    handleBreadcrumbOnClick,
    onFinishProductBaseForm,
    prodBaseDisableFields,
  } = useProductsBaseForm({ setShowForm, queryParams });

  //if the component is unmounted, it is not trying to fetch something
  useEffect(() => {
    setComponentMounted(true);
    return () => setComponentMounted(false);
  }, []);

  if (!componentMounted) {
    return null;
  }

  return !loading ? (
    <>
      <BreadcrumbComponent
        handleOnClick={handleBreadcrumbOnClick}
        showBreadcrumbs={false}
        breadcrumb={{
          title: "Productos base",
          link: "/products-base",
          item: state === null ? "Editar" : "Agregar",
        }}
      />
      {!baseProductsState.baseProduct.loading ? (
        <>
          <ProductForm
            type={ProductType.BASE}
            form={formInstance}
            data={baseProductsState.baseProduct.data}
            catalogsData={catalogsState.catalog.data}
            disableFields={prodBaseDisableFields}
            onFinish={(values) => onFinishProductBaseForm(values, state)}
            submitButton={{ title: "Guardar" }}
            cancelButton={{
              title: "Cancelar",
              onClick: () => setShowForm(false),
              disabled: false,
            }}
            resetButton={{ title: "Limpiar", disabled: true }}
          />
        </>
      ) : (
        <Spin />
      )}
    </>
  ) : (
    <Spin />
  );
};
export default ProductsBaseForm;
