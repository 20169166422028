import { Spin } from "antd";
import { useEffect, useState } from "react";
import useStoreProductsForm from "../../../../hooks/useStoreProductsForm";
import BreadcrumbComponent from "../../../shared/Breadcrumb";
import ProductForm from "../../../shared/forms/productForm/ProductForm";

type customizedButton = {
  title: string;
  disabled: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
};

interface IProps {
  setShowForm?: any;
  disabled?: boolean;
  handleOnCancel?: React.MouseEventHandler<HTMLElement> | undefined;
  disableFields?: string[] | any;
  isModal?: boolean;
  setShowCreateProdModal?: React.Dispatch<React.SetStateAction<boolean>>;
  handleReset?: React.MouseEventHandler<HTMLElement> | undefined;
  promotionProducts?: string[] | null;
  setRestartSearch?: any;
  setShowResumeModal?: any | null;
  submitButton: { title: string };
  cancelButton: customizedButton;
  resetButton: customizedButton;
}

const ProductsStoreFormNew = (props: IProps) => {
  const {
    setShowForm,
    handleOnCancel,
    disableFields,
    isModal = false,
    setShowCreateProdModal,
    promotionProducts,
    setRestartSearch,
    setShowResumeModal,
    submitButton,
    cancelButton,
    resetButton,
  } = props;
  const [componentMounted, setComponentMounted] = useState(false);

  const {
    storeProductsState,
    loading,
    formInstance,
    state,
    onFinishProductBaseForm,
  } = useStoreProductsForm({
    setShowForm,
    handleOnCancel,
    promotionProducts,
    setShowResumeModal,
    setRestartSearch,
    setShowCreateProdModal,
  });

  //if the component is unmounted, it is not trying to fetch something
  useEffect(() => {
    setComponentMounted(true);
    return () => setComponentMounted(false);
  }, []);

  if (!componentMounted) {
    return null;
  }

  return !loading ? (
    <>
      <BreadcrumbComponent
        handleOnClick={handleOnCancel}
        showBreadcrumbs={isModal}
        breadcrumb={{
          title: " Productos de tienda",
          link: "/products-store-new",
          item: state === null ? "Editar" : "Agregar",
        }}
      />
      {!storeProductsState.storeProduct.loading ? (
        <ProductForm
          form={formInstance}
          data={storeProductsState.storeProduct.data}
          disableFields={disableFields}
          onFinish={(values) =>
            onFinishProductBaseForm(
              values,
              state === null && isModal ? false : state,
              setRestartSearch
            )
          }
          submitButton={submitButton}
          cancelButton={cancelButton}
          resetButton={resetButton}
        />
      ) : (
        <Spin />
      )}
    </>
  ) : (
    <Spin />
  );
};
export default ProductsStoreFormNew;
