import { IStoreProduct, IStoreProducts, storeProductsAction } from "../../types/storeProducts.types";
import { storeProductsActionTypes } from "../../constants";
import { IError } from "../../types/interfaces/error.interface";
import { ThunkAction } from "redux-thunk";
import { AppDispatch, RootState } from "../../store/store";
import { ActionCreator } from "redux";
import instance from '../../utils/axios.instance';
import { arraySomeArray } from '../../utils/arrayFunctions';
import { Result } from '../../types/enum/result';

// Actions
/*********************PROMOTE_STORE_PRODUCTS ACTION************************************/
export const noPromoteStoreProductsRequest = () => {
    return {
        type: storeProductsActionTypes.NO_PROMOTE_STORE_PRODUCTS_REQUEST
    };
};

export const noPromoteStoreProductsSuccess = (data: any) => {
    return {
        type: storeProductsActionTypes.NO_PROMOTE_STORE_PRODUCTS_SUCCESS,
        payload: data
    };
};

export const noPromoteStoreProductsFailure = (error: IError) => {
    return {
        type: storeProductsActionTypes.NO_PROMOTE_STORE_PRODUCTS_FAILURE,
        payload: error
    };
};

/*********************PROMOTE_PRODUCT_STORE_BY_BARCODE ACTION************************************/
export const promoteProductStoreByBarcodeRequest = () => {
    return {
        type: storeProductsActionTypes.PROMOTE_PRODUCT_STORE_BY_BARCODE_REQUEST
    };
};

export const promoteProductStoreByBarcodeSuccess = (data: IStoreProducts) => {
    return {
        type: storeProductsActionTypes.PROMOTE_PRODUCT_STORE_BY_BARCODE_SUCCESS,
        payload: data
    };
};

export const promoteProductStoreByBarcodeFailure = (error: IError) => {
    return {
        type: storeProductsActionTypes.PROMOTE_PRODUCT_STORE_BY_BARCODE_FAILURE,
        payload: error
    };
};

/*********************PROMOTE_PRODUCTS_STORE ACTION************************************/
export const promoteProductsStoreRequest = () => {
    return {
        type: storeProductsActionTypes.PROMOTE_PRODUCTS_STORE_REQUEST
    };
};

export const promoteProductsStoreSuccess = (data: IStoreProduct) => {
    return {
        type: storeProductsActionTypes.PROMOTE_PRODUCTS_STORE_SUCCESS,
        payload: data
    };
};

export const promoteProductsStoreFailure = (error: IError) => {
    return {
        type: storeProductsActionTypes.PROMOTE_PRODUCTS_STORE_FAILURE,
        payload: error
    };
};

/*********************PROMOTE_PRODUCTS_STORE_V2 ACTION************************************/
export const promoteProductsStoreV2Request = () => {
    return {
        type: storeProductsActionTypes.PROMOTE_PRODUCTS_STORE_REQUEST_V2
    };
};

export const promoteProductsStoreV2Success = (data: IStoreProduct) => {
    return {
        type: storeProductsActionTypes.PROMOTE_PRODUCTS_STORE_SUCCESS_V2,
        payload: data
    };
};

export const promoteProductsStoreV2Failure = (error: IError) => {
    return {
        type: storeProductsActionTypes.PROMOTE_PRODUCTS_STORE_FAILURE_V2,
        payload: error
    };
};

/*********************PROMOTABLE_PRODUCT_STORE ACTION************************************/
export const promotableProductStoreRequest = () => {
    return {
        type: storeProductsActionTypes.PROMOTABLE_PRODUCT_STORE_REQUEST
    };
};

export const promotableProductStoreSuccess = (data: IStoreProduct) => {
    return {
        type: storeProductsActionTypes.PROMOTABLE_PRODUCT_STORE_SUCCESS,
        payload: data
    };
};

export const promotableProductStoreFailure = (error: IError) => {
    return {
        type: storeProductsActionTypes.PROMOTABLE_PRODUCT_STORE_FAILURE,
        payload: error
    };
};

/*********************PRODUCTS_TO_BE_PROMOTED ACTION************************************/
export const addProductsToBePromoted = (data: IStoreProduct) => {
    return {
        type: storeProductsActionTypes.ADD_PRODUCTS_TO_BE_PROMOTED,
        payload: data
    };
};

export const removeProductsToBePromoted = (data: IStoreProduct) => {
    return {
        type: storeProductsActionTypes.REMOVE_PRODUCTS_TO_BE_PROMOTED,
        payload: data
    };
};

/*********************PRODUCTS_PROMOTED ACTION************************************/
export const addProductsPromoted = (data: IStoreProduct) => {
    return {
        type: storeProductsActionTypes.ADD_PRODUCTS_PROMOTED,
        payload: data
    };
};

export const removeProductsPromoted = (data: IStoreProduct) => {
    return {
        type: storeProductsActionTypes.REMOVE_PRODUCTS_PROMOTED,
        payload: data
    };
};

/*********************PRODUCTS_NO_PROMOTABLE ACTION************************************/
export const addProductsNoPromotable = (data: IStoreProduct) => {
    return {
        type: storeProductsActionTypes.ADD_PRODUCTS_NO_PROMOTABLE,
        payload: data
    };
};

export const removeProductsNoPromotable = (data: IStoreProduct) => {
    return {
        type: storeProductsActionTypes.REMOVE_PRODUCTS_NO_PROMOTABLE,
        payload: data
    };
};

/*********************FETCH SIMILAR_PRODUCTS ACTIONS************************************/
export const fetchSimilarProductsRequest = () => {
    return {
        type: storeProductsActionTypes.FETCH_SIMILAR_PRODUCTS_REQUEST
    };
};

export const fetchSimilarProductsSuccess = (data: any, productId: string) => {
    return {
        type: storeProductsActionTypes.FETCH_SIMILAR_PRODUCTS_SUCCESS,
        payload: data,
        productId: productId
    };
};

export const fetchSimilarProductsFailure = (error: IError) => {
    return {
        type: storeProductsActionTypes.FETCH_SIMILAR_PRODUCTS_FAILURE,
        payload: error
    };
};

/*********************FETCH BASE_PRODUCTS_RELATED ACTIONS************************************/
export const fetchBaseProductsRelatedRequest = () => {
    return {
        type: storeProductsActionTypes.FETCH_BASE_PRODUCTS_RELATED_REQUEST
    };
};

export const fetchBaseProductsRelatedSuccess = (data: any) => {
    return {
        type: storeProductsActionTypes.FETCH_BASE_PRODUCTS_RELATED_SUCCESS,
        payload: data,
    };
};

export const fetchBaseProductsRelatedFailure = (error: IError) => {
    return {
        type: storeProductsActionTypes.FETCH_BASE_PRODUCTS_RELATED_FAILURE,
        payload: error
    };
};

export const AddBaseProductsRelated = (data: any) => {
    return {
        type: storeProductsActionTypes.ADD_BASE_PRODUCTS_RELATED,
        payload: data
    };
};

// Functions
export const noPromoteStoreProducts: ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (products: any) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(noPromoteStoreProductsRequest());
            const response = await instance.patch(`/api/products/store/promotable`, products);
            dispatch(noPromoteStoreProductsSuccess(response));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(noPromoteStoreProductsFailure(data));

            return Result.ERROR;
        }
    };

export const promoteProductStoreByBarcode: ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (body: any) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(promoteProductStoreByBarcodeRequest());
            const response = await instance.patch(`/api/products/promote-by-barcode`, body);
            dispatch(promoteProductStoreByBarcodeSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(promoteProductStoreByBarcodeFailure(data));

            return Result.ERROR;
        }
    };

export const promoteProductsStore: ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (body: any) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(promoteProductsStoreRequest());
            const response = await instance.patch(`/api/products/promote`, body);
            dispatch(promoteProductsStoreSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(promoteProductsStoreFailure(data));

            return Result.ERROR;
        }
    };

export const promoteProductsStoreV2: ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (body: any) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(promoteProductsStoreV2Request());
            const response = await instance.patch(`/api/products/all/promote`, body);
            dispatch(promoteProductsStoreV2Success(response.data));
            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(promoteProductsStoreV2Failure(data));

            return Result.ERROR;
        }
    };

export const promotableProductStore: ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (id: string, reason: string) =>
    async (dispatch: AppDispatch) => {
        try {
            const body = {
                storeProductId: id,
                promotable: true,
                reason
            };

            dispatch(promotableProductStoreRequest());
            const response = await instance.patch(`/api/products/store/${id}/promotable`, body);
            dispatch(promotableProductStoreSuccess(response.data));

            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(promotableProductStoreFailure(data));

            return Result.ERROR;
        }
    };


export const fetchSimilarProducts: ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (currentPage: any = 1, pageSize: any = 20, params: string = '', productId: string, selectedRows: any) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchSimilarProductsRequest());
            const response = await instance.get(`/api/products/store/all?page=${currentPage}&pageSize=${pageSize}${params}`);
            const [newSearchArray] = arraySomeArray(response.data.data, selectedRows);
            response.data.data = newSearchArray;
            dispatch(fetchSimilarProductsSuccess(response.data, productId));
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchSimilarProductsFailure(data));
        }
    };

export const fetchBaseProductsRelated: ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (catalogId: string, barcodes: string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(fetchBaseProductsRelatedRequest());
            const response = await instance.get(`/api/products/store/all/search/base?catalogId=${catalogId}&barcodes=${barcodes}`);
            dispatch(fetchBaseProductsRelatedSuccess(response));
        } catch (error: any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchBaseProductsRelatedFailure(data));
        }
    };
