import { Navigate } from "react-router-dom";
import { hasRole } from "../../keycloak";
import { Role } from "../../types/enum/roles";
import { fetchCountries } from "../../actions/countries.actions";
import { useAppDispatch } from "../../store/store";

const RedirectComponent = () => {
  const dispatch = useAppDispatch();

  if (hasRole([Role.ADMINISTRADOR_GENERAL])) {
    return <Navigate to="/countries" />;
  } else {
    dispatch(fetchCountries());
    return <Navigate to="/stores" />;
  }
};
export default RedirectComponent;
