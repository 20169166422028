import { Table } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const columnsContact = [
    {
      title: 'Orden',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    }
  ];

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableBody = SortableContainer((props: any) => <tbody {...props} />);


const SortableTable = (props:any) =>{
    let {data, columns, returOrderData} = props
    columns = [...columnsContact, ...columns]
    const [dataSource, setDataSource] = useState([])

    const orderData = () =>{
        
    }

    useEffect(() =>{
        const newDate = data.map((item: any, key:any) =>{
            item.key = (key + 1).toString()
            item.index = key
            return item
        })
       setDataSource(newDate)
    }, [data])
     
    

    const onSortEnd = (props: any) => {
        const { oldIndex, newIndex } = props
        if (oldIndex !== newIndex) {    
            const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(
                el => !!el,
              );
            if(returOrderData){
                returOrderData(newData, oldIndex, newIndex)
            }
            setDataSource(newData );
        }
    };

    const DraggableContainer = (props: any) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = (props: any) => {
        const { className, style, ...restProps } = props
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x: any, key:any) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    //if(dataSource.length === 0) return <></>
    return (
        <Table
            pagination={false}
            dataSource={dataSource}
            columns={columns }
            rowKey="index"
            style={{width:"100%"}}
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            }}
        />
    );
}


export default SortableTable;