import CSVFileValidator, { ValidatorConfig } from "csv-file-validator";
import { CSVRow } from "../types/modal.types";

export const validateCsv = async (file: any, CSVConfig: ValidatorConfig) => {
  const data = await CSVFileValidator<CSVRow>(file, CSVConfig);
  const messages: any = [];

  if (data.inValidMessages) {
    data.inValidMessages.forEach((item: string) => {
      messages.push(item);
    });
    return messages;
  }

  return [];
};
