export const catalogsActionTypes = {
    FETCH_CATALOGS_REQUEST: "FETCH_CATALOGS_REQUEST",
    FETCH_CATALOGS_SUCCESS: "FETCH_CATALOGS_SUCCESS",
    FETCH_CATALOGS_FAILURE: "FETCH_CATALOGS_FAILURE",

    FETCH_CATALOG_REQUEST: "FETCH_CATALOG_REQUEST",
    FETCH_CATALOG_SUCCESS: "FETCH_CATALOG_SUCCESS",
    FETCH_CATALOG_FAILURE: "FETCH_CATALOG_FAILURE",

    CATALOG_CLEAN_UP: "CATALOG_CLEAN_UP",

    ADD_CATALOG_CREATE: "ADD_CATALOG_CREATE",

    CREATE_CATALOG_REQUEST:"CREATE_CATALOG_REQUEST",
    CREATE_CATALOG_SUCCESS:"CREATE_CATALOG_SUCCESS",
    CREATE_CATALOG_FAILURE:"CREATE_CATALOG_FAILURE"
};
