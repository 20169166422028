import Select, { SelectValue } from "antd/lib/select";
import { FC } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/store";
import { storesState } from "../../../types/store.types";

const { Option } = Select;

type mode = "multiple" | undefined;

interface IProps {
  initialValue?: string;
  filterOption?: any;
  onChange?: (value: SelectValue, option: any) => void;
  mode?: mode;
  handleChangeSelect?: any;
}
const StoresSelect: FC<IProps> = (props: any) => {
  const { initialValue, onChange, filterOption, mode, handleChangeSelect } =
    props;
  const storesState: storesState = useSelector(
    (state: RootState) => state.stores
  );

  /*
  const handleChange = (value: any) => {
    if (handleChangeSelect) {
      return handleChangeSelect("storeId", value);
    }
    if (onChange) {
      return onChange;
    }
  };*/

  return (
    <Select
      mode={mode ?? undefined}
      defaultValue={initialValue}
      placeholder="Selecciona un comercio"
      onChange={onChange || handleChangeSelect}
      showSearch
      optionFilterProp="children"
      filterOption={filterOption}
      loading={storesState.stores.loading}
      disabled={storesState.stores.loading}
    >
      {storesState.stores.data.data.map((store: any) => {
        return (
          <Option key={store.id} value={store.id} label={store.name}>
            {store.name}
          </Option>
        );
      })}
    </Select>
  );
};
export default StoresSelect;
