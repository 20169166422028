import { Field, Position } from '../../../types/enum/field';
import valuesPromotable from './../../../data/promotable.json';
import valuesCurrency from './../../../data/currency.json';
import valuesUnit from "../../../data/unitOfMeasurement.json";

export const fields = [
    {
        name: 'id',
        type: Field.SEARCH,
        placeholder: 'ID',
        position: Position.HORIZONTAL,
        value: ''
    },
    {
        name: 'barcode',
        type: Field.SEARCH,
        placeholder: 'Código de barra',
        position: Position.HORIZONTAL,
        value: ''
    },
    {
        name: 'description',
        type: Field.INPUT,
        placeholder: 'Descripción',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'contents',
        type: Field.INPUT,
        placeholder: 'Contenido',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'unit',
        type: Field.GENERAL,
        placeholder: 'Unidades de medida',
        mode: 'multiple',
        values: valuesUnit,
        position: Position.VERTICAL,
    },
    {
        name: 'price',
        type: Field.INPUT,
        placeholder: 'Precio',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'taxPercent',
        type: Field.INPUT,
        placeholder: '% de impuesto',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'catalogId',
        type: Field.CATALOG,
        placeholder: '',
        position: Position.VERTICAL,
        value: '60d4f60a378aec6831a3f459'
    },
    {
        name: 'createdAt',
        type: Field.DATEPICKER,
        placeholder: 'Fecha de creación',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'updatedAt',
        type: Field.DATEPICKER,
        placeholder: 'Fecha de modificación',
        position: Position.VERTICAL,
        value: ''
    },
    {
        name: 'storeId',
        type: Field.STORE,
        placeholder: '',
        mode: 'multiple',
        position: Position.VERTICAL,
    },
    /*
    {
        name: 'currency',
        type: Field.GENERAL,
        placeholder: 'Moneda',
        mode: 'multiple',
        values: valuesCurrency,
        position: Position.VERTICAL,
    },
    */
    {
        name: 'promotable',
        type: Field.GENERAL,
        placeholder: 'Tipo de producto',
        values: valuesPromotable,
        position: Position.HORIZONTAL,
        value: null
    },
    {
        name: 'includeBarcode',
        type: Field.CHECKBOX,
        placeholder: 'Con código de barra',
        mode: 'multiple',
        position: Position.VERTICAL, 
    },
]