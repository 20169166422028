import regularExpression from "../utils/regularExpression";

export interface IConfigPowerModel{
    label?:string;
    name:string;
    value?:string;
    required?:boolean;
    type?:string;
    validation?:{expression?:string, message:string};
}

export const configPowerBIModel:IConfigPowerModel[]=[
    { label:'Workspace ID',name: 'workspaceId', required:true, validation:{expression:regularExpression.uniqueIdentifier, message:"Solo se permite identificador unico (UUID)"}},
    { label:'Report ID',name: 'reportId', required:true, validation:{expression:regularExpression.uniqueIdentifier, message:"Solo se permite identificador unico (UUID)"}},
    { label:'Client ID',name: 'clientId', required:true,validation:{expression:regularExpression.uniqueIdentifier, message:"Solo se permite identificador unico (UUID)"}},
    { label:'Username',name: 'username', required:false},
    { label:'Password',name: 'password', required:false},
    { label:'Dataset',name: 'dataset', required:true, validation:{expression:regularExpression.uniqueIdentifier, message:"Solo se permite identificador unico (UUID)"}},
    { label:'Tenant ID',name: 'tenantId', required:true,validation:{expression:regularExpression.uniqueIdentifier, message:"Solo se permite identificador unico (UUID)"}},
    { label:'Client Secret',name: 'clientSecret', required:true},
    { label:'Authentication Mode',name: 'authenticationMode', required:true,validation:{expression:regularExpression.lettersNumbersSpaces, message:"Solo se permite identificador unico (UUID)"}},
    { label:'Authority URI',name: 'authorityUri', required:true, type:'url', validation:{message:"Por favor ingrese un correo electrónico válido."}},
    { label:'Scope',name: 'scope', required:true, type:'url', validation:{message:"Por favor ingrese un correo electrónico válido."}},
    { label:'API URL',name: 'apiUrl', required:true, type:'url', validation:{message:"Por favor ingrese un correo electrónico válido."}}
]
