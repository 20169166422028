export const filtersActionTypes = {
    ADD_FILTER:"ADD_FILTER",
    UPDATE_FILTER:"UPDATE_FILTER",
    FETCH_EXPORT_REQUEST: "FETCH_EXPORT_REQUEST",
    FETCH_EXPORT_SUCCESS: "FETCH_EXPORT_SUCCESS",
    FETCH_EXPORT_ERROR: "FETCH_EXPORT_ERROR",
    CLEAN_UP_FILTER:"CLEAN_UP_FILTER",
    CLEAN_UP_FILTERS:"CLEAN_UP_FILTERS",
    CLEAN_UP_EXPORT: "CLEAN_UP_EXPORT",
    
}