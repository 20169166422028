import { useEffect, useState } from "react";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";

import Table from "../../table/customizedTable/CustomizedTable";
import HelmetTitle from "../../other/HelmetTitle";
import { RootState, useAppDispatch } from "../../../store/store";
import { catalogFilesState } from "../../../types/catalogFiles.types";
import { useSelector } from "react-redux";
import { fetchCatalogFiles } from "../../../actions/catalogFiles.action";
import { Button, Col, message, Row, Tooltip } from "antd";
import CatalogFilesAdd from "./CatalogFilesAdd";
import PageTitle from "../../other/PageTitle";

const CatalogFiles = () => {
  const dispatch = useAppDispatch();
  const catalogFileState: catalogFilesState = useSelector(
    (state: RootState) => state.catalogFiles
  );
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    dispatch(fetchCatalogFiles());
  }, [dispatch]);

  const onClick = () => {
    message.warning("Este evento esta en construcción");
  };

  const onClickButton = () => {
    setShowForm(true);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Nombre de archivo",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "",
      key: "key",
      dataIndex: "key",
      render: (text: any, record: any) => (
        <Tooltip title="Descargar">
          <Button
            type="text"
            shape="circle"
            icon={<CloudDownloadOutlined />}
            className="btn-table"
            style={{ marginRight: 5 }}
            onClick={onClick}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <HelmetTitle
        title="Archivos de catálogo - Backoffice D&#38;N"
        description="Pantalla para listar y generar archivos de catálogo"
      />

      {showForm ? (
        <CatalogFilesAdd setShowForm={setShowForm} />
      ) : (
        <>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <PageTitle title="Archivos de catálogo" />
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }} className="d-flex flex-end">
              <Button type="text" onClick={onClickButton}>
                  <PlusOutlined />
                  Nuevo archivo de catálogo
              </Button>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                dataSource={catalogFileState.catalogFiles.data.data}
                columns={columns}
                name="catálogo"
                path="catalog-files"
                loading={catalogFileState.catalogFiles.loading}
                pagination={{
                  current: catalogFileState.catalogFiles.data.page,
                  pageSize: catalogFileState.catalogFiles.data.pageSize,
                  total: catalogFileState.catalogFiles.data.totalCount,
                  onChange: (page: number, pageSize: number) => {
                    dispatch(fetchCatalogFiles(page, pageSize));
                  },
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CatalogFiles;
