export const usersActionTypes= {
    FETCH_USERS_REQUEST: "FETCH_USERS_REQUEST",
    FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
    FETCH_USERS_FAILURE: "FETCH_USERS_FAILURE",
    
    FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
    FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
    FETCH_USER_FAILURE: "FETCH_USER_FAILURE",
    
    DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
    
    CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
    
    UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

    ENABLE_USER_REQUEST: "ENABLE_USER_REQUEST",
    ENABLE_USER_SUCCESS: "ENABLE_USER_SUCCESS",
    ENABLE_USER_FAILURE: "ENABLE_USER_FAILURE",

    DISABLE_USER_REQUEST: "DISABLE_USER_REQUEST",
    DISABLE_USER_SUCCESS: "DISABLE_USER_SUCCESS",
    DISABLE_USER_FAILURE: "DISABLE_USER_FAILURE",
    
    USER_CLEAN_UP: "USER_CLEAN_UP"
}