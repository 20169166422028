import { authActionsTypes } from "../constants/index";
import { Action } from "../types/actions.types";
import { IAuth, authState } from "../types/auth.types";

let auth: IAuth = { username: "", email: "" };

const initialState = {
  ...auth
};

const loginReducer = (state = initialState, action: Action): authState => {
  switch (action.type) {
    case authActionsTypes.ADD_USER_LOGGED_IN:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default loginReducer;
