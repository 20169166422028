import { keycloak } from "..";

export const login = () => {
  return keycloak.authenticated;
};

export const logout = () => {
  keycloak.logout();
};

export const userInfo = () => {
  keycloak.loadUserInfo();
};

export const userIsLoggedIn = () => {
  return keycloak.authenticated;
};

export const authenticationService = {
  login,
  logout,
  userInfo,
  isLoggedIn: keycloak.authenticated,
};
