import { Button, Col, Input, Modal, Row } from "antd";
import { ProductType } from "../../../../types/enum/modalType";
import Table from "../../../table/customizedTable/CustomizedTable";

const { Search } = Input;

const AddProductsModal = (props: any) => {
  const {
    modalType,
    showAddProductsModal,
    handleCancelAddProductsModal,
    newPromotionProducts,
    addNewPromotionProducts,
    onSearchAddProducts,
    storeProductsState,
    pagination,
    columnsAddProductsTable,
    columnsAddProductsBaseTable,
    handleRowSelectionAddProductsTable,
    searchValue,
    setSearchValue,
    addButtonCreateProduct
  } = props;

  const title =
    modalType === ProductType.STORE
      ? "Adicionar más productos tienda"
      : "Adicionar más productos base";

  const columns =
    modalType === ProductType.STORE
      ? columnsAddProductsTable
      : columnsAddProductsBaseTable;

  return (
    <Modal
      centered
      title={title}
      visible={showAddProductsModal}
      onCancel={handleCancelAddProductsModal}
      footer={[
        <Button key="cerrar" onClick={handleCancelAddProductsModal}>
          Cerrar
        </Button>,
        <Button
          key="adicionar"
          type="primary"
          disabled={!newPromotionProducts?.length ? true : false}
          onClick={() => addNewPromotionProducts(modalType)}
        >
          Adicionar
        </Button>,

        addButtonCreateProduct && <Button key="crear" onClick={addButtonCreateProduct}>
          Crear nuevo producto
        </Button>,

      ]}
      width="60rem"
    >
      <Row gutter={[0, 6]}>
        <Col span={24}>
          <Search
            placeholder="Buscar productos de tienda"
            enterButton="Buscar"
            className="filterAditional"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={(value: string) => onSearchAddProducts(value, modalType)}
            loading={storeProductsState?.searchProducts.loading}
            maxLength={50}
          />
        </Col>

        <Col span={24}>
          <Table
            loading={storeProductsState?.searchProducts.loading}
            pagination={pagination}
            dataSource={storeProductsState?.searchProducts.data.data}
            columns={columns}
            handleRowSelection={handleRowSelectionAddProductsTable}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default AddProductsModal;
