import { Menu } from "antd";
import { nameModules } from "../../types/enum/modules.types";
import { itemModules } from "./ItemsModules";

const ModuleList = (props:any) => {
    const { menu, handleChangeMenu }  = props;

    return (
        <Menu
            selectedKeys={[menu.toLowerCase()]}
        >
            {itemModules.map((item:any, key:number) =>{
                return <Menu.Item key={key}  onClick={ () =>   handleChangeMenu(item.name, item.url)  }>
                {item.title}
            </Menu.Item>
            })}
        
        </Menu>
    );
};

export default ModuleList;