import { useEffect, useState } from "react";
import { combineKeyValueArray, convertArrayToObject } from "../utils/arrayFunctions";
import { RootState, useAppDispatch } from "../store/store";
import {
  addFilter,
  fetchFilterExport,
  updateFilter,
} from "../actions/filters.actions";
import { FormInstance } from "antd";
import { FilterSection } from "../types/enum/filters";
import { useSelector } from "react-redux";
import { filtersState } from "../types/filters.types";

export const useFilters = (
  dispatchAction: any,
  fields: any,
  section: FilterSection,
  defaultParams: string = "",
  form?: FormInstance,
  pageSize?:number | undefined
) => {

  //const catalogId = "60d4f60a378aec6831a3f459";
  const initialFilter = convertArrayToObject(fields)
  const dispatch = useAppDispatch();
  const [restartSearch, setRestartSearch] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [filters, setFilters] = useState(initialFilter);
  const [queryParams, setQueryParams] = useState(defaultParams);

  const filtersState: filtersState = useSelector(
    (state: RootState) => state.filters
  );

  useEffect(() => {
    dispatch(addFilter(fields, section));
  }, []);

  useEffect(() => {
    if (form) {
      form.setFieldsValue(filters);
    }
  }, [filters, form]);

  useEffect(() => {
    if (restartSearch) {
      onFinishFiltering(filters);
    }
  }, [restartSearch]);

  const handleDonwload = (
    setLoading: any,
    setDownload: any,
    buttonRef: any
  ) => {
    setLoading(true);
    dispatch(
      fetchFilterExport(
        queryParams,
        section,
        setLoading,
        setDownload,
        buttonRef,
        pageSize
      )
    );
  };

  const cancelPromote = () => {
    if (form) {
      form.setFieldsValue(filters);
    }
  };

  //reset a specific field within the form
  const resetField = (item:string)=>{
    form?.resetFields([item]);
  }

  const handleChange = (item: string, e: any, type: number = 1) => {
    setFilters({
      ...filters,
      [item]: e.target.value,
    });
  };


  const handleChangeSelect = (item: string, value: any) => {

    /*****only if the field has the Onchange property*************/
    const selectedField = fields.find(({name}:any)=> name === item)
    if (selectedField.hasOwnProperty('onChange')) {
      selectedField?.onChange(item, value);
    }
    //**********************************************************/
    let newFilters = {...filters, [item]: value}

    if (item==="catalogId"){
      newFilters =  {...newFilters, categoryId:[]};
      resetField("categoryId");
    }

    setFilters(newFilters);
  };

  const toggleFilters = () => {
    setCollapsed(!collapsed);
  };

  const generateParams = (values: any) => {
    const dateArray = ["createdAt", "updatedAt", "date"];
    let newValues: any = {};
    Object.keys(values).forEach((index: string) => {
      if (values[index] || values[index] === false) {
        newValues[index] = values[index];
      }
    });

    /*****************************combineKeyValueArray***************************/
    return combineKeyValueArray(newValues, dateArray);
  };

  const reloadFilter = () =>{
    onFinishFiltering(filters)
  }

  const onFinishFiltering = (values: any) => {
    const params = generateParams(values);
    setFilters({...values, ...filters});
    setQueryParams(params);
    dispatch(dispatchAction(1, 20, params));

    dispatch(updateFilter(values, section));
    setFilters(values);
    setRestartSearch(false);
  };

  const resetFilter = (values: any = {}) => {
    const params = generateParams(initialFilter)
    setFilters(initialFilter);
    dispatch(updateFilter(initialFilter, section));
    setQueryParams(params);
    dispatch(dispatchAction(1, 20, params));

     /*****only if the fields has the onReset property*************/
     fields.map((item:any)=> item.hasOwnProperty('onReset') &&  item?.onReset());
     //**********************************************************/
    
    form && form.resetFields();
  };

  return {
    filters,
    toggleFilters,
    collapsed,
    onFinishFiltering,
    handleChange,
    handleChangeSelect,
    resetFilter,
    queryParams,
    form,
    cancelPromote,
    restartSearch,
    setRestartSearch,
    filtersState,
    handleDonwload,
    reloadFilter
  };
};
