import { Button, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import CopyTextColumn from "../../other/CopyTextColumn";

export const Columns = (handleEdit: any) => {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: any) => {
        return <CopyTextColumn text={text} record={record} />;
      },
    },
    {
      title: "Código de barra",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a: any, b: any) => a.barcode - b.barcode,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Contenido",
      dataIndex: "contents",
      key: "contents",
      sorter: (a: any, b: any) => a.contents - b.contents,
    },
    {
      title: "Unidad",
      dataIndex: "unit",
      key: "unit",
      sorter: (a: any, b: any) => a.unit.localeCompare(b.unit),
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      sorter: (a: any, b: any) => a.price - b.price,
    },
    {
      title: "% de impuesto",
      dataIndex: "taxPercent",
      key: "taxPercent",
      sorter: (a: any, b: any) => a.taxPercent - b.taxPercent,
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      render: (text: any, record: any) => {
        return (
          <div className="table__button-container">
            <Tooltip title="Editar">
              <Button
                type="text"
                shape="circle"
                icon={<EditOutlined />}
                className="btn-table"
                style={{ marginRight: 5 }}
                onClick={() => handleEdit(record.id)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
};
