import React, { useState } from 'react';

import DatePicker, { registerLocale } from 'react-datepicker';
import { addDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import "../index.css"

export const InputDate = ({ onChangesDate, numberDays }: any) => {
    const [startDate, setStartDate] = useState < Date | any > (null);
    const [endDate, setEndDate] = useState < Date | any > (null);
    registerLocale('es', es);
    const numberDaysParms = numberDays

    const returFormaDate = (date: any) => {
        return new Date(date).toISOString().split('T')[0]
    }

    const handleStartDateChange = (date: Date | any) => {
        setStartDate(date);
        setEndDate(null); // Reinicia la fecha de fin
        onChangesDate(returFormaDate(date), null);

    };

    const handleEndDateChange = (date: Date | any) => {
        if (startDate && date) {
            if (date < startDate) {
                setEndDate(startDate);
            } else if (date > addDays(startDate, numberDaysParms)) {
                setEndDate(addDays(startDate, numberDaysParms));
            } else {
                setEndDate(date);
            }
        } else {
            setEndDate(date);
        }
        onChangesDate(startDate ? returFormaDate(startDate) : null, returFormaDate(date));
    };

    return (
        <>
            <div className="styleDateRangeFilter" style={{ display: "inline-block" }}>
                <div className="inputDateRange">
                    <label >Fecha inicio</label>
                    <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        selectsStart
                        startDate={startDate}
                        maxDate={new Date()} 
                        endDate={endDate}
                        dateFormat="yyyy-MM-dd"
                        locale="es"
                        className=""
                    />
                </div>
                <div className="inputDateRange">
                    <label >Fecha fin</label>
                    <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        maxDate={startDate ? addDays(startDate, numberDaysParms) : new Date()}
                        dateFormat="yyyy-MM-dd"
                        locale="es"
                        className=""
                    />
                </div>
                {/* 
                {startDate && endDate && (
                    <p className="">
                        Rango seleccionado: {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                    </p>
                )}
                    */}
            </div>
        </>
    )
}