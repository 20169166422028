import { storeProductsActionTypes } from "../../constants"
import { AppDispatch } from "../../store/store"

// Actions
export const cleanUpSimilarProductData = (barcode:string) =>{
    return{
        type: storeProductsActionTypes.SIMILAR_PRODUCT_CLEAN_UP,
        barcode
    }
}
export const cleanUpSimilarProductsData = () =>{
    return{
        type: storeProductsActionTypes.SIMILAR_PRODUCTS_CLEAN_UP,
    }
}
export const cleanUpStoreProductData = () =>{
    return{
        type: storeProductsActionTypes.STORE_PRODUCT_CLEAN_UP
    }
}
export const resetStoreProductData = () =>{
    return{
        type: storeProductsActionTypes.STORE_PRODUCT_RESET
    }
}
export const cleanUpStoreProductsData = () =>{
    return{
        type: storeProductsActionTypes.STORE_PRODUCTS_CLEAN_UP
    }
}
export const cleanUpBarcodeProductData = () =>{
    return{
        type: storeProductsActionTypes.BARCODE_PRODUCT_CLEAN_UP
    }
}
export const cleanUpNoBarcodeProductData = () =>{
    return{
        type: storeProductsActionTypes.NO_BARCODE_PRODUCT_CLEAN_UP
    }
}
export const cleanUpProductsToBePromotedData = () =>{
    return{
        type: storeProductsActionTypes.PRODUCTS_TO_BE_PROMOTED_CLEAN_UP
    }
}
export const cleanUpProductsPromotedData = () =>{
    return{
        type: storeProductsActionTypes.PRODUCTS_PROMOTED_CLEAN_UP
    }
}
export const cleanUpProductsNoPromotableData = () =>{
    return{
        type: storeProductsActionTypes.PRODUCTS_NO_PROMOTABLE_CLEAN_UP
    }
}
export const cleanUpBaseProductRelatedData = (barcode:string) =>{
    return{
        type: storeProductsActionTypes.BASE_PRODUCT_RELATED_CLEAN_UP,
        barcode
    }
}
export const cleanUpBaseProductsRelatedData = () =>{
    return{
        type: storeProductsActionTypes.BASE_PRODUCTS_RELATED_CLEAN_UP
    }
}

// Functions
export const cleanupStoreProduct = () => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpStoreProductData());
    }
};

export const cleanupStoreProducts = () => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpStoreProductsData());
    }
};

export const cleanupSimilarProduct = (barcode:string) => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpSimilarProductData(barcode));
    }
};

export const cleanupSimilarProducts = () => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpSimilarProductsData());
    }
};

export const cleanupBaseProductRelated = (barcode:string) => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpBaseProductRelatedData(barcode));
    }
};

export const cleanupBaseProductsRelated = () => {
    return (dispatch:AppDispatch) => {
        dispatch(cleanUpBaseProductsRelatedData());
    }
};

export const resetStoreProduct = () => {
    return (dispatch:AppDispatch) => {
        dispatch(resetStoreProductData());
    }
};