import { Select } from "antd";
import { FC } from "react";

import showTaxData from "../../../data/showTax.json";
const { Option } = Select;

type mode = "multiple" | undefined;

interface IProps {
  mode?: mode;
  initialValue?: any;
  onChange?: ((value: any[], option: any) => void) | undefined;
}

const TaxDetailSelect: FC<IProps> = (props) => {
  const { initialValue, onChange, mode=undefined } = props;
  return (
    <Select
      mode={mode ?? undefined}
      placeholder="Seleccione una opción"
      optionLabelProp="label"
      defaultValue={initialValue}
      onChange={onChange}
    >
      {showTaxData.map((option: any, idx: number) => (
        <Option key={idx} value={option.value} label={option.name==="Sí" ? "Mostrar" :"No mostrar"}>
          {option.name==="Sí" ? "Mostrar" :"No mostrar"}
        </Option>
      ))}
    </Select>
  );
};
export default TaxDetailSelect;
