import { IStoreProduct, storeProductsAction } from '../../types/storeProducts.types';
import { storeProductsActionTypes } from "../../constants/index";
import { IError } from "../../types/interfaces/error.interface";
import { ThunkAction } from 'redux-thunk';
import { AppDispatch, RootState } from '../../store/store';
import { ActionCreator } from "redux";
import instance from '../../utils/axios.instance';
import { Result } from '../../types/enum/result';

/*********************FETCH STORE_PRODUCTS ACTIONS************************************/
export const fetchStoreProductsRequest = () => {
    return {
        type: storeProductsActionTypes.FETCH_STORE_PRODUCTS_REQUEST
    };
};

export const fetchStoreProductsSuccess = (data:any) => {
    return {
        type: storeProductsActionTypes.FETCH_STORE_PRODUCTS_SUCCESS,
        payload: data
    };
};

export const fetchStoreProductsFailure = (error:IError) => {
    return {
        type: storeProductsActionTypes.FETCH_STORE_PRODUCTS_FAILURE,
        payload: error
    };
};

/*********************FETCH STORE_PRODUCTS ACTIONS************************************/
export const fetchStoreProductRequest = () => {
    return {
        type: storeProductsActionTypes.FETCH_STORE_PRODUCT_REQUEST
    };
};

export const fetchStoreProductSuccess = (data:any) => {
    return {
        type: storeProductsActionTypes.FETCH_STORE_PRODUCT_SUCCESS,
        payload: data
    };
};

export const fetchStoreProductFailure = (error:IError) => {
    return {
        type: storeProductsActionTypes.FETCH_STORE_PRODUCT_FAILURE,
        payload: error
    };
};

/*********************BARCODE_PRODUCT ACTION************************************/
export const addBarcodeProduct = (data:IStoreProduct) => {
    return {
        type: storeProductsActionTypes.ADD_BARCODE_PRODUCT,
        payload: data
    };
};

export const removeBarcodeProduct = (data:IStoreProduct) => {
    return {
        type: storeProductsActionTypes.REMOVE_BARCODE_PRODUCT,
        payload: data
    };
};

/*********************BARCODE_PRODUCT ACTION************************************/
export const addNoBarcodeProduct = (data:IStoreProduct) => {
    return {
        type: storeProductsActionTypes.ADD_NO_BARCODE_PRODUCT,
        payload: data
    };
};

export const removeNoBarcodeProduct = (data:IStoreProduct) => {
    return {
        type: storeProductsActionTypes.REMOVE_NO_BARCODE_PRODUCT,
        payload: data
    };
};

/*********************UPDATE BASE_PRODUCT ACTION************************************/
export const updateStoreProductRequest = () => {
    return {
        type: storeProductsActionTypes.UPDATE_STORE_PRODUCT_REQUEST
    };
};

export const updateStoreProductSuccess = (data:IStoreProduct) => {
    return {
        type: storeProductsActionTypes.UPDATE_STORE_PRODUCT_SUCCESS,
        payload: data
    };
};

export const updateStoreProductFailure = (error:IError) => {
    return {
        type: storeProductsActionTypes.UPDATE_STORE_PRODUCT_FAILURE,
        payload: error
    };
};

// Functions
export const fetchStoreProducts:ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (currentPage:any = 1, pageSize:any = 20,params:string = '') => 
    async (dispatch:AppDispatch) => {
        try {
            dispatch(fetchStoreProductsRequest());
            const response = await instance.get(`/api/products/store/all?page=${currentPage}&pageSize=${pageSize}${params}`);
            dispatch(fetchStoreProductsSuccess(response.data));
        } catch (error:any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchStoreProductsFailure(data));
        }
    };

export const fetchStoreProduct:ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (id:string) => 
    async (dispatch:AppDispatch) => {
        try {
            dispatch(fetchStoreProductRequest());
            const response = await instance.get(`/api/products/store/all/${id}`);
            dispatch(fetchStoreProductSuccess(response.data));
        } catch (error:any) {
            const data: IError = {
                status: error.response.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchStoreProductFailure(data));
        }
    };

export const updateStoreProduct:ActionCreator<ThunkAction<void, RootState, null, storeProductsAction>> = (body:any, id:string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(updateStoreProductRequest());
            const response = await instance.patch(`/api/products/store/all/${id}`, body);
            dispatch(updateStoreProductSuccess(response.data));
            return Result.OK;
        } catch (error: any) {
            const data: IError = {
                status: error?.response?.status,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(updateStoreProductFailure(data));
            return Result.ERROR;
        }
    };