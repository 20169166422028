import { Avatar, Badge, Button, Col, Dropdown, Layout as LayoutAntd, Menu, Row } from "antd";
import { 
    MenuOutlined, 
    BellOutlined, 
} from '@ant-design/icons';
import logo from '../../../assets/logo_1.png';
import logo_2 from '../../../assets/logo_2.png';
import './Header.css';
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../store/store";

import {authState} from "../../../types/auth.types";
import ModuleSelector from "../ModuleSelector";
import { logout } from "../../../keycloak/auth/authentication.service";

const { Header } = LayoutAntd;

interface IHeaderApp {
    toggleCollapsed: any
};

const HeaderApp: FC<IHeaderApp> = ({ toggleCollapsed }) => {
    const dispatch = useAppDispatch();
    const authState: authState = useSelector(
        (state: RootState) => state.auth
    );
    const name:any = authState.firstName;
    const nameFirstLetter = name?.charAt(0).toUpperCase();
    const [screenWidth, setScreenWidth]:any = useState(null);

    const handleLogout = () => {
        dispatch(logout());
    };
    
    const AvatarOptions = () => {
        return (
            <Menu>
                <Menu.Item key="0" onClick={handleLogout}>
                    Cerrar sesión
                </Menu.Item>
            </Menu>
        );
    };

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, []);

    return (
        <Header className="header flex-container">
            <Row style={{ flex: 1 }}>
                <Col xs={{ span: 17 }} sm={{ span: 12 }} className="flex-container">
                    <Row>
                        <Col className="flex-container">
                            <Button onClick={toggleCollapsed} className="header__menu-button">
                                <MenuOutlined />
                            </Button>
                        </Col>

                        <Col 
                            xs={{ span: 1 }}
                            sm={{ span: 12 }}
                        >
                            <img src={screenWidth < 576 ? logo_2 : logo} alt="Logo Dichter y Neira" />
                        </Col>
                    </Row>
                </Col>

                <Col xs={{ span: 7 }} sm={{ span: 12 }} className="flex-container">
                    <Row style={{ flex: 'auto' }} className="flex-end">
                        <Col
                            xs={{ span: 0 }}
                            sm={{ span: 11 }}
                            md={{ span: 8 }}
                            lg={{ span: 6 }}
                            xl={{ span: 5 }}
                            xxl={{ span: 4 }}
                        >
                            <ModuleSelector />
                        </Col>

                        <Col 
                            className="flex-container"
                        >
                            <Badge 
                                count={0}
                                size="small"
                                offset={[-20, 2]}
                            >
                                <Avatar 
                                    style={{ 
                                        backgroundColor: "transparent",
                                        marginRight: 15
                                    }}
                                    icon={
                                        <BellOutlined 
                                            title="Notificaciones" 
                                            style={{
                                                color: 'white',
                                                fontSize: 19
                                            }}
                                        />
                                    } 
                                />
                            </Badge>
                        </Col>

                        <Col 
                            className="flex-container"
                        >
                            <Dropdown overlay={AvatarOptions} trigger={['click']}>
                                <Avatar className="header__avatar">
                                    <span onClick={e => e.preventDefault()}>
                                        {nameFirstLetter}
                                    </span>
                                </Avatar>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Header>
    );
};

export default HeaderApp;