import { Col, Row } from "antd";
import HelmetTitle from "../other/HelmetTitle";
import PageTitle from "../other/PageTitle";

const Forbidden = () => {
  return (
    <>
      <HelmetTitle
        title="Acceso denegado - Backoffice D&#38;N"
        description="Acceso denego al usuario"
      />
      <Row>
        <Col span={24} className="">
          <Col span={24}>
            <PageTitle title="Acceso denegado" />
          </Col>

          <Col span={24} className="">
            El acceso a este recurso ha sido denegado.
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default Forbidden;
