import { Route, Routes } from "react-router-dom";
import routes from "./routes";

import Forbidden from "../components/other/Forbidden";
import { hasRole } from "../keycloak";

const AppRouter = () => {
  return (
    <Routes>
      {routes.map(({ path, Component, permissions }, key) =>
        hasRole(permissions) ? (
          <Route key={key} path={path} element={<Component />} />
        ) : (
          <Route key={key} path={path} element={<Forbidden />} />
        )
      )}
    </Routes>
  );
};

export default AppRouter;
