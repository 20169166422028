export const storeProductsActionTypes= {
    FETCH_STORE_PRODUCTS_REQUEST: "FETCH_STORE_PRODUCTS_REQUEST",
    FETCH_STORE_PRODUCTS_SUCCESS: "FETCH_STORE_PRODUCTS_SUCCESS",
    FETCH_STORE_PRODUCTS_FAILURE: "FETCH_STORE_PRODUCTS_FAILURE",

    FETCH_STORE_PRODUCT_REQUEST: "FETCH_STORE_PRODUCT_REQUEST",
    FETCH_STORE_PRODUCT_SUCCESS: "FETCH_STORE_PRODUCT_SUCCESS",
    FETCH_STORE_PRODUCT_FAILURE: "FETCH_STORE_PRODUCT_FAILURE",
    
    FETCH_SIMILAR_PRODUCTS_REQUEST: "FETCH_SIMILAR_PRODUCTS_REQUEST",
    FETCH_SIMILAR_PRODUCTS_SUCCESS: "FETCH_SIMILAR_PRODUCTS_SUCCESS",
    FETCH_SIMILAR_PRODUCTS_FAILURE: "FETCH_SIMILAR_PRODUCTS_FAILURE",

    FETCH_BASE_PRODUCTS_RELATED_REQUEST: "FETCH_BASE_PRODUCTS_RELATED_REQUEST",
    FETCH_BASE_PRODUCTS_RELATED_SUCCESS: "FETCH_BASE_PRODUCTS_RELATED_SUCCESS",
    FETCH_BASE_PRODUCTS_RELATED_FAILURE: "FETCH_BASE_PRODUCTS_RELATED_FAILURE",

    ADD_BASE_PRODUCTS_RELATED: "ADD_BASE_PRODUCTS_RELATED",

    STORE_PRODUCT_RESET: "STORE_PRODUCT_RESET",

    STORE_PRODUCT_CLEAN_UP: "STORE_PRODUCT_CLEAN_UP",
    STORE_PRODUCTS_CLEAN_UP: "STORE_PRODUCTS_CLEAN_UP",
    SIMILAR_PRODUCT_CLEAN_UP: "SIMILAR_PRODUCT_CLEAN_UP",
    SIMILAR_PRODUCTS_CLEAN_UP: "SIMILAR_PRODUCTS_CLEAN_UP",
    BASE_PRODUCT_RELATED_CLEAN_UP: "BASE_PRODUCT_RELATED_CLEAN_UP",
    BASE_PRODUCTS_RELATED_CLEAN_UP: "BASE_PRODUCTS_RELATED_CLEAN_UP",
    FETCH_STORE_PRODUCTS_SEARCH_CLEAN_UP: "FETCH_STORE_PRODUCTS_SEARCH_CLEAN_UP",

    PROMOTE_PRODUCT_STORE_BY_BARCODE_REQUEST: "PROMOTE_PRODUCT_STORE_BY_BARCODE_REQUEST",
    PROMOTE_PRODUCT_STORE_BY_BARCODE_SUCCESS: "PROMOTE_PRODUCT_STORE_BY_BARCODE_SUCCESS",
    PROMOTE_PRODUCT_STORE_BY_BARCODE_FAILURE: "PROMOTE_PRODUCT_STORE_BY_BARCODE_FAILURE",

    PROMOTE_PRODUCTS_STORE_REQUEST: "PROMOTE_PRODUCTS_STORE_REQUEST",
    PROMOTE_PRODUCTS_STORE_SUCCESS: "PROMOTE_PRODUCTS_STORE_SUCCESS",
    PROMOTE_PRODUCTS_STORE_FAILURE: "PROMOTE_PRODUCTS_STORE_FAILURE",

    PROMOTE_PRODUCTS_STORE_REQUEST_V2: "PROMOTE_PRODUCTS_STORE_REQUEST_V2",
    PROMOTE_PRODUCTS_STORE_SUCCESS_V2: "PROMOTE_PRODUCTS_STORE_SUCCESS_V2",
    PROMOTE_PRODUCTS_STORE_FAILURE_V2: "PROMOTE_PRODUCTS_STORE_FAILURE_V2",

    PROMOTABLE_PRODUCT_STORE_REQUEST: "PROMOTABLE_PRODUCT_STORE_REQUEST",
    PROMOTABLE_PRODUCT_STORE_SUCCESS: "PROMOTABLE_PRODUCT_STORE_SUCCESS",
    PROMOTABLE_PRODUCT_STORE_FAILURE: "PROMOTABLE_PRODUCT_STORE_FAILURE",

    ADD_BARCODE_PRODUCT: "ADD_BARCODE_PRODUCT",
    REMOVE_BARCODE_PRODUCT: "REMOVE_BARCODE_PRODUCT",
    BARCODE_PRODUCT_CLEAN_UP: "BARCODE_PRODUCT_CLEAN_UP",

    ADD_NO_BARCODE_PRODUCT: "ADD_NO_BARCODE_PRODUCT",
    REMOVE_NO_BARCODE_PRODUCT: "REMOVE_NO_BARCODE_PRODUCT",
    NO_BARCODE_PRODUCT_CLEAN_UP: "NO_BARCODE_PRODUCT_CLEAN_UP",

    ADD_PRODUCTS_TO_BE_PROMOTED: "ADD_PRODUCTS_TO_BE_PROMOTED",
    REMOVE_PRODUCTS_TO_BE_PROMOTED: "REMOVE_PRODUCTS_TO_BE_PROMOTED",
    PRODUCTS_TO_BE_PROMOTED_CLEAN_UP: "PRODUCTS_TO_BE_PROMOTED_CLEAN_UP",

    ADD_PRODUCTS_PROMOTED: "ADD_PRODUCTS_PROMOTED",
    REMOVE_PRODUCTS_PROMOTED: "REMOVE_PRODUCTS_PROMOTED",
    PRODUCTS_PROMOTED_CLEAN_UP: "PRODUCTS_PROMOTED_CLEAN_UP",

    ADD_PRODUCTS_NO_PROMOTABLE: "ADD_PRODUCTS_NO_PROMOTABLE",
    REMOVE_PRODUCTS_NO_PROMOTABLE: "REMOVE_PRODUCTS_NO_PROMOTABLE",
    PRODUCTS_NO_PROMOTABLE_CLEAN_UP: "PRODUCTS_NO_PROMOTABLE_CLEAN_UP",

    UPDATE_STORE_PRODUCT_REQUEST: "UPDATE_STORE_PRODUCT_REQUEST",
    UPDATE_STORE_PRODUCT_SUCCESS: "UPDATE_STORE_PRODUCT_SUCCESS",
    UPDATE_STORE_PRODUCT_FAILURE: "UPDATE_STORE_PRODUCT_FAILURE",

    NO_PROMOTE_STORE_PRODUCTS_REQUEST: "NO_PROMOTE_STORE_PRODUCTS_REQUEST",
    NO_PROMOTE_STORE_PRODUCTS_SUCCESS: "NO_PROMOTE_STORE_PRODUCTS_SUCCESS",
    NO_PROMOTE_STORE_PRODUCTS_FAILURE: "NO_PROMOTE_STORE_PRODUCTS_FAILURE",

    FETCH_STORE_PRODUCTS_SEARCH_REQUEST: "FETCH_STORE_PRODUCTS_SEARCH_REQUEST",
    FETCH_STORE_PRODUCTS_SEARCH_SUCCESS: "FETCH_STORE_PRODUCTS_SEARCH_SUCCESS",
    FETCH_STORE_PRODUCTS_SEARCH_FAILURE: "FETCH_STORE_PRODUCTS_SEARCH_FAILURE",
}