import { Form, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createBaseProduct, fetchBaseProducts, updateBaseProduct } from "../actions/baseProducts.actions";
import { fetchCatalog } from "../actions/catalogs.actions";
import { promoteProductsStore, promoteProductStoreByBarcode } from "../actions/storeProducts/promotion.actions";
import { RootState, useAppDispatch } from "../store/store";
import { baseProductsState } from "../types/baseProducts.types";
import { catalogsState } from "../types/catalogs.types";
import { ProductBaseEnum } from "../types/enum/productBaseFields";
import { Result } from "../types/enum/result";
import { storeProductsState } from "../types/storeProducts.types";

const useProductsBaseForm = (props:any) => {
    const baseProductsState:baseProductsState = useSelector((state:RootState) => state.baseProducts);
    const catalogsState:catalogsState = useSelector((state:RootState) => state.catalogs);
    const storeProductsState:storeProductsState = useSelector((state:RootState) => state.storeProducts);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location: any = useLocation();
    const state = location.state;
    const [formInstance] = Form.useForm();
    const dispatch = useAppDispatch();
    let withBarcode = false;

    if (state) {
        withBarcode = state.withBarcode;
    }

    const onChangeCatalog = (value:any) => {
        formInstance.setFieldsValue({
            'taxPercent': 0,
        })
        dispatch(fetchCatalog(value));
    };

    const handleBreadcrumbOnClick = () => {
        props.setShowForm(false);
    };

    //disable fields in ProductsBaseForm
    const prodBaseDisableFields:any = [
        ProductBaseEnum.Barcode,
        ProductBaseEnum.Currency,
        ProductBaseEnum.CatalogId,
        ProductBaseEnum.CategoryId,
    ];

    const onFinishProductBaseForm = (values:any, state:any) => {
        setLoading(true);

        const isEditable = state === null ? true : false;

        if (!isEditable) {
            dispatch(createBaseProduct(values)).then((result:any) => {
                if (result.error !== Result.ERROR) {
                    const baseProductId = result.data.id;
                    const withBarcode = state.withBarcode ? true : false;
    
                    if (withBarcode) {
                        const bodyPromote = {
                            catalogId: baseProductsState.baseProduct.data.category.catalogId,
                            storeProductBarcode: baseProductsState.baseProduct.data.barcode
                        };
    
                        dispatch(promoteProductStoreByBarcode(bodyPromote)).then((result:any) => {
                            if (result === Result.OK) {
                                dispatch(fetchBaseProducts(
                                    baseProductsState.baseProducts.data.page, 
                                    baseProductsState.baseProducts.data.pageSize
                                ));
                                message.success(`El producto "${values.description}" ha sido creado con éxito`);
                                navigate('/products-base');
                            } else {
                                message.error(baseProductsState.baseProduct.error.data.message);
                            }
                            setLoading(false);
                        });
                    } else {
                        const storeProductsIds:any = storeProductsState.noBarcodeProducts.data.map((obj:any) => {
                            return obj.id
                        });
    
                        const bodyPromote = {
                            baseProductId,
                            storeProductsIds
                        };
    
                        dispatch(promoteProductsStore(bodyPromote)).then((result:any) => {
                            if (result === Result.OK) {
                                dispatch(fetchBaseProducts(
                                    baseProductsState.baseProducts.data.page, 
                                    baseProductsState.baseProducts.data.pageSize
                                ));
                                message.success(`El producto "${values.description}" ha sido creado con éxito`);
                                navigate('/products-base');
                            } else {
                                message.error(baseProductsState.baseProduct.error.data.message);
                            }
                            setLoading(false);
                        });
                    }
                } else {
                    // message.error(error.response.data.message[0]);
                    message.error(baseProductsState.baseProduct.error.data.message);
                    setLoading(false);
                }
            });
        } else {
            dispatch(updateBaseProduct(values, baseProductsState.baseProduct.data.id)).then((result:any) => {
                if (result === Result.OK) {
                    dispatch(fetchBaseProducts(
                        baseProductsState.baseProducts.data.page, 
                        baseProductsState.baseProducts.data.pageSize,
                        props.queryParams
                    ));
                    message.success(`El producto "${values.description}" ha sido modificado con éxito`);
                    props.setShowForm(false);
                } else {
                    message.error(baseProductsState.baseProduct.error.data.message);
                }

                setLoading(false);
            });
        }
    };

    useEffect(()=>{
        if (baseProductsState.baseProduct.data.catalogId) {
            dispatch(fetchCatalog(baseProductsState.baseProduct.data.catalogId));
        }
    }, [baseProductsState.baseProduct.data.catalogId, dispatch]);

    return {
        baseProductsState,
        catalogsState,
        loading,
        location,
        formInstance,
        state,
        onChangeCatalog,
        handleBreadcrumbOnClick,
        onFinishProductBaseForm,
        withBarcode,
        prodBaseDisableFields,
    };
};

export default useProductsBaseForm;