export const ticketsActionTypes= {
    FETCH_TICKETS_REQUEST: "FETCH_TICKETS_REQUEST",
    FETCH_TICKETS_SUCCESS: "FETCH_TICKETS_SUCCESS",
    FETCH_TICKETS_FAILURE: "FETCH_TICKETS_FAILURE",
    
    FETCH_TICKET_REQUEST: "FETCH_TICKET_REQUEST",
    FETCH_TICKET_SUCCESS: "FETCH_TICKET_SUCCESS",
    FETCH_TICKET_FAILURE: "FETCH_TICKET_FAILURE",
    
    DELETE_TICKET_REQUEST: "DELETE_TICKET_REQUEST",
    DELETE_TICKET_SUCCESS: "DELETE_TICKET_SUCCESS",
    DELETE_TICKET_FAILURE: "DELETE_TICKET_FAILURE",
    
    CREATE_TICKET_REQUEST: "CREATE_TICKET_REQUEST",
    CREATE_TICKET_SUCCESS: "CREATE_TICKET_SUCCESS",
    CREATE_TICKET_FAILURE: "CREATE_TICKET_FAILURE",
    
    UPDATE_TICKET_REQUEST: "UPDATE_TICKET_REQUEST",
    UPDATE_TICKET_SUCCESS: "UPDATE_TICKET_SUCCESS",
    UPDATE_TICKET_FAILURE: "UPDATE_TICKET_FAILURE",

    ENABLE_TICKET_REQUEST: "ENABLE_TICKET_REQUEST",
    ENABLE_TICKET_SUCCESS: "ENABLE_TICKET_SUCCESS",
    ENABLE_TICKET_FAILURE: "ENABLE_TICKET_FAILURE",

    FETCH_PICTURES_REQUEST: "FETCH_PICTURES_REQUEST",
    FETCH_PICTURES_SUCCESS: "FETCH_PICTURES_SUCCESS",
    FETCH_PICTURES_FAILURE: "FETCH_PICTURES_FAILURE",

    TICKET_CLEAN_UP: "TICKET_CLEAN_UP"
}