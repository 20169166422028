import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchCatalogs } from "../actions/catalogs.actions";
import { fetchCategories } from "../actions/categories.actions";
import { fetchBaseProducts } from "../actions/baseProducts.actions";
import { RootState, useAppDispatch } from "../store/store";
import { Result } from "../types/enum/result";
import { baseProductsState } from "../types/baseProducts.types";
import { storeProductsState } from "../types/storeProducts.types";
import { promoteProductsStore } from "../actions/storeProducts/promotion.actions";

export const usePromote = () => {
    const [baseProductSelected, setBaseProductSelected] = useState('');
    const dispatch = useAppDispatch();
    const baseProductsState:baseProductsState = useSelector((state:RootState) => state.baseProducts);
    const storeProductsState:storeProductsState = useSelector((state:RootState) => state.storeProducts);
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(true);

    const handleRowSelection = {
        onChange: (selectedRowKeys:any, selectedRows:any) => {
            setBaseProductSelected(selectedRows[0].id)
        },
        type: "radio",
    };

    const onClickPromoteStoreProduct = () => {
        if (baseProductSelected) {
            const storeProductsIds:any = storeProductsState.noBarcodeProducts.data.map((obj:any) => {
                return obj.id
            });

            const bodyPromote = {
                baseProductId: baseProductSelected,
                storeProductsIds
            };

            dispatch(promoteProductsStore(bodyPromote)).then((result:any) => {
                if (result === Result.OK) {
                    message.success('Los productos han sido promocionados con éxito');
                    navigate('/products-base');
                } else {
                    message.error(storeProductsState.storeProduct.error.data.message);
                }
            });
        } else {
            message.warning('Por favor seleccione un producto base al cual se promocionarán los productos de tienda previamente seleccionados');
        }
    };

    const toggleFilters = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        dispatch(fetchBaseProducts());
        dispatch(fetchCatalogs());
        dispatch(fetchCategories());
    }, [dispatch]);

    return {
        baseProductsState,
        handleRowSelection,
        onClickPromoteStoreProduct,
        pagination: {
            current: baseProductsState.baseProducts.data.page,
            pageSize: baseProductsState.baseProducts.data.pageSize,
            total: baseProductsState.baseProducts.data.totalCount,
            onChange: (page:number, pageSize:number) => {
                dispatch(fetchBaseProducts(page, pageSize));
                setBaseProductSelected('');
            },
        },
        collapsed,
        toggleFilters,
        baseProductSelected
    };
};