export enum Field{
    STORE = "STORE",
    STOREKEY = "STOREKEY",
    CATALOG = "CATALOG",
    CATEGORY = "CATEGORY",
    CURRENCY = "CURRENCY",
    UNIT = "UNIT",
    INPUT = "INPUT",
    MULTIVALUE = "MULTIVALUE",
    CHECKBOX = "CHECKBOX",
    DATEPICKER = "DATEPICKER",
    GENERAL = "GENERAL",
    SEARCH = "SEARCH",
    COUNTRY= "COUNTRY"
}

export enum Position{
    HORIZONTAL='HORIZONTAL',
    VERTICAL='VERTICAL'
}