import { message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { cleanupStoreProducts } from "../../actions/storeProducts/cleanup.actions";
import { cleanUpSearchProducts, fetchStoreProductsBaseBySearch, fetchStoreProductsBySearch } from "../../actions/storeProductsSearch.actions";
import { RootState, useAppDispatch } from "../../store/store";
import { ProductType } from "../../types/enum/modalType";
import { storeProductsState } from "../../types/storeProducts.types";
import { removeDuplicates } from "../../utils/arrayFunctions";

interface IProps{
    newPromotionProducts:any[];
    setNewPromotionProducts:React.Dispatch<React.SetStateAction<any[]>>;
    catalogId:string;
    promotionProducts:never[];
}

const useAddProductsModal = (props:IProps) =>{
    const {setNewPromotionProducts,newPromotionProducts,catalogId,promotionProducts}=props;
    const [modalType, setModalType] = useState(ProductType.STORE);
    const [searchValue, setSearchValue] = useState("");
    const [showAddProductsModal, setShowAddProductsModal] = useState(false);
    const dispatch = useAppDispatch();
    
    const storeProductsState: storeProductsState = useSelector(
        (state: RootState) => state.storeProducts
      );

     // Add products modal
  const handleCancelAddProductsModal = () => {
    setShowAddProductsModal(false);
  };

  const handleShowAddProductsModal = (type = ProductType.STORE) => {
    setSearchValue("");
    dispatch(cleanUpSearchProducts());
    setNewPromotionProducts([]);
    setShowAddProductsModal(true);
    setModalType(type);
  };

  const onSearchAddProducts = (
    value: string,
    type: ProductType = ProductType.STORE
  ) => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      message.warning(
        "Por favor, especifique un valor antes de iniciar la búsqueda"
      );
      dispatch(cleanupStoreProducts());
      return;
    }

    setSearchValue(trimmedValue);

    if (type === ProductType.BASE) {
      // Especificamos el id del catalogo de los productos actuales para evitar adherir productos de otros catalogos
      dispatch(
        fetchStoreProductsBaseBySearch(
          trimmedValue,
          catalogId,
          storeProductsState.baseProductsRelated,
          1,
          10
        )
      );
    } else {
      // Especificamos el id del catalogo de los productos actuales para evitar adherir productos de otros catalogos
      dispatch(
        fetchStoreProductsBySearch(
          trimmedValue,
          catalogId,
          promotionProducts,
          storeProductsState.similarProducts,
          1,
          10
        )
      );
    }
  };

  const handleRowSelectionAddProductsTable = {
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      setNewPromotionProducts([]);
      let newPromotionProductsTemp: string[] = [];

      if (selectedRows.length) {
        selectedRows.forEach((row: any) => {
          if (!row) {
            return;
          }

          newPromotionProductsTemp.push(row);
        });

        const products = removeDuplicates(newPromotionProductsTemp);
        setNewPromotionProducts(products);
      }
    },
    onSelect: (
      record: any,
      selected: any,
      selectedRows: any,
      nativeEvent: any
    ) => {
      if (selected) {
        if (!promotionProducts.some((item: any) => item.id === record.id)) {
          setNewPromotionProducts([...newPromotionProducts, record]);
        }
      } else {
        setNewPromotionProducts([
          ...newPromotionProducts.filter((obj: any) => obj.id !== record.id),
        ]);
      }
    },
    type: "checkbox",
  };

  return {searchValue, setSearchValue,modalType,showAddProductsModal,handleShowAddProductsModal,handleCancelAddProductsModal,onSearchAddProducts,handleRowSelectionAddProductsTable}
}

export default useAddProductsModal;