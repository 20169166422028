import { Input, Card, FormInstance } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { useEffect, useState } from "react";
//hola
import { customizedTag } from "./customizedTag";

interface IInitialValues {
  tags: string[];
  inputValue: string;
}

interface IProps {
  placeholder: string;
  form: FormInstance<any>;
  name: any;
  handleChange?: any;
}

const initialValues: IInitialValues = {
  tags: [],
  inputValue: "",
};

const MultiSearchInput = (props: IProps) => {
  const { placeholder, form, name, handleChange } = props;

  const setFieldValues = (value: any) => {
    form.setFieldsValue({
      [name]: value.length > 0 ? value[0] : "",
    });
    setWrite(true);
  };

  const [state, setState] = useState(initialValues);
  const [write, setWrite] = useState(false);

  useEffect(() => {
    if (write && form.getFieldValue(name) === "") {
      setState(initialValues);
      setWrite(false);
    }
  });

  const handleInputChange = (e: any) => {
    handleChange(name, e);
    setState({ ...state, inputValue: e.target.value });
    setFieldValues(state.tags);
    setWrite(false);
  };

  const handleOnPaste = (e: any) => {
    e.preventDefault();
    handleChange(name, e);
    const data = e.clipboardData
      .getData("Text")
      .trim()
      .split(/[ ,\n\t]+/);
    const copiedTags = [...state.tags, ...data];
    const filteredTags = copiedTags.filter((item, index) => {
      return copiedTags.indexOf(item) === index;
    });

    setState({ tags: filteredTags, inputValue: "" });
    setFieldValues(filteredTags);
    setWrite(false);
  };

  const handleInputConfirm = () => {
    const { inputValue } = state;
    let { tags } = state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    setState({
      tags,
      inputValue: "",
    });
    setFieldValues(tags);
  };

  const tagChild = customizedTag(state, setState, form, name);
  return (
    <>
      <Card
        className="multi-search-card"
        style={{
          marginBottom: 5,
          padding: 0,
        }}
      >
        <div>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: "from",
              duration: 100,
            }}
            onEnd={(e: any) => {
              if (e.type === "appear" || e.type === "enter") {
                e.target.style = "display: inline-block";
              }
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {tagChild}
          </TweenOneGroup>
        </div>
        <Input
          className="multi-search-input"
          placeholder={placeholder}
          type="text"
          size="small"
          style={{
            height: 20,
            borderStyle: "none",
            border: 0,
            borderColor: "InactiveBorder",
          }}
          value={state.inputValue}
          onChange={(e: any) => handleInputChange(e)}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
          onPaste={handleOnPaste}
        />
      </Card>
    </>
  );
};
export default MultiSearchInput;
