import {  modulesActionTypes } from "../constants/index";
import { ActionCreator } from "redux";
import { ThunkAction } from 'redux-thunk';
import { AppDispatch, RootState } from '../store/store';
import {  usersAction } from '../types/users.types';



export const updateChangesModules = (data: string) => {
    return {
        type: modulesActionTypes.CHANGES_MODULES,
        payload: data
    };
};

export const changesModules: ActionCreator<ThunkAction<void, RootState, null, usersAction>> = (typeModule) =>
    async (dispatch: AppDispatch) => {
        try {
           
            dispatch(updateChangesModules(typeModule))
        } catch (error: any) {
            console.log("error")
        }
    };


 

