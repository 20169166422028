import { FC, useEffect } from "react";
import { Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../store/store";
import { categoriesState } from "../../../types/categories.types";
import { fetchCategories } from "../../../actions/categories.actions";
const { Option } = Select;

type mode = "multiple" | undefined;

interface IProps {
  initialValue?: string;
  onChange?: ((value: SelectValue, option: any) => void) | undefined;
  filterOption?: boolean | any | undefined;
  mode?: mode;
  handleChangeSelect?: any;
  parentId?:string
}

const CategoriesSelect: FC<IProps> = (props) => {
  const {
    onChange,
    handleChangeSelect,
    filterOption,
    parentId,
    mode = undefined,
  } = props;
  const categoriesState: categoriesState = useSelector(
    (state: RootState) => state.categories
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCategories(1, 400));
  }, [dispatch]);

  const handleChange = (value: any) => {
    if (handleChangeSelect) {
      return handleChangeSelect("categoryId", value);
    }
    if (onChange) {
      return onChange;
    }
  };
  let dataItems = categoriesState.categories.data.data;
  
  if(parentId){
    dataItems = categoriesState.categories.data.data.filter((item => item.catalogId === parentId))
  }

  return (
    <Select
      mode={mode ?? undefined}
      placeholder="Selecciona una categoría"
      onChange={handleChange}
      showSearch
      optionFilterProp="children"
      filterOption={filterOption}
    >
      {dataItems.map((categoy: any) => {
        return (
          <Option key={categoy.id} value={categoy.id} label={categoy.name}>
            {categoy.name}
          </Option>
        );
      })}
    </Select>
  );
};
export default CategoriesSelect;
