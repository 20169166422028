export enum ProductStoreEnum{
    Description="description",
    Barcode="barcode",
    Brand="brand",
    Contents="contents",
    Unit="unit",
    Price="price",
    Currency="currency",
    TaxPercent="taxPercent",
    SellMode="sellMode",
    CatalogId="catalogId",
    CategoryId="categoryId",
    categoryList="categoryList"
}