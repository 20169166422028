import { Form, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createBaseProduct } from "../actions/baseProducts.actions";
import { fetchCatalog } from "../actions/catalogs.actions";
import { updateStoreProduct } from "../actions/storeProducts/main.actions";
import { promoteProductsStoreV2 } from "../actions/storeProducts/promotion.actions";
import { RootState, useAppDispatch } from "../store/store";
import { baseProductsState } from "../types/baseProducts.types";
import { catalogsState } from "../types/catalogs.types";
import { Result } from "../types/enum/result";
import { storeProductsState } from "../types/storeProducts.types";

const useStoreProductsForm = (props: any) => {
  const [formInstance] = Form.useForm();

  const baseProductsState: baseProductsState = useSelector(
    (state: RootState) => state.baseProducts
  );
  const catalogsState: catalogsState = useSelector(
    (state: RootState) => state.catalogs
  );
  const storeProductsState: storeProductsState = useSelector(
    (state: RootState) => state.storeProducts
  );

  const [loading, setLoading] = useState(false);
  const location: any = useLocation();
  const state = location.state;
  const dispatch = useAppDispatch();
  let withBarcode = false;

  useEffect(() => {
    if (baseProductsState.baseProduct.data.catalogId) {
      dispatch(fetchCatalog(baseProductsState.baseProduct.data.catalogId));
    }
  }, [baseProductsState.baseProduct.data.catalogId, dispatch]);

  if (state) {
    withBarcode = state.withBarcode;
  }

  const onChangeCatalog = (value: any) => {
    formInstance.setFieldsValue({
      taxPercent: 0,
    });
    dispatch(fetchCatalog(value));
  };

  const onFinishProductBaseForm = (
    values: any,
    state: any,
    setResetFilter: any
  ) => {
    setLoading(true);

    const isEditable = state === null ? true : false;
    if (!isEditable) {
      values.catalogId = values.catalogId._id
        ? values.catalogId._id
        : values.catalogId;
      dispatch(createBaseProduct(values)).then((result: any) => {
        if (result.error !== Result.ERROR) {
          const baseProductId = result.data.id;
          const catalogId = result.data.category.catalogId;
          let productsArray = props.promotionProducts;

          // storeProductsIds
          if (storeProductsState.similarProducts.data.data.length) {
            productsArray = productsArray.concat(
              storeProductsState.similarProducts.data.data
            );
          }

          // We remove duplicates
          const removedDuplicates = productsArray.reduce(
            (unique: any, o: any) => {
              if (!unique.some((obj: any) => obj.id === o.id)) {
                unique.push(o);
              }
              return unique;
            },
            []
          );

          // Get ids
          const storeProductsIds = removedDuplicates.map((obj: any) => obj.id);

          const body = {
            baseProductId,
            storeProductsIds,
            catalogId,
          };

          dispatch(promoteProductsStoreV2(body)).then((result: any) => {
            if (result === Result.OK) {
              props.setShowCreateProdModal(false);
              props.setShowResumeModal(true);
              setLoading(false);

              if (setResetFilter) {
                setResetFilter(true);
              }

              // dispatch(fetchStoreProducts(storeProductsState.storeProducts.data.page, storeProductsState.storeProducts.data.pageSize));
            } else {
              message.error(
                storeProductsState.promotionProductsResult.error.data.message
              );
            }
          });
        } else {
          message.error(result.dataError.data.message);
          setLoading(false);
        }
      });
    } else {
      setLoading(true);

      const productId = storeProductsState.storeProduct.data.id;
      const body = {
        description: values.description,
        barcode: values.barcode,
        brand: values.brand,
      };

      dispatch(updateStoreProduct(body, productId)).then((result: any) => {
        if (result === Result.OK) {
          message.success(
            `El producto "${values.description}" ha sido modificado con éxito`
          );
          if (setResetFilter) {
            setResetFilter(true);
          }
          props.setShowForm(false);
        } else {
          message.error(storeProductsState.storeProduct.error.data.message);
        }

        setLoading(false);
      });
    }
  };

  return {
    baseProductsState,
    catalogsState,
    loading,
    location,
    formInstance,
    state,
    onChangeCatalog,
    /*handleBreadcrumbOnClick,*/
    onFinishProductBaseForm,
    withBarcode,
    storeProductsState,
  };
};

export default useStoreProductsForm;
