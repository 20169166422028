import {FC, useEffect} from "react";
import { Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import {catalogsState} from "../../../types/catalogs.types";
import { fetchCatalogs } from "../../../actions/catalogs.actions";
const { Option } = Select;

interface IProps{
    initialValue?:string;
    filterOption?:any;
    onChange?: ((value: SelectValue, option: any) => void);
    disabled?:boolean;
}

const CatalogSelect:FC<IProps>=(props) =>{
    const {initialValue, filterOption, disabled=false, onChange} = props;
    const catalogState:catalogsState = useSelector((state: RootState) => state.catalogs);
    const dispatch = useAppDispatch();

    
    useEffect(() => {
        dispatch(fetchCatalogs());
    }, [dispatch]);

  
    return(<Select defaultValue={initialValue}
        placeholder="Seleccione un catálogo"
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        onChange={onChange}
        disabled={disabled}
    >
        {catalogState.catalogs.data.data.map((catalog:any) => {
            return (
                <Option key={catalog.id} value={catalog.id} label={catalog.name}>
                    {catalog.name}
                </Option>
            );
        })}
    </Select>)
}
export default CatalogSelect;