import moment from "moment";
import { Field, Position } from "../../types/enum/field";
import { useAppDispatch } from "../../store/store";
import { fetchStores } from "../../actions/stores.actions";
import { Form } from "antd";

const useTicketFields = () =>{
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    
    const fields = [
        {
          name: "storeId",
          type: Field.STORE,
          placeholder: "Comercios",
          mode: "multiple",
          position: Position.VERTICAL,
        },
        {
          name: "storeKey",
          type: Field.STOREKEY,
          placeholder: "Mail comercio",
          position: Position.HORIZONTAL,
        },
        {
          name: "countryId",
          type: Field.COUNTRY,
          placeholder: "Pais",
          position: Position.HORIZONTAL,
          value: "60db267049c5f13a8d0f0e86",
          onChange: (field: string, value: any) =>{
            dispatch(fetchStores(1, 1000000, `&country=${value}`))
            //reset nested storeKey and storeId fields in tickets form
            form.resetFields(["storeKey","storeId"])
          },
          onReset:() =>{
            //when the filter is reset the store is dispatched with the default country.
            dispatch(fetchStores(1, 1000000, `&country=60db267049c5f13a8d0f0e86`))
            form.resetFields(["storeKey","storeId"])
          }
        },
        {
          name: "date",
          type: Field.DATEPICKER,
          placeholder: "Fecha de creación",
          position: Position.VERTICAL,
          value: moment(Date.now()),
        }
      ];

      return {fields,form}

}
export default useTicketFields;