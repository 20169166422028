export const columns = [
    {
        title: 'Campo',
        dataIndex: 'field',
        key: 'field',
    },
    {
        title: 'Fila',
        dataIndex: 'rowNumber',
        key: 'rowNumber',
    },
    {
        title: 'Mensaje',
        dataIndex: 'message',
        key: 'message',
    },
];

export const columnsErrors = [
    {
        title: 'Campo',
        dataIndex: 'field',
        key: 'field',
    },
    {
        title: 'Fila',
        dataIndex: 'row',
        key: 'row',
    },
    {
        title: 'Mensaje',
        dataIndex: 'message',
        key: 'message',
    },
];