import { ICategories, ICategory, IData } from '../types/categories.types';
import { categoriesActionTypes } from "../constants";
import { v4 as uuid } from "uuid";
import { IError } from "../types/interfaces/error.interface";
import { Action } from "../types/actions.types";
import { categoriesState } from "../types/categories.types";

let error:IError = {
    status: '',
    data: { 
        timestamp: '', 
        path: '',
        message: ''
    }
};

let categories:ICategories = {
    data: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

let category:ICategory = {
    id: '',
    name: '',
    internal_ref: '',
    defaultTaxPercentage: 0,
    catalogId: '',
    type: '',
    barcode: '',
    dashboardVisible: false,
    picture: '',
    attributes: {
        internal_ref: '',
    },
};

const initialState = {
    categories: {
        loading: false,
        data: categories,
        error: error,
    },
    category: {
        loading: false,
        data: category,
        error: error,
        pictures: []
    },
    pictures: {
        loading: false,
        data: [""],
        error: error
    }
};

const categoriesReducer = (state = initialState, action:Action):categoriesState => {
    switch (action.type) {
        case categoriesActionTypes.FETCH_CATEGORIES_REQUEST:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    loading: true
                }
            };    
        case categoriesActionTypes.FETCH_CATEGORIES_SUCCESS:
            const data:IData[] = [...action.payload.data];

            data.forEach((item:IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });

            return {
                ...state,
                categories: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case categoriesActionTypes.FETCH_CATEGORIES_FAILURE:
            return {
                ...state,
                categories: {
                    loading: false,
                    data: categories,
                    error: action.payload
                }
            };
        case categoriesActionTypes.FETCH_CATEGORY_REQUEST:
        case categoriesActionTypes.CREATE_CATEGORY_REQUEST:
        case categoriesActionTypes.UPDATE_CATEGORY_REQUEST:
        case categoriesActionTypes.DELETE_CATEGORY_REQUEST:
        case categoriesActionTypes.ENABLE_CATEGORY_REQUEST:
            return {
                ...state,
                category: {
                    ...state.category,
                    loading: true
                }
            };
        case categoriesActionTypes.ENABLE_CATEGORY_SUCCESS:
        case categoriesActionTypes.DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                category: {
                    loading: false,
                    data: {
                        ...state.category.data,
                        id: action.payload
                    },
                    error: error
                }
            };
        case categoriesActionTypes.FETCH_CATEGORY_FAILURE:
        case categoriesActionTypes.CREATE_CATEGORY_FAILURE:
        case categoriesActionTypes.UPDATE_CATEGORY_FAILURE:
        case categoriesActionTypes.DELETE_CATEGORY_FAILURE:
        case categoriesActionTypes.ENABLE_CATEGORY_FAILURE:
            return {
                ...state,
                category: {
                    loading: false,
                    data: category,
                    error: action.payload,
                }
            };
        case categoriesActionTypes.FETCH_CATEGORY_SUCCESS:
        case categoriesActionTypes.CREATE_CATEGORY_SUCCESS:
        case categoriesActionTypes.UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                category: {
                    loading: false,
                    data: action.payload,
                    error: error,
                }
            };
        case categoriesActionTypes.CATEGORY_CLEAN_UP:
            return {
                ...state,
                category: initialState.category
            };
        case categoriesActionTypes.FETCH_PICTURES_REQUEST:
            return {
                ...state,
                pictures: {
                    ...state.pictures,
                    loading: true
                }
            };
        case categoriesActionTypes.FETCH_PICTURES_SUCCESS:
            return{
                ...state,
                pictures:{
                    loading:false,
                    data:action.payload,
                    error:error
                }
            };
        case categoriesActionTypes.FETCH_PICTURES_FAILURE:
            return{
                ...state,
                pictures:{
                    loading:false,
                    data:[],
                    error:action.payload
                }
            };
        default:
            return state;
    }
};

export default categoriesReducer;