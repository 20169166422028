import { Button, Col, Row } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import HelmetTitle from "../../../other/HelmetTitle";
import { usePromote } from "../../../../hooks/usePromote";
import Table from "../../../table/customizedTable/CustomizedTable";
import Filters from "../../../shared/filters/Filters";
import { combineKeyValueArray } from "../../../../utils/arrayFunctions";
import { useAppDispatch } from "../../../../store/store";
import { fetchBaseProducts } from "../../../../actions/baseProducts.actions";
import PageTitle from "../../../other/PageTitle";

const Promote = () => {
  const {
    baseProductsState,
    handleRowSelection,
    onClickPromoteStoreProduct,
    pagination,
    collapsed,
    toggleFilters,
    baseProductSelected,
  } = usePromote();
  const dispatch = useAppDispatch();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Código de barra",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a: any, b: any) => a.barcode - b.barcode,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Contenido",
      dataIndex: "contents",
      key: "contents",
      sorter: (a: any, b: any) => a.contents - b.contents,
    },
    {
      title: "Unidad",
      dataIndex: "unit",
      key: "unit",
      sorter: (a: any, b: any) => a.unit.localeCompare(b.unit),
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      sorter: (a: any, b: any) => a.price - b.price,
    },
    {
      title: "% de impuesto",
      dataIndex: "taxPercent",
      key: "taxPercent",
      sorter: (a: any, b: any) => a.taxPercent - b.taxPercent,
    },
  ];

  const onFinishFiltering = (values: any) => {
    const dateArray = ["createdAt", "updatedAt"];
    const params = combineKeyValueArray(values, dateArray);
    dispatch(
      fetchBaseProducts(
        baseProductsState.baseProducts.data.page,
        baseProductsState.baseProducts.data.pageSize,
        params
      )
    );
  };

  return (
    <>
      <HelmetTitle
        title="Promoción de productos de tienda - Backoffice D&#38;N"
        description="Pantalla para la promoción de productos de tienda"
      />
      <Row>
        <Col span={24} className="d-flex space-between">
          <Col span={12}>
            <PageTitle title="Promoción a producto base existente" />
          </Col>

          <Col span={12} className="d-flex flex-end">
            <Button
              type="text"
              onClick={onClickPromoteStoreProduct}
              disabled={!baseProductSelected ? true : false}
            >
              Promover
            </Button>

            <Button type="text" onClick={toggleFilters}>
              <FilterOutlined />
              Filtros
            </Button>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table
            dataSource={baseProductsState.baseProducts.data.data}
            columns={columns}
            name="producto base"
            title={null}
            path="products-base"
            loading={baseProductsState.baseProducts.loading}
            pagination={pagination}
            handleRowSelection={handleRowSelection}
          />
        </Col>
      </Row>

      <Filters
        collapsed={collapsed}
        onFinishFiltering={onFinishFiltering}
        productStoreFilters={false}
        toggleFilters={toggleFilters}
      />
    </>
  );
};

export default Promote;
