import { Modal } from "antd";
import { resetStoreProduct } from "../../../../../actions/storeProducts/cleanup.actions";
import { useAppDispatch } from "../../../../../store/store";
import { ProductStoreEnum } from "../../../../../types/enum/productStoreFields";
//import ProductsStoreForm from "../../ProductsStoreForm";
import ProductsStoreFormNew from "../../productStoreForm/ProductsStoreForm";

export interface IProp {
  showCreateProdModal: boolean;
  setShowCreateProdModal: React.Dispatch<React.SetStateAction<boolean>>;
  promotionProducts: any[];
  setShowForm: boolean;
  setRestartSearch: any;
  setShowResumeModal: any;
}

const CreateProductModal = (props: IProp) => {
  const {
    showCreateProdModal,
    setShowCreateProdModal,
    promotionProducts,
    setShowForm,
    setRestartSearch,
    setShowResumeModal,
  } = props;
  const disableFields = [
    ProductStoreEnum.CatalogId,
    ProductStoreEnum.CategoryId,
  ];
  const dispatch = useAppDispatch();

  const handleReset = () => {
    dispatch(resetStoreProduct());
  };

  const handleOnCancel = () => {
    setShowCreateProdModal(false);
  };

  return (
    <>
      <Modal
        bodyStyle={{ padding: 5 }}
        title="Crear Producto"
        style={{ top: 10, border: 0 }}
        visible={showCreateProdModal}
        onOk={() => setShowCreateProdModal(false)}
        onCancel={() => setShowCreateProdModal(false)}
        width={"80%"}
        footer={null}
        forceRender
      >
        <ProductsStoreFormNew
          isModal={true}
          setShowCreateProdModal={setShowCreateProdModal}
          setRestartSearch={setRestartSearch}
          disableFields={disableFields}
          promotionProducts={promotionProducts}
          setShowForm={setShowForm}
          setShowResumeModal={setShowResumeModal}
          submitButton={{ title: "Guardar y Promover" }}
          cancelButton={{
            title: "Cancelar",
            onClick: handleOnCancel,
            disabled: false,
          }}
          resetButton={{
            title: "Limpiar",
            onClick: handleReset,
            disabled: false,
          }}
        />
      </Modal>
    </>
  );
};
export default CreateProductModal;
