import {useEffect} from 'react';
import { Button, Col, Form, Input, Row, Select, Spin,Breadcrumb  } from "antd";
import { Link } from 'react-router-dom';

import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../store/store";
import { regionsState } from '../../types/regions.types';
import { countriesState } from '../../types/countries.types';
import {fetchCountries} from '../../actions/countries.actions';
import {cleanupRegion} from '../../actions/regions.actions';


const { Option } = Select;

const RegionsForm = (props:any) => {
    const {onFinish,setShowForm}=props;
    const regionsState:regionsState = useSelector((state:RootState) => state.regions);
    const countriesState:countriesState = useSelector((state:RootState) => state.countries);
    const dispatch = useAppDispatch();

    useEffect(()=>{
        dispatch(fetchCountries());
    },[dispatch]);

    const handleOnClick = ()=>{
        setShowForm(false);
        dispatch(cleanupRegion());
    }
    
       return (
        <Row gutter={[0, 16]}>
            <Col span={24}>
            <Breadcrumb>
            <Breadcrumb.Item><Link  to="/regions" onClick={handleOnClick}>Regiones</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{ !regionsState.region.loading ? (regionsState.region.data.id)? 'Editar':'Agregar':''}</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
            {
                !regionsState.region.loading ?
                <Col span={24}>
                    <Form
                        name="form"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="País"
                            name="countryId"
                            rules={[{ required: true, message: 'Por favor seleccione un país!' }]}
                            initialValue={regionsState.region?.data?.country?.id}
                        >
                            <Select 
                                placeholder="Seleccione un país"
                            >
                                {
                                    countriesState.countries.data.data.map((el:any, key) => (
                                        <Option key={key} value={el.id}>
                                            {el.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Provincia / Departamento"
                            name="provincia/departamento"
                            rules={[{ required: true, message: 'Por favor inserte una Provincia / Departamento!' }]}
                            initialValue={regionsState.region.data["provincia/departamento"]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Municipio / Cantón / Distrito"
                            name="municipio/canton/distrito"
                            rules={[{ required: true, message: 'Por favor inserte un Municipio / Cantón / Distrito!' }]}
                            initialValue={regionsState.region.data["municipio/canton/distrito"]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Tamaño"
                            name="tamano"
                            rules={[{ required: true, message: 'Por favor inserte un tamaño!' }]}
                            initialValue={regionsState.region.data.tamano}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                :
                <Spin />
            }
        </Row>
    );
};

export default RegionsForm;