import { ICatalogs, ICatalog, IData, catalogsState } from '../types/catalogs.types';
import { catalogsActionTypes } from "../constants";
import { IError } from "../types/interfaces/error.interface";
import { Action } from "../types/actions.types";
import { v4 as uuid } from "uuid";

let error: IError = {
    status: '',
    data: {
        timestamp: '',
        path: '',
        message: ''
    }
};

let catalogs: ICatalogs = {
    data: [], page: 0, pageSize: 0, totalCount: 0
};

let catalog: ICatalog = {
    id: '',
    taxPercentCatalog: [],
    name: '',
    attributes: {
        internal_ref: ''
    },
    countryCode: '',
};

const initialState = {
    catalogs: {
        loading: false,
        data: catalogs,
        error: error,
    },
    catalog: {
        loading: false,
        data: catalog,
        error: error
    }
};

const catalogsReducer = (state = initialState, action: Action): catalogsState => {
    switch (action.type) {
        case catalogsActionTypes.FETCH_CATALOGS_REQUEST:
            return {
                ...state,
                catalogs: {
                    ...state.catalogs,
                    loading: true
                }
            };
        case catalogsActionTypes.FETCH_CATALOGS_SUCCESS:
            const data: IData[] = [...action.payload];

            data.forEach((item: IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });


            return {
                ...state,
                catalogs: {
                    loading: false,
                    data: {
                        data: action.payload
                    },
                    error: error
                }
            };
        case catalogsActionTypes.FETCH_CATALOGS_FAILURE:
            return {
                ...state,
                catalogs: {
                    loading: false,
                    data: catalogs,
                    error: error
                }
            };
        case catalogsActionTypes.FETCH_CATALOG_REQUEST:
        case catalogsActionTypes.CREATE_CATALOG_REQUEST:
            return {
                ...state,
                catalog: {
                    ...state.catalog,
                    loading: true
                }
            };
        case catalogsActionTypes.FETCH_CATALOG_FAILURE:
        case catalogsActionTypes.CREATE_CATALOG_FAILURE:
            return {
                ...state,
                catalog: {
                    loading: false,
                    data: catalog,
                    error: action.payload
                }
            };
        case catalogsActionTypes.FETCH_CATALOG_SUCCESS:
        case catalogsActionTypes.CREATE_CATALOG_SUCCESS:
            return {
                ...state,
                catalog: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case catalogsActionTypes.CATALOG_CLEAN_UP:
            return {
                ...state,
                catalog: initialState.catalog
            };
        case catalogsActionTypes.ADD_CATALOG_CREATE:
            const dataAll = [...state.catalogs.data.data, action.payload];
            return {
                ...state,
                catalogs: {
                    loading: false,
                    data: {
                        data: dataAll
                    },
                    error: error
                }

            }
        default:
            return state;
    }
};

export default catalogsReducer;