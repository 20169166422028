
import { ValidatorConfig } from 'csv-file-validator';
import { requiredError, uniqueError, isEmailValid, isPasswordValid, validateError, isIntegerValid, isFormatText } from './../../functionsCsv';

export const CSVConfig: ValidatorConfig = {
    headers: [
        { name: 'StoreName', inputName: 'StoreName', required: true, requiredError },
        { name: 'Email', inputName: 'Email', required: true, requiredError, unique: true, uniqueError, validate: isEmailValid, validateError },
        { name: 'Password', inputName: 'Password', required: true, requiredError, validate: isPasswordValid, validateError },
        { name: 'Address', inputName: 'Address', required: true, requiredError },
        { name: 'Latitude', inputName: 'Latitude', required: true, requiredError, validate: isIntegerValid, validateError },
        { name: 'Longitude', inputName: 'Longitude', required: true, requiredError, validate: isIntegerValid, validateError },
        { name: 'TaxDetail', inputName: 'TaxDetail', required: true, requiredError },
        { name: 'Username', inputName: 'Username', required: true, requiredError },
        { name: 'Pin', inputName: 'Pin', required: true, requiredError },
        { name: 'UserEmail', inputName: 'UserEmail', required: true, requiredError, unique: true, uniqueError, validate: isEmailValid, validateError  },
        { name: 'FirstName', inputName: 'FirstName', required: true, requiredError, validate: isFormatText, validateError },
        { name: 'LastName', inputName: 'LastName', required: true, requiredError, validate: isFormatText, validateError },
    ]
};