import { Button, Modal, Table } from "antd";

const PromoteResumeModal = (props: any) => {
  return (
    <Modal
      title="Resumen"
      visible={props.showResumeModal}
      closable={false}
      maskClosable={false}
      footer={[
        <Button onClick={() => props.togglePromotionWindow()}>Cerrar</Button>,
      ]}
    >
      <p>El estatus de los productos promovidos es el siguiente:</p>
      <Table dataSource={props.dataSource} columns={props.resumeModalColumns} />
    </Modal>
  );
};

export default PromoteResumeModal;
