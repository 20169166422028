export const confPowerBITypes={
    FETCH_CONFPOWERBI_REQUEST:"FETCH_CONFPOWERBI_REQUEST",
    FETCH_CONFPOWERBI_SUCCESS:"FETCH_CONFPOWERBI_SUCCESS",
    FETCH_CONFPOWERBI_FAILURE:"FETCH_CONFPOWERBI_FAILURE",

    UPDATE_CONFPOWERBI_REQUEST:"UPDATE_CONFPOWERBI_REQUEST",
    UPDATE_CONFPOWERBI_SUCCESS:"UPDATE_CONFPOWERBI_SUCCESS",
    UPDATE_CONFPOWERBI_FAILURE:"UPDATE_CONFPOWERBI_FAILURE",
    
    EDIT_CONFPOWERBI:"EDIT_CONFPOWERBI"
}
