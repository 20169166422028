import { CategoriesButton } from './CategoriesButton'

export const columnsData = (actions: any) => {
    
    const { handleActionCategories } = actions;

    return [
        { 
            title: 'ID',
            key: 'id',
            sorter: 1
        },
        { 
            title: 'Nombre',
            key: 'name',
            sorter: 1
        },
        { 
            title: 'Código País',
            key: 'countryCode',
            sorter: 0
        },
        { 
            title: 'Impuestos',
            key: 'taxPercentCatalog',
            render: (text:any, record:any, index:any) => record.taxPercentCatalog.join(', '),
            sorter: 0
        },
        /*
        { 
            title: '',
            key: '',
            render: (text:any, record:any, index:any) => <CategoriesButton handleActionCategories={ () => { handleActionCategories(record.id) }}  />,
            sorter: 0
        },
        */
    ]

}

    