import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { itemsMenu } from "./itemsMenu";

const MenuComponent = (props: any) => {
  const typeModules = useSelector((state: RootState) => state.typemodules);
  const { currentPage, toggleCollapsed } = props;

  return (
    <Menu mode="inline" selectedKeys={[currentPage]} onClick={toggleCollapsed}>
      {itemsMenu(typeModules.modules).map(({ Icon, link, name }: any) => {
        return (
          <Menu.Item key={"/" + link} icon={<Icon />}>
            <Link to={"/" + link}>{name}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default MenuComponent;
