import { Button, Tooltip } from "antd";
import ButtonsTable from "../../table/customizedTable/ButtonsTable";
import { EyeOutlined } from "@ant-design/icons";

export const ColumnsLogs = (
  viewLogs: any
) => {
  return [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      // ...getColumnSearchProps('name')
    },
    {
      title: "País",
      dataIndex: ["country", "name"],
      key: "country",
      sorter: (a: any, b: any) => {
        if (a.country && b.country) {
          return a.country.name.localeCompare(b.country.name)
        }

        return;
      },
      // ...getColumnSearchProps(['country', 'name'])
    },


    {
      title: "Username (E-mail)",
      dataIndex: "storeKey",
      key: "storeKey",
      sorter: (a: any, b: any) => a.storeKey.localeCompare(b.storeKey),
      // ...getColumnSearchProps('storeKey')
    },
    {
      title: "Estado",
      dataIndex: "active",
      key: "active",
      render: (text: any, record: any, index: any) => {
        if (!record.active) {
          return "Deshabilitado";
        }

        return "Habilitado";
      },


    },


    {
      title: "Acciones",
      key: "key",
      dataIndex: "key",
      render: (text: any, record: any) => (
        <>
          <Tooltip title="Ver historico">
            <Button
              type="text"
              shape="circle"
              icon={<EyeOutlined />}
              className="btn-table"
              style={{ marginRight: 5 }}
              onClick={() => viewLogs(record)}
            />
          </Tooltip>
        </>
      ),
    },



  ];
};


export const listLogs = [
  {
    type: "login",
    name: "Inicio de sesión"
  },
  {
    type: "login-user",
    name: "Selección de usuario"
  },
  {
    type: "home",
    name: "Dashboard Principal"
  },
  {
    type: "product-list",
    name: "Buscar producto"
  },
  {
    type: "add-product",
    name: "Crear producto"
  },
  {
    type: "help",
    name: "Ayuda"
  },
  {
    type: "operator-admin",
    name: "Panel de control"
  },
  {
    type: "edit-operator",
    name: "Editar operador"
  },
  {
    type: "delete-operator",
    name: "Eliminar operador"
  },
  {
    type: "add-operator",
    name: "Agregar operador"
  },
  {
    type: "statistics-my-sales",
    name: "Reporte Mis Ventas"
  },
 
  {
    type: "statistics-categories-bestselling",
    name: "Reporte Canastos más vendidos"
  },
  {
    type:"statistics-products-bestselling",
    name: "Reporte Productos más vendidos"
  },
  {
    type:"statistics-sale-details",
    name: "Reporte Detalle Ventas"
  },
  {
    type:"statistics-missing-products",
    name: "Reporte productos que me faltan"
  },
  {
    type:"statistics-increase-your-price",
    name: "Reporte Incrementa tu precio"
  },
  {
    type:"statistics-expensive-prices",
    name: "Reporte precios costosos"
  },
  {
    type:"statistics-schedules",
    name: "Reporte Horarios"
  },
  {
    type:"shortcut",
    name: "Cartilla"
  },
  {
    type:"product-list-by-category",
    name: "Productos por categoria"
  },
  {
    type:"calculator",
    name: "Calculadora"
  },
  {
    type:"new-product-details",
    name: "Crear producto detalles"
  }
]

export const ColumnsLogsView =  () => {
  let dataTable : any = [
    {
      title: "Dia",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, index: any) => {
        return Object.keys(record)[0]?.split('-')?.reverse()?.join('-');
      },
      fixed: 'left',
   
    },
  ]

   listLogs.map((item: any) =>{
    dataTable.push({
      title: item.name,
      dataIndex: item.type,
      key: item.type, 
      align: "center",
      render: (text: any, record: any, index: any) => {
        return record?.[Object.keys(record)?.[0]]?.[item.type] ?? 0
      },
    },)
  })

  return dataTable
}