import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  fetchRegions,
  deleteRegion,
  fetchRegion,
  createRegion,
  cleanupRegion,
  updateRegion,
} from "../actions/regions.actions";
import { RootState, useAppDispatch } from "../store/store";
import { Result } from "../types/enum/result";
import { regionsState } from "../types/regions.types";

export const useRegions = () => {
  const [showForm, setShowForm] = useState(false);
  const dispatch = useAppDispatch();
  const regionsState: regionsState = useSelector(
    (state: RootState) => state.regions
  );
  const pageSize = 10;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemID, setItemID] = useState("");

  useEffect(() => {
    dispatch(fetchRegions(1, pageSize));
  }, [dispatch]);

  const onFinish = (values: any) => {
    if (!regionsState.region.data.id) {
      dispatch(createRegion(values)).then((result: any) => {
        if (result === Result.OK) {
          message.success("El registro ha sido creado con éxito");
          dispatch(
            fetchRegions(
              regionsState.regions.data.page,
              regionsState.regions.data.pageSize
            )
          );
          setShowForm(false);
          dispatch(cleanupRegion());
        } else {
          message.error(regionsState.region.error.data.message);
        }
      });
    } else {
      dispatch(updateRegion(values, regionsState.region.data.id)).then(
        (result: any) => {
          if (result === Result.OK) {
            message.success("El registro ha sido modificado con éxito");
            dispatch(
              fetchRegions(
                regionsState.regions.data.page,
                regionsState.regions.data.pageSize
              )
            );
            setShowForm(false);
            dispatch(cleanupRegion());
          } else {
            message.error(regionsState.region.error.data.message);
          }
        }
      );
    }
  };

  const handleDelete = (id: any) => {
    dispatch(deleteRegion(id)).then((result: any) => {
      if (result === Result.OK) {
        dispatch(
          fetchRegions(
            regionsState.regions.data.page,
            regionsState.regions.data.pageSize
          )
        );
        setItemID("");
        setShowForm(false);
        setIsModalVisible(false);
        message.success("El registro ha sido eliminado con éxito");
      } else message.error(regionsState.region.error.data.message);
    });
  };

  const onChangeFetchItems = (page: any, pageSize: any) => {
    dispatch(fetchRegions(page, pageSize));
  };

  const handleEdit = (body: any) => {
    setShowForm(true);
    dispatch(fetchRegion(body.id));
  };

  const onClick = () => {
    setShowForm(true);
  };

  const showEnableModal = (id: any = null) => {
    setIsModalVisible(true);
    setItemID(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return {
    regionsState,
    pagination: {
      current: regionsState.regions?.data?.page,
      pageSize: regionsState.regions?.data?.pageSize,
      total: regionsState.regions?.data?.totalCount,
      onChange: (page: number, pageSize: number) => {
        dispatch(fetchRegions(page, pageSize));
      },
    },
    onChangeFetchItems,
    handleDelete,
    handleEdit,
    onClick,
    onFinish,
    showForm,
    setShowForm,
    isModalVisible,
    itemID,
    showEnableModal,
    handleCancel,
  };
};
