import { Button, Checkbox, Modal, Select } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";

import CopyTextColumn from "../../other/CopyTextColumn";

const { Option } = Select;

export const Columns = (
  onChangeCheckbox: any,
  isNoPromoteModalVisible: any,
  onClickNoPromoteProduct: any,
  handleCancel: any,
  onChangeNoPromoteReason: any,
  showNoPromoteModal: any,
  productStoreID: any,
  noPromoteReason: any
) => {
  return [
    {
      title: "",
      dataIndex: "checkbox",
      key: "checkbox",
      render: (text: any, record: any, index: any) => {
        if (record.hasOwnProperty("promotable")) {
          if (!record.promotable) {
            return;
          }
        } else {
          return (
            <Checkbox
              className="checkbox"
              onChange={(e) => onChangeCheckbox(e, record)}
            />
          );
        }
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: function RevealTextOnHover(text: any, record: any, index: any) {
        return <CopyTextColumn text={text} record={record} />;
      },
    },
    {
      title: "Código de barra",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a: any, b: any) => a.barcode - b.barcode,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Contenido",
      dataIndex: "contents",
      key: "contents",
      sorter: (a: any, b: any) => a.contents - b.contents,
    },
    {
      title: "Unidad",
      dataIndex: "unit",
      key: "unit",
      sorter: (a: any, b: any) => a.unit.localeCompare(b.unit),
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      sorter: (a: any, b: any) => a.price - b.price,
    },
    {
      title: "% de impuesto",
      dataIndex: "taxPercent",
      key: "taxPercent",
      sorter: (a: any, b: any) => a.taxPercent - b.taxPercent,
    },
    {
      title: "Comercio",
      dataIndex: ["store", "storeName"],
      key: "store",
      sorter: (a: any, b: any) =>
        a.store.storeName.localeCompare(b.store.storeName),
    },
    {
      title: "Tipo de producto",
      key: "productType",
      render: (text: any, record: any, index: any) => {
        if (record.baseProduct) {
          return "Promovido";
        }

        if (
          !record.hasOwnProperty("baseProduct") &&
          !record.hasOwnProperty("reason")
        ) {
          return "Por promover";
        }

        if (
          !record.hasOwnProperty("baseProduct") &&
          record.hasOwnProperty("reason")
        ) {
          return "No promovible";
        }
      },
    },
    {
      title: "",
      dataIndex: "rowButtons",
      key: "rowButtons",
      render: (text: any, record: any, index: any) => {
        if (record.hasOwnProperty("promotable")) {
          if (!record.promotable) {
            return record.reason;
          }
        } else {
          return (
            <div className="table__button-container">
              <PoweroffOutlined
                title="No promover"
                className="table__button"
                alt="Icono de habilitar"
                onClick={() => showNoPromoteModal(record.id)}
              />
              <Modal
                title={`No promover producto`}
                visible={isNoPromoteModalVisible}
                onCancel={handleCancel}
                footer={[
                  <Button key="cancel" type="primary" onClick={handleCancel}>
                    Cancelar
                  </Button>,
                  <Button
                    key="no-promote"
                    onClick={() =>
                      onClickNoPromoteProduct(productStoreID, noPromoteReason)
                    }
                  >
                    No promover
                  </Button>,
                ]}
                mask={false}
                maskStyle={{ backgroundColor: "#00000073" }}
              >
                <p>
                  ¿Esta seguro que desea marcar el producto como no promovible?,
                  en caso de aceptar no se mostrará nuevamente en el listado de
                  Productos de tienda
                </p>
                <Select
                  placeholder="Seleccione una razón"
                  dropdownClassName="select"
                  onChange={(value: string) => onChangeNoPromoteReason(value)}
                >
                  <Option
                    value="Sin código de barra"
                    label="Sin código de barra"
                  >
                    Sin código de barra
                  </Option>

                  <Option
                    value="Código de barra inválido"
                    label="Código de barra inválido"
                  >
                    Código de barra inválido
                  </Option>

                  <Option
                    value="Código de barra asociado a varios productos"
                    label="Código de barra asociado a varios productos"
                  >
                    Código de barra asociado a varios productos
                  </Option>

                  <Option
                    value="Código de barra no encontrado en internet"
                    label="Código de barra no encontrado en internet"
                  >
                    Código de barra no encontrado en internet
                  </Option>
                </Select>
              </Modal>
            </div>
          );
        }
      },
    },
  ];
};
