export const fieldsPowerBi = [
    {
        name: "workspaceId",
        label: "Workspace ID"
    },
    {
        name: "reportId",
        label: "Report ID"
    },
    {
        name: "clientId",
        label: "Client ID"
    },
    {
        name: "username",
        label: "Username",
        isNotrequired:true
    },
    {
        name: "password",
        label: "Password",
        isNotrequired:true
    },
    {
        name: "dataset",
        label: "Dataset"
    },
    {
        name: "tenantId",
        label: "Tenant ID"
    },
    {
        name: "clientSecret",
        label: "Client Secret"
    },
    {
        name: "authenticationMode",
        label: "Authentication Mode"
    },
    {
        name: "authorityUri",
        label: "Authority URI",
        isUrl: true
    },
    {
        name: "scope",
        label: "Scope",
        isUrl: true
    },
    {
        name: "apiUrl",
        label: "API URL",
        isUrl: true
    },
    
]