import { IRegions, IRegion, ICountry } from '../types/regions.types';
import {regionsActionTypes} from "../constants/index";
import { Action } from "../types/actions.types";
import { IError } from "../types/interfaces/error.interface";
import { regionsState } from '../types/regions.types';
import { v4 as uuid } from "uuid";

let error:IError={status:'', data:{timestamp:'', path:'',message:''}};
let country:ICountry={ id:'',name:'',countryCode:'',currency:'',unitMeasureCodes:[""],paymentMethods:[""]};
let region:IRegion={
    id:'',
    key:'',    
    country:country,
    "provincia/departamento": '',
    "municipio/canton/distrito": '',
    "tamano": ''
}
let regions:IRegions={data:[{...region}],page:0, pageSize:0, totalCount:0}

const initialState ={
    regions:{
        loading:false,
        data:regions,
        error:error
    },
    region:{
        loading: false,
        data:region,
        error: error,
    }
}

const regionsReducer = (state=initialState,action:Action):regionsState =>{
    switch(action.type){
        case regionsActionTypes.FETCH_REGIONS_REQUEST:
            return{
                ...state,
                regions:{
                    ...state.regions,
                    loading:true
                }
            }
            case regionsActionTypes.FETCH_REGIONS_SUCCESS:
                const data:IRegion[] = [...action.payload.data];
                data.forEach((item:IRegion, idx:number) => {
                    const unique_id = uuid();
                    item.key = unique_id;
                });
                action.payload.totalCount=action.payload.data.length;
                return{
                    ...state,
                    regions:{
                        loading:false,
                        data: action.payload,
                        error:error
                    }
                }
            case regionsActionTypes.FETCH_REGIONS_FAILURE:
                return{
                    ...state,
                    regions:{
                        loading:false,
                        data:regions,
                        error:error
                    }
                }
            case regionsActionTypes.FETCH_REGION_REQUEST:
            case regionsActionTypes.CREATE_REGION_REQUEST:
            case regionsActionTypes.UPDATE_REGION_REQUEST:
            case regionsActionTypes.DELETE_REGION_REQUEST:
                return{
                    ...state,
                    region:{ 
                        ...state.region,
                        loading:true
                    }
                }
                case regionsActionTypes.DELETE_REGION_SUCCESS:
                return{
                    ...state,
                    region:{ 
                        loading:false,
                        data:{...state.region.data, id:action.payload},
                        error:error
                    }
                }
                case regionsActionTypes.FETCH_REGION_FAILURE:
                case regionsActionTypes.CREATE_REGION_FAILURE:
                case regionsActionTypes.UPDATE_REGION_FAILURE:                    
                case regionsActionTypes.DELETE_REGION_FAILURE:
                return{
                    ...state,
                    region:{ 
                        loading:false,
                        data:region,
                        error:action.payload
                    }
                }
                case regionsActionTypes.FETCH_REGION_SUCCESS:
                case regionsActionTypes.CREATE_REGION_SUCCESS:
                case regionsActionTypes.UPDATE_REGION_SUCCESS:
                return{
                    ...state,
                    region:{ 
                        loading:false,
                        data:action.payload,
                        error:error
                    }
                }
                case regionsActionTypes.REGION_CLEAN_UP:
                    return{
                        ...state,
                        region:initialState.region
                        
                    }
            default:
                return state;
    }
}

export default regionsReducer;