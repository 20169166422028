import { Form, message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createBaseProduct } from "../actions/baseProducts.actions";
import { RootState, useAppDispatch } from "../store/store";
import { baseProductsState } from "../types/baseProducts.types";
import { Result } from "../types/enum/result";

const useCreateProductBase = (props: any) => {
    const [openLoading, setOpenLoading] = useState(false)
    const [formInstance] = Form.useForm();
    const dispatch = useAppDispatch();
    const baseProductsState:baseProductsState = useSelector((state:RootState) => state.baseProducts);

    const onFinishProductBaseForm = (values: any) => {
        setOpenLoading(true)
        values.taxPercent  = parseInt(values.taxPercent)
        dispatch(createBaseProduct(values, true)).then((result: any) => {
            setOpenLoading(false)
            if (result.error !== Result.ERROR) {
                props.createPodrucAddResult(result.data, true)
            }else{
                message.error(result.dataError.data.message); 
            }

        })
    };

    return {
        formInstance,
        onFinishProductBaseForm,
        openLoading
    };
};

export default useCreateProductBase;
