import { Spin } from 'antd';
import "./index.css"
export default function Loading(props:any) {
    return (
        <>
            {props.open &&
                <div className="styleLoading">
                    <Spin />
                </div>
            }
        </>
    )
}
