import { Button, Modal, Table } from "antd";
import { ticketColumn } from "./Columns";

export const Ticket = (props: any) => {
  const { showModal, closeModal, items } = props;

  const columns = ticketColumn();

  return (
    <div>
      <Modal
        wrapClassName="modal-csv"
        title={"Productos"}
        width={900}
        visible={showModal}
        onCancel={() => {
          closeModal();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              closeModal();
            }}
          >
            Cancelar
          </Button>,
        ]}
        mask={false}
        maskStyle={{ backgroundColor: "#00000073" }}
      >
        <Table dataSource={items} columns={columns} />
      </Modal>
    </div>
  );
};
