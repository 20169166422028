import {
  storesState,
  IStores,
  IStore,
  IUserStore,
} from "../types/store.types";
import { Action } from "../types/actions.types";
import { storeActionTypes } from "../constants/index";
import { IError } from "../types/interfaces/error.interface";
import { v4 as uuid } from "uuid";

const userStore: IUserStore[] = [
  {
    id: "",
    name: "",
    lastname: "",
    email: "",
    username: "",
    pin: "",
    role: "",
  },
];
let store: IStore = {
  id:"",
  name: "",
  storeKey: "",
  catalog: { id: "", name: "", taxPercentCatalog: [], countryCode: [] },
  country: {
    id: "",
    name: "",
    countryCode: "",
    currency: "",
    unitMeasureCodes: [],
    paymentMethods: [],
  },
  region: {
    id: "",
    country: {
      id: "",
      name: "",
      countryCode: "",
      currency: "",
      unitMeasureCodes: [],
      paymentMethods: [],
    },
    "provincia/departamento": "",
    "municipio/canton/distrito": "",
    tamano: "",
  },
  address: "",
  gps: {
    latitude: "",
    longitude: "",
  },
  taxDetail: "",
  users: userStore
};

const stores: IStores = { data: [], page: 0, pageSize: 0, totalCount: 0 };
let error: IError = {
  status: "",
  data: { timestamp: "", path: "", message: "" },
};

const initialState: storesState = {
  stores: {
    loading: false,
    data: stores,
    error: error,
  },
  store: {
    loading: false,
    data: store,
    error: error,
  },
};

const storesReducer = (state = initialState, action: Action): storesState => {
  switch (action.type) {
    case storeActionTypes.FETCH_STORES_REQUEST:
      return {
        ...state,
        stores: {
          ...state.stores,
          loading: true,
        },
      };
    case storeActionTypes.FETCH_STORES_SUCCESS:
      const data: IStore[] = [...action.payload.data];
      data.forEach((item: IStore, idx: number) => {
        const unique_id = uuid();
        item.key = unique_id;
      });

      return {
        ...state,
        stores: {
          loading: false,
          data: action.payload,
          error: error,
        },
      };
    case storeActionTypes.FETCH_STORES_FAILURE:
      return {
        ...state,
        stores: {
          loading: false,
          data: stores,
          error: action.payload,
        },
      };
    case storeActionTypes.FETCH_STORE_REQUEST:
    case storeActionTypes.CREATE_STORE_REQUEST:
    case storeActionTypes.UPDATE_STORE_REQUEST:
    case storeActionTypes.ENABLE_STORE_REQUEST:
    case storeActionTypes.DISABLE_STORE_REQUEST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: true,
        },
      };
    case storeActionTypes.CREATE_STORE_SUCCESS:
    case storeActionTypes.UPDATE_STORE_SUCCESS:
      return {
        ...state,
        store: {
          loading: false,
          data: action.payload,
          error: error,
        },
      };
    case storeActionTypes.FETCH_STORE_FAILURE:
    case storeActionTypes.CREATE_STORE_FAILURE:
    case storeActionTypes.UPDATE_STORE_FAILURE:
    case storeActionTypes.ENABLE_STORE_FAILURE:
    case storeActionTypes.DISABLE_STORE_FAILURE:
      return {
        ...state,
        store: {
          loading: false,
          data: store,
          error: action.payload,
        },
      };
    case storeActionTypes.FETCH_STORE_SUCCESS:
      return {
        ...state,
        store: {
          loading: false,
          data: action.payload,
          error: error,
        },
      };
    case storeActionTypes.STORE_CLEAN_UP:
      return {
        ...state,
        store: initialState.store
      };
      case storeActionTypes.ENABLE_STORE_SUCCESS:
      case storeActionTypes.DISABLE_STORE_SUCCESS:
            return {
              ...state,
              store: {...state.store, loading:false}
            };
    default:
      return state;
  }
};
export default storesReducer;
