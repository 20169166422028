import { FC } from "react";
import { Helmet } from "react-helmet"

interface HelmetTitleProps {
    title: string,
    description: string
};

const HelmetTitle: FC<HelmetTitleProps> = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    );
};

export default HelmetTitle