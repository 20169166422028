import instance from '../utils/axios.instance';
import { usersActionTypes, shortcutActionTypes } from "../constants/index";
import { ActionCreator } from "redux";
import { ThunkAction } from 'redux-thunk';
import { AppDispatch, RootState } from '../store/store';
import {  usersAction } from '../types/users.types';
import { IError } from "../types/interfaces/error.interface";


export const fetchShortcutRequest = () => {
    return {
        type: shortcutActionTypes.FETCH_SHORTCUT_REQUEST
    };
};

export const fetchShortcutSuccess = (data: any) => {
    return {
        type: shortcutActionTypes.FETCH_SHORTCUT_SUCCESS,
        payload: data
    };
};

export const fetchShortcut: ActionCreator<ThunkAction<void, RootState, null, usersAction>> = (currentPage: any = 1, pageSize: any = 20, params: string = '') =>
    async (dispatch: AppDispatch) => {
        try {
            //dispatch(fetchShortcutRequest());
            const response = await instance.get(`/api/shortcut/?page=${currentPage}&pageSize=${pageSize}${params}`);
            dispatch(fetchShortcutSuccess(response.data));
        } catch (error: any) {
            const data: IError = {
                status: error.response?.status ?? 400,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchShortcutFailure(data));
        }
    };


    export const fetchShortcutCreateOrUpdate: ActionCreator<ThunkAction<void, RootState, null, usersAction>> = (body, id ) =>
    async (dispatch: AppDispatch) => {
        try {
           let response;
            if(id){
                response = await instance.patch(`/api/shortcut/` + id, body);
            }else{
                response = await instance.post(`/api/shortcut`, body);
            }
            dispatch(fetchShortcutSuccess(response.data));

          
        } catch (error: any) {
          
            const data: IError = {
                status: error.response?.status ?? 400,
                data: {
                    timestamp: error.response?.data?.timestamp,
                    path: error.response?.data?.path,
                    message: error.response?.data?.message
                }
            };
            dispatch(fetchShortcutFailure(data));
        }
    };


export const fetchShortcutFailure = (error: IError) => {
    return {
        type: shortcutActionTypes.FETCH_SHORTCUT_FAILURE,
        payload: error
    };
};
