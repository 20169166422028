import { countriesAction, ICountry } from '../types/countries.types';
import { ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import {countriesActionTypes} from "../constants/index";
import instance from "../utils/axios.instance";
import { AppDispatch, RootState } from '../store/store';
import { IError } from "../types/interfaces/error.interface";
import { Result } from '../types/enum/result';
import { IResponse } from '../types/interfaces/response.interface';

/*********************FETCH COUNTRIES ACTIONS************************************/
export const fetchCountriesRequest = () =>{
    return{
        type:countriesActionTypes.FETCH_COUNTRIES_REQUEST
    }
}

export const fetchCountriesSuccess = (data:any) =>{
    return{
        type:countriesActionTypes.FETCH_COUNTRIES_SUCCESS,  
        payload:data
    }
}

export const fetchCountriesFailure = (error:IError) =>{
    return{
        type:countriesActionTypes.FETCH_COUNTRIES_FAILURE,
        payload:error
    }
}
/*********************FETCH COUNTRY ACTIONS************************************/
export const fetchCountryRequest = () =>{
    return{
        type:countriesActionTypes.FETCH_COUNTRY_REQUEST
    }
}

export const fetchCountrySuccess = (data:any) =>{
    return{
        type:countriesActionTypes.FETCH_COUNTRY_SUCCESS,  
        payload:data
    }
}

export const fetchCountryFailure = (error:IError) =>{
    return{
        type:countriesActionTypes.FETCH_COUNTRY_FAILURE,
        payload:error
    }
}
/*********************DELETE COUNTRY ACTION************************************/
export const deleteCountryRequest = () =>{
    return{
        type:countriesActionTypes.DELETE_COUNTRY_REQUEST
    }
}

export const deleteCountrySuccess = (data:any) =>{
    return{
        type:countriesActionTypes.DELETE_COUNTRY_SUCCESS,  
        payload:data
    }
}

export const deleteCountryFailure = (error:IError) =>{
    return{
        type:countriesActionTypes.DELETE_COUNTRY_FAILURE,
        payload:error
    }
}
/*********************FETCH COUNTRIES SELECT ACTIONS************************************/
export const fetchCountriesSelectRequest = () =>{
    return{
        type:countriesActionTypes.FETCH_COUNTRIES_SELECT_REQUEST
    }
}

export const fetchCountriesSelectSuccess = (data:any) =>{
    return{
        type:countriesActionTypes.FETCH_COUNTRIES_SELECT_SUCCESS,  
        payload:data
    }
}

export const fetchCountriesSelectFailure = (error:IError) =>{
    return{
        type:countriesActionTypes.FETCH_COUNTRIES_SELECT_FAILURE,
        payload:error
    }
}
/*********************CREATE COUNTRY ACTION************************************/
export const createCountryRequest = () =>{
    return{
        type:countriesActionTypes.CREATE_COUNTRY_REQUEST
    }
}

export const createCountrySuccess = (data:ICountry) =>{
    return{
        type:countriesActionTypes.CREATE_COUNTRY_SUCCESS,  
        payload:data
    }
}

export const createCountryFailure = (error:IError) =>{
    return{
        type:countriesActionTypes.CREATE_COUNTRY_FAILURE,
        payload:error
    }
}
/*********************CREATE COUNTRY ACTION************************************/
export const updateCountryRequest = () =>{
    return{
        type:countriesActionTypes.UPDATE_COUNTRY_REQUEST
    }
}

export const updateCountrySuccess = (data:ICountry) =>{
    return{
        type:countriesActionTypes.UPDATE_COUNTRY_SUCCESS,  
        payload:data
    }
}

export const updateCountryFailure = (error:IError) =>{
    return{
        type:countriesActionTypes.UPDATE_COUNTRY_FAILURE,
        payload:error
    }
}
/*********************CLEAN UP COUNTRY ACTION************************************/
export const cleanUpCountryData = () =>{
    return{
        type:countriesActionTypes.COUNTRY_CLEAN_UP
    }
}
/****************************************************************************/
//Get all Countries
export const fetchCountries:ActionCreator<ThunkAction<void, RootState, null, countriesAction>> = (currentPage:any = 1, pageSize:any = 20)=>{
    return async (dispatch:AppDispatch)=>{
        dispatch(fetchCountriesRequest());
       await instance.get(`/api/countries?page=${currentPage}&pageSize=${pageSize}`)
      .then((response:any) => {
        const data=response.data;
        dispatch(fetchCountriesSuccess(data));
      })
      .catch((error:any) => {
        if(error.response){
            const data:IError= {status:error.response.status, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message.string()}}
            dispatch(fetchCountriesFailure(data));
        }
      });
    }
}
//Get a Country
export const fetchCountry:ActionCreator<ThunkAction<void, RootState, null, countriesAction>> = (id:string)=>{
    return async (dispatch:AppDispatch)=>{
        dispatch(fetchCountryRequest());
       await instance.get(`/api/countries/${id}`)
      .then((response:any) => {
        const data=response.data;
        dispatch(fetchCountrySuccess(data));
      })
      .catch((error:any) => {
        if(error.response){
            const data:IError= {status:error.response.status, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}}
            dispatch(fetchCountryFailure(data));
        }
      });
    }
}
//Get a Country from store
//temporary solution
export const fetchCountryFromStore=(body:any)=>{
    return (dispatch:AppDispatch)=>{
        dispatch(fetchCountryRequest());
        dispatch(fetchCountrySuccess(body));
    }
}
//Delete a country by Id
export const deleteCountry:ActionCreator<ThunkAction<void, RootState, null, countriesAction>> = (id:string)=>
     async (dispatch:AppDispatch)=>{
        try{
            dispatch(deleteCountryRequest());
            const response = await instance.delete(`/api/countries/${id}`)
            dispatch(deleteCountrySuccess(response.data));
            const result: IResponse = {status:Result.OK}
            return result;
        }catch(error:any){
            if(error.response){
                const result:IError= {status:Result.ERROR, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}};
                dispatch(deleteCountryFailure(result));
                return result;
            }
      };
    };
//Create a Country
export const createCountry:ActionCreator<ThunkAction<void, RootState, null, countriesAction>> = (body:any)=>
     async (dispatch:AppDispatch)=>{
        try{
            dispatch(createCountryRequest());
            const response = await instance.post(`/api/countries`, body)
            dispatch(createCountrySuccess(response.data));
            const result: IResponse = {status:Result.OK}
            return result;
        }catch(error:any){
            const result:IError= {status:Result.ERROR, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}}
            dispatch(createCountryFailure(result));
            return result;
      };
    };
//Update a Country
export const updateCountry:ActionCreator<ThunkAction<void, RootState, null, countriesAction>> = (body:any,id:string)=>
     async (dispatch:AppDispatch)=>{
        try{
            dispatch(updateCountryRequest());
            const response = await instance.patch(`/api/countries/${id}`, body)
            dispatch(updateCountrySuccess(response.data));
            return Result.OK;
        }catch(error:any){
            const data:IError= {status:error.response.status, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message}}
            dispatch(updateCountryFailure(data));
            return Result.ERROR;
      };
    };

export const cleanupCountry=()=>{
    return (dispatch:AppDispatch)=>{
            dispatch(cleanUpCountryData());
        }
}

//Get coutries for Select
export const fetchCountriesSelect:ActionCreator<ThunkAction<void, RootState, null, countriesAction>> = (currentPage:any = 1, pageSize:any = 50)=>{
    return async (dispatch:AppDispatch)=>{
        dispatch(fetchCountriesSelectRequest());
       await instance.get(`/api/countries?page=${currentPage}&pageSize=${pageSize}`)
      .then((response:any) => {
        const data=response.data;
        dispatch(fetchCountriesSelectSuccess(data));
      })
      .catch((error:any) => {
        if(error.response){
            const data:IError= {status:error.response.status, data:{timestamp:error.response?.data?.timestamp,path:error.response?.data?.path, message:error.response?.data?.message.string()}}
            dispatch(fetchCountriesSelectFailure(data));
        }
      });
    }
}