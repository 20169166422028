import { Button, Col, Form, Modal, Row, Spin } from "antd";
import { FilterOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import HelmetTitle from "../../other/HelmetTitle";
import Table from "../../table/customizedTable/CustomizedTable";
import "./ProductsStore.css";
import { CSVLink } from "react-csv";
import Filters from "../../shared/filters/Filters";
//import { useStoreProducts } from "../../../hooks/useStoreProductsNew";
import { useStoreProducts } from "../../../hooks/useStoreProducts/useStoreProducts";
import PageTitle from "../../other/PageTitle";
import { Columns } from "./Columns";
import { headers } from "./FileHeaders";
import { Link } from "react-router-dom";
import NoPromotableReasonSelect from "../../selects/products/noPromotableReasonSelect";
import { fields } from "./fields";
import { useFilters } from "../../../hooks/useFilters";
import { fetchStoreProducts } from "../../../actions/storeProducts/main.actions";
import HorizontalFilter from "../../shared/filters/HorizontalFilter";
import FilterBreadcrumb from "../../shared/filters/FilterBreadcrumb";
import ProductsPromotion from "./promotion/ProductsPromotion";
import ProductsStoreFormNew from "./productStoreForm/ProductsStoreForm";
import { FilterSection } from "../../../types/enum/filters";
import { DownloadCSV } from "../../shared/buttons/DownloadCSV";
import { useState } from "react";
import UpdateProducts from "../../updateProduct";
import { useDownloadStoreProduct } from "../../../hooks/downloadStoreProduct";
import CatalogSelect from "../../selects/catalog/CatalogSelect";
import { InputDate } from "../../statistics/inputDate";
import Loading from "../../loading/loading";

const defaultParams = `&catalogId=60d4f60a378aec6831a3f459`;

const ProductsStoreNew = (props: any) => {

  const [form] = Form.useForm();

  const {
    filters,
    handleChange,
    handleChangeSelect,
    resetFilter,
    toggleFilters,
    collapsed,
    onFinishFiltering,
    queryParams,
    cancelPromote,
    setRestartSearch,
    handleDonwload,
    reloadFilter
  } = useFilters(
    fetchStoreProducts,
    fields,
    FilterSection.STOREPRODUCTS,
    defaultParams,
    form
  );

  const {
    storeProductsState,
    pagination,
    onClick,
    loading,
    productStoreID,
    isNoPromoteModalVisible,
    noPromoteReason,
    showNoPromoteModal,
    handleCancel,
    onChangeNoPromoteReason,
    onChangeCheckbox,
    date,
    onClickNoPromoteProduct,
    handleRowSelectionCheckbox,
    handleEdit,
    showForm,
    setShowForm,
    onClickPromoteProducts,
    filtersState,
    promotableButtonIsDisabled,
    noPromotableButtonIsDisabled,
    prodStoreDisableFields,
    showPromotionWindow,
    togglePromotionWindow,
    openModal,
    setOpenModal,
    updateProductSave
  } = useStoreProducts(queryParams, defaultParams);

  const columns = Columns(
    onChangeCheckbox,
    isNoPromoteModalVisible,
    onClickNoPromoteProduct,
    handleCancel,
    onChangeNoPromoteReason,
    showNoPromoteModal,
    productStoreID,
    noPromoteReason,
    handleEdit,
    onClickPromoteProducts,
    queryParams
  );
  const { openModalDownload, setOpenModalDownload, onChangesDate, downloadData, dataDownload, buttonRefDownload, openLoadingDownload } = useDownloadStoreProduct()
  return !loading ? (
    <>
      <HelmetTitle
        title="Productos de tienda - Backoffice D&#38;N"
        description="Pantalla para listar, y promover productos de tienda"
      />
      {showForm ? (
        <ProductsStoreFormNew
          isModal={false}
          setRestartSearch={setRestartSearch}
          disableFields={prodStoreDisableFields}
          setShowForm={setShowForm}
          submitButton={{ title: "Guardar" }}
          cancelButton={{
            title: "Cancelar",
            onClick: () => setShowForm(false),
            disabled: false,
          }}
          resetButton={{ title: "Limpiar", disabled: true }}
        />
      ) : showPromotionWindow ? (
        <ProductsPromotion
          togglePromotionWindow={togglePromotionWindow}
          cancelPromote={cancelPromote}
          setRestartSearch={setRestartSearch}
        />
      ) : (
        <>
          <Row gutter={[0, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 5 }}>
              <PageTitle title="Productos de tienda" />
              <FilterBreadcrumb filters={filtersState.storeProducts} />
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 19 }}>
              <Row className="d-flex flex-end">



                <Button
                  type="text"
                  disabled={promotableButtonIsDisabled}
                  onClick={onClickPromoteProducts}
                  id="promote-button"
                >
                  Promover
                </Button>

                <Button
                  type="text"
                  disabled={noPromotableButtonIsDisabled}
                  id="no-promotable-button"
                >
                  <Link to="/products-store-new/no-promotable">
                    No promovible
                  </Link>
                </Button>

                <Button
                  type="text"
                  onClick={() => setOpenModal(true)}
                  id="promote-button"
                >

                  Actualización masiva

                </Button>

                <Button
                  type="text"
                  onClick={() => setOpenModalDownload(true)}
                  id="promote-button"
                >

                  Descarga masiva

                </Button>
      
                <DownloadCSV
                  headers={headers}
                  date={date}
                  section={FilterSection.STOREPRODUCTS}
                  handleDownload={handleDonwload}
                />
      


                <Button type="text" onClick={toggleFilters}>
                  <FilterOutlined />
                  Filtros
                </Button>
              </Row>
            </Col>
          </Row>

          <Form form={form} onFinish={onFinishFiltering}>
            <Filters
              fields={fields}
              filters={filters}
              collapsed={collapsed}
              onFinishFiltering={onFinishFiltering}
              productStoreFilters={true}
              toggleFilters={toggleFilters}
              resetFilter={resetFilter}
              handleChange={handleChange}
              handleChangeSelect={handleChangeSelect}
              form={form}
            />

            <HorizontalFilter
              fields={fields}
              onFinishFiltering={onFinishFiltering}
              filters={filters}
              handleChange={handleChange}
              resetFilter={resetFilter}
              handleChangeSelect={handleChangeSelect}
              form={form}
            />
          </Form>

          <Row>
            <Col span={24}>
              <Table
                dataSource={storeProductsState.storeProducts.data.data}
                columns={columns}
                name="producto de tienda"
                title={null}
                path="products-store"
                onClick={onClick}
                loading={storeProductsState.storeProducts.loading}
                pagination={pagination}
                handleRowSelection={handleRowSelectionCheckbox}
              />
            </Col>
          </Row>
        </>
      )}
      <>
        {openModal &&
          <Modal
            title="Actualización masiva de productos tienda"
            centered
            visible={openModal}
            footer={null}
            onCancel={() => setOpenModal(false)}
            width={1000}
          >
            <UpdateProducts updateProductSave={updateProductSave} reloadFilter={reloadFilter} />
          </Modal>
        }

        {openModalDownload &&
          <Modal
            title="Descarga masiva"
            centered
            visible={openModalDownload}
            footer={null}
            onCancel={() => setOpenModalDownload(false)}
            width={550}
          >
            <>
              <InputDate onChangesDate={onChangesDate} numberDays={60} />
              <Button type="primary" onClick={() => downloadData(filters)}>
                Descargar
              </Button>
              <Loading open={openLoadingDownload} />
              <CSVLink
                headers={headers}
                data={dataDownload}
                separator={","}
                filename={"productos-tienda"+new Date()}
                ref={buttonRefDownload}
              ></CSVLink>

            </>
          </Modal>
        }
      </>


    </>
  ) : (
    <Spin />
  );
};

export default ProductsStoreNew;
