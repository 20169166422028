
import { FormOutlined } from "@ant-design/icons";

export const columnsPowerBi = (handleEdit: any) => {
    return [

        {
            title: 'País',
            dataIndex: ["countryId", "countyName"],
            key: 'countryId',
            render: (text: any, record: any, index: any) => {
                return record.countryId.name
            }

        },
        {
            title: 'workspaceId',
            dataIndex: 'workspaceId',
            key: 'workspaceId',
        },
        {
            title: 'reportId',
            dataIndex: 'reportId',
            key: 'reportId',
        },
        {
            title: 'clientId',
            dataIndex: 'clientId',
            key: 'clientId',
        },

        {
            title: "Acciones",
            dataIndex: "rowButtons",
            key: "rowButtons",
            render: (text: any, record: any, index: any) => {
                return (
                    <div
                        className="table__button-container"
                        style={{ justifyContent: "space-around" }}
                    >
                        <FormOutlined
                            title="Ver detalle"
                            alt="Icono de ver detalle"
                            className="table__button"
                            onClick={() => handleEdit(record)}
                        />
                    </div>
                );
            },
        },



    ]

}

