import { useEffect, useState } from "react";
import { fetchCatalogs } from "../actions/catalogs.actions";
import { IResponse } from "../types/interfaces/response.interface";
import { fetchCatalogFiles, generateCatalogFile } from "../actions/catalogFiles.action";
import { message } from "antd";
import { Result } from "../types/enum/result";
import { RootState, useAppDispatch } from "../store/store";
import { useSelector } from "react-redux";
import { catalogFilesState } from "../types/catalogFiles.types";

export const useCatalogFilesAdd = (props:any) =>{
    const { setShowForm } = props;
    const catalogFilesState: catalogFilesState = useSelector(
      (state: RootState) => state.catalogFiles
    );
    const [catalogId, setCatalogId] = useState("");
    const dispatch = useAppDispatch();
  
    const onFinish = (values: any) => {
      const page= catalogFilesState?.catalogFiles?.data?.page;
      const pageSize= catalogFilesState?.catalogFiles?.data?.pageSize;
      
      if (catalogId === "") {
        message.error("Por favor, seleccione un catalogo.");
        return;
      }
      dispatch(generateCatalogFile(catalogId)).then((result: IResponse) => {
        if (result.status === Result.OK) {
          dispatch(fetchCatalogFiles(page, pageSize));
          setShowForm(false);
          message.success("Se ha generado el catalogo con éxito");
        } else {
          setShowForm(false);
          message.error(result.data?.message ?? "Error en el proceso de generar un nuevo archivo de catálogo");
        }
      });
    };
  
    const changesField = (name: any, value: any) => {
      setCatalogId(value);
    };
  
    useEffect(() => {
      dispatch(fetchCatalogs());
    }, [dispatch]);

    return {catalogFilesState,setShowForm,onFinish,changesField}
}