import { useState } from "react";
import "./TaxPercentCatalog.css"
const TaxPercentCatalogTable = ({ taxPercentCatalog, setTaxPercentCatalog }: any) => {

    const removeTax = (keyDelete: number) => {
        const newtaxPercentCatalog = taxPercentCatalog.filter((item: any, key: number) => keyDelete != key)
        setTaxPercentCatalog(newtaxPercentCatalog)
    }
    return (
        <>
            {taxPercentCatalog?.length > 0 &&
                <table className="styleTableTax">
                    <thead>
                        <tr>
                            <th>Impuestos</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {taxPercentCatalog.map((item: any, key: number) => {
                            return (
                                <tr key={key}>
                                    <td>{item}</td>
                                    <td><a onClick={() => removeTax(key)}>Eliminar</a></td>
                                </tr>
                            )
                        })}

                    </tbody>

                </table>
            }
        </>

    )
}

export default TaxPercentCatalogTable