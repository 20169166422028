import { Form, Select} from "antd";
import CopyTextColumn from "../../../other/CopyTextColumn";
import noPromotableReasonData from "../../../../data/NoPromotableReason.json";
import { 
  DeleteOutlined,
} from '@ant-design/icons';

const { Option } = Select;

export const Columns = (
  onChangeSingleReason:any,
  reasonFormInstance:any,
  removeNoPromotableProduct:any,
) => {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: function RevealTextOnHover(text: any, record: any, index: any) {
        return <CopyTextColumn text={text} record={record} />;
      },
    },
    {
      title: "Código de barra",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a: any, b: any) => a.barcode - b.barcode,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Contenido",
      dataIndex: "contents",
      key: "contents",
      sorter: (a: any, b: any) => a.contents - b.contents,
    },
    {
      title: "Unidad",
      dataIndex: "unit",
      key: "unit",
      sorter: (a: any, b: any) => a.unit.localeCompare(b.unit),
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      sorter: (a: any, b: any) => a.price - b.price,
    },
    {
      title: "% de impuesto",
      dataIndex: "taxPercent",
      key: "taxPercent",
      sorter: (a: any, b: any) => a.taxPercent - b.taxPercent,
    },
    {
      title: "Comercio",
      dataIndex: ["store", "storeName"],
      key: "store",
      sorter: (a: any, b: any) =>
        a.store.storeName.localeCompare(b.store.storeName),
    },
    {
      title: "Estado producto",
      dataIndex: "",
      key: "",
      render: (text: any, record: any, index: any) => {
        return (
          <Form
            name="reasonFormInstance"
            form={reasonFormInstance}
          >
            <Form.Item
              name={`reason-${record.id}`}
            >
               <Select
                placeholder="Seleccione una razón"
                optionLabelProp="label"
                onChange={(reason) => onChangeSingleReason(reason, record.id)}
              >
                {noPromotableReasonData.map((option: any, idx: number) => (
                  <Option key={idx} value={option.value} label={option.name}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: "Acciones",
      dataIndex: "rowButtons",
      key: "rowButtons",
      render: (text: any, record: any, index: any) => {
          return (
            <div className="table__button-container" style={{ justifyContent: "space-around" }}>
              <DeleteOutlined 
                title="Eliminar" 
                className="table__button" 
                alt="Icono de borrado" 
                onClick={() => removeNoPromotableProduct(record.id)} 
              />
            </div>
          );
      },
    },
  ];
};
