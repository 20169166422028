import { message, Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchStores } from "../actions/stores.actions";
import { updateStoreProduct } from "../services/UpdateStoreProduct";
import { useNavigate } from "react-router-dom";


interface IInitialValues {
    catalogId: string;
    type: string;
    barcode: string;
    description: string;
    storeId?: string[];
  }

const initialValues: IInitialValues = {
    catalogId: "",
    type: "",
    barcode: "",
    description: "",
    storeId: [],
  };
  

export const useUpdateProduct = (updateProductSave: any, reloadFilter: any) => {  
    const [formInstance] = Form.useForm();
    const dispatch = useDispatch();
    const [dataField, setDataField]: any = useState(initialValues)
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fetchStores(1, 1000000));
    }, [])

    const handleChange = (value: any, name: any) => {
        setDataField({ ...dataField, [name]: value })
    }
    const saveData = () =>{
        if(dataField.type === "howMany"){
            dataField.storeId = dataField.storeId.map((item: any)  => item.value)
        }else{
            delete dataField.storeId
        }
        
        updateStoreProduct(dataField).then((response) =>{
            message.success('Hemos actualizado correctamente la descripción');
            setDataField(initialValues)
            updateProductSave(false)
            reloadFilter()
        }).catch((error) =>{
            message.error('Hemos tenido problemas al actualizar la descripción, intenta de nuevo');
            updateProductSave(true)
        })
    }
    return {formInstance, handleChange, dataField, saveData}
}