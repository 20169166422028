import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from "antd";
import { useEffect, useRef } from "react";
import { ProductType } from "../../../../types/enum/modalType";
import regularExpression from "../../../../utils/regularExpression";
import CatalogSelect from "../../../selects/catalog/CatalogSelect";
import CategoriesSelect from "../../../selects/categories/CategoriesSelect";
import CurrencySelect from "../../../selects/currency/CurrencySelect";
import UnitSelect from "../../../selects/unit/UnitSelect";
import FormButtons from "./FormButtons";

const { Text } = Typography;
const { Option } = Select;

type customizedButton = {
  title: string;
  disabled: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
};

interface IProps {
  type?: ProductType;
  form?: FormInstance<any> | undefined;
  onFinish?: ((values: any) => void) | undefined;
  data: any;
  catalogsData?: any;
  disableFields?: string[] | any;
  submitButton: { title: string };
  cancelButton: customizedButton;
  resetButton: customizedButton;
  fieldExceptions?: string[] | any;
}

const FormComponent = (props: IProps) => {
  const {
    type,
    form,
    onFinish,
    data,
    catalogsData,
    disableFields,
    submitButton,
    cancelButton,
    resetButton,
    fieldExceptions,
  } = props;
  const componentMounted = useRef(true); //component is mounted

  const handleChangeSelect = (name: string, value: any) => {
    form?.setFieldsValue({
      [name]: value,
    });
  };

  useEffect(() => {
    if (componentMounted.current) {
      //is component still mounted?
      if (data.category) {
        data.categoryId =
          data.category.length > 0 ? data.category[0]?.id : data.category?.id;
      }
      form?.setFieldsValue(data);
    }

    //Clean up form
    return () => {
      form?.setFieldsValue({});
    };
  }, [form, data]);

  return (
    <>
      <Form
        form={form}
        name="form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={[8, 8]}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label="Descripción"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Por favor inserte la Descripción.",
                },
                {
                  pattern: new RegExp(
                    regularExpression.nonWhitespacesAtBeginnig
                  ),
                  message: "El campo no puede estar vacío.",
                },
              ]}
              initialValue={data.description}
            >
              <Input
                disabled={
                  data.baseProduct
                    ? false
                    : disableFields?.includes("description")
                    ? true
                    : false
                }
              />
            </Form.Item>

            <Form.Item
              label="Código de barra"
              name="barcode"
              rules={[
                {
                  required: true,
                  message: "Por favor inserte el Código de barra.",
                },
                {
                  pattern: new RegExp(regularExpression.lettersAndNumbers),
                  message: "Solo se permiten letras y numeros",
                },
                {
                  pattern: new RegExp(
                    regularExpression.nonWhitespacesAtBeginnig
                  ),
                  message: "El campo no puede estar vacío.",
                },
              ]}
              initialValue={data.barcode}
            >
              <Input
                disabled={
                  data.baseProduct
                    ? true
                    : disableFields?.includes("barcode")
                    ? true
                    : false
                }
              />
            </Form.Item>

            <Form.Item
              label="Marca"
              name="brand"
              rules={[
                { required: true, message: "Por favor inserte la Marca." },
                {
                  pattern: new RegExp(regularExpression.alphanumeric),
                  message: "Solo se permiten letras y numeros",
                },
                {
                  pattern: new RegExp(
                    regularExpression.nonWhitespacesAtBeginnig
                  ),
                  message: "El campo no puede estar vacío.",
                },
              ]}
              initialValue={data.brand}
            >
              <Input
                disabled={
                  data.baseProduct
                    ? false
                    : disableFields?.includes("brand")
                    ? true
                    : false
                }
              />
            </Form.Item>

            <Form.Item
              label="Contenido"
              name="contents"
              rules={[
                { required: true, message: "Por favor inserte el Contenido." },
                {
                  pattern: new RegExp(regularExpression.alphanumeric),
                  message: "Solo se permiten letras y numeros",
                },
                {
                  pattern: new RegExp(
                    regularExpression.nonWhitespacesAtBeginnig
                  ),
                  message: "El campo no puede estar vacío.",
                },
              ]}
              initialValue={data.contents}
            >
              <InputNumber
                disabled={disableFields?.includes("contents") ? true : false}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              label="Unidad"
              name="unit"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione una unidad de medida.",
                },
              ]}
              initialValue={data.unit}
            >
              <UnitSelect
                initialValue={data.unit}
                disabled={disableFields?.includes("unit") ? true : false}
              />
            </Form.Item>

            <Form.Item
              label="Precio"
              name="price"
              rules={[
                { required: true, message: "Por favor inserte el Precio." },
                {
                  pattern: new RegExp(regularExpression.price),
                  message:
                    "Solo se permiten numeros con el siguiente formato #.##",
                },
                {
                  pattern: new RegExp(
                    regularExpression.nonWhitespacesAtBeginnig
                  ),
                  message: "El campo no puede estar vacío.",
                },
              ]}
              initialValue={data.price}
            >
              <InputNumber
                disabled={disableFields?.includes("price") ? true : false}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label="Moneda"
              name="currency"
              rules={[
                { required: true, message: "Por favor seleccione una moneda." },
              ]}
              initialValue={data.currency}
            >
              <CurrencySelect
                initialValue={data.currency}
                disabled={disableFields?.includes("currency") ? true : false}
              />
            </Form.Item>

            <Form.Item
              label="Porcentaje de impuesto"
              name="taxPercent"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un porcentaje de impuesto.",
                },
              ]}
              initialValue={data.taxPercent}
            >
              {type === ProductType.BASE ? (
                <Select
                  placeholder="Seleccione un porcentaje de impuesto"
                  disabled={
                    disableFields?.includes("taxPercent") ? true : false
                  }
                >
                  {catalogsData.taxPercentCatalog.map(
                    (item: any, idx: number) => (
                      <Option key={idx} value={item} label={item}>
                        {item}
                      </Option>
                    )
                  )}
                </Select>
              ) : (
                <Input
                  disabled={
                    disableFields?.includes("taxPercent") ? true : false
                  }
                />
              )}
            </Form.Item>

            <Form.Item
              label="Modo de venta"
              name="sellMode"
              rules={[
                {
                  required: true,
                  message: "Por favor inserte el Modo de venta.",
                },
                {
                  pattern: new RegExp(
                    regularExpression.nonWhitespacesAtBeginnig
                  ),
                  message: "El campo no puede estar vacío.",
                },
              ]}
              initialValue={data.sellMode}
            >
              <Input
                disabled={disableFields?.includes("sellMode") ? true : false}
              />
            </Form.Item>

            <Form.Item
              label="Catálogo"
              name="catalogId"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un Catálogo.",
                },
              ]}
              initialValue={
                Array.isArray(data.category)
                  ? data.category[0]?.catalogId
                  : data.category?.catalogId
              }
            >
              <CatalogSelect
                initialValue={
                  Array.isArray(data.category)
                    ? data.category[0]?.catalogId
                    : data.category?.catalogId
                }
                disabled={disableFields?.includes("catalogId") ? true : false}
              />
            </Form.Item>

            <Form.Item label="ID de catálogo">
              <Input
                defaultValue={
                  Array.isArray(data.category)
                    ? data.category[0]?.catalogId
                    : data.category?.catalogId
                }
                disabled
                addonAfter={
                  <Text
                    copyable={{
                      text: Array.isArray(data.category)
                        ? data.category[0]?.catalogId
                        : data.category?.catalogId,
                    }}
                    style={{ width: 10 }}
                  />
                }
              />
            </Form.Item>

            {fieldExceptions?.includes("categoryList") && (
              <Form.Item
                label="Categoría"
                name="categoryId"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione una categoría.",
                  },
                ]}
              >
                <CategoriesSelect
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  parentId={
                    Array.isArray(data.category)
                      ? data.category[0]?.catalogId
                      : data.category?.catalogId
                  }
                  handleChangeSelect={handleChangeSelect}
                />
              </Form.Item>
            )}
            {!fieldExceptions?.includes("categoryList") && (
              <Form.Item
                label="ID de categoría"
                name="categoryId"
                rules={[
                  {
                    required: true,
                    message: "Por favor inserte el ID de categoría!",
                  },
                  {
                    pattern: new RegExp(regularExpression.lettersAndNumbers),
                    message: "Solo se permiten letras y numeros",
                  },
                ]}
                initialValue={
                  Array.isArray(data.category)
                    ? data.category[0]?.id
                    : data.category?.id
                }
              >
                <Input
                  disabled={
                    disableFields?.includes("categoryId") ? true : false
                  }
                  addonAfter={
                    <Text
                      copyable={{
                        text: Array.isArray(data.category)
                          ? data.category[0]?.id
                          : data.category?.id,
                      }}
                      style={{ width: 10 }}
                    />
                  }
                />
              </Form.Item>
            )}
          </Col>
          <FormButtons
            submitButton={submitButton}
            cancelButton={cancelButton}
            resetButton={resetButton}
          />
        </Row>
      </Form>
    </>
  );
};
export default FormComponent;
