import { Breadcrumb, Form, Input, Checkbox, Col, Space, Button } from "antd";
import { Link } from "react-router-dom";
import regularExpression from "../../utils/regularExpression";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { countriesState } from "../../types/countries.types";
import CountrySelect from "../selects/country/CountrySelect";
import TaxPercentCatalogForm from "./TaxPercentCatalogForm";
import { useState, useEffect } from "react";
import TaxPercentCatalogTable from "./TaxPercentCatalogTable";
import { fetchCountries } from "../../actions/countries.actions";
import { useCatalogsForm } from "../../hooks/useCatalogsForm";

const CatalogForm = (props: any) => {
        const {setShowForm, formInstance,onChangesField, dataForm, countriesState, handleOnChangeCountry, setTaxPercentCatalog, taxPercentCatalog, saveCatalogs } = useCatalogsForm(props)

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link
                        to="/catalogs"
                        onClick={() => {
                            setShowForm(false)
                        }}
                    >
                        Catálogo
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Agregar
                </Breadcrumb.Item>
            </Breadcrumb>

            <>
                <Form
                    form={formInstance}
                    name="form"
                    labelCol={{ span: 8 }}
                    wrapperCol={{
                        xs: { span: 24 },
                        lg: { span: 12 },
                    }}
                    layout="vertical"
                    onFinish={saveCatalogs}

                >

                    <Form.Item
                        label="Nombre"
                        name="name"

                        rules={[
                            { required: true, message: "Por favor inserte el nombre." },
                            {
                                pattern: new RegExp(regularExpression.nonWhitespacesAtBeginnig),
                                message: "El campo no puede estar vacío.",
                            }
                        ]}

                    >
                        <Input
                            onChange={(e) => onChangesField(e.target.value, "name")}
                            value={dataForm?.name}
                        />
                    </Form.Item>


                    <Form.Item
                        label="País"
                        name="countryCode"
                        rules={[
                            { required: true, message: "Por favor seleccione un país." },
                        ]}

                    >
                        <CountrySelect

                            filterOption={(input: any, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                            onChange={handleOnChangeCountry}

                        ></CountrySelect>
                    </Form.Item>

                    <Form.Item
                        label="Contacto"
                        name="contactInfo"
                        rules={[
                            { required: true, message: "Por favor inserte la información de contacto." },
                            {
                                pattern: new RegExp(regularExpression.nonWhitespacesAtBeginnig),
                                message: "El campo no puede estar vacío.",
                            }
                        ]}

                    >
                        <Input
                            onChange={(e) => onChangesField(e.target.value, "contactInfo")}
                            value={dataForm?.contactInfo}
                        />
                    </Form.Item>


                    <Form.Item
                        label="Internal ref"
                        name="attributes"
                        rules={[
                            { required: true, message: "Por favor inserte la información de Internal ref." },
                            {
                                pattern: new RegExp(regularExpression.nonWhitespacesAtBeginnig),
                                message: "El campo no puede estar vacío.",
                            }
                        ]}

                    >
                        <Input
                            onChange={(e) => onChangesField(e.target.value, "attributes")}
                            value={dataForm?.attributes}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Activo"
                        name="active"
                        initialValue={true}
                        className="styleCheckboxLeft"
                        valuePropName="checked"
                    >
                        <Checkbox
                            className="checkbox"
                            onChange={(e) => onChangesField(e.target.checked, "active")}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Impuestos"
                        name="tax">
                        <TaxPercentCatalogForm setTaxPercentCatalog={setTaxPercentCatalog} />
                    </Form.Item>


                    <Form.Item >
                        <TaxPercentCatalogTable taxPercentCatalog={taxPercentCatalog} setTaxPercentCatalog={setTaxPercentCatalog} />
                    </Form.Item>


                    <Form.Item>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                Guardar
                            </Button>
                            <Button
                                onClick={() => setShowForm(false)}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>



                </Form>

            </>


        </>
    );
};

export default CatalogForm;



