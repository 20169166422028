import { Breadcrumb, Form, Input, Button, Space } from "antd"
import PageTitle from "../../other/PageTitle"
import { Link } from "react-router-dom"
import { fieldsPowerBi } from "./Fields"
import CountrySelect from "../../selects/country/CountrySelect"
import { useEffect, useState } from "react"
import regularExpression from "../../../utils/regularExpression"


interface IInitialValues {
    apiUrl: string,
    authenticationMode: string,
    authorityUri: string,
    clientId: string,
    clientSecret: string,
    countryId: string,
    dataset: string,
    password: string,
    reportId: string,
    scope: string,
    tenantId: string,
    username: string,
    workspaceId: string,
    _id?:string,
    updatedAt?:string,
    createdAt?:string
}

const initValue : IInitialValues= {
    apiUrl: "",
    authenticationMode: "",
    authorityUri: "",
    clientId: "",
    clientSecret: "",
    countryId: "",
    dataset: "",
    password: "",
    reportId: "",
    scope: "",
    tenantId: "",
    username: "",
    workspaceId: ""
}

export const PowerBiForm = ({ setShowForm, savePowerBi, powerBiEdit }: any) => {
    const [dataFields, setDataFields] : any = useState(initValue)
    const changesField = (name: string, value: any) => {
        setDataFields({ ...dataFields, [name]: value })
    }

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item >
                    <Link to="/conf-powerbi" onClick={() => setShowForm(false)}>
                        Configuracion Power Bi
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {"Agregar"}
                </Breadcrumb.Item>
            </Breadcrumb>
            <PageTitle
                title={"Agregar Nuevo Power BI"}
            />
            <>
                <Form
                    name="form"
                    labelCol={{ span: 8 }}
                    wrapperCol={{
                        xs: { span: 24 },
                        lg: { span: 12 },
                    }}
                    layout="vertical"
                    onFinish={() => savePowerBi(dataFields)}
                    className="formCreateUser"
                >
                    <Form.Item
                        label="País"
                        name="countryId"
                        initialValue={powerBiEdit?.countryId?._id}
                        rules={[
                            { required: true, message: "Por favor seleccione un país." },
                        ]}

                    >
                        <CountrySelect
                            initialValue={powerBiEdit?.countryId?._id}
                            filterOption={(input: any, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                            onChange={(e: any, options: any) => changesField("countryId", options.value)}
                            disabled={!!powerBiEdit?.countryId?._id}    
                        ></CountrySelect>
                    </Form.Item>
                    {fieldsPowerBi.map((item: any, key: number) => {
                      
                        return (
                            <Form.Item
                                label={item.label}
                                name={item.name}
                                rules={[
                                    { required: item.isNotrequired ? false : true, message: "Por favor inserte el " + item.label },
                                    item.isUrl ? {
                                        message: "Por favor inserte una url valida",
                                        pattern: new RegExp(regularExpression.url),
                                    } : {},

                                ]}
                                initialValue={powerBiEdit[item.name]}


                                key={key}>
                                <Input onChange={(e) => changesField(item.name, e.target.value)} />
                            </Form.Item>
                        )
                    })}
                    <Form.Item>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                Guardar
                            </Button>
                            <Button
                                onClick={() => setShowForm(false)}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>

                </Form>
            </>
        </>
    )
}   