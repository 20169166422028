export const regionsActionTypes= {
    FETCH_REGIONS_REQUEST:"FETCH_REGIONS_REQUEST",
    FETCH_REGIONS_SUCCESS:"FETCH_REGIONS_SUCCESS",
    FETCH_REGIONS_FAILURE:"FETCH_REGIONS_FAILURE",
    
    FETCH_REGION_REQUEST:"FETCH_REGION_REQUEST",
    FETCH_REGION_SUCCESS:"FETCH_REGION_SUCCESS",
    FETCH_REGION_FAILURE:"FETCH_REGION_FAILURE",
    
    DELETE_REGION_REQUEST:"DELETE_REGION_REQUEST",
    DELETE_REGION_SUCCESS:"DELETE_REGION_SUCCESS",
    DELETE_REGION_FAILURE:"DELETE_REGION_FAILURE",
    
    CREATE_REGION_REQUEST:"CREATE_REGION_REQUEST",
    CREATE_REGION_SUCCESS:"CREATE_REGION_SUCCESS",
    CREATE_REGION_FAILURE:"CREATE_REGION_FAILURE",
    
    UPDATE_REGION_REQUEST:"UPDATE_REGION_REQUEST",
    UPDATE_REGION_SUCCESS:"UPDATE_REGION_SUCCESS",
    UPDATE_REGION_FAILURE:"UPDATE_REGION_FAILURE",
    
    REGION_CLEAN_UP:"REGION_CLEAN_UP"
    
    }