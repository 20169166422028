import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetchStoreProduct,
  fetchStoreProducts,
} from "../../actions/storeProducts/main.actions";
import { RootState, useAppDispatch } from "../../store/store";
import { storeProductsState } from "../../types/storeProducts.types";
import { fetchStores } from "../../actions/stores.actions";
import { useNavigate } from "react-router-dom";
import { filtersState } from "../../types/filters.types";
import { ProductStoreEnum } from "../../types/enum/productStoreFields";
import {
  cleanUpProductsNoPromotableData,
  cleanUpProductsPromotedData,
  cleanUpProductsToBePromotedData,
  cleanupStoreProduct,
  cleanUpStoreProductData,
} from "../../actions/storeProducts/cleanup.actions";
import {
  addProductsNoPromotable,
  addProductsPromoted,
  addProductsToBePromoted,
  removeProductsNoPromotable,
  removeProductsPromoted,
  removeProductsToBePromoted,
} from "../../actions/storeProducts/promotion.actions";
import useSearchByFilters from "./useSearchByFilters";
import usePromoteFunctions from "./usePromoteFunctions";
import usePromoteBarCode from "./usePromoteBarCode";

export const useStoreProducts = (
  queryParams: string,
  defaultParams: string
) => {
  const [showForm, setShowForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useAppDispatch();
  const storeProductsState: storeProductsState = useSelector(
    (state: RootState) => state.storeProducts
  );
  const navigate = useNavigate();
  const dateObject = new Date();
  const filtersState: filtersState = useSelector(
    (state: RootState) => state.filters
  );

  /************************ related custom hooks *****************************/
  const {
    showPromotionWindow,
    noPromoteReason,
    setNoPromoteReason,
    productStoreID,
    setProductStoreID,
    isNoPromoteModalVisible,
    promotableButtonIsDisabled,
    noPromotableButtonIsDisabled,
    setIsNoPromoteModalVisible,
    showNoPromoteModal,
    handleCancel,
    onClickPromoteProducts,
    onClickNoPromoteProduct,
    togglePromotionWindow,
  } = usePromoteFunctions();

  const {
    loading,
    setLoading,
    showProductStoreByBarcodeTable,
    setShowProductStoreByBarcodeTable,
    onClickPromoteToNewBaseProduct,
    onClickPromoteWithBarcode,
    handleRowSelection,
    selectedProductStoreByBarcodeToBePromoted,
    setSelectedProductStoreByBarcodeToBePromoted,
  } = usePromoteBarCode();
  
  //Search store products by filters
  const { onFinishFiltering, toggleFilters, collapsed, setCollapsed } =
    useSearchByFilters();

  /*********************** end related custom hooks ***************************/

  //disable fields in ProductsStoreForm
  const prodStoreDisableFields = [
    ProductStoreEnum.CatalogId,
    ProductStoreEnum.CategoryId,
    ProductStoreEnum.Contents,
    ProductStoreEnum.Currency,
    ProductStoreEnum.Price,
    ProductStoreEnum.SellMode,
    ProductStoreEnum.TaxPercent,
    ProductStoreEnum.Unit,
  ];

  const updateProductSave = (modalType: boolean) =>{
    setOpenModal(modalType)
    
  }

  useEffect(() => {
    dispatch(fetchStoreProducts(1, 20, defaultParams));
    dispatch(fetchStores(1, 1000000));

    dispatch(cleanUpProductsToBePromotedData());
    dispatch(cleanUpProductsPromotedData());
    dispatch(cleanUpProductsNoPromotableData());
  }, [dispatch, defaultParams]);

  const handleEdit = (id: string) => {
    dispatch(cleanUpStoreProductData());
    dispatch(fetchStoreProduct(id));
    setShowForm(true);
  };

  const onClick = () => {
    dispatch(cleanupStoreProduct());
    setShowForm(true);
  };

  const onClickPromoteToExisingBaseProduct = () => {
    navigate("/products-base/promote");
  };

  const onChangeNoPromoteReason = (reason: string) => {
    setNoPromoteReason(reason);
  };

  const onChangeCheckbox = (isSelected: Boolean, record: any, type: string) => {
    if (type) {
      let some: boolean = false;
      if (isSelected) {
        some = storeProductsState.productsToBePromoted.data.some(
          (item: any) => item.id === record.id
        );
      }

      if (!some) {
        switch (type) {
          case "Por promover":
            isSelected
              ? dispatch(addProductsToBePromoted(record))
              : dispatch(removeProductsToBePromoted(record));
            break;
          case "Promovido":
            isSelected
              ? dispatch(addProductsPromoted(record))
              : dispatch(removeProductsPromoted(record));
            break;
          case "No promovible":
            isSelected
              ? dispatch(addProductsNoPromotable(record))
              : dispatch(removeProductsNoPromotable(record));
            break;
          default:
            break;
        }
      }
    }
  };

  const getType = (row: any) => {
    let type = "";

    if (!row.hasOwnProperty("baseProduct") && !row.hasOwnProperty("reason")) {
      type = "Por promover";
    }

    if (!row.hasOwnProperty("baseProduct") && row.hasOwnProperty("reason")) {
      type = "No promovible";
    }

    if (row.hasOwnProperty("baseProduct") && !row.hasOwnProperty("reason")) {
      type = "Promovido";
    }
    return type;
  };

  const handleRowSelectionCheckbox = {
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      dispatch(cleanUpProductsToBePromotedData());
      dispatch(cleanUpProductsPromotedData());
      dispatch(cleanUpProductsNoPromotableData());

      if (selectedRows.length) {
        selectedRows.forEach((row: any) => {
          if (!row) {
            return;
          }
          const type = getType(row);
          onChangeCheckbox(selected, row, type);
        });
      }
    },
    onSelect: (
      record: any,
      selected: any,
      selectedRows: any,
      nativeEvent: any
    ) => {
      const type = getType(record);
      onChangeCheckbox(selected, record, type);
    },
    type: "checkbox",
  };

  const date = `${dateObject.getFullYear()}_${
    dateObject.getMonth() + 1
  }_${dateObject.getDate()}`;

  // Cleanup data after filtering
  useEffect(() => {
    dispatch(cleanUpProductsToBePromotedData());
    dispatch(cleanUpProductsPromotedData());
    dispatch(cleanUpProductsNoPromotableData());
  }, [queryParams, dispatch]);

  return {
    storeProductsState,
    pagination: {
      current: storeProductsState.storeProducts.data.page,
      pageSize: storeProductsState.storeProducts.data.pageSize,
      total: storeProductsState.storeProducts.data.totalCount,
      onChange: (page: number, pageSize: number) => {
        dispatch(fetchStoreProducts(page, pageSize, queryParams));
        dispatch(cleanUpProductsToBePromotedData());
        dispatch(cleanUpProductsPromotedData());
        dispatch(cleanUpProductsNoPromotableData());
      },
    },
    onClick,
    showForm,
    setShowForm,
    loading,
    setLoading,
    collapsed,
    setCollapsed,
    productStoreID,
    setProductStoreID,
    isNoPromoteModalVisible,
    setIsNoPromoteModalVisible,
    noPromoteReason,
    setNoPromoteReason,
    showProductStoreByBarcodeTable,
    setShowProductStoreByBarcodeTable,
    selectedProductStoreByBarcodeToBePromoted,
    setSelectedProductStoreByBarcodeToBePromoted,
    onClickPromoteToExisingBaseProduct,
    toggleFilters,
    showNoPromoteModal,
    handleCancel,
    onChangeNoPromoteReason,
    onChangeCheckbox,
    handleRowSelection,
    date,
    onFinishFiltering,
    onClickPromoteToNewBaseProduct,
    onClickNoPromoteProduct,
    onClickPromoteWithBarcode,
    handleRowSelectionCheckbox,
    handleEdit,
    onClickPromoteProducts,
    URLSearchParams,
    filtersState,
    promotableButtonIsDisabled,
    noPromotableButtonIsDisabled,
    prodStoreDisableFields,
    showPromotionWindow,
    togglePromotionWindow,
    openModal,
    setOpenModal,
    updateProductSave
  };
};
