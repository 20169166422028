import { Button, Tooltip } from "antd";
import ButtonsTable from "../table/customizedTable/ButtonsTable";
import { PoweroffOutlined } from "@ant-design/icons";

export const Columns = (
  getColumnSearchProps: any,
  item: any,
  handleEdit: any,
  isModalVisible: any,
  handleCancel: any,
  handleOnClickEnable: any,
  hadleOnClickDeleteModal: any,
  showModal: any
) => {
  return [
    {
        title: "Nombre",
        dataIndex: "name",
        key: "name",
        sorter: (a:any, b:any) => a.name.localeCompare(b.name),
        // ...getColumnSearchProps('name')
      },
      {
        title: "País",
        dataIndex: ["country", "name"],
        key: "country",
        sorter: (a:any, b:any) => {
          if (a.country && b.country) {
            return a.country.name.localeCompare(b.country.name)
          }
  
          return;
        },
        // ...getColumnSearchProps(['country', 'name'])
      },
      {
        title: "Dirección",
        dataIndex: "address",
        key: "address",
        sorter: (a:any, b:any) => a.address.localeCompare(b.address),
        // ...getColumnSearchProps('address')
      },
      {
        title: "Mostrar impuestos",
        dataIndex: "taxDetail",
        key: "taxDetail",
        render: (text: any, record: any, index: any) => {
          if (record.taxDetail === "NOT_SHOW_TAX") {
            return "No";
          }
  
          return "Sí";
        },
        sorter: (a:any, b:any) => a.taxDetail.localeCompare(b.taxDetail),
        // filters: [
        //   {
        //     text: "Sí",
        //     value: "SHOW_TAX"
        //   },
        //   {
        //     text: "No",
        //     value: "NOT_SHOW_TAX"
        //   }
        // ],
        // onFilter: (value:any, record:any) => record.taxDetail.indexOf(value) === 0,
      },
      {
        title: "Username (E-mail)",
        dataIndex: "storeKey",
        key: "storeKey",
        sorter: (a:any, b:any) => a.storeKey.localeCompare(b.storeKey),
        // ...getColumnSearchProps('storeKey')
      },
      {
        title: "Estado",
        dataIndex: "active",
        key: "active",
        render: (text: any, record: any, index: any) => {
          if (!record.active) {
            return "Deshabilitado";
          } 
  
          return "Habilitado";
        },
        // sorter: (a:any, b:any) => b.active - a.active,
        // filters: [
        //   {
        //     text: "Habilitado",
        //     value: true
        //   },
        //   {
        //     text: "Deshabilitado",
        //     value: false
        //   }
        // ],
        // onFilter: (value:any, record:any) => record.active === value,
      },
      {
        title: "",
        key: "key",
        dataIndex: "key",
        render: (text: any, record: any) => (
          <>
            {record.active ? (
              <ButtonsTable
                titleModal="Comercio"
                onClickEdit={() => handleEdit(record)}
                onClickDelete={() => hadleOnClickDeleteModal(item)}
                isVisible={isModalVisible}
                onClickOKModal={() => showModal(record)}
                onClickCancelModal={() => handleCancel()}
                deleteButtonAction="deshabilitar"
                item={item}
              />
            ) : (
              <div className="table__button-container">
                 {/* <Tooltip title="Editar">
                    <Button
                        type="text"
                        shape="circle"
                        icon={<EditOutlined />}
                        className="btn-table"
                        style={{ marginRight: 5 }}
                        onClick={() => handleEdit(record)}
                    />
                </Tooltip> */}
                <Tooltip title="Habilitar">
                  <Button
                    type="text"
                    shape="circle"
                    icon={<PoweroffOutlined />}
                    className="btn-table"
                    style={{ marginRight: 5 }}
                    onClick={() => handleOnClickEnable(record)}
                  />
                </Tooltip>
              </div>
            )}
          </>
        ),
    },
  ];
};
