import { Dispatch, ActionCreator } from "redux"
import { ThunkAction } from "redux-thunk";

import { uploadActionTypes } from "../constants/index"
import instance from "../utils/axios.instance";
import { uploadFileAction } from '../types/uploadFile.types';
import { RootState } from '../store/store';
import { IError } from "../types/interfaces/error.interface";
import { message } from "antd";
import { translateMessage } from "../utils/arrayFunctions";

export const uploadFileRequest = () => {
    return {
        type: uploadActionTypes.UPLOAD_FILE_REQUEST
    }
};

export const uploadFileSuccess = (data: any) => {
    return {
        type: uploadActionTypes.UPLOAD_FILE_SUCCESS,
        payload: data
    }
};

export const uploadFileFailure = (error: any) => {
    return {
        type: uploadActionTypes.UPLOAD_FILE_FAILURE,
        payload: error
    }
};

export const uploadErrorData = (data: any) => {
    return {
        type: uploadActionTypes.UPLOAD_ERROR_DATA,
        payload: data
    }
};

export const cleanUploadErrorData = () => {
    return {
        type: uploadActionTypes.CLEAN_UPLOAD_ERROR_DATA,
    }
};

export const cleanUploadData = () => {
    return {
        type: uploadActionTypes.CLEAN_UPLOAD_DATA,
    }
};


export const uploadFile: ActionCreator<ThunkAction<void, RootState, null, uploadFileAction>> = (file: any, type: string = "stores", filter: any = {}, fetchEntity: any, entityState: any, closeUploadModal: any, setFile: any) => {
    const formData = new FormData();
    formData.append('file', file);

    return async (dispatch: Dispatch) => {

        if (Object.keys(filter).length > 0) {
            Object.keys(filter).forEach((key) => {
                formData.append(key, filter[key]);
            })
        }
        dispatch(uploadFileRequest());
        let url = "/api/stores/multiple";
        if (type === "users") {
            url = "/api/users/multiple";
        }
        await instance.post(url, formData)
            .then((response: any) => {
                dispatch(uploadFileSuccess(response.data));
                if (response.data.success.length > 0) {
                    dispatch(fetchEntity(entityState.data.page, entityState.data.pageSize));
                }
                if(response.data.failed.length === 0) {
                    closeUploadModal();
                    message.success('La carga masiva de comercios fue realizada exitosamente');
                }else{   
                    message.warning('Ocurrieron errores en la subida masiva.');
                }
                if (response.data.failed.length > 0) {
                    const newFailed =  response.data.failed.map((item: any) => {
                        item.message = translateMessage(item);
                        return item;
                    });
                    newFailed.sort((a: any,b: any) => {
                        if (a.row > b.row) {
                            return 1;
                          }
                          if (a.row < b.row) {
                            return -1;
                          }
                          return 0;
                    })
                    response.data.failed = newFailed;
                    dispatch(uploadErrorData(response.data.failed));
                }
                setFile("");
            })
            .catch((error: any) => {
                const data: IError = { status: error.response.status, data: { timestamp: error?.response?.data.timestamp, path: error?.response?.data.path, message: error?.response?.data.message } }
                message.error(error.response?.data?.message);
                dispatch(uploadFileFailure(data));
            });
    }
};