import { Action } from '../types/actions.types';
import {confPowerBITypes} from "../constants/index";
import {IConfigPowerModel} from "../models/configPowerBI.model";
import { IError, confPowerBIState } from '../types/confPowerBI.types';

let error:IError={status:"", message:""}
let properties:any[] =  [];

const initialState = {
    loading:false,
    configuration:properties,
    error:error
}

const confPowerBIReducer = (state=initialState, action:Action):confPowerBIState =>{
    switch(action.type){
        case confPowerBITypes.FETCH_CONFPOWERBI_REQUEST:
        case confPowerBITypes.UPDATE_CONFPOWERBI_REQUEST:
            return{
                ...state,
                loading:true
            }
        case confPowerBITypes.FETCH_CONFPOWERBI_SUCCESS:
        case confPowerBITypes.UPDATE_CONFPOWERBI_SUCCESS:
            return{
                loading:false,
                configuration:action.payload,
                error:error
            }
        case confPowerBITypes.FETCH_CONFPOWERBI_FAILURE:
        case confPowerBITypes.UPDATE_CONFPOWERBI_FAILURE:
            return{
                loading:false,
                configuration:{},
                error:action.payload
            }
        case confPowerBITypes.EDIT_CONFPOWERBI:
            const newFields= [...state.configuration]
            const field = newFields.filter(conf => conf.name ===action.payload.name );
            field[0].value=action.payload.value
            return{
                ...state,
                configuration:newFields
            }
        default:
            return state;
            
    }
}

export default confPowerBIReducer;