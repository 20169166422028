export const catalogFilesTypes = {
    FETCH_CATALOGLIST_REQUEST:"FETCH_CATALOGLIST_REQUEST",
    FETCH_CATALOGLIST_SUCCESS:"FETCH_CATALOGLIST_SUCCESS",
    FETCH_CATALOGLIST_FAILURE:"FETCH_CATALOGLIST_FAILURE",

    FETCH_CATALOGFILES_REQUEST:"FETCH_CATALOGFILES_REQUEST",
    FETCH_CATALOGFILES_SUCCESS:"FETCH_CATALOGFILES_SUCCESS",
    FETCH_CATALOGFILES_FAILURE:"FETCH_CATALOGFILES_FAILURE",

    GENERATE_CATALOG_REQUEST:"GENERATE_CATALOG_REQUEST",
    GENERATE_CATALOG_SUCCESS:"GENERATE_CATALOG_SUCCESS",
    GENERATE_CATALOG_FAILURE:"GENERATE_CATALOG_FAILURE"
}