import { Breadcrumb, Button, Space } from "antd";
import { Form } from "antd";
import { Link } from "react-router-dom";
import HelmetTitle from "../../other/HelmetTitle";

import Loading from "../../other/Loading";
import CatalogFilesSelect from "../../selects/catalogFiles/CatalogFilesSelect";
import PageTitle from "../../other/PageTitle";
import { useCatalogFilesAdd } from "../../../hooks/useCatalogFilesAdd";

const CatalogFilesAdd = (props: any) => {
  const {catalogFilesState,setShowForm,onFinish,changesField} = useCatalogFilesAdd(props);
  
  return !catalogFilesState.generateCatalog.loading ? (
    <>
      <HelmetTitle
        title="Configuración PowerBI - Backoffice D&#38;N"
        description="Pantalla para configurar el tablero de PowerBI"
      />

      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/catalog-files" onClick={() => setShowForm(false)}>
            Catálogo
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Generar Catálogos</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle title="Generar nuevo archivo de catálogo" />

      <Form
        name="form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Catálogo"
          name="catalogId"
          rules={[
            { required: true, message: "Por favor seleccione un catálogo" },
          ]}
        >
          <CatalogFilesSelect onChange={changesField} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Generar
            </Button>
            <Button onClick={() => setShowForm(false)}>Cancelar</Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  ) : (
    <Loading />
  );
};

export default CatalogFilesAdd;
