import { Select } from "antd";
import { FC } from "react";

import currencyData from "../../../data/currency.json";
import { dataCurrency } from "../../../data/countries";
const { Option } = Select;

type mode = "multiple" | undefined;

interface IProps {
  initialValue?: any;
  onChange?: ((value: any[], option: any) => void) | undefined;
  filterOption?: boolean | any | undefined;
  mode?: mode;
  handleChangeSelect?: any;
  disabled?: boolean;
}

const CurrencySelect: FC<IProps> = (props: any) => {
  const {
    initialValue,
    onChange,
    optionFilterProp,
    mode = undefined,
    handleChangeSelect,
    disabled = false,
  } = props;

  /*
  const handleChange = (value: any) => {
    if (handleChangeSelect) {
      return handleChangeSelect("currency", value);
    }
    if (onChange) {
      return onChange;
    }
  };*/

  return (
    <Select
      mode={mode ?? undefined}
      defaultValue={initialValue}
      placeholder="Seleccione una moneda"
      onChange={onChange || handleChangeSelect}
      showSearch
      optionFilterProp="children"
      filterOption={optionFilterProp}
      disabled={disabled}
    >
      {dataCurrency().map((option: any, idx: number) => (
        <Option key={idx} value={option.value} label={option.name}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
};
export default CurrencySelect;
