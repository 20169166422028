import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  addBarcodeProduct,
  addNoBarcodeProduct,
  fetchStoreProducts,
  removeBarcodeProduct,
  removeNoBarcodeProduct,
} from "../actions/storeProducts/main.actions";
import { RootState, useAppDispatch } from "../store/store";
import { storeProductsState } from "../types/storeProducts.types";
import { fetchStores } from "../actions/stores.actions";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  cleanupBaseProduct,
  cleanUpBaseProductFormData,
  setBaseProductFormData,
} from "../actions/baseProducts.actions";
import { fetchCatalog } from "../actions/catalogs.actions";
import { Result } from "../types/enum/result";
import { filtersState } from "../types/filters.types";
import { cleanUpBarcodeProductData, cleanUpNoBarcodeProductData, cleanupStoreProduct } from "../actions/storeProducts/cleanup.actions";
import { promotableProductStore, promoteProductStoreByBarcode } from "../actions/storeProducts/promotion.actions";

export const useStoreProducts = (queryParams:string, defaultParams:string = '') => {
  const [showForm, setShowForm] = useState(false);
  const dispatch = useAppDispatch();
  const storeProductsState: storeProductsState = useSelector(
    (state: RootState) => state.storeProducts
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dateObject = new Date();
  const pageSize = 10000;
  const filtersState: filtersState = useSelector(
    (state: RootState) => state.filters
  );

  // Estos estado controlan los valores que se utilizan en la interacción con el boton de "No promover"
  const [productStoreID, setProductStoreID] = useState("");
  const [isNoPromoteModalVisible, setIsNoPromoteModalVisible] = useState(false);
  const [noPromoteReason, setNoPromoteReason] = useState("");

  // Este estado setea la visualización de la tabla que se muestra cuando se van a promocionar
  // productos con código de barra
  const [showProductStoreByBarcodeTable, setShowProductStoreByBarcodeTable] =
    useState(false);

  // Este estado setea el producto de comercio con código de barra seleccionado a ser promovido
  const [
    selectedProductStoreByBarcodeToBePromoted,
    setSelectedProductStoreByBarcodeToBePromoted,
  ] = useState({
    products: [],
  });

  useEffect(() => {
    dispatch(fetchStoreProducts(1, 20, defaultParams));
    dispatch(fetchStores(1, pageSize));
    dispatch(cleanUpBarcodeProductData());
    dispatch(cleanUpNoBarcodeProductData());
  }, [dispatch]);

  const onClick = () => {
    dispatch(cleanupStoreProduct());
    setShowForm(true);
  };

  const onClickPromoteToExisingBaseProduct = () => {
    navigate("/products-base/promote");
  };

  const showNoPromoteModal = (id: any = null) => {
    setIsNoPromoteModalVisible(true);
    setProductStoreID(id);
  };

  const handleCancel = () => {
    setIsNoPromoteModalVisible(false);
  };

  const onChangeNoPromoteReason = (reason: string) => {
    setNoPromoteReason(reason);
  };

  const onChangeCheckbox = (e: any, record: any) => {
    if (e.target.checked) {
      if (!record.barcode) {
        dispatch(addNoBarcodeProduct(record));
      } else {
        dispatch(addBarcodeProduct(record));
      }
    } else {
      if (!record.barcode) {
        dispatch(removeNoBarcodeProduct(record));
      } else {
        dispatch(removeBarcodeProduct(record));
      }
    }
  };

  const handleRowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedProductStoreByBarcodeToBePromoted({ products: selectedRows });
    },
    type: "radio",
  };

  const date = `${dateObject.getFullYear()}_${
    dateObject.getMonth() + 1
  }_${dateObject.getDate()}`;

  const onClickPromoteToNewBaseProduct = (withBarcode: boolean) => {
    // if (!selectedProductStoreByBarcodeToBePromoted.products.length) {
    //     message.warning('Por favor seleccione un nuevo producto base el cual sera promocionado');
    //     return;
    // }

    dispatch(cleanUpBaseProductFormData());
    dispatch(cleanupBaseProduct());

    let product: any;

    if (!withBarcode) {
      product = storeProductsState.noBarcodeProducts.data[0];
      product.category = {
        ...product.category[0],
      };

      dispatch(setBaseProductFormData(product));
    } else {
      product = selectedProductStoreByBarcodeToBePromoted.products[0];
      product.category = {
        ...product.category[0],
      };

      dispatch(setBaseProductFormData(product));
    }

    dispatch(fetchCatalog(product.category.catalogId));

    navigate("/products-base/add", {
      state: {
        edit: false,
        withBarcode: withBarcode ? true : false,
      },
    });
  };

  const onClickNoPromoteProduct = (productID: string, reason: string) => {
    if (reason) {
      dispatch(promotableProductStore(productID, reason)).then(
        (result: any) => {
          if (result === Result.OK) {
            message.success(
              "El registro ha sido marcado como no promocionable con éxito"
            );
            setIsNoPromoteModalVisible(false);
            dispatch(
              fetchStoreProducts(
                storeProductsState.storeProducts.data.page,
                storeProductsState.storeProducts.data.pageSize
              )
            );
          } else {
            message.error(
              storeProductsState.promoteProductStoreByBarcode.error.data.message
            );
          }
        }
      );
    } else {
      message.warning("Por favor seleccione una razón");
    }
  };

  const onClickPromoteWithBarcode = () => {
    setLoading(true);

    const products: any = storeProductsState.barcodeProducts.data;
    let filteredProducts: any = [];
    let arrayOfPromises: any = [];
    let noPromotedProducts: number = 0;

    products.forEach((p: any) => {
      const catalogId = p.category[0].catalogId;
      const barcode = p.barcode;
      const productAlreadyExistInArray =
        filteredProducts.filter(
          (p: any) => p.storeProductBarcode === barcode
        )[0] || false;

      if (productAlreadyExistInArray) {
        if (productAlreadyExistInArray.catalogId === catalogId) {
          dispatch(removeBarcodeProduct(p));
        } else {
          filteredProducts.push({
            catalogId,
            storeProductBarcode: barcode,
          });
        }
      } else {
        filteredProducts.push({
          catalogId,
          storeProductBarcode: barcode,
        });
      }
    });

    storeProductsState.barcodeProducts.data.forEach((p: any) => {
      const catalogId = p.category[0].catalogId;
      const barcode = p.barcode;
      const body = {
        catalogId,
        storeProductBarcode: barcode,
      };

      arrayOfPromises.push(
        dispatch(promoteProductStoreByBarcode(body)).then((result: any) => {
          if (result === Result.OK) {
            message.success(
              `El registro ha sido promocionado con éxito (Código de barra: ${p.barcode})`
            );

            // Producto que se vaya promocionando se remueve de la lista
            // remove by barcode
            dispatch(removeBarcodeProduct(p));
          } else {
            noPromotedProducts = noPromotedProducts++;
            // message.error(storeProductsState.promoteProductStoreByBarcode.error.data.message + ` (Código de barra: ${p.barcode})`);
            message.error(
              `No se pudo promover el producto (Código de barra: ${p.barcode})`
            );
            setShowProductStoreByBarcodeTable(true);
          }
        })
      );
    });

    Promise.all(arrayOfPromises).then(() => {
      setLoading(false);

      if (noPromotedProducts === 0) {
        dispatch(
          fetchStoreProducts(
            storeProductsState.storeProducts.data.page,
            storeProductsState.storeProducts.data.pageSize
          )
        );
      }
    });
  };

  return {
    storeProductsState,
    pagination: {
      current: storeProductsState.storeProducts.data.page,
      pageSize: storeProductsState.storeProducts.data.pageSize,
      total: storeProductsState.storeProducts.data.totalCount,
      onChange: (page: number, pageSize: number) => {
        dispatch(fetchStoreProducts(page, pageSize, queryParams));
        dispatch(cleanUpBarcodeProductData());
        dispatch(cleanUpNoBarcodeProductData());
      },
    },
    onClick,
    showForm,
    setShowForm,
    loading,
    setLoading,
    productStoreID,
    setProductStoreID,
    isNoPromoteModalVisible,
    setIsNoPromoteModalVisible,
    noPromoteReason,
    setNoPromoteReason,
    showProductStoreByBarcodeTable,
    setShowProductStoreByBarcodeTable,
    selectedProductStoreByBarcodeToBePromoted,
    setSelectedProductStoreByBarcodeToBePromoted,
    onClickPromoteToExisingBaseProduct,
    showNoPromoteModal,
    handleCancel,
    onChangeNoPromoteReason,
    onChangeCheckbox,
    handleRowSelection,
    date,
    onClickPromoteToNewBaseProduct,
    onClickNoPromoteProduct,
    onClickPromoteWithBarcode,
    filtersState,
  };
};
