import Table from "../table/customizedTable/CustomizedTable";
import HelmetTitle from "../other/HelmetTitle";

import { useRegions } from "../../hooks/useRegions";
import RegionsForm from "./RegionsForm";
import ButtonsTable from "../table/customizedTable/ButtonsTable";

const Regions = () => {
  const {
    regionsState,
    pagination,
    handleDelete,
    handleEdit,
    onClick,
    showForm,
    setShowForm,
    onFinish,
    isModalVisible,
    itemID,
    showEnableModal,
    handleCancel,
  } = useRegions();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Código país",
      dataIndex: "countryCode",
      key: "countryCode",
      render: (text:any, record:any, index:any) => {
          return record.country.countryCode;
      }
    },
    {
      title: "Moneda",
      dataIndex: "currency",
      key: "currency",
      render: (text:any, record:any, index:any) => {
        return record.country.currency;
      }
    },
    {
      title: "Provincia / Departamento",
      dataIndex: "provincia/departamento",
      key: "provincia/departamento",
    },
    {
      title: "Municipio / Cantón / Distrito",
      dataIndex: "municipio/canton/distrito",
      key: "municipio/canton/distrito",
    },
    {
      title: "Tamaño",
      dataIndex: "tamano",
      key: "tamano",
    },
    {
      title: "",
      key: "key",
      dataIndex: "key",
      render: (text: any, record: any) => (
        <ButtonsTable
          titleModal="Regiones"
          onClickEdit={() => handleEdit(record)}
          onClickDelete={() => handleDelete(itemID)}
          isVisible={isModalVisible}
          onClickOKModal={() => showEnableModal(record.id)}
          onClickCancelModal={() => handleCancel()}
        />
      ),
    },
  ];

  return (
    <>
      <HelmetTitle
        title="Regiones - Backoffice D&#38;N"
        description="Pantalla para listar, crear, y editar regiones"
      />
      {showForm ? (
        <RegionsForm onFinish={onFinish} setShowForm={setShowForm} />
      ) : (
        <Table
          dataSource={regionsState.regions.data.data}
          columns={columns}
          name="región"
          newItemTitle="Nueva región"
          path="regions"
          onClick={onClick}
          loading={regionsState.regions.loading}
          pagination={pagination}
        />
      )}
    </>
  );
};

export default Regions;
