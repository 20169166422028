import { Col, Row, Space } from "antd";
//import useProductsPromotion from "../../../../hooks/useProductsPromotion";
import useProductsPromotion from "../../../../hooks/useProductPromotion/useProductsPromotion";
import HelmetTitle from "../../../other/HelmetTitle";
import AddProductsModal from "./AddProductsModal";
import { ColumnsAddProductsTable } from "./ColumnsAddProductsTable";
import { ColumnsAddProductsBaseTable } from "./ColumnsAddProductsBaseTable";
import "./ProductsPromotion.css";
import { SimilarProducts } from "./SimilarProducts";
import PromoteResumeModal from "./PromoteResumeModal";
import CreateProductModal from "./createProduct/createProductModal";
import BaseProductsRelated from "./baseProductsRelated/BaseProductsRelated";
import PromotionProducts from "./promotionProducts/PromotionProducts";
import PromotionButtons from "./promotionButtons/PromotionButtons";

const ProductsPromotion = (props: any) => {
  const {
    handleRowSelectionPromotionProducts,
    promotionProducts,
    showAddProductsModal,
    handleCancelAddProductsModal,
    handleShowAddProductsModal,
    onSearchAddProducts,
    storeProductsState,
    searchValue,
    setSearchValue,
    paginationSearch,
    handleRowSelectionAddProductsTable,
    newPromotionProducts,
    addNewPromotionProducts,
    searchParametersList,
    handleRowSelectionBaseProductRelated,
    onClickCreateProduct,
    onClickPromoteProduct,
    showResumeModal,
    resumeModalColumns,
    modalType,
    showCreateProdModal,
    setShowCreateProdModal,
    setShowResumeModal,
  } = useProductsPromotion(props.setRestartSearch);

  const columnsAddProductsTable = ColumnsAddProductsTable();
  const columnsAddProductsBaseTable = ColumnsAddProductsBaseTable();

  return (
    <div className="wrapper-promote">
      <HelmetTitle
        title="Promoción de productos - Backoffice D&#38;N"
        description="Pantalla para listar, y promover productos de tienda"
      />

      <Row gutter={[8, 8]}>
        <Col 
          xs={{ span: 24 }} 
          lg={{ span: 12 }}
        >
          <Row 
            className="section-prod-parent" 
            gutter={[0, 8]}
          >
            <PromotionProducts
              handleShowAddProductsModal={handleShowAddProductsModal}
              promotionProducts={promotionProducts}
              handleRowSelectionPromotionProducts={handleRowSelectionPromotionProducts}
            />
          </Row>

          <Row 
            className="similar-row-parent section-prod-parent" 
            gutter={[0, 8]}
          >
            <SimilarProducts />
          </Row>
        </Col>

        <Col 
          xs={{ span: 24 }} 
          lg={{ span: 12 }} 
          className="product-base-content"
        >
          <BaseProductsRelated
            handleShowAddProductsModal={handleShowAddProductsModal}
            handleRowSelectionBaseProductRelated={handleRowSelectionBaseProductRelated}
          />
        </Col>
      </Row>

      <AddProductsModal
        showAddProductsModal={showAddProductsModal}
        handleCancelAddProductsModal={handleCancelAddProductsModal}
        newPromotionProducts={newPromotionProducts}
        addNewPromotionProducts={addNewPromotionProducts}
        onSearchAddProducts={onSearchAddProducts}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        storeProductsState={storeProductsState}
        searchParametersList={searchParametersList}
        pagination={paginationSearch}
        columnsAddProductsTable={columnsAddProductsTable}
        columnsAddProductsBaseTable={columnsAddProductsBaseTable}
        handleRowSelectionAddProductsTable={handleRowSelectionAddProductsTable}
        modalType={modalType}
      />

      <CreateProductModal
        showCreateProdModal={showCreateProdModal}
        setShowCreateProdModal={setShowCreateProdModal}
        promotionProducts={promotionProducts}
        setShowForm={props.togglePromotionWindow}
        setRestartSearch={props.setRestartSearch}
        setShowResumeModal={setShowResumeModal}
      />

      <PromoteResumeModal
        showResumeModal={showResumeModal}
        dataSource={storeProductsState.promotionProductsResult.data}
        resumeModalColumns={resumeModalColumns}
        togglePromotionWindow={props.togglePromotionWindow}
      />

      <Col span={24} className="d-flex flex-end bar-actions-promote">
        <PromotionButtons
          onClickCreateProduct={onClickCreateProduct}
          onClickPromoteProduct={onClickPromoteProduct}
          cancelPromote={props.cancelPromote}
          togglePromotionWindow={props.togglePromotionWindow}
        />
      </Col>
    </div>
  );
};

export default ProductsPromotion;
