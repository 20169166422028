import React, { useState } from 'react';

import { Button, Col, Row, Form } from "antd";
import HelmetTitle from "../other/HelmetTitle";
import PageTitle from "../other/PageTitle";


import { dataItemsStatistics } from './items';
import "./index.css"
import { SalesByCountry } from './salesByCountry';
import { InputDate } from './inputDate';
import { useStatistics } from '../../hooks/statistics/useStatistics';


export const Statistics = (props: any) => {
    const { indexTab, setIndexTab } = useStatistics()
    return (
        <div >
            <HelmetTitle
                title="Estadísticas - Backoffice D&#38;N"
                description="Estadísticas"
            />
            <>
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 5 }}>
                        <PageTitle title="Estadísticas" />
                    </Col>
                </Row>
                <Row>
                    <Col className='styleTabHeader'>
                        {dataItemsStatistics.map((item: any) => {
                            let classAdd = ""
                            if (item.key === indexTab) classAdd = "selectStatistics"
                            return (
                                <div className={classAdd} key={item.key} onClick={() => setIndexTab(item.key)}>
                                    <h6>{item.name}</h6>
                                </div>
                            )
                        })}
                    </Col>

                    <Col span={24}>
                        {React.createElement(dataItemsStatistics[indexTab].component)}
                    </Col>
                </Row>

            </>
        </div>
    );
};
