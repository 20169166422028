import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  cleanUpProductsNoPromotableData,
  cleanUpProductsPromotedData,
  cleanUpProductsToBePromotedData,
} from "../../actions/storeProducts/cleanup.actions";
import { fetchStoreProducts } from "../../actions/storeProducts/main.actions";
import { promotableProductStore } from "../../actions/storeProducts/promotion.actions";
import { RootState, useAppDispatch } from "../../store/store";
import { Result } from "../../types/enum/result";

import { storeProductsState } from "../../types/storeProducts.types";

const usePromoteFunctions = () => {
  // Estos estado controlan los valores que se utilizan en la interacción con el boton de "No promover"
  const [showPromotionWindow, setShowPromotionWindow] = useState(false);
  const [isNoPromoteModalVisible, setIsNoPromoteModalVisible] = useState(false);
  const [productStoreID, setProductStoreID] = useState("");
  const [noPromoteReason, setNoPromoteReason] = useState("");
  const [promotableButtonIsDisabled, setPromotableButtonIsDisabled] =
    useState(true);
  const [noPromotableButtonIsDisabled, setNoPromotableButtonIsDisabled] =
    useState(true);
  const storeProductsState: storeProductsState = useSelector(
    (state: RootState) => state.storeProducts
  );
  const dispatch = useAppDispatch();

  const promotableButtonActivation = (productsArray: any) => {
    let catalogIds = productsArray.map((obj: any) => obj.category[0].catalogId);
    let productsWithDifferentCatalog = productsArray.find(
      (obj: any) => obj.category[0].catalogId !== catalogIds[0]
    );

    if (!productsWithDifferentCatalog) {
      setPromotableButtonIsDisabled(false);
    } else {
      setPromotableButtonIsDisabled(true);
    }

    return productsWithDifferentCatalog;
  };

  useEffect(() => {
    if (
      storeProductsState.productsToBePromoted.data.length &&
      !storeProductsState.productsPromoted.data.length &&
      !storeProductsState.productsNoPromotable.data.length
    ) {
      let productsWithDifferentCatalog = promotableButtonActivation(
        storeProductsState.productsToBePromoted.data
      );

      if (!productsWithDifferentCatalog) {
        setNoPromotableButtonIsDisabled(false);
      } else {
        setNoPromotableButtonIsDisabled(true);
      }
      return;
    }

    if (
      !storeProductsState.productsToBePromoted.data.length &&
      storeProductsState.productsPromoted.data.length &&
      !storeProductsState.productsNoPromotable.data.length
    ) {
      promotableButtonActivation(storeProductsState.productsPromoted.data);
      return;
    }

    if (
      !storeProductsState.productsToBePromoted.data.length &&
      !storeProductsState.productsPromoted.data.length &&
      storeProductsState.productsNoPromotable.data.length
    ) {
      promotableButtonActivation(storeProductsState.productsNoPromotable.data);
      return;
    }

    setPromotableButtonIsDisabled(true);
    setNoPromotableButtonIsDisabled(true);
  }, [
    storeProductsState.productsToBePromoted.data,
    storeProductsState.productsPromoted.data,
    storeProductsState.productsNoPromotable.data,
  ]);

  const showNoPromoteModal = (id: any = null) => {
    setIsNoPromoteModalVisible(true);
    setProductStoreID(id);
  };

  const handleCancel = () => {
    setIsNoPromoteModalVisible(false);
  };

  const togglePromotionWindow = () => {
    if (showPromotionWindow) {
      dispatch(cleanUpProductsToBePromotedData());
      dispatch(cleanUpProductsPromotedData());
      dispatch(cleanUpProductsNoPromotableData());
    }

    setShowPromotionWindow(!showPromotionWindow);
  };

  const onClickPromoteProducts = () => {
    const promotionProductsConcat =
      storeProductsState.productsToBePromoted.data.concat(
        storeProductsState.productsPromoted.data,
        storeProductsState.productsNoPromotable.data
      );

    const promotionProductsConcatCatalogId = promotionProductsConcat.map(
      (obj: any) => {
        return obj.category[0].catalogId;
      }
    );

    // Check para revisar si los catalogos de los productos son los mismos
    const catalogsAreTheSame =
      promotionProductsConcatCatalogId.filter(
        (value, index, array) => value === array[0]
      ).length === promotionProductsConcatCatalogId.length;

    if (!catalogsAreTheSame) {
      message.warning(
        "Los productos seleccionados deben pertenecer al mismo catálogo, por favor haga uso del filtro de catalogos"
      );
      return;
    } else {
      togglePromotionWindow();
    }
  };

  const onClickNoPromoteProduct = (productID: string, reason: string,params:string="") => {
    if (reason) {
      dispatch(promotableProductStore(productID, reason)).then(
        (result: any) => {
          if (result === Result.OK) {
            message.success(
              "El registro ha sido marcado como no promocionable con éxito"
            );
            setIsNoPromoteModalVisible(false);
            dispatch(
              fetchStoreProducts(
                storeProductsState.storeProducts.data.page,
                storeProductsState.storeProducts.data.pageSize,
                params
              )
            );
          } else {
            message.error(
              storeProductsState.promoteProductStoreByBarcode.error.data.message
            );
          }
        }
      );
    } else {
      message.warning("Por favor seleccione una razón");
    }
  };

  return {
    showPromotionWindow, 
    noPromoteReason,
    setNoPromoteReason,
    productStoreID,
    setProductStoreID,
    isNoPromoteModalVisible,
    promotableButtonIsDisabled,
    setPromotableButtonIsDisabled,
    noPromotableButtonIsDisabled,
    setNoPromotableButtonIsDisabled,
    setIsNoPromoteModalVisible,
    showNoPromoteModal,
    handleCancel,
    onClickPromoteProducts,
    onClickNoPromoteProduct,
    togglePromotionWindow
  };
};
export default usePromoteFunctions;
