import { uuid } from "uuidv4";
import { filtersActionTypes } from "../constants/filters.actionTypes";
import { IError } from "../types/interfaces/error.interface";


let error:IError = {
    status: '',
    data: { 
        timestamp: '', 
        path: '',
        message: ''
    }
};

let exports = {
    data: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

const initialState = {
    storeProducts: [],
    export: {
        loading: false,
        data: exports,
        error: error,
    },
};

const filtersReducer = (state:any = initialState, action:any) => {
    switch (action.type) {
        case filtersActionTypes.FETCH_EXPORT_REQUEST:
            return {
                ...state,
                export: {
                    ...state.export,
                    loading: true
                }
            }; 
        case filtersActionTypes.FETCH_EXPORT_SUCCESS:
            return {
                ...state,
                export: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case filtersActionTypes.CLEAN_UP_EXPORT:
            return {
                ...state,
                export: initialState.export
            };
        case filtersActionTypes.ADD_FILTER:
            return {
                ...state,
                [action.section]: {
                    ...action.fields
                }
            }
        case filtersActionTypes.UPDATE_FILTER:
            let updatedFilters:any = [];

            Object.keys(state).forEach((key:any) => {
                if (key === action.section) {
                    updatedFilters = Object.values(state[key]).map((mapValue:any, index:number) => {
                        return {
                            ...state[key][index],
                            value: action.values[mapValue.name]
                        }
                    })
                }
            });

            return {
                ...state,
                [action.section]: {
                    ...updatedFilters
                }
            }
        default:
            return state;
    }
};

export default filtersReducer;