import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  cleanupBaseProduct,
  cleanUpBaseProductFormData,
  fetchBaseProduct,
  fetchBaseProducts,
} from "../actions/baseProducts.actions";
import { RootState, useAppDispatch } from "../store/store";
import { baseProductsState } from "../types/baseProducts.types";

export const useProductsBase = (
  queryParams: string,
  defaultParams: string = ""
) => {
  const [showForm, setShowForm] = useState(false);
  const dispatch = useAppDispatch();
  const baseProductsState: baseProductsState = useSelector(
    (state: RootState) => state.baseProducts
  );

  useEffect(() => {
    dispatch(fetchBaseProducts(1, 20, defaultParams));
  }, [dispatch]);

  const handleEdit = (id: string) => {
    dispatch(cleanupBaseProduct());
    dispatch(cleanUpBaseProductFormData());
    dispatch(fetchBaseProduct(id));
    setShowForm(true);
  };

  const onClick = () => {
    dispatch(cleanupBaseProduct());
    setShowForm(true);
  };

  const dateObject = new Date();
  const date = `${dateObject.getFullYear()}_${
    dateObject.getMonth() + 1
  }_${dateObject.getDate()}`;

  return {
    baseProductsState,
    pagination: {
      current: baseProductsState.baseProducts.data.page,
      pageSize: baseProductsState.baseProducts.data.pageSize,
      total: baseProductsState.baseProducts.data.totalCount,
      onChange: (page: number, pageSize: number) => {
        dispatch(fetchBaseProducts(page, pageSize, queryParams));
      },
    },
    handleEdit,
    onClick,
    showForm,
    setShowForm,
    date,
  };
};
