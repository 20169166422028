import { combineReducers } from "redux";
import {persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


import auth from '../reducers/auth.reducers';
import stores from '../reducers/store.reducer';
import confPowerBI from '../reducers/confPowerBI.reducer';
import catalogFiles from '../reducers/catalogFiles.reducer';
import uploadFile from '../reducers/upload.reducer';
import countries from '../reducers/countries.reducer';
import regions from '../reducers/regions.reducer';
import users from '../reducers/users.reducer';
import categories from '../reducers/categories.reducer';
import catalogs from '../reducers/catalogs.reducer';
import baseProducts from '../reducers/baseProducts.reducer';
import storeProducts from '../reducers/storeProducts.reducer';
import filters from '../reducers/filters.reducer';
import shortcurt from '../reducers/shortcurt.reducer';
import typemodules from '../reducers/modules.reducers';
import tickets from '../reducers/tickets.reducer';



const persistConfig={
  key:'root',
  storage,
  whitelist:['auth','countries']
}

const authPersistConfig = {
  key: 'typemodules',
  storage: storage,
  blacklist: ['typemodules']
}



const rootReducers = combineReducers({
  //add reducers
  auth,
  confPowerBI,
  catalogFiles,
  uploadFile,
  countries,
  regions,
  users,
  stores,
  categories,
  catalogs,
  baseProducts,
  storeProducts,
  filters,
  shortcurt,
  tickets,
  typemodules: persistReducer(authPersistConfig, typemodules)
});

export default persistReducer(persistConfig,rootReducers);