import { IUsers, IUser, IData } from '../types/users.types';
import { usersActionTypes } from "../constants";
import { v4 as uuid } from "uuid";
import { IError } from "../types/interfaces/error.interface";
import { Action } from "../types/actions.types";
import { usersState } from "../types/users.types";

let error:IError = {
    status: '',
    data: { 
        timestamp: '', 
        path: '',
        message: ''
    }
};

let users:IUsers = {
    data: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

let user:IUser = {
    id: '',
    name: '',
    lastname: '',
    username: '',
    email: '',
    role: '',
};

const initialState = {
    users: {
        loading: false,
        data: users,
        error: error,
    },
    user: {
        loading: false,
        data: user,
        error: error
    }
};

const usersReducer = (state = initialState, action:Action):usersState => {
    switch (action.type) {
        case usersActionTypes.FETCH_USERS_REQUEST:
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: true
                }
            };    
        case usersActionTypes.FETCH_USERS_SUCCESS:
            const data:IData[] = [...action.payload.data];

            data.forEach((item:IData) => {
                const unique_id = uuid();
                item.key = unique_id;
            });

            return {
                ...state,
                users: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case usersActionTypes.FETCH_USERS_FAILURE:
            return {
                ...state,
                users: {
                    loading: false,
                    data: users,
                    error: action.payload
                }
            };
        case usersActionTypes.FETCH_USER_REQUEST:
        case usersActionTypes.CREATE_USER_REQUEST:
        case usersActionTypes.UPDATE_USER_REQUEST:
        case usersActionTypes.DELETE_USER_REQUEST:
        case usersActionTypes.ENABLE_USER_REQUEST:
        case usersActionTypes.DISABLE_USER_REQUEST:
            return {
                ...state,
                user: {
                    ...state.user,
                    loading: true
                }
            };
        case usersActionTypes.ENABLE_USER_SUCCESS:
        case usersActionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                user: {
                    loading: false,
                    data: {
                        ...state.user.data,
                        id: action.payload
                    },
                    error: error
                }
            };
        case usersActionTypes.FETCH_USER_FAILURE:
        case usersActionTypes.CREATE_USER_FAILURE:
        case usersActionTypes.UPDATE_USER_FAILURE:
        case usersActionTypes.DELETE_USER_FAILURE:
        case usersActionTypes.ENABLE_USER_FAILURE:
            return {
                ...state,
                user: {
                    loading: false,
                    data: user,
                    error: action.payload
                }
            };
        case usersActionTypes.FETCH_USER_SUCCESS:
        case usersActionTypes.CREATE_USER_SUCCESS:
        case usersActionTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: {
                    loading: false,
                    data: action.payload,
                    error: error
                }
            };
        case usersActionTypes.USER_CLEAN_UP:
            return {
                ...state,
                user: initialState.user
            };
        case usersActionTypes.DISABLE_USER_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user, 
                    loading:false
                }
            };
        default:
            return state;
    }
};

export default usersReducer;